import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-google";

import { Redirect } from "react-router-dom";
import login from "../assets/login.png";
import logo from "../assets/logo1.png";
import { connect } from "react-redux";
import { auth } from "../actions";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import login_overlay from "../assets/login_overlay.png";

import Spinner from "./spinner";

import styles from "./login.css";

const AnalysisOptions = [
  { key: "HongKong", value: "local", text: "HongKong" },
  { key: "Global", value: "global", text: "Global" },
];

class Login extends Component {
  state = {
    username: "",
    password: "",
    company_code: "",
    loading: false,
    remember: true,
    analysis: "",
  };

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("username", this.state.username);
    formdata.append("password", this.state.password);
    formdata.append("company_code", this.state.company_code);

    this.setState({ loading: true }, () => {
      this.props.login(formdata);
    });
  };
  handleAnalysis = (e, value) => {
    this.setState({ analysis: value.value }, () =>
      console.log("this.state.ana", this.state.analysis)
    );
  };

  render() {
    if (auth.isUserAuthenticated()) {
      return <Redirect to="/location" />;
    }
    console.log(this.props.errors);
    return (
      <Container fluid id="login">
        <Container className="vertical-center">
          <Row className="login_row">
            <Col lg={1}></Col>
            <Col lg={4} className="align-items-center">
              <br />
              <br />
              <h1>Welcome Back</h1>
              <p>Please enter your details to login </p>
              {this.props.errors.length > 0 ? (
                <div className="row" style={{ textAlign: "center" }}>
                  <span style={{ color: "red" }}>
                    {this.props.errors[0].message}
                  </span>
                </div>
              ) : null}
              <br />
              <Form>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  id="form-input-control-username"
                  type="email"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />

                <Form.Label>Password</Form.Label>
                <Form.Control
                  id="form-input-control-password"
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />

                <Form.Label>Company Code</Form.Label>
                <Form.Control
                  id="form-input-control-company"
                  value={this.state.company_code}
                  onChange={(e) =>
                    this.setState({ company_code: e.target.value })
                  }
                />

                <br />
                {this.state.loading && this.props.errors.length == 0 ? (
                  <Button className="login_button">
                    <Spinner />
                  </Button>
                ) : (
                  <div className="d-grid gap-2">
                    <Button
                      className="login_button"
                      onClick={this.onSubmit}
                      variant="secondary"
                      size="lg"
                    >
                      Login
                    </Button>
                  </div>
                )}
              </Form>
              <br />
              Don't have an Account ? <a href="/register">Create an Account</a>
            </Col>
            <Col lg={2}></Col>
            <Col lg={5} id="login_overlay">
              <Image src={login_overlay} />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map((field) => {
      return { field, message: state.auth.errors[field] };
    });
  }

  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (formdata) => {
      return dispatch(auth.login(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
