import React, { Component, useState, useEffect } from "react";
import { Grid, Button, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { project } from "../actions";
import ReportMap from "./report_map";
import "./detailedreport.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import IntenselLogo from "../assets/logo_land.png";
import BlueswanLogo from "../assets/logo1.png";
import NavigationIcon from "../assets/navigation.png";
import OverallIcon from "../assets/overall.png";
import StormIcon from "../assets/storm3.png";
import WindspeedIcon from "../assets/wind2.png";
import TyphoonIcon from "../assets/typhoon2.png";
import RainfallFloodIcon from "../assets/rfl2.png";
import ExtremeHeatIcon from "../assets/eh3.png";
import DroughtIndexIcon from "../assets/droughtImage2.png";
import EmptyIcon from "../assets/EmptyIcon.png";
import FloodHazardInformation from "../assets/flood-hazard.png";
import DetailedReportCover from "../assets/Detailed-Report-Cover.png";
import Spinner from "./loader";
import { Link } from "react-router-dom";
import {
  VictoryBar,
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryTheme,
  VictoryGroup,
  VictoryTooltip,
  VictoryLegend,
  VictoryScatter,
  VictoryStack,
} from "victory";
import mapboxgl from "mapbox-gl";
import OverallMap from "./OverallMap";
import html2pdf from "html2pdf.js";
import Heatmap from "react-simple-heatmap";
import { HeatMap } from "@nivo/heatmap";
mapboxgl.accessToken =
  "pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";

const api_url = process.env.REACT_APP_API_URL;

const data = [
  {
    name: "Jan",
    2050: 590,
    2030: 800,
    amt: 1400,
    2020: 490,
  },
  {
    name: "Feb",
    2050: 868,
    2030: 967,
    amt: 1506,
    2020: 590,
  },
  {
    name: "March",
    2050: 997,
    2030: 1098,
    amt: 989,
    2020: 350,
  },
  {
    name: "April",
    2050: 1080,
    2030: 1200,
    amt: 1228,
    2020: 480,
  },
  {
    name: "May ",
    2050: 1020,
    2030: 1108,
    amt: 1100,
    2020: 460,
  },
  {
    name: "June",
    2050: 500,
    2030: 680,
    amt: 1700,
    2020: 380,
  },
  {
    name: "Jul",
    2050: 600,
    2030: 680,
    amt: 1700,
    2020: 320,
  },
  {
    name: "Aug",
    2050: 300,
    2030: 680,
    amt: 1700,
    2020: 300,
  },
  {
    name: "Sep",
    2050: 500,
    2030: 680,
    amt: 1700,
    2020: 280,
  },
  {
    name: "Oct",
    2050: 600,
    2030: 680,
    amt: 1700,
    2020: 180,
  },
  {
    name: "Nov",
    2050: 300,
    2030: 680,
    amt: 1700,
    2020: 120,
  },
  {
    name: "Dec",
    2050: 700,
    2030: 680,
    amt: 1700,
    2020: 80,
  },
];

let yeardata = [];
let rcpdata = [];
let heatmapdata = [];
let report = [];
let storm_surge_year_data = [];
let storm_surge_rcp_data = [];
let wind_year_data = [];
let typhoon_rcp_data = [];
let typhoon_year_data = [];
let flood_rcp_data = [];
let flood_year_data = [];
let heat_rcp_data = [];
let heat_year_data = [];
let drought = [];
let year_loss_data = [];
let risk_status_data = [];
let assetNames = [];
let country_loss = [];
let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
let commonProperties = {};

function FloodMap(props) {
  let [floodMap, setFloodMap] = useState("");
  useEffect(() => {
    setFloodMap(props.mapimage);
  });
  return (
    <div id="floodmap-container">
      <img src={`data:image/jpeg;base64,${floodMap}`} />
    </div>
  );
}

function ExtremeHeatMap(props) {
  let [extremeHeatMap, setExtremeHeatMap] = useState("");
  useEffect(() => {
    setExtremeHeatMap(props.mapimage);
  });
  return (
    <div id="extremeheatmap-container">
      <img src={`data:image/jpeg;base64,${extremeHeatMap}`} />
    </div>
  );
}

class DetailedReport extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    detail: "",
    heatmap: "",
    winddata: "",
    risk_scores: "",
    countrywiseloss: "",
    overallmap: "",
    assetmap: "",
  };

  componentDidMount() {
    yeardata = [];
    rcpdata = [];
    heatmapdata = [];
    storm_surge_year_data = [];
    storm_surge_rcp_data = [];
    wind_year_data = [];
    typhoon_rcp_data = [];
    typhoon_year_data = [];
    flood_rcp_data = [];
    flood_year_data = [];
    heat_rcp_data = [];
    heat_year_data = [];
    drought = [];
    report = [];
    year_loss_data = [];
    risk_status_data = [];
    assetNames = [];
    country_loss = [];
    let formdata = new FormData();
    formdata.append("portfolio_name", this.props.location.state.portfolio);
    this.props.getDetailedReport(formdata);
    this.props.getWindReport(formdata);
    this.props.getLossReport(formdata);
    this.props.getCountryWiseLossReport(formdata);
    // this.props.getOverallMap(formdata);
    let formdata2 = new FormData();
    formdata.append("portfolio", this.props.location.state.portfolio);
    this.props.getAssetMap(formdata);
  }
  printDocument() {
    window.print();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detailreport != this.props.detailreport) {
      this.setState(
        {
          detail: this.props.detailreport.data,
          heatmap: this.props.detailreport.heatmaps,
          risk_scores: this.props.detailreport.risk_score,
        },
        () =>
          console.log("detailedreport", this.state.heatmap["RCP26-Year 2030"])
      );
    }
    if (prevProps.windreport != this.props.windreport) {
      this.setState({ winddata: this.props.windreport });
    }
    if (prevProps.countrywiselossreport != this.props.countrywiselossreport) {
      this.setState({
        countrywiseloss: this.props.countrywiselossreport,
      });
    }
    if (prevProps.overallmapimage != this.props.overallmapimage) {
      this.setState({
        overallmap: this.props.overallmapimage["map"],
      });
    }
    if (prevProps.assetmapimage != this.props.assetmapimage) {
      this.setState({
        assetmap: this.props.assetmapimage,
      });
    }
  }
  getFloodandStormRiskStatus(value) {
    if (value == 0) {
      return (
        <span style={{ color: "green" }}>
          <strong>No Risk</strong>
        </span>
      );
    } else if (value > 0 && value < 0.5) {
      return (
        <span style={{ color: "yellowgreen" }}>
          <strong>Low Risk</strong>
        </span>
      );
    } else if (value >= 0.5 && value < 1) {
      return (
        <span style={{ color: "yellow" }}>
          <strong>Medium Risk</strong>
        </span>
      );
    } else if (value >= 1 && value < 2) {
      return (
        <span style={{ color: "orange" }}>
          <strong>Medium-High Risk</strong>
        </span>
      );
    } else if (value >= 2 && value <= 5) {
      return (
        <span style={{ color: "red" }}>
          <strong>High Risk</strong>
        </span>
      );
    } else if (value > 5) {
      return (
        <span style={{ color: "darkred" }}>
          <strong>Very High Risk</strong>
        </span>
      );
    }
  }

  getWindandTyphoonRiskStatus(value) {
    if (value < 75) {
      return (
        <span style={{ color: "green" }}>
          <strong>No Risk</strong>
        </span>
      );
    } else if (value >= 75 && value < 95) {
      return (
        <span style={{ color: "yellowgreen" }}>
          <strong>Low Risk</strong>
        </span>
      );
    } else if (value >= 95 && value < 130) {
      return (
        <span style={{ color: "#c49c23" }}>
          <strong>Medium Risk</strong>
        </span>
      );
    } else if (value >= 130 && value < 156) {
      return (
        <span style={{ color: "orange" }}>
          <strong>Medium-High Risk</strong>
        </span>
      );
    } else if (value >= 156 && value <= 220) {
      return (
        <span style={{ color: "red" }}>
          <strong>High Risk</strong>
        </span>
      );
    } else if (value > 220) {
      return (
        <span style={{ color: "darkred" }}>
          <strong>Very High Risk</strong>
        </span>
      );
    }
  }

  getHeatRiskStatus(value) {
    if (value >= 0 && value < 27) {
      return (
        <span style={{ color: "green" }}>
          <strong>No Risk</strong>
        </span>
      );
    } else if (value >= 27 && value < 32) {
      return (
        <span style={{ color: "yellowgreen" }}>
          <strong>Low Risk</strong>
        </span>
      );
    } else if (value >= 32 && value < 41) {
      return (
        <span style={{ color: "#c49c23" }}>
          <strong>Medium Risk</strong>
        </span>
      );
    } else if (value >= 41 && value < 54) {
      return (
        <span style={{ color: "orange" }}>
          <strong>Medium-High Risk</strong>
        </span>
      );
    } else if (value >= 54 && value <= 65) {
      return (
        <span style={{ color: "red" }}>
          <strong>High Risk</strong>
        </span>
      );
    } else if (value > 65) {
      return (
        <span style={{ color: "darkred" }}>
          <strong>Very High Risk</strong>
        </span>
      );
    }
  }

  getDroughtRiskStatus(value) {
    if (value > 1566) {
      return (
        <span style={{ color: "green" }}>
          <strong>No Risk</strong>
        </span>
      );
    } else if (value >= 1044 && value <= 1566) {
      return (
        <span style={{ color: "yellowgreen" }}>
          <strong>Low Risk</strong>
        </span>
      );
    } else if (value >= 522 && value < 1044) {
      return (
        <span style={{ color: "#c49c23" }}>
          <strong>Medium Risk</strong>
        </span>
      );
    } else if (value >= 200 && value < 522) {
      return (
        <span style={{ color: "orange" }}>
          <strong>Medium-High Risk</strong>
        </span>
      );
    } else if (value >= 100 && value < 200) {
      return (
        <span style={{ color: "red" }}>
          <strong>High Risk</strong>
        </span>
      );
    } else if (value < 100) {
      return (
        <span style={{ color: "darkred" }}>
          <strong>Very High Risk</strong>
        </span>
      );
    }
  }

  render() {
    yeardata = [];
    rcpdata = [];
    heatmapdata = [];
    storm_surge_year_data = [];
    storm_surge_rcp_data = [];
    wind_year_data = [];
    typhoon_rcp_data = [];
    typhoon_year_data = [];
    flood_rcp_data = [];
    flood_year_data = [];
    heat_rcp_data = [];
    heat_year_data = [];
    drought = [];
    report = [];
    year_loss_data = [];
    risk_status_data = [];
    assetNames = [];
    country_loss = [];

    const backgroundColors = ["#d1f4ff", "#fff1c9", "#d6ffdf"];
    if (this.state.detail.length > 0 && this.state.winddata.length > 0) {
      let keysForCountryLoss = [];
      keysForCountryLoss = Object.keys(this.state.countrywiseloss);
      for (let i = 0; i < keysForCountryLoss.length; i++) {
        country_loss.push({
          name: keysForCountryLoss[i],
          RVFL: this.state.countrywiseloss[keysForCountryLoss[i]][
            "River Flood"
          ],
          RFL: this.state.countrywiseloss[keysForCountryLoss[i]][
            "Rainfall Flood"
          ],
          SS: this.state.countrywiseloss[keysForCountryLoss[i]]["Storm Surge"],
          TY: this.state.countrywiseloss[keysForCountryLoss[i]]["Typhoon"],
          EH: this.state.countrywiseloss[keysForCountryLoss[i]]["Extreme Heat"],
        });
      }

      let n = this.state.heatmap["RCP 85 - Year 2050"].names.length;
      commonProperties = {
        width: 400,
        height: n > 1 ? n * 50 : 100,
      };

      for (let i = 0; i < this.state.detail.length; i++) {
        storm_surge_year_data.push({
          name: this.state.detail[i].name,
          2020: this.state.detail[i]["SS2620"],
          2030: this.state.detail[i]["SS8550"],
          2050: this.state.detail[i]["SS8570"],
        });

        storm_surge_rcp_data.push({
          name: this.state.detail[i].name,
          "RCP 2.6": this.state.detail[i]["SS2670"],
          "RCP 4.5": this.state.detail[i]["SS4570"],
          "RCP 8.5": this.state.detail[i]["SS8570"],
        });

        yeardata.push({
          name: this.state.detail[i].name,
          2020: this.state.detail[i]["SS0020"],
          2030: this.state.detail[i]["SS8550"],
          2050: this.state.detail[i]["SS8570"],
        });
        rcpdata.push({
          name: this.state.detail[i].name,
          "RCP 2.6": this.state.detail[i]["SS2670"],
          "RCP 4.5": this.state.detail[i]["SS4570"],
          "RCP 8.5": this.state.detail[i]["SS8570"],
        });

        heatmapdata.push({
          asset: this.state.heatmap["RCP 85 - Year 2050"].names[i]
            .replace(/[,-.]/g, "")
            .split(" ")
            .slice(0, 2)
            .join(" "),
          Landslide:
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["Landslide"],
          "River Flood":
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["River Flood"],
          Drought:
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["Drought"],
          "Storm Surge":
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["Storm Surge"],
          "Extreme Heat":
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["Extreme Heat"],
          "Snow Melt":
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["Snow Melt"],
          "Rainfall Flood":
            this.state.heatmap["RCP 85 - Year 2050"].values[i][
              "Rainfall Flood"
            ],
          Typhoon:
            this.state.heatmap["RCP 85 - Year 2050"].values[i]["Typhoon"],
          "Sea Level Rise":
            this.state.heatmap["RCP 85 - Year 2050"].values[i][
              "Sea Level Rise"
            ],
        });

        let temp_wind_year_data = [];

        for (let j = 0; j < 12; j++) {
          temp_wind_year_data.push({
            name: months[j],
            2020: this.state.winddata[i]["RCP85-2020"][j],
            2030: this.state.winddata[i]["RCP85-2030"][j],
            2050: this.state.winddata[i]["RCP85-2050"][j],
          });
        }

        wind_year_data.push(temp_wind_year_data);

        typhoon_rcp_data.push({
          name: this.state.winddata[i].name,
          "RCP 2.6": this.state.winddata[i]["TY2670"],
          "RCP 4.5": this.state.winddata[i]["TY4570"],
          "RCP 8.5": this.state.winddata[i]["TY8570"],
        });

        typhoon_year_data.push({
          name: this.state.winddata[i].name,
          2020: this.state.winddata[i]["TY0020"],
          2030: this.state.winddata[i]["TY8550"],
          2050: this.state.winddata[i]["TY8570"],
        });

        let tempNameArr = this.state.detail[i].name
          .replace(/[,-.]/g, "")
          .split(" ")
          .slice(0, 2)
          .join(" ");
        let tempName = "";
        for (let i = 0; i < tempNameArr.length; i++) {
          tempName += tempNameArr[i][0];
        }
        year_loss_data.push({
          originalName: this.state.detail[i].name,
          name: tempName,
          2020: this.state.detail[i]["TO2620"],
          2030: this.state.detail[i]["TO8550"],
          RCP2670: this.state.detail[i]["TO2670"],
          RCP4570: this.state.detail[i]["TO4570"],
          2050: this.state.detail[i]["TO8570"],
          "2030_loss": this.state.detail[i]["TO8550"],
          "2050_loss": this.state.detail[i]["TO8570"],
          "2030_risk":
            this.state.risk_scores[this.state.detail[i].name]["85-50"][
              "OVERALL"
            ],
          "2050_risk":
            this.state.risk_scores[this.state.detail[i].name]["85-70"][
              "OVERALL"
            ],
        });

        flood_rcp_data.push({
          name: this.state.winddata[i].name,
          "RCP 2.6": this.state.winddata[i]["RFL2670"],
          "RCP 4.5": this.state.winddata[i]["RFL4570"],
          "RCP 8.5": this.state.winddata[i]["RFL8570"],
        });
        flood_year_data.push({
          name: this.state.winddata[i].name,
          2020: this.state.winddata[i]["RFL0020"],
          2030: this.state.winddata[i]["RFL8550"],
          2050: this.state.winddata[i]["RFL8570"],
        });
        heat_rcp_data.push({
          name: this.state.winddata[i].name,
          "RCP 2.6": this.state.winddata[i]["EH2670"],
          "RCP 4.5": this.state.winddata[i]["EH4570"],
          "RCP 8.5": this.state.winddata[i]["EH8570"],
        });
        heat_year_data.push({
          name: this.state.winddata[i].name,
          2020: this.state.winddata[i]["EH0020"],
          2030: this.state.winddata[i]["EH8550"],
          2050: this.state.winddata[i]["EH8570"],
        });
        drought.push({
          name: this.state.winddata[i].name,
          2020: this.state.winddata[i]["Drought Index Year-2020"],
          2030: this.state.winddata[i]["Drought Index Year-2030"],
          2050: this.state.winddata[i]["Drought Index Year-2050"],
        });

        assetNames.push({
          asset_name: this.state.detail[i]["name"],
          asset_link: `detailed-report-${this.state.detail[i]["name"]
            .replace(/[,-.]/g, "")
            .split(" ")
            .join("-")}`,
        });

        risk_status_data.push({
          storm: this.state.detail[i].hasOwnProperty("SS8570")
            ? this.state.detail[i]["SS8570"]
            : "Not Available",
          typhoon: this.state.detail[i].hasOwnProperty("TY8570")
            ? this.state.detail[i]["TY8570"]
            : "Not Available",
          flood: this.state.detail[i].hasOwnProperty("RFL8570")
            ? this.state.detail[i]["RFL8570"]
            : "Not Available",
          heat: this.state.detail[i].hasOwnProperty("EH8570")
            ? this.state.detail[i]["EH8570"]
            : "Not Available",
          drought: this.state.detail[i].hasOwnProperty("DY8570")
            ? this.state.detail[i]["DY8570"]
            : "Not Available",
        });

        report.push(
          <div
            id={`detailed-report-${this.state.detail[i]["name"]
              .replace(/[,-.]/g, "")
              .split(" ")
              .join("-")}`}
            className="detailed-report"
          >
            {/* <div className="detailed-report-page-break"></div> */}
            <div
              className="detailed-report-column detailed-report-main-column"
              style={{ backgroundColor: backgroundColors[i % 3] }}
            >
              <div className="detailed-report-main-title-header">
                <img src={IntenselLogo} />
                <img src={BlueswanLogo} />
              </div>
              <h1>
                <strong>Property Climate Risk Report</strong>
              </h1>
              <div className="detailed-report-main-map">
                <ReportMap
                  data={i}
                  lat={this.state.detail[i]["latitude"]}
                  long={this.state.detail[i]["longitude"]}
                />
              </div>
              <div className="detailed-report-main-details">
                <div className="detailed-report-main-asset-details">
                  <h3>Asset Details:</h3>
                  <p>
                    Asset Name: {this.state.detail[i]["name"]}
                    <br />
                    Latitude: {this.state.detail[i]["latitude"]}
                    <br />
                    Longitude: {this.state.detail[i]["longitude"]}
                  </p>
                </div>
                <div className="detailed-report-main-variables-details">
                  <h3>Climate Variables in Analysis:</h3>
                  <p>
                    <ul>
                      <li>
                        Storm Surge:{" "}
                        {risk_status_data[i]["storm"] != "Not Available"
                          ? this.getFloodandStormRiskStatus(
                              risk_status_data[i]["storm"]
                            )
                          : "Not Available"}
                      </li>
                      <li>
                        Wind Speed:{" "}
                        {risk_status_data[i]["typhoon"] != "Not Available"
                          ? this.getWindandTyphoonRiskStatus(
                              risk_status_data[i]["typhoon"]
                            )
                          : "Not Available"}
                      </li>
                      <li>
                        Typhoon:{" "}
                        {risk_status_data[i]["typhoon"] != "Not Available"
                          ? this.getWindandTyphoonRiskStatus(
                              risk_status_data[i]["typhoon"]
                            )
                          : "Not Available"}
                      </li>
                      <li>
                        Rainfall Flood:{" "}
                        {risk_status_data[i]["flood"] != "Not Available"
                          ? this.getFloodandStormRiskStatus(
                              risk_status_data[i]["flood"]
                            )
                          : "Not Available"}
                      </li>
                      <li>
                        Extreme Heat:{" "}
                        {risk_status_data[i]["heat"] != "Not Available"
                          ? this.getHeatRiskStatus(risk_status_data[i]["heat"])
                          : "Not Available"}
                      </li>
                      <li>
                        Drought Index:{" "}
                        {risk_status_data[i]["drought"] != "Not Available"
                          ? this.getDroughtRiskStatus(
                              risk_status_data[i]["drought"]
                            )
                          : "Not Available"}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-storm-surge-column">
              <div className="detailed-report-title detailed-report-storm-surge-title">
                <img src={StormIcon} />
                <h2>Storm Surge</h2>
              </div>
              <div className="detailed-report-storm-surge-description">
                <p>
                  Extreme water Level height from storm tides, sea level rise,
                  and change in land movement (either rising, falling or stable)
                  also known as Still High Water Level at the Coast is the
                  technical term for the potential height of ocean water levels
                  during storms. These high water events then have potential to
                  move inland and impact the built and natural environment
                  through what is commonly referred to as a Storm Surge.
                </p>
                <p>
                  Often the costal extreme sea level elevations (in addition to
                  sea level rise) arise with a confluence of events such as
                  exceptional seasonal high tides, wind and waves associated
                  with tropical depressions or extratropical low pressure
                  systems and coastal bathymetry (the depth to the ocean floor).
                </p>
              </div>
              <div className="detailed-report-storm-surge-information">
                {storm_surge_year_data[i]["2020"] +
                  storm_surge_year_data[i]["2030"] +
                  storm_surge_year_data[i]["2050"] +
                  storm_surge_rcp_data[i]["RCP 2.6"] +
                  storm_surge_rcp_data[i]["RCP 4.5"] +
                  storm_surge_rcp_data[i]["RCP 8.5"] >
                0 ? (
                  <>
                    <div className="detailed-report-storm-surge-information-chart">
                      {yeardata ? (
                        storm_surge_year_data[i]["2020"] +
                          storm_surge_year_data[i]["2030"] +
                          storm_surge_year_data[i]["2050"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Storm Surge Year Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={100}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                { name: "2020", symbol: { fill: "#73a2ff" } },
                                { name: "2030", symbol: { fill: "#0055ff" } },
                                { name: "2050", symbol: { fill: "#002b82" } },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[storm_surge_year_data[i]]}
                                x="name"
                                y="2020"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[storm_surge_year_data[i]]}
                                x="name"
                                y="2030"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[storm_surge_year_data[i]]}
                                x="name"
                                y="2050"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-storm-surge-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>No Storm Surge detected at this property.</h4>
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className="detailed-report-storm-surge-information-chart">
                      {rcpdata ? (
                        storm_surge_rcp_data[i]["RCP 2.6"] +
                          storm_surge_rcp_data[i]["RCP 4.5"] +
                          storm_surge_rcp_data[i]["RCP 8.5"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Storm Surge RCP Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={70}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                {
                                  name: "RCP 2.6",
                                  symbol: { fill: "#73a2ff" },
                                },
                                {
                                  name: "RCP 4.5",
                                  symbol: { fill: "#0055ff" },
                                },
                                {
                                  name: "RCP 8.5",
                                  symbol: { fill: "#002b82" },
                                },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[storm_surge_rcp_data[i]]}
                                x="name"
                                y="RCP 2.6"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 2.6: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[storm_surge_rcp_data[i]]}
                                x="name"
                                y="RCP 4.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 4.5: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[storm_surge_rcp_data[i]]}
                                x="name"
                                y="RCP 8.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 8.5: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-storm-surge-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>No Storm Surge detected at this property.</h4>
                          </div>
                        )
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className="detailed-report-storm-surge-information-chart-not-available">
                    <img src={EmptyIcon} />
                    <h4>No Storm Surge detected at this property.</h4>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-windspeed-column">
              <div className="detailed-report-title detailed-report-windspeed-title">
                <img src={WindspeedIcon} />
                <h2>Wind</h2>
              </div>

              <div className="detailed-report-windspeed-information">
                <p>
                  Extreme wind speeds, as presented in this report, could be
                  derived from hurricanes, typhoons and other tropical
                  depressions, downslope or dericho winds BUT NOT
                  tornado-related winds.
                </p>

                <div style={{ width: "100%" }}>
                  <VictoryChart
                    padding={{ top: 110, left: 70, bottom: 50, right: 10 }}
                    width={900}
                    height={600}
                    theme={VictoryTheme.material}
                  >
                    <VictoryLabel
                      text="Wind Data"
                      x={450}
                      y={15}
                      textAnchor="middle"
                      style={{ fontSize: 30 }}
                    />
                    <VictoryLegend
                      x={550}
                      y={40}
                      title="Legend"
                      centerTitle
                      orientation="horizontal"
                      gutter={20}
                      symbolSpacer={7}
                      style={{
                        border: { stroke: "black" },
                        title: { fontSize: 20 },
                        labels: { fontSize: 16 },
                      }}
                      data={[
                        { name: "2020", symbol: { fill: "#73a2ff" } },
                        { name: "2030", symbol: { fill: "#0055ff" } },
                        { name: "2050", symbol: { fill: "#002b82" } },
                        {
                          name: "2030",
                          symbol: { fill: "#c43a31", type: "minus" },
                        },
                        {
                          name: "2050",
                          symbol: { fill: "#c28a31", type: "minus" },
                        },
                      ]}
                    />
                    <VictoryAxis
                      style={{
                        axisLabel: { fontSize: 20 },
                        tickLabels: { fontSize: 18 },
                      }}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickFormat={(x) => x}
                      axisLabelComponent={<VictoryLabel dy={-40} />}
                      label="Wind speed (in kmph)"
                      style={{
                        axisLabel: { fontSize: 20 },
                        tickLabels: { fontSize: 18 },
                      }}
                    />
                    <VictoryGroup
                      offset={20}
                      colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                    >
                      <VictoryBar
                        data={wind_year_data[i]}
                        x="name"
                        y="2020"
                        labelComponent={
                          <VictoryTooltip
                            flyoutPadding={15}
                            cornerRadius={0}
                            flyoutStyle={{ fill: "white" }}
                          />
                        }
                        labels={({ datum }) => {
                          return `${datum.name} 2020: ${datum["2020"]}`;
                        }}
                      />
                      <VictoryBar
                        data={wind_year_data[i]}
                        x="name"
                        y="2030"
                        labelComponent={
                          <VictoryTooltip
                            flyoutPadding={15}
                            cornerRadius={0}
                            flyoutStyle={{ fill: "white" }}
                          />
                        }
                        labels={({ datum }) => {
                          return `${datum.name} 2030: ${datum["2030"]}`;
                        }}
                      />
                      <VictoryBar
                        data={wind_year_data[i]}
                        x="name"
                        y="2050"
                        labelComponent={
                          <VictoryTooltip
                            flyoutPadding={15}
                            cornerRadius={0}
                            flyoutStyle={{ fill: "white" }}
                          />
                        }
                        labels={({ datum }) => {
                          return `${datum.name} 2050: ${datum["2050"]}`;
                        }}
                      />
                    </VictoryGroup>
                    <VictoryLine
                      data={wind_year_data[i]}
                      x="name"
                      y="2030"
                      style={{
                        parent: {
                          border: "1px solid #ccc",
                        },
                        data: {
                          stroke: "#c43a31",
                          strokeWidth: 3,
                        },
                      }}
                      labelComponent={
                        <VictoryTooltip
                          flyoutPadding={15}
                          cornerRadius={0}
                          flyoutStyle={{ fill: "white" }}
                        />
                      }
                      labels={({ datum }) => {
                        return `${datum.name} 2030: ${datum["2030"]}`;
                      }}
                    />
                    <VictoryLine
                      data={wind_year_data[i]}
                      x="name"
                      y="2050"
                      style={{
                        parent: {
                          border: "1px solid #ccc",
                        },
                        data: {
                          stroke: "#c28a31",
                          strokeWidth: 3,
                        },
                      }}
                      labelComponent={
                        <VictoryTooltip
                          flyoutPadding={15}
                          cornerRadius={0}
                          flyoutStyle={{ fill: "white" }}
                        />
                      }
                      labels={({ datum }) => {
                        return `${datum.name} 2050: ${datum["2050"]}`;
                      }}
                    />
                  </VictoryChart>
                </div>
                <p>
                  The extreme daily wind speed is in kilometres or miles per
                  hour from the baseline (1995) with 100-year return periods and
                  future year time-steps for your location.
                  <br />
                  With a risk score at 2 or above you may consider the following
                  adaptation methods: Install impact-resistant windows. Avoid
                  roof overhangs, particularly those facing the prevailing
                  winds. Design your doors to be outward-opening. Build
                  hurricane-resistant walls. Install rain-screens in vulnerable
                  areas (such as coastal regions). Consider a back-up generator
                  or renewable site-generated electricity.
                </p>
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-typhoon-column">
              <div className="detailed-report-title detailed-report-typhoon-title">
                <img src={TyphoonIcon} />
                <h2>Typhoon</h2>
              </div>
              <div className="detailed-report-typhoon-information">
                {typhoon_year_data[i]["2020"] +
                  typhoon_year_data[i]["2030"] +
                  typhoon_year_data[i]["2050"] +
                  typhoon_rcp_data[i]["RCP 2.6"] +
                  typhoon_rcp_data[i]["RCP 4.5"] +
                  typhoon_rcp_data[i]["RCP 8.5"] >
                0 ? (
                  <>
                    <div className="detailed-report-typhoon-information-chart">
                      {yeardata ? (
                        typhoon_year_data[i]["2020"] +
                          typhoon_year_data[i]["2030"] +
                          typhoon_year_data[i]["2050"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Typhoon Year Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={100}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                { name: "2020", symbol: { fill: "#73a2ff" } },
                                { name: "2030", symbol: { fill: "#0055ff" } },
                                { name: "2050", symbol: { fill: "#002b82" } },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[typhoon_year_data[i]]}
                                x="name"
                                y="2020"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[typhoon_year_data[i]]}
                                x="name"
                                y="2030"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2030: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[typhoon_year_data[i]]}
                                x="name"
                                y="2050"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2050: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-typhoon-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>No Typhoon detected at this property.</h4>
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className="detailed-report-typhoon-information-chart">
                      {rcpdata ? (
                        typhoon_rcp_data[i]["RCP 2.6"] +
                          typhoon_rcp_data[i]["RCP 4.5"] +
                          typhoon_rcp_data[i]["RCP 8.5"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Typhoon RCP Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={70}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                {
                                  name: "RCP 2.6",
                                  symbol: { fill: "#73a2ff" },
                                },
                                {
                                  name: "RCP 4.5",
                                  symbol: { fill: "#0055ff" },
                                },
                                {
                                  name: "RCP 8.5",
                                  symbol: { fill: "#002b82" },
                                },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[typhoon_rcp_data[i]]}
                                x="name"
                                y="RCP 2.6"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 2.6: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[typhoon_rcp_data[i]]}
                                x="name"
                                y="RCP 4.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 4.5: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[typhoon_rcp_data[i]]}
                                x="name"
                                y="RCP 8.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 8.5: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-typhoon-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>No Typhoon detected at this property.</h4>
                          </div>
                        )
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className="detailed-report-typhoon-information-chart-not-available">
                    <img src={EmptyIcon} />
                    <h4>No Typhoon detected at this property.</h4>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-rainfall-flood-column">
              <div className="detailed-report-title detailed-report-rainfall-flood-title">
                <img src={RainfallFloodIcon} />
                <h2>Rainfall Flood</h2>
              </div>
              <div className="detailed-report-rainfall-flood-map">
                <FloodMap
                  mapimage={
                    this.state.assetmap == ""
                      ? undefined
                      : this.state.assetmap[this.state.detail[i].name]["flood"]
                  }
                  key={i}
                />
              </div>
              <div className="detailed-report-rainfall-flood-description">
                <p>
                  The flood hazard has been classified in 5 categories H1,H2,
                  H3, H4 and H5:
                  <ul className="detailed-report-rainfall-flood-description-list">
                    <li>
                      <strong>H1 Flood Hazard (depth less than 0.5 m)</strong>:
                      H1 flood hazard or also titled very low hazard zone. Where
                      people do notexposed to any hazard and people can evacuate
                      on foot easily.
                    </li>
                    <li>
                      <strong>H2 Flood Hazard (depth 0.5–1 m)</strong>: H2 flood
                      hazard also known as low hazard zone where people may get
                      exposed to hazard especially children’s, cattle’s and
                      animals, in this, evacuation on foot for adults become
                      difficult.
                    </li>
                    <li>
                      <strong>H3 Flood Hazard (depth 1–2 m)</strong>: In H3 high
                      flood hazard, people may get drowned in this zone but they
                      are safe in their homes where plinth level is 1–1.2 m
                      above ground level.
                    </li>
                    <li>
                      <strong>H4 Flood Hazard (depth 2–5 m)</strong>: H4 flood
                      hazard is a very high flood hazard where people are not
                      safe even inside their house so in this zone people should
                      make their way towards roof for safety purpose.
                    </li>
                    <li>
                      <strong>H5 Flood Hazard</strong>: H5 extreme flood
                      hazard/Catastrophic is the zone where single storied house
                      may drowned and people are not at all safe in this
                      scenario even if they make their way towards the roof.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-rainfall-flood-column">
              <div className="detailed-report-rainfall-flood-description">
                <img
                  className="detailed-report-rainfall-flood-description-flood-hazard-information"
                  src={FloodHazardInformation}
                />
                <Table
                  celled
                  selectable
                  textAlign="center"
                  size="small"
                  compact="very"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Depth</Table.HeaderCell>
                      <Table.HeaderCell>Risk Severity</Table.HeaderCell>
                      <Table.HeaderCell>Risk Score</Table.HeaderCell>
                      <Table.HeaderCell>Increase in 0.1m Rise</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle">0</Table.Cell>
                      <Table.Cell verticalAlign="middle">Zero Risk</Table.Cell>
                      <Table.Cell verticalAlign="middle">0</Table.Cell>
                      <Table.Cell verticalAlign="middle">0</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle">0.5</Table.Cell>
                      <Table.Cell verticalAlign="middle">Low Risk</Table.Cell>
                      <Table.Cell verticalAlign="middle">25</Table.Cell>
                      <Table.Cell verticalAlign="middle">0+5</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle">0.5-1</Table.Cell>
                      <Table.Cell verticalAlign="middle">
                        Medium Risk
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle">50</Table.Cell>
                      <Table.Cell verticalAlign="middle">25+(5.25)</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle">1-2</Table.Cell>
                      <Table.Cell verticalAlign="middle">
                        Medium High Risk
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle">75</Table.Cell>
                      <Table.Cell verticalAlign="middle">50+(1.92)</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle">2-5</Table.Cell>
                      <Table.Cell verticalAlign="middle">High Risk</Table.Cell>
                      <Table.Cell verticalAlign="middle">100</Table.Cell>
                      <Table.Cell verticalAlign="middle">75+(2.27)</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle">&gt;5</Table.Cell>
                      <Table.Cell verticalAlign="middle">
                        Very High Risk
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle">100</Table.Cell>
                      <Table.Cell verticalAlign="middle">100</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
              <div className="detailed-report-rainfall-flood-information">
                {flood_year_data[i]["2020"] +
                  flood_year_data[i]["2030"] +
                  flood_year_data[i]["2050"] +
                  flood_rcp_data[i]["RCP 2.6"] +
                  flood_rcp_data[i]["RCP 4.5"] +
                  flood_rcp_data[i]["RCP 8.5"] >
                0 ? (
                  <>
                    <div className="detailed-report-rainfall-flood-information-chart">
                      {yeardata ? (
                        flood_year_data[i]["2020"] +
                          flood_year_data[i]["2030"] +
                          flood_year_data[i]["2050"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Flood Year Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={100}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                { name: "2020", symbol: { fill: "#73a2ff" } },
                                { name: "2030", symbol: { fill: "#0055ff" } },
                                { name: "2050", symbol: { fill: "#002b82" } },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                              label="Flood Year Data"
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[flood_year_data[i]]}
                                x="name"
                                y="2020"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[flood_year_data[i]]}
                                x="name"
                                y="2030"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[flood_year_data[i]]}
                                x="name"
                                y="2050"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-rainfall-flood-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>
                              No Rainfall Flood detected at this property.
                            </h4>
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className="detailed-report-rainfall-flood-information-chart">
                      {rcpdata ? (
                        flood_rcp_data[i]["RCP 2.6"] +
                          flood_rcp_data[i]["RCP 4.5"] +
                          flood_rcp_data[i]["RCP 8.5"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Flood RCP Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={70}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                {
                                  name: "RCP 2.6",
                                  symbol: { fill: "#73a2ff" },
                                },
                                {
                                  name: "RCP 4.5",
                                  symbol: { fill: "#0055ff" },
                                },
                                {
                                  name: "RCP 8.5",
                                  symbol: { fill: "#002b82" },
                                },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                              label="Flood RCP Data"
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[flood_rcp_data[i]]}
                                x="name"
                                y="RCP 2.6"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 2.6: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[flood_rcp_data[i]]}
                                x="name"
                                y="RCP 4.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 4.5: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[flood_rcp_data[i]]}
                                x="name"
                                y="RCP 8.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 8.5: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-rainfall-flood-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>
                              No Rainfall Flood detected at this property.
                            </h4>
                          </div>
                        )
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className="detailed-report-rainfall-flood-information-chart-not-available">
                    <img src={EmptyIcon} />
                    <h4>No Rainfall Flood detected at this property.</h4>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-extreme-heat-column">
              <div className="detailed-report-title detailed-report-extreme-heat-title">
                <img src={ExtremeHeatIcon} />
                <h2>Extreme Heat</h2>
              </div>
              <div className="detailed-report-extreme-heat-map">
                <ExtremeHeatMap
                  mapimage={
                    this.state.assetmap == ""
                      ? undefined
                      : this.state.assetmap[this.state.detail[i].name][
                          "temperature"
                        ]
                  }
                  key={i}
                />
              </div>
              <div className="detailed-report-extreme-heat-information">
                {heat_year_data[i]["2020"] +
                  heat_year_data[i]["2030"] +
                  heat_year_data[i]["2050"] +
                  heat_rcp_data[i]["RCP 2.6"] +
                  heat_rcp_data[i]["RCP 4.5"] +
                  heat_rcp_data[i]["RCP 8.5"] >
                0 ? (
                  <>
                    <div className="detailed-report-extreme-heat-information-chart">
                      {yeardata ? (
                        heat_year_data[i]["2020"] +
                          heat_year_data[i]["2030"] +
                          heat_year_data[i]["2050"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Extreme Heat Year Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={100}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                { name: "2020", symbol: { fill: "#73a2ff" } },
                                { name: "2030", symbol: { fill: "#0055ff" } },
                                { name: "2050", symbol: { fill: "#002b82" } },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[heat_year_data[i]]}
                                x="name"
                                y="2020"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2020: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[heat_year_data[i]]}
                                x="name"
                                y="2030"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2030: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[heat_year_data[i]]}
                                x="name"
                                y="2050"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `2050: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-extreme-heat-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>No Extreme Heat detected at this property.</h4>
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className="detailed-report-extreme-heat-information-chart">
                      {rcpdata ? (
                        heat_rcp_data[i]["RCP 2.6"] +
                          heat_rcp_data[i]["RCP 4.5"] +
                          heat_rcp_data[i]["RCP 8.5"] >
                        0 ? (
                          <VictoryChart
                            padding={{
                              top: 110,
                              left: 50,
                              right: 30,
                              bottom: 50,
                            }}
                            width={300}
                            height={400}
                            theme={VictoryTheme.material}
                          >
                            <VictoryLabel
                              text="Extreme Heat RCP Data"
                              x={150}
                              y={15}
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                            />
                            <VictoryLegend
                              x={70}
                              y={40}
                              title="Legend"
                              centerTitle
                              orientation="horizontal"
                              gutter={20}
                              symbolSpacer={7}
                              style={{
                                border: { stroke: "black" },
                                title: { fontSize: 12 },
                              }}
                              data={[
                                {
                                  name: "RCP 2.6",
                                  symbol: { fill: "#73a2ff" },
                                },
                                {
                                  name: "RCP 4.5",
                                  symbol: { fill: "#0055ff" },
                                },
                                {
                                  name: "RCP 8.5",
                                  symbol: { fill: "#002b82" },
                                },
                              ]}
                            />
                            <VictoryAxis
                              axisLabelComponent={<VictoryLabel dy={30} />}
                            />
                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                            <VictoryGroup
                              offset={40}
                              colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                            >
                              <VictoryBar
                                data={[heat_rcp_data[i]]}
                                x="name"
                                y="RCP 2.6"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 2.6: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[heat_rcp_data[i]]}
                                x="name"
                                y="RCP 4.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 4.5: ${datum._y}`;
                                }}
                              />
                              <VictoryBar
                                data={[heat_rcp_data[i]]}
                                x="name"
                                y="RCP 8.5"
                                labelComponent={
                                  <VictoryTooltip
                                    flyoutPadding={20}
                                    cornerRadius={0}
                                    flyoutStyle={{ fill: "white" }}
                                  />
                                }
                                labels={({ datum }) => {
                                  return `RCP 8.5: ${datum._y}`;
                                }}
                              />
                            </VictoryGroup>
                          </VictoryChart>
                        ) : (
                          <div className="detailed-report-extreme-heat-information-chart-not-available">
                            <img src={EmptyIcon} />
                            <h4>No Extreme Heat detected at this property.</h4>
                          </div>
                        )
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className="detailed-report-extreme-heat-information-chart-not-available">
                    <img src={EmptyIcon} />
                    <h4>No Extreme Heat detected at this property.</h4>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
            <div className="detailed-report-column detailed-report-drought-column">
              <div className="detailed-report-title detailed-report-drought-title">
                <img src={DroughtIndexIcon} />
                <h2>Drought Index</h2>
              </div>
              <div className="detailed-report-drought-information">
                <div className="detailed-report-drought-information-chart">
                  {yeardata ? (
                    drought[i]["2020"] +
                      drought[i]["2030"] +
                      drought[i]["2050"] >
                    0 ? (
                      <VictoryChart
                        padding={{ top: 110, left: 50, right: 30, bottom: 50 }}
                        width={300}
                        height={400}
                        theme={VictoryTheme.material}
                      >
                        <VictoryLabel
                          text="Drought Year Data"
                          x={150}
                          y={15}
                          textAnchor="middle"
                          style={{ fontSize: 20 }}
                        />
                        <VictoryLegend
                          x={100}
                          y={40}
                          title="Legend"
                          centerTitle
                          orientation="horizontal"
                          gutter={20}
                          symbolSpacer={7}
                          style={{
                            border: { stroke: "black" },
                            title: { fontSize: 12 },
                          }}
                          data={[
                            { name: "2020", symbol: { fill: "#73a2ff" } },
                            { name: "2030", symbol: { fill: "#0055ff" } },
                            { name: "2050", symbol: { fill: "#002b82" } },
                          ]}
                        />
                        <VictoryAxis
                          axisLabelComponent={<VictoryLabel dy={30} />}
                        />
                        <VictoryAxis dependentAxis tickFormat={(x) => x} />
                        <VictoryGroup
                          offset={40}
                          colorScale={["#73a2ff", "#0055ff", "#002b82"]}
                        >
                          <VictoryBar
                            data={[drought[i]]}
                            x="name"
                            y="2020"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `2020: ${datum._y}`;
                            }}
                          />
                          <VictoryBar
                            data={[drought[i]]}
                            x="name"
                            y="2030"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `2030: ${datum._y}`;
                            }}
                          />
                          <VictoryBar
                            data={[drought[i]]}
                            x="name"
                            y="2050"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `2050: ${datum._y}`;
                            }}
                          />
                        </VictoryGroup>
                      </VictoryChart>
                    ) : (
                      <div className="detailed-report-drought-information-chart-not-available">
                        <img src={EmptyIcon} />
                        <h4>No Drought detected at this property.</h4>
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
            {/* <div className="detailed-report-page-break"></div> */}
          </div>
        );
      }
    }

    year_loss_data.sort(function (a, b) {
      return b["2050"] - a["2050"];
    });

    const isDarkMode = localStorage.getItem("theme") == "dark";
    if (this.state.assetmap.length == 0 && isDarkMode) {
      return <div id="darkloading"></div>;
    } else if (this.state.assetmap.length == 0) {
      return <div id="loading"></div>;
    }

    return (
      <>
        <div id="detail">
          <div className="detailed-report-download">
            <Button primary onClick={this.printDocument}>
              Download
            </Button>
          </div>
          <Grid>
            <Grid.Row>
              <div id="detailed-report-cover" className="detailed-report">
                <img src={DetailedReportCover} />
              </div>
            </Grid.Row>

            <Grid.Row>
              <div id="detailed-report-overall" className="detailed-report">
                <div className="detailed-report-column detailed-report-overall-column">
                  <div className="detailed-report-title detailed-report-overall-title">
                    <img src={OverallIcon} />
                    <h2>Overall</h2>
                  </div>
                  <div className="overallmap-container">
                    <OverallMap
                      portfolio={this.props.location.state.portfolio}
                      lossreport={this.props.lossreport}
                    />
                    {/* <img
                      src={`data:image/jpeg;base64,${this.state.overallmap}`}
                    /> */}
                  </div>
                  <div className="detailed-report-overall-information">
                    {year_loss_data.length > 1 ? (
                      <p>
                        {year_loss_data[0]["originalName"]} has the highest loss
                        of all the assets with a total loss of{" "}
                        {year_loss_data[0][2050]} million dollars and a
                        percentage loss of around{" "}
                        {(
                          ((year_loss_data[0][2050] - year_loss_data[0][2020]) /
                            year_loss_data[0][2020]) *
                          100
                        ).toFixed(0)}
                        %. {year_loss_data[1]["originalName"]} is the second
                        leading asset with the highest loss with a total loss of{" "}
                        {year_loss_data[1][2050]} million dollars and a
                        percentage loss of{" "}
                        {(
                          ((year_loss_data[1][2050] - year_loss_data[1][2020]) /
                            year_loss_data[1][2020]) *
                          100
                        ).toFixed(2)}
                        %.
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="detailed-report-column detailed-report-overall-column">
                  <div className="detailed-report-overall-information">
                    <h3 className="detailed-report-overall-information-table-title">
                      RCP Loss of{" "}
                      {this.state.detail.length > 15
                        ? "Top 15 assets"
                        : "all assets"}{" "}
                      for 2050
                    </h3>
                    <Table
                      celled
                      selectable
                      textAlign="center"
                      size="small"
                      compact="very"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Asset Name</Table.HeaderCell>
                          <Table.HeaderCell>RCP 2.6</Table.HeaderCell>
                          <Table.HeaderCell>RCP 4.5</Table.HeaderCell>
                          <Table.HeaderCell>RCP 8.5</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {year_loss_data.map((asset) => {
                          return (
                            <Table.Row>
                              <Table.Cell verticalAlign="middle">{`${asset.originalName} (${asset.name})`}</Table.Cell>
                              <Table.Cell verticalAlign="middle">
                                {asset["RCP2670"]}
                              </Table.Cell>
                              <Table.Cell verticalAlign="middle">
                                {asset["RCP4570"]}
                              </Table.Cell>
                              <Table.Cell verticalAlign="middle">
                                {asset[2050]}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div className="detailed-report-column detailed-report-overall-column">
                  <div className="detailed-report-overall-information">
                    <h3 className="detailed-report-overall-information-table-title">
                      Total Loss of{" "}
                      {this.state.detail.length > 15
                        ? "Top 15 assets"
                        : "all assets"}{" "}
                    </h3>
                    <Table
                      celled
                      selectable
                      striped
                      textAlign="center"
                      size="small"
                      compact="very"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Asset Name</Table.HeaderCell>
                          <Table.HeaderCell>
                            Total Loss (in $ million)
                          </Table.HeaderCell>
                          <Table.HeaderCell>Percentage Loss</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {year_loss_data.slice(0, 15).map((asset) => {
                          return (
                            <Table.Row>
                              <Table.Cell verticalAlign="middle">{`${asset.originalName} (${asset.name})`}</Table.Cell>
                              <Table.Cell verticalAlign="middle">
                                {asset["2050_loss"]}
                              </Table.Cell>
                              <Table.Cell verticalAlign="middle">
                                {(
                                  ((asset[2050] - asset[2020]) / asset[2020]) *
                                  100
                                ).toFixed(2)}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div className="detailed-report-column detailed-report-overall-column">
                  <div className="detailed-report-overall-information">
                    <div className="detailed-report-overall-information-chart">
                      <VictoryChart
                        padding={{
                          top: 120,
                          left: 100,
                          right: 40,
                          bottom: 50,
                        }}
                        domainPadding={50}
                        width={500}
                        height={360 + 15 * year_loss_data.length}
                        theme={VictoryTheme.material}
                      >
                        <VictoryLabel
                          text="Asset-wise Loss based on Risk Score"
                          x={250}
                          y={30}
                          textAnchor="middle"
                          style={{ fontSize: 20 }}
                        />
                        <VictoryLegend
                          x={350}
                          y={55}
                          title="Legend"
                          centerTitle
                          orientation="horizontal"
                          gutter={20}
                          symbolSpacer={7}
                          style={{
                            border: { stroke: "black" },
                            title: { fontSize: 12 },
                          }}
                          data={[
                            {
                              name: "2030",
                              symbol: { fill: "#0055ff" },
                            },
                            {
                              name: "2050",
                              symbol: { fill: "#002b82" },
                            },
                          ]}
                        />
                        <VictoryAxis
                          tickFormat={(x) => x.toFixed(2)}
                          axisLabelComponent={<VictoryLabel dy={30} />}
                          label="Total Loss (in $ million)"
                        />
                        <VictoryAxis
                          dependentAxis
                          tickFormat={(x) => x.toFixed(2)}
                          axisLabelComponent={<VictoryLabel dy={-75} />}
                          label="Risk Score"
                        />
                        <VictoryGroup colorScale={["#0055ff", "#002b82"]}>
                          <VictoryScatter
                            size={7}
                            data={Object.values(year_loss_data)}
                            x="2030_loss"
                            y="2030_risk"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "white",
                                }}
                              />
                            }
                            labels={({ datum }) => {
                              return `${datum.name}, ${datum["2030_loss"]}`;
                            }}
                          />
                          <VictoryScatter
                            size={7}
                            data={Object.values(year_loss_data)}
                            x="2050_loss"
                            y="2050_risk"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "white",
                                }}
                              />
                            }
                            labels={({ datum }) => {
                              return `${datum.name}, ${datum["2050_loss"]}`;
                            }}
                          />
                        </VictoryGroup>
                      </VictoryChart>
                    </div>
                    <div className="detailed-report-overall-information-chart">
                      <VictoryChart
                        padding={{
                          top: 50,
                          left: 100,
                          right: 150,
                          bottom: 100,
                        }}
                        domainPadding={30}
                        width={500}
                        height={500}
                        theme={VictoryTheme.material}
                      >
                        <VictoryLabel
                          text="Country-wise Loss"
                          x={250}
                          y={30}
                          textAnchor="middle"
                          style={{ fontSize: 20 }}
                        />
                        <VictoryLegend
                          x={380}
                          y={50}
                          borderPadding={3}
                          title="Legend"
                          centerTitle
                          itemsPerRow={1}
                          orientation="horizontal"
                          gutter={20}
                          symbolSpacer={7}
                          style={{
                            border: { stroke: "black" },
                            title: { fontSize: 12 },
                          }}
                          data={[
                            {
                              name: "River Flood",
                              symbol: { fill: "#d48625" },
                            },
                            {
                              name: "Rainfall Flood",
                              symbol: { fill: "#002c56" },
                            },
                            {
                              name: "Storm Surge",
                              symbol: { fill: "#6c8ba2" },
                            },
                            { name: "Typhoon", symbol: { fill: "#999999" } },
                            {
                              name: "Extreme Heat",
                              symbol: { fill: "#0c6924" },
                            },
                          ]}
                        />
                        <VictoryAxis
                          style={{
                            tickLabels: {
                              verticalAnchor: "middle",
                              angle: -25,
                              padding: 20,
                            },
                          }}
                          tickFormat={(x) => x}
                          axisLabelComponent={<VictoryLabel dy={70} />}
                          label="Country"
                        />
                        <VictoryAxis
                          dependentAxis
                          tickFormat={(x) => x.toFixed(2)}
                          axisLabelComponent={<VictoryLabel dy={-75} />}
                          label="Loss (in $ million)"
                        />
                        <VictoryStack
                          colorScale={[
                            "#d48625",
                            "#002c56",
                            "#6c8ba2",
                            "#999999",
                            "#0c6924",
                          ]}
                        >
                          <VictoryBar
                            barWidth="25"
                            data={Object.values(country_loss)}
                            x="name"
                            y="RVFL"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `River Flood: ${datum._y}`;
                            }}
                          />
                          <VictoryBar
                            barWidth="25"
                            data={Object.values(country_loss)}
                            x="name"
                            y="RFL"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `Rainfall Flood: ${datum._y}`;
                            }}
                          />
                          <VictoryBar
                            barWidth="25"
                            data={Object.values(country_loss)}
                            x="name"
                            y="SS"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `Storm Surge: ${datum._y}`;
                            }}
                          />
                          <VictoryBar
                            barWidth="25"
                            data={Object.values(country_loss)}
                            x="name"
                            y="TY"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `Typhoon: ${datum._y}`;
                            }}
                          />
                          <VictoryBar
                            barWidth="25"
                            data={Object.values(country_loss)}
                            x="name"
                            y="EH"
                            labelComponent={
                              <VictoryTooltip
                                flyoutPadding={20}
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                            labels={({ datum }) => {
                              return `Extreme Heat: ${datum._y}`;
                            }}
                          />
                        </VictoryStack>
                      </VictoryChart>
                    </div>
                  </div>
                </div>
                <div className="detailed-report-column detailed-report-overall-column">
                  <div className="detailed-report-overall-information">
                    <h3 className="detailed-report-overall-information-table-title">
                      Climate Risk Heat Map
                    </h3>
                    <div className="detailed-report-overall-information-chart">
                      {heatmapdata.length > 0 ? (
                        <HeatMap
                          {...commonProperties}
                          data={heatmapdata}
                          indexBy="asset"
                          keys={[
                            "Landslide",
                            "River Flood",
                            "Drought",
                            "Storm Surge",
                            "Extreme Heat",
                            "Snow Melt",
                            "Rainfall Flood",
                            "Typhoon",
                            "Sea Level Rise",
                          ]}
                          margin={{ top: 60, right: 30, bottom: 0, left: 60 }}
                          axisBottom={null}
                          axisRight={null}
                          axisTop={{
                            orient: "top",
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -35,
                            legend: "",
                            legendOffset: 36,
                          }}
                          axisLeft={{
                            orient: "left",
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 55,
                            legendPosition: "middle",
                            legendOffset: -40,
                          }}
                          cellOpacity={1}
                          enableContainerX={true}
                          enableContainerY={true}
                          cellBorderWidth={2}
                          colors="YlOrRd"
                          cellBorderColor="#ffffff"
                          labelTextColor="black"
                          defs={[
                            {
                              id: "lines",
                              type: "patternLines",
                              background: "inherit",
                              color: "rgba(0, 0, 0, 0.1)",
                              rotation: -45,
                              lineWidth: 4,
                              spacing: 7,
                            },
                          ]}
                          fill={[{ id: "lines" }]}
                          animate={true}
                          motionConfig="gentle"
                          motionStiffness={80}
                          motionDamping={9}
                          hoverTarget="cell"
                          cellHoverOthersOpacity={0.25}
                          tooltip={({ xKey, yKey, value, color }) => (
                            <strong style={{ color }}>
                              {xKey} / {yKey}: {value}
                            </strong>
                          )}
                          theme={{
                            tooltip: {
                              container: {
                                background: "black",
                              },
                            },
                          }}
                          theme={{
                            textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Row>

            {report.map((asset) => {
              return (
                <>
                  {/* <div className="detailed-report-page-break"></div> */}
                  <Grid.Row>{asset}</Grid.Row>
                </>
              );
            })}
          </Grid>
        </div>
        <div className="detailed-report-download">
          <Button primary onClick={this.printDocument}>
            Download
          </Button>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    detailreport: state.project.detailreport,
    windreport: state.project.windreport,
    errors: state.project.errors,
    detail: state.feedback.detail,
    assets: state.asset.asset,
    lossreport: state.project.lossreport,
    countrywiselossreport: state.project.countrywiselossreport,
    overallmapimage: state.project.overallmapimage,
    assetmapimage: state.project.assetmapimage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailedReport: (formdata) => {
      dispatch(project.getDetailedReport(formdata));
    },
    getWindReport: (formdata) => {
      dispatch(project.getWindReport(formdata));
    },
    getLossReport: (formdata) => {
      dispatch(project.getLossReport(formdata));
    },
    getCountryWiseLossReport: (formdata) => {
      dispatch(project.getCountryWiseLossReport(formdata));
    },
    getOverallMap: (formdata) => {
      dispatch(project.getOverallMap(formdata));
    },
    getAssetMap: (formdata) => {
      dispatch(project.getAssetMap(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailedReport);
