import React, { useRef, useEffect, useState } from 'react';
import {Divider, Header, Icon, Menu, Label, Button, Grid, Radio, Image, Form, Input, Modal, Popup, Select, Progress, Table, Checkbox, Accordion, Dropdown, Card } from 'semantic-ui-react';
import logo from '../assets/logo22.png';
import {connect} from 'react-redux';
import {locus,auth,project} from '../actions';
import jsPDF from 'jspdf';
import './invoice.css';
import html2canvas from 'html2canvas';

let projectOption=[];
let breakdown=[];

class Invoice extends React.Component{

	state={
		project_name:'',
		invoice:''
	}

	componentDidMount(){
		this.props.getProject();
		
	}
	handleInvoice(){
		
		let formdata=new FormData();
		formdata.append("name",this.state.project_name)
		this.props.getInvoice(formdata)

	
	}
	
	handleProject=(e,{value})=>{
		this.setState({project_name:value},()=>this.handleInvoice())
	}
	componentDidUpdate(prevProps,prevState){
		if(prevProps.project!=this.props.project){
			this.setState({
				project_name:this.props.project[0].name
			},this.handleInvoice);
			for(let i=0;i<this.props.project.length;i++){
				projectOption.push({
					key:this.props.project[i].name,
					value:this.props.project[i].name,
					text:this.props.project[i].name,
				})
			}
		}
		if(prevProps.invoice!=this.props.invoice){
			this.setState({
				invoice:this.props.invoice
			},()=>console.log("invoice",this.state.invoice))
		}
	}
	 
	 printDocument() {
    window.print();
  }

	render(){
		if(this.state.invoice.breakdown){
			breakdown=[];
			for(let i=0;i<this.state.invoice.breakdown.length;i++){
				
					breakdown.push(
						<Table.Row>
						<Table.Cell>{this.state.invoice.breakdown[i][0]}</Table.Cell>
						<Table.Cell>{this.state.invoice.breakdown[i][1]}</Table.Cell>
						<Table.Cell>{this.state.invoice.breakdown[i][2]}</Table.Cell>
						<Table.Cell>{this.state.invoice.breakdown[i][3]}</Table.Cell>
						<Table.Cell>{this.state.invoice.breakdown[i][4]}</Table.Cell>
						</Table.Row>
						)
				
				}
			
		}
		

	return(
			<div style={{marginTop:'50px'}} className="invoice">
			
			<Grid verticalAlign="middle" centered id="invoice">
				<Grid.Row verticalAlign="middle" >
					
					<Grid.Column computer={6} >
						<Image src={logo} size="small"/>
					</Grid.Column>
					<Grid.Column computer={4}>
						<Icon name="globe" color="blue" size="large"/>
						<p >www.intensel.com<br/>
						www.intensel.live</p>
						
					</Grid.Column>
					
					<Grid.Column computer={3} >
						<Icon name="map marker" color="blue" size="large"/>
						<p>Intensel Limited, Unit 517, 5/F Building 19W<br/> No.19 Science Park West Avenue, Hong Kong Science Park</p>
					</Grid.Column>

				</Grid.Row>
				<Divider/>
				<Grid.Row>
				
					<Grid.Column computer={3} >
						<p>Invoice Id:</p>
						<p>Invoice Date:{this.state.invoice["Invoice Date"]}</p>
					</Grid.Column>
					<Grid.Column computer={3} >
						<p>Billed to: {this.state.invoice["Company Name"]}</p>
						<p>Project: {this.state.invoice["Project Name"]}</p>

					</Grid.Column>
					<Grid.Column computer={3}>
						<p>Shipped to:{this.state.invoice["Company Name"]}</p>
						<p>Status: {this.state.invoice["Status"]}</p>
					</Grid.Column>
					<Grid.Column computer={3}>
					<Form.Select className="invoice-logo" placeholder={this.state.project_name}
      					options={projectOption}
                          style={{float:'right',minHeight:'1.7em',paddingRight:'10px'}}
                          onChange={this.handleProject}
                          
                          
                          />
					</Grid.Column>

				</Grid.Row>
				<Grid.Row>
				
				<Grid.Column width="12">
					<Table style={{background:'none'}}>
						<Table.Header style={{backgroundColor:'transparent'}}>
							<Table.Row style={{backgrounds:'none'}}>
								<Table.HeaderCell>Project Breakdown</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell>Cost</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>

							
							{this.state.invoice['breakdown']?
								<>{breakdown}</>
							:null}
							{/*
								(this.state.invoice['breakdown']&&this.state.invoice['breakdown'][1])?<Table.Row>
								<Table.Cell>{this.state.invoice["breakdown"][1][0]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][1][1]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][1][2]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][1][3]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][1][1]}</Table.Cell>
							</Table.Row>:null

							}
							{
								(this.state.invoice['breakdown']&&this.state.invoice['breakdown'][2])?<Table.Row>
								<Table.Cell>{this.state.invoice["breakdown"][2][0]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][2][1]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][2][2]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][2][3]}</Table.Cell>
								<Table.Cell>{this.state.invoice["breakdown"][2][1]}</Table.Cell>
							</Table.Row>:null

							}*/}


						</Table.Body>
						<Table.Row style={{backgroundColor:'white',color:'blsck' ,fontWeight:'600'}}>
							<Table.Cell></Table.Cell>
							<Table.Cell></Table.Cell>
							<Table.Cell></Table.Cell>
							<Table.Cell>Total</Table.Cell>
							<Table.Cell>US $ {this.state.invoice["cost"]}</Table.Cell>
						</Table.Row>
					</Table>
				</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="9"></Grid.Column>
					<Grid.Column width="2"><Button className="invoice-download" onClick={this.printDocument} primary style={{backgroundColor:'#002060'}} floated="right">Download</Button></Grid.Column>
					<Grid.Column width="1"><Button primary className="invoice-download" style={{backgroundColor:'#002060'}}>Email</Button></Grid.Column>


				</Grid.Row>
			</Grid>
			</div>
		);

}
}
const mapStateToProps = state =>{
	return {
		errors:state.project.errors,
		locus:state.locus.locus,
		project:state.project.project,
		invoice:state.project.invoice

	}
}
const mapDispatchToProps = dispatch =>{
	return{
		getProject:()=>{
			dispatch(project.getProjects());
		},
		getInvoice:(formdata)=>{
			dispatch(project.getInvoice(formdata));	
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Invoice);