import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";

let feature = [];
let marks = [];

class OverallMap extends Component {
  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();

    this.state = {
      lng: -70.9,
      lat: 42.35,
      zoom: 10,
      loss: [],
      variables: "TO",
      rcp: "85",
      year: "50",
      max: "",
      Slidervalue: "",
      marks: "",
      center: "",
    };
  }

  componentDidMount() {
    let formdata = new FormData();
    formdata.append("portfolio_name", this.props.portfolio);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lossreport != this.props.lossreport) {
      console.log("inside component update", this.props.lossreport);
      this.setState({
        center: this.props.lossreport.loc_data,
        loss: this.props.lossreport.data,
        max: this.props.lossreport.max_loss,
        Slidervalue: this.props.lossreport.max_loss.toFixed(0),
      });
    }
  }
  render() {
    const isDarkMode = localStorage.getItem("theme") == "dark";
    console.log("OverallMap Component", this.state.loss, this.props.lossreport);
    if (this.state.loss.length > 0) {
      let centerLongitude = 0;
      let centerLatitude = 0;
      let numberOfPoints = 0;
      for (let i = 0; i < this.state.loss.length; i++) {
        centerLongitude += this.state.loss[i].longitude;
        centerLatitude += this.state.loss[i].latitude;
        numberOfPoints += 1;
      }
      centerLongitude = centerLongitude / numberOfPoints;
      centerLatitude = centerLatitude / numberOfPoints;
      // let highestLoss = 0;
      // let loss = 0;
      // for (let i = 0; i < this.state.loss.length; i++) {
      //   loss = 0;
      //   loss +=
      //     this.state.loss[i]["EC8570"] +
      //     this.state.loss[i]["RFL8570"] +
      //     this.state.loss[i]["RVFL8570"] +
      //     this.state.loss[i]["SS8570"] +
      //     this.state.loss[i]["TY8570"];
      //   if (loss > highestLoss) {
      //     highestLoss = loss;
      //   }
      // }
      var map = new mapboxgl.Map({
        container: this.mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [centerLongitude, centerLatitude],
        zoom: this.state.zoom,
        scrollZoom: true,
      });
      map.addControl(new mapboxgl.NavigationControl());

      feature = [];
      for (let i = 0; i < this.state.loss.length; i++) {
        feature.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              this.state.loss[i].longitude,
              this.state.loss[i].latitude,
            ],
          },
          properties: {
            address: this.state.loss[i].name,
            loss: this.state.loss[i],
          },
        });
        console.log(
          "the props received in overall map",
          this.state.loss[i].longitude
        );
      }
      console.log("features are", feature);
      var stores = {
        type: "FeatureCollection",
        features: feature,
      };
      stores.features.forEach(function (store, i) {
        store.properties.id = i;
      });
      map.on("load", function (e) {
        map.addSource("places", {
          type: "geojson",
          data: stores,
        });
        addMarkers();
      });

      function addMarkers() {
        /* For each feature in the GeoJSON object above: */
        stores.features.map((marker) => {
          /* Create a div element for the marker. */
          var el = document.createElement("div");
          /* Assign a unique `id` to the marker. */
          el.id = "marker-" + marker.properties.id;
          /* Assign the `marker` class to each marker for styling. */
          el.className = "marker";

          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);

          let popupOpen = true;
          let popup = new mapboxgl.Popup({
            closeOnClick: false,
          });
          // let loss = 0;
          // loss +=
          //   marker.properties.loss["EC8570"] +
          //   marker.properties.loss["RFL8570"] +
          //   marker.properties.loss["RVFL8570"] +
          //   marker.properties.loss["SS8570"] +
          //   marker.properties.loss["TY8570"];
          // if (loss === highestLoss) {
          //   createPopUp(marker, popup);
          // }

          el.addEventListener("click", function (e) {
            if (popupOpen === true) {
              popup.remove();
              popupOpen = false;
            } else {
              popupOpen = true;
              createPopUp(marker, popup);
            }
          });
        });
        // stores.features.forEach(function (marker) {
        //   /* Create a div element for the marker. */
        //   var el = document.createElement("div");
        //   /* Assign a unique `id` to the marker. */
        //   el.id = "marker-" + marker.properties.id;
        //   /* Assign the `marker` class to each marker for styling. */
        //   el.className = "marker";

        //   new mapboxgl.Marker(el)
        //     .setLngLat(marker.geometry.coordinates)
        //     .addTo(map);

        //   let popupOpen = true;
        //   let popup = new mapboxgl.Popup({
        //     closeOnClick: false,
        //   });
        //   let loss = 0;
        //   loss +=
        //     marker.properties.loss["EC8570"] +
        //     marker.properties.loss["RFL8570"] +
        //     marker.properties.loss["RVFL8570"] +
        //     marker.properties.loss["SS8570"] +
        //     marker.properties.loss["TY8570"];
        //   if (loss === highestLoss) {
        //     createPopUp(marker, popup);
        //   }

        //   el.addEventListener("click", function (e) {
        //     if (popupOpen === true) {
        //       popup.remove();
        //       popupOpen = false;
        //     } else {
        //       popupOpen = true;
        //       createPopUp(marker, popup);
        //     }
        //   });
        // });
      }
      function createPopUp(currentFeature, popup) {
        var popUps = document.getElementsByClassName("mapboxgl-popup");
        popup
          .setLngLat(currentFeature.geometry.coordinates)
          .setHTML(
            `<p>Electricity: $${currentFeature.properties.loss[
              "EC8570"
            ].toFixed(2)} mil <br>
            Rainfall Flood: $${currentFeature.properties.loss[
              "RFL8570"
            ].toFixed(2)} mil<br>
            River Flood: $${currentFeature.properties.loss["RVFL8570"].toFixed(
              2
            )} mil<br>
            Storm Surge: $${currentFeature.properties.loss["SS8570"].toFixed(
              2
            )} mil<br>
            Typhoon: $${currentFeature.properties.loss["TY8570"].toFixed(
              2
            )} mil`
          )
          .addTo(map);
      }
    }

    console.log("stores are", stores);
    console.log("the loss report in overall map are", this.props.lossreport);
    return (
      <div className="detailed-report-overall-map-container">
        <Button
          className="detailed-report-overall-map"
          onClick={() => this.setState({ ...this.state, zoom: 14 })}
        >
          Increase zoom
        </Button>
        <div
          className="detailed-report-overall-map"
          ref={this.mapContainer}
          style={{ height: "400px", width: "100%" }}
        />
      </div>
    );
  }
}

export default OverallMap;
