import React, { useState,useCallback,useEffect,Component} from 'react';
import {Icon,Button,Accordion} from 'semantic-ui-react';
import {Grid} from 'semantic-ui-react';
import './faqs.css'
import {
    CollapsibleComponent,
    CollapsibleHead,
    CollapsibleContent
} from "react-collapsible-component";

export default class Faqs extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="faqs">
            <Grid.Column id="riskindices" className="card" style={{height:'90%',width:'89%%',maxWidth:'92.5%',overflowX:'auto',overflowY:'hidden' ,marginLeft:'90px' ,marginTop:'20px'}}>
            <h1>FAQs</h1>

            <nav id="topbar-wrap">
         <ul className="topbar">
            <li><a class="active" href="#riskindices">General Queries</a></li>
            <li><a href="#cliindex">Queries#2</a></li>
            <li><a href="#climodels">Queries#3</a></li>
            <li><a href="#datasets">Queries#4</a></li>
            <li><a href="#resandref">Queries#5</a></li>
            <li><a href="#hazardsbasics">Queries#6</a></li>
         </ul>
      </nav>
                <CollapsibleComponent name="second">
                    <CollapsibleHead className="faqshead">1.How was the climate risk variables estimated – was it based on HK experience?</CollapsibleHead>
                    <CollapsibleContent className="faqscontent" id="firstque" isExpanded={true}>
                        <p>
                           There are over nine climate risk variables on the intensel blue-swan platform, and the estimation of most variables varies from one another. 
                           We try to model these variables according to the best standards and peer-reviewed models. Further, as the platform provides global analysis, 
                           we have a uniform modelling approach globally. However, several inputs are region-specific, for example, DEMs, bathymetry, footprints, historical data; thus, 
                           model output is both scalable and is also has regional information,
                            Pluvial/fluvial Flood Modelling: In-house models using HEC and Flow-2d. 
                            Storm Surge: HEC and Geo hydrodynamic models.’
                            Extreme heat: outputs from integrated GCM-RCM models 
                            These are in-house run models with validation ppt available on request.
                        </p>
                    </CollapsibleContent>
                    <CollapsibleHead className="faqshead">
                        2.The attached xls contains different levels of climate risk variables in different years.
May I ask if the number is an average value under different scenario-year?Would the entire probability distribution across years be available upon request?
                    </CollapsibleHead>
                    <CollapsibleContent className="faqscontent">
                        <p>
                            The outputs are based on recommendations of TCFD, where the recommendations are to provide physical climate risk for two time periods, 2030-2050 and 2050 -2070 only. Though we can generate these events yearly, we neither recommend nor prefer it because for two reasons:

    1. As we provide asset-level granularity, the size of the datasets increases exponentially and can go beyond 100 terabytes as 9 variables, global yearly for 70+ years. This much data is challenging to handle also cloud infrastructure handling becomes arduous.
    2. Climate data does not make sense yearly, unlike the weather. Climate data mean over the time variables makes more sense as the uncertainty is reduced when averaged over the time period. It gives a much better estimation of extremes and trends.
                        </p>
                    </CollapsibleContent>
                    
                    <CollapsibleHead className="faqshead">3.How was the climate risk variable translated to Asset Damage and Operational Loss – was it referenced to ex-HK experience? </CollapsibleHead>
                    <CollapsibleContent className="faqscontent">
                        <p>
                                The losses are based on the insurance approach wherein Mean Damage Ratio (MDR) is modelled for different levels of floods and surges for types, floors, basements of assets. The MDR approach is documented in various journals. 
Temperature loss models are trained on historical losses because of the increase/decrease in energy consumption and change in temperature and humidity; both are modelled from outputs of integrated GCM-RCMs.
(Ref: 10.1007/s11069-013-1017-z https://doi.org/10.1007/s11069-013-1017-z , http://hdl.handle.net/2117/22402 etc)
The typhoon loss is based on calculations of coefficients for factors that lead to typhoon losses and training it for historical data. This was developed at intensel with our PhD who also published a paper on this loss calculation approach
 (Ref: https://doi.org/10.3389/fbuil.2020.575534).
                        </p>
                    </CollapsibleContent>
                    

                    <CollapsibleHead className="faqshead">4.Does the asset damage translates to property valuation decrease, and operational loss translates to a reduction in property income? How should we interpret the loss number in the xls?</CollapsibleHead>
                    <CollapsibleContent className="faqscontent">
                        <p>
                            LThere is facilitation to adjust property valuation according to the clients boh using APIs and UI. A client who has already calculated an increase/decrement in valuation can provide those inputs from his end. This is done as an increase and decrease in property valuation depends on several variables and varies from region to region; modelling this is not our expertise.

                        </p>
                        <p>
                            
The operations loss considers the increase/decrease in economic activity with year; however, this is done by the assumption that increase/decrease in all the regions is directly proportional to the increase/decrease in GDP of a country in the coming 60 years.
                        </p>
                    </CollapsibleContent>

                    <CollapsibleHead className="faqshead">5.Was there any assumption made when aggregating the damage/loss across perils? Any consideration in the correlation between different climate risk variables?</CollapsibleHead>
                    <CollapsibleContent className="faqscontent">
                        <p>
                            While aggregating the losses, we made sure that these individual variable-losses were mutually exhaustive and did not overlap. As all the outputs are probable max loss, what we give clients is total and also break down individual losses to better understand the risk. 
                        </p>
                        <p>
                            Though the losses are mutually exhaustive, two events might occur together, like extreme temperature and storm surge. The co-occurrence is very difficult to model for anyone. Hence the client can use the individual losses and can create his own set of co-occurring events,
                        </p>
                    </CollapsibleContent>

                    <CollapsibleHead className="faqshead">6.Was there an estimation of typhoon risk?</CollapsibleHead>
                    <CollapsibleContent className="faqscontent">
                    <p>
                        Intensel uses multiple models in-house to model typhoon risk (mainly rain and wind from typhoons). These models take into consideration some of the essential factors for modelling typhoon risk, such as changes in wind intensity over time, change in 5 days cumulative rainfall from the typhoon, deviation in typhoon tracks and landfalls (this has a significant impact on exposure) and lastly the prediction of month/year and location of origin of typhoons. 
                    </p>
                        </CollapsibleContent>
                </CollapsibleComponent>
            </Grid.Column>
            </div>
        );
    }
}

