import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

let data = [
  {
    id: "Japan",
    data: [
      {
        x: "Train",
        y: 98299,
      },
      {
        x: "Subway",
        y: 23057,
      },
      {
        x: "Bus",
        y: -12489,
      },
      {
        x: "Car",
        y: 3761,
      },
      {
        x: "Boat",
        y: 14377,
      },
      {
        x: "Moto",
        y: -30907,
      },
      {
        x: "Moped",
        y: -62700,
      },
      {
        x: "Bicycle",
        y: 26783,
      },
      {
        x: "Others",
        y: -32364,
      },
    ],
  },
  {
    id: "France",
    data: [
      {
        x: "Train",
        y: -56378,
      },
      {
        x: "Subway",
        y: 63379,
      },
      {
        x: "Bus",
        y: -24724,
      },
      {
        x: "Car",
        y: -56099,
      },
      {
        x: "Boat",
        y: -39914,
      },
      {
        x: "Moto",
        y: 36836,
      },
      {
        x: "Moped",
        y: -29364,
      },
      {
        x: "Bicycle",
        y: -18516,
      },
      {
        x: "Others",
        y: -67411,
      },
    ],
  },
  {
    id: "US",
    data: [
      {
        x: "Train",
        y: -17563,
      },
      {
        x: "Subway",
        y: -57029,
      },
      {
        x: "Bus",
        y: -15795,
      },
      {
        x: "Car",
        y: 3751,
      },
      {
        x: "Boat",
        y: 53930,
      },
      {
        x: "Moto",
        y: 36207,
      },
      {
        x: "Moped",
        y: -29698,
      },
      {
        x: "Bicycle",
        y: -30376,
      },
      {
        x: "Others",
        y: -12956,
      },
    ],
  },
  {
    id: "Germany",
    data: [
      {
        x: "Train",
        y: 39660,
      },
      {
        x: "Subway",
        y: 34574,
      },
      {
        x: "Bus",
        y: -32452,
      },
      {
        x: "Car",
        y: 29989,
      },
      {
        x: "Boat",
        y: -70217,
      },
      {
        x: "Moto",
        y: -73262,
      },
      {
        x: "Moped",
        y: 33684,
      },
      {
        x: "Bicycle",
        y: -94815,
      },
      {
        x: "Others",
        y: 35289,
      },
    ],
  },
  {
    id: "Norway",
    data: [
      {
        x: "Train",
        y: -49373,
      },
      {
        x: "Subway",
        y: -22527,
      },
      {
        x: "Bus",
        y: 75685,
      },
      {
        x: "Car",
        y: -99258,
      },
      {
        x: "Boat",
        y: -36823,
      },
      {
        x: "Moto",
        y: 65608,
      },
      {
        x: "Moped",
        y: -79814,
      },
      {
        x: "Bicycle",
        y: -72759,
      },
      {
        x: "Others",
        y: -42028,
      },
    ],
  },
  {
    id: "Iceland",
    data: [
      {
        x: "Train",
        y: -12140,
      },
      {
        x: "Subway",
        y: 42695,
      },
      {
        x: "Bus",
        y: -73575,
      },
      {
        x: "Car",
        y: 6733,
      },
      {
        x: "Boat",
        y: 19144,
      },
      {
        x: "Moto",
        y: 18743,
      },
      {
        x: "Moped",
        y: 35211,
      },
      {
        x: "Bicycle",
        y: 59758,
      },
      {
        x: "Others",
        y: 44556,
      },
    ],
  },
  {
    id: "UK",
    data: [
      {
        x: "Train",
        y: 73653,
      },
      {
        x: "Subway",
        y: -73310,
      },
      {
        x: "Bus",
        y: 50594,
      },
      {
        x: "Car",
        y: -37519,
      },
      {
        x: "Boat",
        y: 14699,
      },
      {
        x: "Moto",
        y: -84864,
      },
      {
        x: "Moped",
        y: 36288,
      },
      {
        x: "Bicycle",
        y: 44229,
      },
      {
        x: "Others",
        y: 30312,
      },
    ],
  },
  {
    id: "Vietnam",
    data: [
      {
        x: "Train",
        y: 55146,
      },
      {
        x: "Subway",
        y: 27993,
      },
      {
        x: "Bus",
        y: -14380,
      },
      {
        x: "Car",
        y: 51472,
      },
      {
        x: "Boat",
        y: 37211,
      },
      {
        x: "Moto",
        y: 36608,
      },
      {
        x: "Moped",
        y: -34793,
      },
      {
        x: "Bicycle",
        y: -5267,
      },
      {
        x: "Others",
        y: -51995,
      },
    ],
  },
];

const Heatmap = (props) => {
  const isDarkMode = localStorage.getItem("theme") == "dark";

  let heatmapdata = [];
  let commonProperties = {};

  for (let i = 0; i < props.data.names.length; i++) {
    heatmapdata.push({
      id: props.data.names[i].slice(0, 25),

      data: [
        {
          x: "River Flood",
          y: props.data.values[i]["River Flood"],
        },

        { x: "Drought", y: props.data.values[i]["Drought"] },
        { x: "Storm Surge", y: props.data.values[i]["Storm Surge"] },
        { x: "Extreme heat", y: props.data.values[i]["Extreme Heat"] },
        { x: "Snow Melt", y: props.data.values[i]["Snow Melt"] },
        { x: "Rainfall Flood", y: props.data.values[i]["Rainfall Flood"] },
        { x: "Typhoon", y: props.data.values[i]["Typhoon"] },
        { x: "Sea Level Rise", y: props.data.values[i]["Sea Level Rise"] },
        { x: "Landslide", y: props.data.values[i]["Landslide"] },
        { x: "Wild Fire", y: props.data.values[i]["Wild Fire"] },
      ],
    });
  }
  console.log("new heatmap", heatmapdata);

  let n = props.data.names.length;
  commonProperties = {
    width: 1300,
    height: n * 100,
  };

  return (
    <ResponsiveHeatMap
      data={heatmapdata}
      keys={props.variable}
      indexBy="id"
      margin={{ top: 120, right: 0, bottom: 60, left: 250 }}
      valueFormat=">-.2s"
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        legend: "",
        legendOffset: 46,
      }}
      colors={{
        type: "diverging",
        scheme: "yellow_orange_red",
        divergeAt: 0.5,
        minValue: 0,
        maxValue: 100,
      }}
      emptyColor="#555555"
      legends={[
        {
          anchor: "bottom",
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Value →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}
    />
  );
};

export default Heatmap;
