import React, {
  Component
} from 'react'
import  { loadModules } from 'esri-loader';


export default class Map extends Component {

  constructor(props) {  
    super(props);
     }
     state={
      variable:'FL8570',
      locations:''
     }

 
   
  componentDidUpdate(prevProps,prevState){
    if(prevProps.data!==this.props.data){
      this.setState({variable:this.props.data},()=>console.log("map error",this.state.variable,this.props.data))
    }
  }


    render() {
      
       loadModules(['esri/Map', 'esri/views/MapView','esri/layers/FeatureLayer','esri/widgets/Legend','esri/Graphic','esri/widgets/Search','esri/widgets/Fullscreen','esri/symbols/WebStyleSymbol'], { css: true })
    .then(([ArcGISMap, MapView,FeatureLayer,Legend,Graphic,Search,Fullscreen,WebStyleSymbol]) => {
      
    if(this.state.variable==='FL8570'){
      console.log("lat long of ass",this.props.lat,this.props.long)
       const defaultSym = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255, 0.5],
            width: "0.5px"
        }
    }
    const renderer = {
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: defaultSym,
        // label: "Flood at RCP 85 shapefile projection",
        visualVariables: [
            {
            type: "color",
            field: "FL8570",
              stops: [
                {
                value: 0.22,
                color: "#FFFFCC",
                label: "0 - 0.22"
                },
                {
                value: 0.609,
                color: "#FFEDA0",
                label: "0.22 - 0.609"
                },
                {
                value: 1.25,
                color: "#FEB24C",
                label: "0.609 - 1.25"
                },
                {
                value: 2.19,
                color: "#FC4E2A",
                label: "1.25 - 2.19"
                },
                {
                value: 2.9,
                color: "#E31A1C",
                label: "2.19 - 2.9"
                },
                {
                value: 4,
                color: "#BD0026",
                label: "2.9 - 4"
                },
                {
                value: 6,
                color: "#940025",
                label: "4 - 6"
                },
                {
                value: 8,
                color: "#67001F",
                label: "6 - 8"
                }
            ]
            }
        ]
    };
    // Adding shapefile as a feature layer
    const povLayer = new FeatureLayer({
        url: "https://services9.arcgis.com/VkScCoIvdvWoNy79/arcgis/rest/services/shapefiles/FeatureServer/0",
        renderer: renderer,
        title: "Flood at RCP 8.5 Year 2050",
        popupTemplate: {
            // autocasts as new PopupTemplate()
            title: "{name}, {Type}",
            content:
            "Flood Value {FL8570}",
        }
    });


    
      const map = new ArcGISMap({
        basemap: 'streets-night-vector',
        layers:[povLayer]
        
      });

      const view = new MapView({
        container:'viewDiv2',
        map: map,
        center: [this.props.long,this.props.lat],
        zoom: this.props.zoom
      });

      for(let i=0;i<this.props.locations.length;i++){
    var point={
      type:'point',
      longitude:this.props.locations[i].longitude,
      latitude:this.props.locations[i].latitude
    };

    var webStyleSymbol = new WebStyleSymbol({
       name: "Pushpin 4",
  styleName: "EsriIconsStyle"
  
               });

       var pointGraphic = new Graphic({
         geometry: point,
         symbol: webStyleSymbol
       });
       view.graphics.add(pointGraphic);
      
    }
    

      var search = new Search({
    view: view
    });

    var legend= new Legend({
      view:view,
    });

    legend.style={
      type:'card',
      layout:'auto'

    }
       view.ui.add(
    legend,

    "bottom-right"
    );
    view.ui.add(search, "top-right");

    var fullscreen = new Fullscreen({
  view: view
});
view.ui.add(fullscreen, "bottom-left");
       
     

      }
      else if(this.state.variable==='SS8570'){
        
          const defaultSym = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255, 0.5],
            width: "0.5px"
        }
    }
    const renderer = {
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: defaultSym,
        // label: "Flood at RCP 85 shapefile projection",
visualVariables: [
            {
            type: "color",
            field: "SS8570",
            stops: [
                {
                value: 0.5,
                color: "#FFFFCC",
                label: "0 - 0.5"
                },
                {
                value: 1,
                color: "#FFEDA0",
                label: "0.5 - 1"
                },
                {
                value: 1.5,
                color: "#FEB24C",
                label: "1 - 1.5"
                },
                {
                value: 2,
                color: "#FC4E2A",
                label: "1.5 - 2"
                },
                {
                value: 2.5,
                color: "#E31A1C",
                label: "2 - 2.5"  
                },
                {
                value: 3,
                color: "#BD0026",
                label: "2.5 - 3"
                },
                {
                value: 4.8,
                color: "#940025",
                label: "3- 4.8"
                },
                {
                value: 5.36,
                color: "#67001F",
                label: "4.8 - 5.36"
                }
            ]
            }
        ]    };
    // Adding shapefile as a feature layer
    const povLayer = new FeatureLayer({
        url: "https://services9.arcgis.com/VkScCoIvdvWoNy79/arcgis/rest/services/SS_HK/FeatureServer",
        renderer: renderer,
        title: "Storm Surge at RCP 8.5 Year 2050",
        popupTemplate: {
            // autocasts as new PopupTemplate()
            title: "{name}, {Type}",
            content:
            "Flood Value {SS8570}",
        }
    });


    
      const map = new ArcGISMap({
        basemap: 'streets-night-vector',
        layers:[povLayer]
        
      });

      const view = new MapView({
        container:'viewDiv2',
        map: map,
        center: [this.props.long,this.props.lat],
        zoom: this.props.zoom/8
      });
           for(let i=0;i<this.props.locations.length;i++){
    var point={
      type:'point',
      longitude:this.props.locations[i].longitude,
      latitude:this.props.locations[i].latitude
    };

    var webStyleSymbol = new WebStyleSymbol({
       name: "Pushpin 4",
  styleName: "EsriIconsStyle"
  
               });

       var pointGraphic = new Graphic({
         geometry: point,
         symbol: webStyleSymbol
       });

      view.graphics.add(pointGraphic);
    }

      var search = new Search({
    view: view
    });

    var legend= new Legend({
      view:view,
    });

    legend.style={
      type:'card',
      layout:'auto'

    }
       view.ui.add(
    legend,

    "bottom-right"
    );
    view.ui.add(search, "top-right");

     var fullscreen = new Fullscreen({
  view: view
});
view.ui.add(fullscreen, "bottom-left");
       
    
      }
            else if(this.state.variable==='LS8570'){
        console.log("this is storm surege map")
          const defaultSym = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255, 0.5],
            width: "0.5px"
        }
    }
    const renderer = {
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: defaultSym,
        // label: "Flood at RCP 85 shapefile projection",
visualVariables: [
            {
            type: "color",
            field: "LS8570",
            stops: [
                {
                value: 6,
                color: "#FFFFCC",
                label: "0 - 6"
                },
                {
                value: 18,
                color: "#FFEDA0",
                label: "6 - 18"
                },
                {
                value: 30,
                color: "#FEB24C",
                label: "18 - 30"
                },
                {
                value: 42,
                color: "#FC4E2A",
                label: "30 - 42"
                },
                {
                value: 54,
                color: "#E31A1C",
                label: "42 - 54"
                },
                {
                value: 66,
                color: "#BD0026",
                label: "54 - 66"
                },
                {
                value: 78,
                color: "#940025",
                label: "66 - 78"
                },
                {
                value: 96,
                color: "#67001F",
                label: "78 - 96"
                }
            ]
            }
        ]
    };
    // Adding shapefile as a feature layer
    const povLayer = new FeatureLayer({
        url: "https://services9.arcgis.com/VkScCoIvdvWoNy79/arcgis/rest/services/shapefiles/FeatureServer/0",
        renderer: renderer,
        title: "Land Slide at RCP 8.5 Year 2050",
        popupTemplate: {
            // autocasts as new PopupTemplate()
            title: "{name}, {Type}",
            content:
            "Flood Value {LS8570}",
        }
    });


    
      const map = new ArcGISMap({
        basemap: 'streets-night-vector',
        layers:[povLayer]
        
      });

      const view = new MapView({
        container:'viewDiv2',
        map: map,
        center: [this.props.long,this.props.lat],
        zoom: this.props.zoom
      });

           for(let i=0;i<this.props.locations.length;i++){
    var point={
      type:'point',
      longitude:this.props.locations[i].longitude,
      latitude:this.props.locations[i].latitude
    };

    var webStyleSymbol = new WebStyleSymbol({
       name: "Pushpin 4",
  styleName: "EsriIconsStyle"
  
               });

       var pointGraphic = new Graphic({
         geometry: point,
         symbol: webStyleSymbol
       });

      view.graphics.add(pointGraphic);
    }

      var search = new Search({
    view: view
    });

    var legend= new Legend({
      view:view,
    });

    legend.style={
      type:'card',
      layout:'auto'

    }
       view.ui.add(
    legend,

    "bottom-right"
    );
    view.ui.add(search, "top-right");

     var fullscreen = new Fullscreen({
  view: view
});
view.ui.add(fullscreen, "bottom-left");
       
      
      }
         else if(this.state.variable==='SLR8570'){
        
          const defaultSym = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255, 0.5],
            width: "0.5px"
        }
    }
    const renderer = {
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: defaultSym,
        // label: "Flood at RCP 85 shapefile projection",
visualVariables: [
            {
            type: "color",
            field: "SLR8570",
            stops: [
                {
                value: 0.018499169,
                color: "#00FF00",
                label: "0 - 0.1"
                },
                {
                value: 0.393814928,
                color: "#FFFF00",
                label: "0.1 - 0.2"
                },
                {
                value: 0.705052385,
                color: "#FF8000",
                label: "0.2 - 0.3"
                },
                {
                value: 1.052906015,
                color: "#FF0000",
                label: "0.3 - 0.4"
                },
                {
                value: 1.666226888,
                color: "#E31A1C",
                label: "0.5 - 1"
                },
                {
                value: 2.352780104,
                color: "#BD0026",
                label: "1 - 1.5"
                },
                {
                value: 2.5,
                color: "#940025",
                label: "2- 3.5"
                }
            ]
            }
        ]
    };
    // Adding shapefile as a feature layer
    const povLayer = new FeatureLayer({
        url: "https://services9.arcgis.com/VkScCoIvdvWoNy79/arcgis/rest/services/SSLR_8570/FeatureServer",
        renderer: renderer,
        title: "Sea Level Rise at RCP 8.5 Year 2050",
        popupTemplate: {
            // autocasts as new PopupTemplate()
            title: "{name}, {Type}",
            content:
            "Flood Value {SLR8570}",
        }
    });


    
      const map = new ArcGISMap({
        basemap: 'streets-night-vector',
        layers:[povLayer]
        
      });

      const view = new MapView({
        container:'viewDiv2',
        map: map,
        center: [this.props.long,this.props.lat],
        zoom: this.props.zoom/8
      });
    
           for(let i=0;i<this.props.locations.length;i++){
    var point={
      type:'point',
      longitude:this.props.locations[i].longitude,
      latitude:this.props.locations[i].latitude
    };

    var webStyleSymbol = new WebStyleSymbol({
       name: "Pushpin 4",
  styleName: "EsriIconsStyle"
  
               });

       var pointGraphic = new Graphic({
         geometry: point,
         symbol: webStyleSymbol
       });

      view.graphics.add(pointGraphic);
    }

      var search = new Search({
    view: view
    });

    var legend= new Legend({
      view:view,
    });

    legend.style={
      type:'card',
      layout:'auto'

    }
       view.ui.add(
    legend,

    "bottom-right"
    );
    view.ui.add(search, "top-right");

     var fullscreen = new Fullscreen({
  view: view
});
view.ui.add(fullscreen, "bottom-left");
       
      
      }
         });
  

      return (<div id ='viewDiv2' style={{padding:'1.2rem',width:'100%'}}></div>)

    }


  }
