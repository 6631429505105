import * as React from "react";
import { useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Popup, FlyToInterpolator } from "react-map-gl";

import { ConsoleSqlOutlined } from "@ant-design/icons";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax

const LayerMap = (props) => {
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 450,
    latitude: 22,
    longitude: 113,
    zoom: 1,
  });

  return (
    <div>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="450px"
        mapStyle="mapbox://styles/pramodkumarm/ckybfdq8v87c814nu7jpkn6ei"
        mapboxApiAccessToken="pk.eyJ1IjoicHJhbW9ka3VtYXJtIiwiYSI6ImNrdWk5YzNyZTA3cHkycHA2aGhqcG5sNWEifQ.eQGLRDYefA-Y16r1xisVjA"
      ></ReactMapGL>
    </div>
  );
};
export default LayerMap;
