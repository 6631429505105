const initialState ={
	feedback:[],
	errors:{},
	building:[],
	detail:[],
	detailyear:[],
	isLoading:true,
	summary:[],
};

export default function feedback(state=initialState,action){
	switch(action.type){
		case 'DASHBOARD_LOADING':
			return{...state,isLoading:true};
		case 'ADD_DASHBOARD':
			return {...state,feedback:action.feedback,isLoading:false};
		case 'GET_BUILDING':
			return {...state,building:action.building,isLoading:false};
		case 'GET_SUMMARY':
			return {...state,summary:action.summary,isLoading:false};
		case 'GET_DETAIL':
			return {...state,detail:action.detail}
		case 'GET_DETAIL_YEAR':
			return {...state,detailyear:action.detailyear}	
		case 'AUTHENTICATION_ERROR':
		case 'DASHBOARD_FAIL':
			return {...state,errors:action.data,feedback:null,isLoading:false};
		case 'BUILDING_FAIL':
			return {...state,errors:action.data,building:null};
		case 'DETAIL_FAIL':
			return {...state,errors:action.data,detail:null};
		case 'SUMMARY_FAIL':
			return {...state,errors:action.data,summary:null};
		case 'DETAIL_YEAR_FAIL':
			return {...state,errors:action.data,detailyear:null}
		default:
			return state;
	}
}