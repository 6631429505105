import React, { Component } from "react";
import {
  Form,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Menu,
  Select,
  Dropdown,
  Icon,
  Table,
  Modal,
  Card,
} from "semantic-ui-react";
import cover from "../assets/cover.jpg";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import jsPDF from "jspdf";
import "./tcfd.css";
import html2canvas from "html2canvas";
import EditableTable from "./table";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Row, Col, Tabs, Tab } from "react-bootstrap";

let heatmapdata = [];
let data = [];

class TCFD extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    input: "",
    text: "",
    activeStep: 4,
    edit: 0,
    ques1: "",
    ques2: "",
    ques3: "",
    ques4: "",
    ques5: "",
    ques6: "",
    ques7: "",
    ques8: "",
    ques9: "",
    governques1: "",
    governques2: "",
    governques3: "",
    governques4: "",
    governques5: "",
    governques6: "",
    governques7: "",
    stratques1: "",
    stratques2: "",
    stratques3: "",
    stratques4: "",
    stratques5: "",
    stratques6: "",
    stratques7: "",
    stratques8: "",
    stratques9: "",
    stratques10: "",
    riskques1: "",
    riskques2: "",
    riskques3: "",
    riskques4: "",

    metricques1: "",
    metricques2: "",
    metricques3: "",
    metricques4: "",
    metricques5: "",
    tcfd_table: "",
    tcfd_bar: "",
  };

  printDocument() {
    window.print();
  }

  componentDidMount() {
    let formdata = new FormData();
    formdata.append("project_name", "Project - dict_1");
    this.props.getReport(formdata);
    this.props.getTcfdReport(formdata);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tcfd !== this.props.tcfd) {
      this.setState(
        {
          tcfd_table: this.props.tcfd.asset_tables,
          tcfd_bar: this.props.tcfd["overall_barchart"],
          tcfd_heat: this.props.tcfd["heatmaps"],
        },
        () => console.log("tcfd", this.state.tcfd_bar)
      );
    }
  }
  getSteps() {
    return [
      "Cover Page",
      "Questionare",
      "Strategy",
      "Governance",
      "Risk Management",
      "Metrics  and Targets",
      "Download TCFD Report",
    ];
  }
  getNumberofSteps() {
    return 7;
  }
  Edit = () => {
    this.setState({ edit: 1 });
  };
  getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Grid centered className="tcfd">
            {/*<p style={{textAlign:'left'}}>Recommendation and Supporting Recommended Disclosures</p>*/}
            <Image src={cover} size="massive" style={{ marginTop: "15px" }} />
          </Grid>
        );
      case 1:
        return (
          <Grid centered className="tcfd-page-break">
            {/*<p style={{textAlign:'left'}}>Recommendation and Supporting Recommended Disclosures</p>*/}

            <Row>
              <Grid.Column
                computer={3}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#d8e9ec" }}
              >
                <Header
                  as="h1"
                  style={{ backgroundColor: "#2092bc", color: "white" }}
                >
                  Governance
                </Header>
                <p>
                  Disclose the organization's governance around climate related
                  risks and opportunities.
                </p>
              </Grid.Column>
              <Grid.Column
                computer={3}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#c2d3e1" }}
              >
                <Header
                  as="h1"
                  style={{ backgroundColor: "#073674", color: "white" }}
                >
                  Strategy
                </Header>
                <p>
                  Disclose the actual and potential impacts of climate related
                  risks and opportunities on the organization's
                  businesses,strategy and financial planning where such
                  information is material
                </p>
              </Grid.Column>
              <Grid.Column
                computer={3}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#ced2d6" }}
              >
                <Header
                  as="h1"
                  style={{ backgroundColor: "#343741", color: "white" }}
                >
                  Risk Management
                </Header>
                <p>
                  Disclose how the organization identifies ,assesses and manages
                  climate related risks
                </p>
              </Grid.Column>
              <Grid.Column
                computer={3}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#dedede" }}
              >
                <Header
                  as="h1"
                  style={{ backgroundColor: "#878b8f", color: "white" }}
                >
                  Metrics and Targets
                </Header>
                <p>
                  Disclose the metrics and targets used to assess and manage
                  relevant climate related risks and opportunities where such
                  information is material
                </p>
              </Grid.Column>
            </Row>
            <Row className="disc">
              <Grid.Column computer={3} tablet={3} mobile={3}>
                <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                <hr></hr>
                <p>
                  a)Describe the board's oversight of climate risks and
                  opportunities
                </p>

                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques1: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques1}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques1}
                      onChange={(e) => this.setState({ ques1: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques1}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report != "null" ? (
                  <p>{this.props.report.ques1}</p>
                ) : null}

                <hr></hr>
                <p>
                  b)Describe management's role in assessing and managing
                  climate-related risks and opportunities
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques2: e.target.value })}
                      strategyle={{ borderRadius: "0px" }}
                    >
                      {this.state.ques2}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques2}
                      onChange={(e) => this.setState({ ques2: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques2}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques2}</p>
                ) : null}
                <hr></hr>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3} mobile={3}>
                <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                <hr></hr>
                <p>
                  a)Describe the climate related risks and opportunities the
                  organization has identified over the short,medium and long
                  term
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques3: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques3}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques3}
                      onChange={(e) => this.setState({ ques3: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques3}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques3}</p>
                ) : null}
                <hr></hr>
                <p>
                  b)Describe the impact of climate-related risks and
                  opportunities on the organization's businesses,strategy and
                  financial planning
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques4: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques4}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques4}
                      onChange={(e) => this.setState({ ques4: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques4}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques4}</p>
                ) : null}
                <hr></hr>
                <p>
                  c)Describe the resillience of the organization's
                  strategy,taking into consideration different cliamte related
                  scenarios including a 2c or lower scenario{" "}
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques5: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques5}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques5}
                      onChange={(e) => this.setState({ ques5: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques5}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques5}</p>
                ) : null}
                <hr></hr>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3} mobile={3}>
                <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                <hr></hr>
                <p>
                  a)Describe the board's oversight of climate risks and
                  opportunities
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques6: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques6}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques6}
                      onChange={(e) => this.setState({ ques6: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques6}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques6}</p>
                ) : null}
                <hr></hr>
                <p>
                  a)Describe the board's oversight of climate risks and
                  opportunities
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques7: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques7}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques7}
                      onChange={(e) => this.setState({ ques7: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques7}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques7}</p>
                ) : null}
                <hr></hr>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3} mobile={3}>
                <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                <hr></hr>
                <p>
                  a)Describe the board's oversight of climate risks and
                  opportunities
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques8: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques8}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques8}
                      onChange={(e) => this.setState({ ques8: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques8}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques8}</p>
                ) : null}
                <hr></hr>
                <p>
                  a)Describe the board's oversight of climate risks and
                  opportunities
                </p>
                {this.state.edit && this.props.report == "null" ? (
                  <Form.Field>
                    <TextArea
                      placeholder="Answer"
                      onChange={(e) => this.setState({ ques9: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques9}
                    </TextArea>
                  </Form.Field>
                ) : this.state.edit && this.props.report ? (
                  <Form.Field>
                    <TextArea
                      placeholder={this.props.report.ques9}
                      onChange={(e) => this.setState({ ques9: e.target.value })}
                      style={{ borderRadius: "0px" }}
                    >
                      {this.state.ques9}
                    </TextArea>
                  </Form.Field>
                ) : this.props.report ? (
                  <p>{this.props.report.ques9}</p>
                ) : null}
                <hr></hr>
              </Grid.Column>
            </Row>
          </Grid>
        );
      case 2:
        return (
          <Grid centered className="tcfdtable">
            <Row>
              <Grid.Column computer={8} style={{ backgroundColor: "#2092bc" }}>
                <Header as="h1">
                  Governance
                  <p>
                    Disclose the organization’s governance around
                    climate-related risks and opportunities.
                  </p>
                </Header>
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#d8e9ec" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  a) Describe the board’s oversight of climate-related risks and
                  opportunities.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      In describing the board’s oversight of climate-related
                      issues, organizations should consider including a
                      discussion of the following:
                      <br />
                      ‒ processes and frequency by which the board and/or board
                      committees (e.g., audit, risk, or other committees) are
                      informed about climate-related issues,
                      <br />
                      ‒ whether the board and/or board committees consider
                      climate-related issues when reviewing and guiding
                      strategy, major plans of action, risk management policies,
                      annual budgets, and business plans as well as setting
                      Scenario Analysis and Climate-Related Issues the
                      organization’s performance objectives, monitoring
                      implementation and performance, and overseeing major
                      capital expenditures, acquisitions, and Key Issues
                      Considered and Areas for Further Work Conclusion
                      Appendices divestitures, and
                      <br />‒ how the board monitors and oversees progress
                      against goals and targets for addressing climate-related
                      issues.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques1}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques2}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques3}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      In describing the board’s oversight of climate-related
                      issues, organizations should consider including a
                      discussion of the following:
                      <br />
                      ‒ processes and frequency by which the board and/or board
                      committees (e.g., audit, risk, or other committees) are
                      informed about climate-related issues,
                      <br />
                      ‒ whether the board and/or board committees consider
                      climate-related issues when reviewing and guiding
                      strategy, major plans of action, risk management policies,
                      annual budgets, and business plans as well as setting
                      Scenario Analysis and Climate-Related Issues the
                      organization’s performance objectives, monitoring
                      implementation and performance, and overseeing major
                      capital expenditures, acquisitions, and Key Issues
                      Considered and Areas for Further Work Conclusion
                      Appendices divestitures, and
                      <br />‒ how the board monitors and oversees progress
                      against goals and targets for addressing climate-related
                      issues.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques1}
                        onChange={(e) =>
                          this.setState({ governques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques1}
                      </TextArea>
                    </Form.Field>

                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques2}
                        onChange={(e) =>
                          this.setState({ governques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques2}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques3}
                        onChange={(e) =>
                          this.setState({ governques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques3}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.governques1}
                    <br />
                    {this.props.report.governques2}
                    <br />

                    {this.props.report.governques3}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#d8e9ec" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  b) Describe management’s role in assessing and managing
                  climate- related risks and opportunities.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      In describing management’s role related to the assessment
                      and management of climate-related issues, organizations
                      should consider including the following information:
                      <br />
                      ‒ whether the organization has assigned climate-related
                      responsibilities to management-level positions or
                      committees; and, if so, whether such management positions
                      or committees report to the board or a committee of the
                      board and whether those responsibilities include assessing
                      and/or managing climate-related issues,
                      <br />
                      ‒ a description of the associated organizational
                      structure(s),
                      <br />
                      ‒ processes by which management is informed about
                      climate-related issues, and
                      <br />‒ how management (through specific positions and/or
                      management committees) monitors climate-related issues.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques4}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques5}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques6: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques6}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ governques7: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques7}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      In describing management’s role related to the assessment
                      and management of climate-related issues, organizations
                      should consider including the following information:
                      <br />
                      ‒ whether the organization has assigned climate-related
                      responsibilities to management-level positions or
                      committees; and, if so, whether such management positions
                      or committees report to the board or a committee of the
                      board and whether those responsibilities include assessing
                      and/or managing climate-related issues,
                      <br />
                      ‒ a description of the associated organizational
                      structure(s),
                      <br />
                      ‒ processes by which management is informed about
                      climate-related issues, and
                      <br />‒ how management (through specific positions and/or
                      management committees) monitors climate-related issues.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques4}
                        onChange={(e) =>
                          this.setState({ governques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques4}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques5}
                        onChange={(e) =>
                          this.setState({ governques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques5}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques6}
                        onChange={(e) =>
                          this.setState({ governques6: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques6}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.governques7}
                        onChange={(e) =>
                          this.setState({ governques7: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.governques7}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.governques4}
                    <br />
                    {this.props.report.governques5}
                    <br />
                    {this.props.report.governques6}
                    <br />
                    {this.props.report.governques7}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
          </Grid>
        );
      case 3:
        return (
          <Grid centered className="tcfdtable">
            <Row>
              <Grid.Column computer={8} style={{ backgroundColor: "#073674" }}>
                <Header as="h1">
                  Strategy
                  <p>
                    Disclose the actual and potential impacts of climate-related
                    risks and opportunities on the organization’s businesses,
                    strategy, and financial planning where such information is
                    material.
                  </p>
                </Header>
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#d8e9ec" }}
              >
                <Header as="h3">Recommended Disclosure</Header>
                <p>
                  a) Describe the climate- related risks and opportunities the
                  organization has identified over the short, medium, and long
                  term.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organization should provide the following information:
                      <br />‒ a description of what they consider to be relevant
                      short-,medium-,and long-term time horizons ,taking into
                      consideration the useful life of the organization's assets
                      or infrastructure and the fact that climate-related issues
                      often manifest themselves over the medium and longer-terms
                      ‒ a description of the specific climate-related issues for
                      each time horizon (short,medium and long term) that could
                      have a material financial impact on the organization,and{" "}
                      <br />
                      -a description of the process(es) used to determine which
                      risks and opportunities could have a material financial
                      impact on the organization.
                      <br />
                      Organizations should consider providing a description of
                      their risks and opportunities by Sector and/or geography
                      ,as appropriate.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques1}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques2}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques3}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organization should provide the following information:
                      <br />‒ a description of what they consider to be relevant
                      short-,medium-,and long-term time horizons ,taking into
                      consideration the useful life of the organization's assets
                      or infrastructure and the fact that climate-related issues
                      often manifest themselves over the medium and longer-terms
                      ‒ a description of the specific climate-related issues for
                      each time horizon (short,medium and long term) that could
                      have a material financial impact on the organization,and{" "}
                      <br />
                      -a description of the process(es) used to determine which
                      risks and opportunities could have a material financial
                      impact on the organization.
                      <br />
                      Organizations should consider providing a description of
                      their risks and opportunities by Sector and/or geography
                      ,as appropriate.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques1}
                        onChange={(e) =>
                          this.setState({ stratques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques1}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques2}
                        onChange={(e) =>
                          this.setState({ stratques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques2}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques3}
                        onChange={(e) =>
                          this.setState({ stratques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques3}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.stratques1}
                    <br />
                    {this.props.report.stratques2}
                    <br />
                    {this.props.report.stratques3}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#d8e9ec" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  b) Describe the board’s oversight of climate-related risks and
                  opportunities.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Building on recommended disclosure (a), organizations
                      should discuss how identified climate-related issues have
                      affected their businesses, strategy, and financial
                      planning.
                      <br />
                      Organizations should consider including the impact on
                      their businesses and strategy in the following areas:
                      <br />
                      ‒ Products and services
                      <br />
                      ‒ Supply chain and/or value chain
                      <br />
                      ‒ Adaptation and mitigation activities
                      <br />
                      ‒ Investment in research and development
                      <br />
                      ‒ Operations (including types of operations and location
                      of facilities)
                      <br />
                      Organizations should describe how climate-related issues
                      serve as an input to their financial planning process, the
                      time period(s) used, and how these risks and opportunities
                      are prioritized. Organizations’ disclosures should reflect
                      a holistic picture of the interdependencies among the
                      factors that affect their ability to create value over
                      time. Organizations should also consider including in
                      their disclosures the impact on financial planning in the
                      following areas:
                      <brb />
                      ‒ Operating costs and revenues
                      <br />
                      ‒ Capital expenditures and capital allocation
                      <br />
                      ‒ Acquisitions or divestments
                      <br />
                      ‒ Access to capital
                      <br />
                      If climate-related scenarios were used to inform the
                      organization’s strategy and financial planning, such
                      scenarios should be described.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques4}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques5}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques6: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques6}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques7: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques7}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Building on recommended disclosure (a), organizations
                      should discuss how identified climate-related issues have
                      affected their businesses, strategy, and financial
                      planning.
                      <br />
                      Organizations should consider including the impact on
                      their businesses and strategy in the following areas:
                      <br />
                      ‒ Products and services
                      <br />
                      ‒ Supply chain and/or value chain
                      <br />
                      ‒ Adaptation and mitigation activities
                      <br />
                      ‒ Investment in research and development
                      <br />
                      ‒ Operations (including types of operations and location
                      of facilities)
                      <br />
                      Organizations should describe how climate-related issues
                      serve as an input to their financial planning process, the
                      time period(s) used, and how these risks and opportunities
                      are prioritized. Organizations’ disclosures should reflect
                      a holistic picture of the interdependencies among the
                      factors that affect their ability to create value over
                      time. Organizations should also consider including in
                      their disclosures the impact on financial planning in the
                      following areas:
                      <brb />
                      ‒ Operating costs and revenues
                      <br />
                      ‒ Capital expenditures and capital allocation
                      <br />
                      ‒ Acquisitions or divestments
                      <br />
                      ‒ Access to capital
                      <br />
                      If climate-related scenarios were used to inform the
                      organization’s strategy and financial planning, such
                      scenarios should be described.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques4}
                        onChange={(e) =>
                          this.setState({ stratques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques4}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques5}
                        onChange={(e) =>
                          this.setState({ stratques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques5}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques6}
                        onChange={(e) =>
                          this.setState({ stratques6: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques6}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques7}
                        onChange={(e) =>
                          this.setState({ stratques7: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques7}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.stratques4}
                    <br />
                    {this.props.report.stratques5}
                    <br />
                    {this.props.report.stratques6}
                    <br />
                    {this.props.report.stratques7}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#d8e9ec" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  c) Describe the resilience of the organization’s strategy,
                  taking into consideration different climate-related scenarios,
                  including a 2°C or lower scenario.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organization should provide the following information:
                      <br />‒ a description of what they consider to be relevant
                      short-,medium-,and long-term time horizons ,taking into
                      consideration the useful life of the organization's assets
                      or infrastructure and the fact that climate-related issues
                      often manifest themselves over the medium and longer-terms
                      ‒ a description of the specific climate-related issues for
                      each time horizon (short,medium and long term) that could
                      have a material financial impact on the organization,and{" "}
                      <br />
                      -a description of the process(es) used to determine which
                      risks and opportunities could have a material financial
                      impact on the organization.
                      <br />
                      Organizations should consider providing a description of
                      their risks and opportunities by Sector and/or geography
                      ,as appropriate.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques8: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques8}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques9: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques9}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ stratques10: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques10}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organization should provide the following information:
                      <br />‒ a description of what they consider to be relevant
                      short-,medium-,and long-term time horizons ,taking into
                      consideration the useful life of the organization's assets
                      or infrastructure and the fact that climate-related issues
                      often manifest themselves over the medium and longer-terms
                      ‒ a description of the specific climate-related issues for
                      each time horizon (short,medium and long term) that could
                      have a material financial impact on the organization,and{" "}
                      <br />
                      -a description of the process(es) used to determine which
                      risks and opportunities could have a material financial
                      impact on the organization.
                      <br />
                      Organizations should consider providing a description of
                      their risks and opportunities by Sector and/or geography
                      ,as appropriate.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques8}
                        onChange={(e) =>
                          this.setState({ stratques8: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques8}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques9}
                        onChange={(e) =>
                          this.setState({ stratques9: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques9}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.stratques10}
                        onChange={(e) =>
                          this.setState({ stratques10: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.stratques10}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.stratques8}
                    <br />
                    {this.props.report.stratques9}
                    <br />
                    {this.props.report.stratques10}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
          </Grid>
        );
      case 4:
        return (
          <Grid centered className="tcfdtable">
            <Row>
              <Grid.Column computer={8} style={{ backgroundColor: "#3b3b3b" }}>
                <Header as="h1">
                  Risk Management
                  <p>
                    Disclose how the organization identifies, assesses, and
                    manages climate-related risks.
                  </p>
                </Header>
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#e8e8e8" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  a) Describe the organization’s processes for identifying and
                  assessing climate- related risks.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should describe their risk management
                      processes for identifying and assessing climate-related
                      risks. An important aspect of this description is how
                      organizations determine the relative significance of
                      climate-related risks in relation to other risks.
                      <br />
                      Organizations should describe whether they consider
                      existing and emerging regulatory requirements related to
                      climate change (e.g., limits on emissions) as well as
                      other relevant factors considered.
                      <br />
                      Organizations should also consider disclosing the
                      following:
                      <br />
                      ‒ processes for assessing the potential size and scope of
                      identified climate- related risks and
                      <br />‒ definitions of risk terminology used or references
                      to existing risk classification frameworks used.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ riskques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques1}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ riskques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques2}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should describe their risk management
                      processes for identifying and assessing climate-related
                      risks. An important aspect of this description is how
                      organizations determine the relative significance of
                      climate-related risks in relation to other risks.
                      <br />
                      Organizations should describe whether they consider
                      existing and emerging regulatory requirements related to
                      climate change (e.g., limits on emissions) as well as
                      other relevant factors considered.
                      <br />
                      Organizations should also consider disclosing the
                      following:
                      <br />
                      ‒ processes for assessing the potential size and scope of
                      identified climate- related risks and
                      <br />‒ definitions of risk terminology used or references
                      to existing risk classification frameworks used.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.riskques1}
                        onChange={(e) =>
                          this.setState({ riskques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques1}
                      </TextArea>
                    </Form.Field>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.riskques2}
                        onChange={(e) =>
                          this.setState({ riskques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques2}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.riskques1}
                    <br />
                    {this.props.report.riskques2}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#e8e8e8" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  b) Describe the organization’s processes for managing climate-
                  related risks..
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should describe their processes for managing
                      climate-related risks, including how they make decisions
                      to mitigate, transfer, accept, or control those risks. In
                      addition, organizations should describe their processes
                      for prioritizing climate-related risks, including how
                      materiality determinations are made within their
                      organizations.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ riskques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques3}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should describe their processes for managing
                      climate-related risks, including how they make decisions
                      to mitigate, transfer, accept, or control those risks. In
                      addition, organizations should describe their processes
                      for prioritizing climate-related risks, including how
                      materiality determinations are made within their
                      organizations.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.riskques3}
                        onChange={(e) =>
                          this.setState({ riskques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques3}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>{this.props.report.riskques3}</p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#e8e8e8" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  c) Describe the organization’s processes for managing climate-
                  related risks..
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Describe how processes for identifying, assessing, and
                      managing climate-related risks are integrated into the
                      organization’s overall risk management.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ riskques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques4}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Describe how processes for identifying, assessing, and
                      managing climate-related risks are integrated into the
                      organization’s overall risk management.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.riskques4}
                        onChange={(e) =>
                          this.setState({ riskques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.riskques4}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>{this.props.report.riskques4}</p>
                ) : null}
              </Grid.Column>
            </Row>

            <hr></hr>
            <br />
            <Row>
              <Row className="mainheading" style={{ width: "100%" }}>
                Asset Table
              </Row>
              {this.state.tcfd_table ? (
                <EditableTable data={this.state.tcfd_table["2650"]} />
              ) : null}
              <br />
            </Row>
            <Row>
              <Row className="mainheading" style={{ width: "100%" }}>
                Climate Risk Increase By 2050
              </Row>
              <Grid.Column computer={16} style={{ height: "500px" }}>
                {data.length > 0 ? (
                  <ResponsiveBar
                    data={data}
                    keys={["RCP2.6", "RCP4.5", "RCP8.5"]}
                    indexBy="name"
                    margin={{ top: 60, right: 130, bottom: 100, left: 50 }}
                    padding={0.3}
                    enableContainerY={false}
                    groupMode="grouped"
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: false }}
                    colors={["#bac3d2", "#6c85bd", "#60b1cc"]}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "#38bcb2",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "#eed312",
                        rotation: -45,
                        lineWidth: 1,
                        spacing: 10,
                        padding: 2,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: ``,
                        },
                        id: "gradientA",
                      },
                      {
                        match: {
                          id: "sandwich",
                        },
                        id: "lines",
                      },
                    ]}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 10,
                      tickRotation: 0,
                      legend: "Year",
                      legendPosition: "middle",
                      legendOffset: 40,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Climate  Risk  Index",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    enableLabel={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                  />
                ) : null}
              </Grid.Column>
            </Row>
            <Row style={{ height: "500px" }}>
              <Row className="mainheading" style={{ width: "100%" }}>
                Climate Risk HeatMap(0 to 10)
              </Row>
              {heatmapdata.length > 0 ? (
                <ResponsiveHeatMap
                  data={heatmapdata}
                  keys={[
                    "River Flood",
                    "Rainfall Flood",
                    "Typhoon",
                    "Storm Surge",
                    "Extreme Heat",
                    "Drought",
                  ]}
                  indexBy="asset"
                  margin={{ top: 0, right: 0, bottom: 80, left: 160 }}
                  forceSquare={false}
                  axisBottom={{
                    orient: "top",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendOffset: 36,
                  }}
                  axisRight={null}
                  axisTop={null}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,

                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  cellOpacity={1}
                  cellShape="circle"
                  minValue={0}
                  maxValue={10}
                  sizeVariation={0.8}
                  enableContainerX={true}
                  enableContainerY={true}
                  cellBorderWidth={2}
                  colors="YlOrRd"
                  cellBorderColor="#ffffff"
                  labelTextColor="black"
                  defs={[
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "rgba(0, 0, 0, 0.1)",
                      rotation: -45,
                      lineWidth: 4,
                      spacing: 7,
                    },
                  ]}
                  fill={[{ id: "lines" }]}
                  animate={true}
                  motionConfig="wobbly"
                  motionStiffness={80}
                  motionDamping={9}
                  hoverTarget="cell"
                  cellHoverOthersOpacity={0.25}
                  tooltip={({ xKey, yKey, value, color }) => (
                    <strong style={{ color }}>
                      {xKey} / {yKey}: {value}
                    </strong>
                  )}
                  theme={{
                    tooltip: {
                      container: {
                        background: "black",
                      },
                    },
                  }}
                />
              ) : null}
            </Row>
            <br />
          </Grid>
        );
      case 5:
        return (
          <Grid centered className="tcfdtable">
            <Row>
              <Grid.Column computer={8} style={{ backgroundColor: "#858585" }}>
                <Header as="h1">
                  Metrics and Targets
                  <p>
                    Disclose the metrics and targets used to assess and manage
                    relevant climate-related risks and opportunities where such
                    information is material.
                  </p>
                </Header>
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#e0e0e0" }}
              >
                <Header as="h3">Recommended Disclosure</Header>
                <p>
                  a) Disclose the metrics used by the organization to assess
                  climate-related risks and opportunities in line with its
                  strategy and risk management process.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should provide the key metrics used to
                      measure and manage climate-related risks and
                      opportunities, as described in Organizations should
                      consider including metrics on climate-related risks
                      associated with water, energy, land use, and waste
                      management where relevant and applicable.
                      <br />
                      Where climate-related issues are material, organizations
                      should consider describing whether and how related
                      performance metrics are incorporated into remuneration
                      policies.
                      <br />
                      Where relevant, organizations should provide their
                      internal carbon prices as well as climate-related
                      opportunity metrics such as revenue from products and
                      Metrics should be provided for historical periods to allow
                      for trend analysis. In services designed for a
                      lower-carbon economy.
                      <br />
                      addition, where not apparent, organizations should provide
                      a description of the methodologies used to calculate or
                      estimate climate-related metrics.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ metricques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques1}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should provide the key metrics used to
                      measure and manage climate-related risks and
                      opportunities, as described in Organizations should
                      consider including metrics on climate-related risks
                      associated with water, energy, land use, and waste
                      management where relevant and applicable.
                      <br />
                      Where climate-related issues are material, organizations
                      should consider describing whether and how related
                      performance metrics are incorporated into remuneration
                      policies.
                      <br />
                      Where relevant, organizations should provide their
                      internal carbon prices as well as climate-related
                      opportunity metrics such as revenue from products and
                      Metrics should be provided for historical periods to allow
                      for trend analysis. In services designed for a
                      lower-carbon economy.
                      <br />
                      addition, where not apparent, organizations should provide
                      a description of the methodologies used to calculate or
                      estimate climate-related metrics.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.metricques1}
                        onChange={(e) =>
                          this.setState({ metericques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metericques1}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>{this.props.report.metricques1}</p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#e0e0e0" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  b) Disclose Scope 1, Scope 2, and, if appropriate, Scope 3
                  greenhouse gas (GHG) emissions, and the related risks.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should provide their Scope 1 and Scope 2 GHG
                      emissions and, if appropriate, Scope 3 GHG emissions and
                      the related risks.
                      <br />
                      GHG emissions should be calculated in line with the GHG
                      Protocol methodology to allow for aggregation and
                      comparability across organizations and jurisdictions. 40
                      As appropriate, organizations should consider providing
                      related, generally accepted industry-specific GHG
                      efficiency ratios.
                      <br />
                      GHG emissions and associated metrics should be provided
                      for historical periods to allow for trend analysis. In
                      addition, where not apparent, organizations should provide
                      a description of the methodologies used to calculate or
                      estimate the metrics.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ metricques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques2}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should provide their Scope 1 and Scope 2 GHG
                      emissions and, if appropriate, Scope 3 GHG emissions and
                      the related risks.
                      <br />
                      GHG emissions should be calculated in line with the GHG
                      Protocol methodology to allow for aggregation and
                      comparability across organizations and jurisdictions. 40
                      As appropriate, organizations should consider providing
                      related, generally accepted industry-specific GHG
                      efficiency ratios.
                      <br />
                      GHG emissions and associated metrics should be provided
                      for historical periods to allow for trend analysis. In
                      addition, where not apparent, organizations should provide
                      a description of the methodologies used to calculate or
                      estimate the metrics.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.metricques2}
                        onChange={(e) =>
                          this.setState({ metricques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques2}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>{this.props.report.metricques2}</p>
                ) : null}
              </Grid.Column>
            </Row>
            <hr></hr>
            <Row>
              <Grid.Column
                computer={2}
                tablet={3}
                mobile={3}
                style={{ backgroundColor: "#e0e0e0" }}
              >
                <Header as="h3">Recommended Disclosure </Header>
                <p>
                  c) Describe the targets used by the organization to manage
                  climate- related risks and opportunities and performance
                  against targets.
                </p>
              </Grid.Column>

              <Grid.Column computer={6} tablet={3} mobile={3}>
                <Header as="h3">Guidance for All Sectors</Header>
                {this.state.edit && this.props.report == "null" ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should describe their key climate-related
                      targets such as those related to GHG emissions, water
                      usage, energy usage, etc., in line with anticipated
                      regulatory requirements or market constraints or other
                      goals. Other goals may include efficiency or financial
                      goals, financial loss tolerances, avoided GHG emissions
                      through the entire product life cycle, or net revenue
                      goals for products and services designed for a
                      lower-carbon economy. In describing their targets,
                      organizations should consider including the following:
                      <br />
                      ‒ whether the target is absolute or intensity based,
                      <br />
                      ‒ time frames over which the target applies,
                      <br />
                      ‒ base year from which progress is measured, and
                      <br />
                      ‒ key performance indicators used to assess progress
                      against targets.
                      <br />
                      Where not apparent, organizations should provide a
                      description of the methodologies used to calculate targets
                      and measures.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ metricques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques3}
                      </TextArea>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ metricques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques4}
                      </TextArea>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ metricques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques5}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.state.edit && this.props.report ? (
                  <div>
                    <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                      Organizations should describe their key climate-related
                      targets such as those related to GHG emissions, water
                      usage, energy usage, etc., in line with anticipated
                      regulatory requirements or market constraints or other
                      goals. Other goals may include efficiency or financial
                      goals, financial loss tolerances, avoided GHG emissions
                      through the entire product life cycle, or net revenue
                      goals for products and services designed for a
                      lower-carbon economy. In describing their targets,
                      organizations should consider including the following:
                      <br />
                      ‒ whether the target is absolute or intensity based,
                      <br />
                      ‒ time frames over which the target applies,
                      <br />
                      ‒ base year from which progress is measured, and
                      <br />
                      ‒ key performance indicators used to assess progress
                      against targets.
                      <br />
                      Where not apparent, organizations should provide a
                      description of the methodologies used to calculate targets
                      and measures.
                    </p>
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.metricques3}
                        onChange={(e) =>
                          this.setState({ metricques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques3}
                      </TextArea>
                      <TextArea
                        placeholder={this.props.report.metricques4}
                        onChange={(e) =>
                          this.setState({ metricques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques4}
                      </TextArea>

                      <TextArea
                        placeholder={this.props.report.metricques5}
                        onChange={(e) =>
                          this.setState({ metricques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.metricques5}
                      </TextArea>
                    </Form.Field>
                  </div>
                ) : this.props.report != "null" ? (
                  <p>
                    {this.props.report.metricques3}
                    <br />
                    {this.props.report.metricques4}
                    <br />
                    {this.props.report.metricques5}
                  </p>
                ) : null}
              </Grid.Column>
            </Row>
          </Grid>
        );
      case 6:
        return (
          <div id="tcfd">
            <div className="tcfd-download">
              <Button
                onClick={this.printDocument}
                primary
                style={{
                  backgroundColor: "#002060",
                  color: "white",
                  marginLeft: "100%",
                }}
                floated="left"
              >
                Download
              </Button>
            </div>
            <Grid centered className="tcfd-page-break">
              {/*<p style={{textAlign:'left'}}>Recommendation and Supporting Recommended Disclosures</p>*/}
              <Image
                src={cover}
                style={{ marginTop: "15px", height: "1500px", width: "1200px" }}
              />
            </Grid>
            <Grid classname="tcfd-page-break" centered>
              {/*<p style={{textAlign:'left'}}>Recommendation and Supporting Recommended Disclosures</p>*/}

              <Row>
                <Grid.Column
                  computer={3}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header
                    as="h1"
                    style={{ backgroundColor: "#2092bc", color: "white" }}
                  >
                    Governance
                  </Header>
                  <p>
                    Disclose the organization's governance around climate
                    related risks and opportunities.
                  </p>
                </Grid.Column>
                <Grid.Column
                  computer={3}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#c2d3e1" }}
                >
                  <Header
                    as="h1"
                    style={{ backgroundColor: "#073674", color: "white" }}
                  >
                    Strategy
                  </Header>
                  <p>
                    Disclose the actual and potential impacts of climate related
                    risks and opportunities on the organization's
                    businesses,strategy and financial planning where such
                    information is material
                  </p>
                </Grid.Column>
                <Grid.Column
                  computer={3}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#ced2d6" }}
                >
                  <Header
                    as="h1"
                    style={{ backgroundColor: "#5a5a5c", color: "white" }}
                  >
                    Risk Management
                  </Header>
                  <p>
                    Disclose how the organization identifies ,assesses and
                    manages climate related risks
                  </p>
                </Grid.Column>
                <Grid.Column
                  computer={3}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#dedede" }}
                >
                  <Header
                    as="h1"
                    style={{ backgroundColor: "#878b8f", color: "white" }}
                  >
                    Metrics and Targets
                  </Header>
                  <p>
                    Disclose the metrics and targets used to assess and manage
                    relevant climate related risks and opportunities where such
                    information is material
                  </p>
                </Grid.Column>
              </Row>
              <Row className="disc">
                <Grid.Column computer={3} tablet={3} mobile={3}>
                  <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                  <hr></hr>
                  <p>
                    a)Describe the board's oversight of climate risks and
                    opportunities
                  </p>

                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques1}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques1}
                        onChange={(e) =>
                          this.setState({ ques1: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques1}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report != "null" ? (
                    <p>{this.props.report.ques1}</p>
                  ) : null}

                  <hr></hr>
                  <p>
                    b)Describe management's role in assessing and managing
                    climate-related risks and opportunities
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques2: e.target.value })
                        }
                        strategyle={{ borderRadius: "0px" }}
                      >
                        {this.state.ques2}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques2}
                        onChange={(e) =>
                          this.setState({ ques2: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques2}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques2}</p>
                  ) : null}
                  <hr></hr>
                </Grid.Column>
                <Grid.Column computer={3} tablet={3} mobile={3}>
                  <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                  <hr></hr>
                  <p>
                    a)Describe the climate related risks and opportunities the
                    organization has identified over the short,medium and long
                    term
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques3}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques3}
                        onChange={(e) =>
                          this.setState({ ques3: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques3}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques3}</p>
                  ) : null}
                  <hr></hr>
                  <p>
                    b)Describe the impact of climate-related risks and
                    opportunities on the organization's businesses,strategy and
                    financial planning
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques4}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques4}
                        onChange={(e) =>
                          this.setState({ ques4: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques4}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques4}</p>
                  ) : null}
                  <hr></hr>
                  <p>
                    c)Describe the resillience of the organization's
                    strategy,taking into consideration different cliamte related
                    scenarios including a 2c or lower scenario{" "}
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques5}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques5}
                        onChange={(e) =>
                          this.setState({ ques5: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques5}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques5}</p>
                  ) : null}
                  <hr></hr>
                </Grid.Column>
                <Grid.Column computer={3} tablet={3} mobile={3}>
                  <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                  <hr style={{ width: "48%" }}></hr>
                  <p>
                    a)Describe the board's oversight of climate risks and
                    opportunities
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques6: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques6}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques6}
                        onChange={(e) =>
                          this.setState({ ques6: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques6}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques6}</p>
                  ) : null}
                  <hr></hr>
                  <p>
                    a)Describe the board's oversight of climate risks and
                    opportunities
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques7: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques7}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques7}
                        onChange={(e) =>
                          this.setState({ ques7: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques7}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques7}</p>
                  ) : null}
                  <hr></hr>
                </Grid.Column>
                <Grid.Column computer={3} tablet={3} mobile={3}>
                  <p style={{ fontWeight: "bold" }}>Recommended Disclosures</p>
                  <hr></hr>
                  <p>
                    a)Describe the board's oversight of climate risks and
                    opportunities
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques8: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques8}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques8}
                        onChange={(e) =>
                          this.setState({ ques8: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques8}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques8}</p>
                  ) : null}
                  <hr></hr>
                  <p>
                    a)Describe the board's oversight of climate risks and
                    opportunities
                  </p>
                  {this.state.edit && this.props.report == "null" ? (
                    <Form.Field>
                      <TextArea
                        placeholder="Answer"
                        onChange={(e) =>
                          this.setState({ ques9: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques9}
                      </TextArea>
                    </Form.Field>
                  ) : this.state.edit && this.props.report ? (
                    <Form.Field>
                      <TextArea
                        placeholder={this.props.report.ques9}
                        onChange={(e) =>
                          this.setState({ ques9: e.target.value })
                        }
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.ques9}
                      </TextArea>
                    </Form.Field>
                  ) : this.props.report ? (
                    <p>{this.props.report.ques9}</p>
                  ) : null}
                  <hr></hr>
                </Grid.Column>
              </Row>
            </Grid>
            <Grid className="tcfd-page-break" centered>
              <Row>
                <Grid.Column
                  computer={8}
                  style={{ backgroundColor: "#2092bc" }}
                >
                  <Header as="h1">
                    Governance
                    <p>
                      Disclose the organization’s governance around
                      climate-related risks and opportunities.
                    </p>
                  </Header>
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    a) Describe the board’s oversight of climate-related risks
                    and opportunities.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        In describing the board’s oversight of climate-related
                        issues, organizations should consider including a
                        discussion of the following:
                        <br />
                        ‒ processes and frequency by which the board and/or
                        board committees (e.g., audit, risk, or other
                        committees) are informed about climate-related issues,
                        <br />
                        ‒ whether the board and/or board committees consider
                        climate-related issues when reviewing and guiding
                        strategy, major plans of action, risk management
                        policies, annual budgets, and business plans as well as
                        setting Scenario Analysis and Climate-Related Issues the
                        organization’s performance objectives, monitoring
                        implementation and performance, and overseeing major
                        capital expenditures, acquisitions, and Key Issues
                        Considered and Areas for Further Work Conclusion
                        Appendices divestitures, and
                        <br />‒ how the board monitors and oversees progress
                        against goals and targets for addressing climate-related
                        issues.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques1}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques2}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques3}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        In describing the board’s oversight of climate-related
                        issues, organizations should consider including a
                        discussion of the following:
                        <br />
                        ‒ processes and frequency by which the board and/or
                        board committees (e.g., audit, risk, or other
                        committees) are informed about climate-related issues,
                        <br />
                        ‒ whether the board and/or board committees consider
                        climate-related issues when reviewing and guiding
                        strategy, major plans of action, risk management
                        policies, annual budgets, and business plans as well as
                        setting Scenario Analysis and Climate-Related Issues the
                        organization’s performance objectives, monitoring
                        implementation and performance, and overseeing major
                        capital expenditures, acquisitions, and Key Issues
                        Considered and Areas for Further Work Conclusion
                        Appendices divestitures, and
                        <br />‒ how the board monitors and oversees progress
                        against goals and targets for addressing climate-related
                        issues.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques1}
                          onChange={(e) =>
                            this.setState({ governques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques1}
                        </TextArea>
                      </Form.Field>

                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques2}
                          onChange={(e) =>
                            this.setState({ governques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques2}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques3}
                          onChange={(e) =>
                            this.setState({ governques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques3}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.governques1}
                      <br />
                      {this.props.report.governques2}
                      <br />

                      {this.props.report.governques3}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    b) Describe management’s role in assessing and managing
                    climate- related risks and opportunities.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        In describing management’s role related to the
                        assessment and management of climate-related issues,
                        organizations should consider including the following
                        information:
                        <br />
                        ‒ whether the organization has assigned climate-related
                        responsibilities to management-level positions or
                        committees; and, if so, whether such management
                        positions or committees report to the board or a
                        committee of the board and whether those
                        responsibilities include assessing and/or managing
                        climate-related issues,
                        <br />
                        ‒ a description of the associated organizational
                        structure(s),
                        <br />
                        ‒ processes by which management is informed about
                        climate-related issues, and
                        <br />‒ how management (through specific positions
                        and/or management committees) monitors climate-related
                        issues.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques4}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques5: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques5}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques6: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques6}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ governques7: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques7}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        In describing management’s role related to the
                        assessment and management of climate-related issues,
                        organizations should consider including the following
                        information:
                        <br />
                        ‒ whether the organization has assigned climate-related
                        responsibilities to management-level positions or
                        committees; and, if so, whether such management
                        positions or committees report to the board or a
                        committee of the board and whether those
                        responsibilities include assessing and/or managing
                        climate-related issues,
                        <br />
                        ‒ a description of the associated organizational
                        structure(s),
                        <br />
                        ‒ processes by which management is informed about
                        climate-related issues, and
                        <br />‒ how management (through specific positions
                        and/or management committees) monitors climate-related
                        issues.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques4}
                          onChange={(e) =>
                            this.setState({ governques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques4}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques5}
                          onChange={(e) =>
                            this.setState({ governques5: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques5}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques6}
                          onChange={(e) =>
                            this.setState({ governques6: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques6}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.governques7}
                          onChange={(e) =>
                            this.setState({ governques7: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.governques7}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.governques4}
                      <br />
                      {this.props.report.governques5}
                      <br />
                      {this.props.report.governques6}
                      <br />
                      {this.props.report.governques7}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
            </Grid>
            <Grid className="tcfd-page-break" centered>
              <Row>
                <Grid.Column
                  computer={8}
                  style={{ backgroundColor: "#073674" }}
                >
                  <Header as="h1">
                    Strategy
                    <p>
                      Disclose the actual and potential impacts of
                      climate-related risks and opportunities on the
                      organization’s businesses, strategy, and financial
                      planning where such information is material.
                    </p>
                  </Header>
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    a) Describe the climate- related risks and opportunities the
                    organization has identified over the short, medium, and long
                    term.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organization should provide the following information:
                        <br />
                        ‒ a description of what they consider to be relevant
                        short-,medium-,and long-term time horizons ,taking into
                        consideration the useful life of the organization's
                        assets or infrastructure and the fact that
                        climate-related issues often manifest themselves over
                        the medium and longer-terms ‒ a description of the
                        specific climate-related issues for each time horizon
                        (short,medium and long term) that could have a material
                        financial impact on the organization,and <br />
                        -a description of the process(es) used to determine
                        which risks and opportunities could have a material
                        financial impact on the organization.
                        <br />
                        Organizations should consider providing a description of
                        their risks and opportunities by Sector and/or geography
                        ,as appropriate.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques1}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques2}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques3}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organization should provide the following information:
                        <br />
                        ‒ a description of what they consider to be relevant
                        short-,medium-,and long-term time horizons ,taking into
                        consideration the useful life of the organization's
                        assets or infrastructure and the fact that
                        climate-related issues often manifest themselves over
                        the medium and longer-terms ‒ a description of the
                        specific climate-related issues for each time horizon
                        (short,medium and long term) that could have a material
                        financial impact on the organization,and <br />
                        -a description of the process(es) used to determine
                        which risks and opportunities could have a material
                        financial impact on the organization.
                        <br />
                        Organizations should consider providing a description of
                        their risks and opportunities by Sector and/or geography
                        ,as appropriate.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques1}
                          onChange={(e) =>
                            this.setState({ stratques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques1}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques2}
                          onChange={(e) =>
                            this.setState({ stratques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques2}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques3}
                          onChange={(e) =>
                            this.setState({ stratques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques3}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.stratques1}
                      <br />
                      {this.props.report.stratques2}
                      <br />
                      {this.props.report.stratques3}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    b) Describe the board’s oversight of climate-related risks
                    and opportunities.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Building on recommended disclosure (a), organizations
                        should discuss how identified climate-related issues
                        have affected their businesses, strategy, and financial
                        planning.
                        <br />
                        Organizations should consider including the impact on
                        their businesses and strategy in the following areas:
                        <br />
                        ‒ Products and services
                        <br />
                        ‒ Supply chain and/or value chain
                        <br />
                        ‒ Adaptation and mitigation activities
                        <br />
                        ‒ Investment in research and development
                        <br />
                        ‒ Operations (including types of operations and location
                        of facilities)
                        <br />
                        Organizations should describe how climate-related issues
                        serve as an input to their financial planning process,
                        the time period(s) used, and how these risks and
                        opportunities are prioritized. Organizations’
                        disclosures should reflect a holistic picture of the
                        interdependencies among the factors that affect their
                        ability to create value over time. Organizations should
                        also consider including in their disclosures the impact
                        on financial planning in the following areas:
                        <brb />
                        ‒ Operating costs and revenues
                        <br />
                        ‒ Capital expenditures and capital allocation
                        <br />
                        ‒ Acquisitions or divestments
                        <br />
                        ‒ Access to capital
                        <br />
                        If climate-related scenarios were used to inform the
                        organization’s strategy and financial planning, such
                        scenarios should be described.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques4}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques5: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques5}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques6: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques6}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques7: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques7}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Building on recommended disclosure (a), organizations
                        should discuss how identified climate-related issues
                        have affected their businesses, strategy, and financial
                        planning.
                        <br />
                        Organizations should consider including the impact on
                        their businesses and strategy in the following areas:
                        <br />
                        ‒ Products and services
                        <br />
                        ‒ Supply chain and/or value chain
                        <br />
                        ‒ Adaptation and mitigation activities
                        <br />
                        ‒ Investment in research and development
                        <br />
                        ‒ Operations (including types of operations and location
                        of facilities)
                        <br />
                        Organizations should describe how climate-related issues
                        serve as an input to their financial planning process,
                        the time period(s) used, and how these risks and
                        opportunities are prioritized. Organizations’
                        disclosures should reflect a holistic picture of the
                        interdependencies among the factors that affect their
                        ability to create value over time. Organizations should
                        also consider including in their disclosures the impact
                        on financial planning in the following areas:
                        <brb />
                        ‒ Operating costs and revenues
                        <br />
                        ‒ Capital expenditures and capital allocation
                        <br />
                        ‒ Acquisitions or divestments
                        <br />
                        ‒ Access to capital
                        <br />
                        If climate-related scenarios were used to inform the
                        organization’s strategy and financial planning, such
                        scenarios should be described.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques4}
                          onChange={(e) =>
                            this.setState({ stratques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques4}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques5}
                          onChange={(e) =>
                            this.setState({ stratques5: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques5}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques6}
                          onChange={(e) =>
                            this.setState({ stratques6: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques6}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques7}
                          onChange={(e) =>
                            this.setState({ stratques7: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques7}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.stratques4}
                      <br />
                      {this.props.report.stratques5}
                      <br />
                      {this.props.report.stratques6}
                      <br />
                      {this.props.report.stratques7}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    c) Describe the resilience of the organization’s strategy,
                    taking into consideration different climate-related
                    scenarios, including a 2°C or lower scenario.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organization should provide the following information:
                        <br />
                        ‒ a description of what they consider to be relevant
                        short-,medium-,and long-term time horizons ,taking into
                        consideration the useful life of the organization's
                        assets or infrastructure and the fact that
                        climate-related issues often manifest themselves over
                        the medium and longer-terms ‒ a description of the
                        specific climate-related issues for each time horizon
                        (short,medium and long term) that could have a material
                        financial impact on the organization,and <br />
                        -a description of the process(es) used to determine
                        which risks and opportunities could have a material
                        financial impact on the organization.
                        <br />
                        Organizations should consider providing a description of
                        their risks and opportunities by Sector and/or geography
                        ,as appropriate.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques8: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques8}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques9: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques9}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ stratques10: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques10}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organization should provide the following information:
                        <br />
                        ‒ a description of what they consider to be relevant
                        short-,medium-,and long-term time horizons ,taking into
                        consideration the useful life of the organization's
                        assets or infrastructure and the fact that
                        climate-related issues often manifest themselves over
                        the medium and longer-terms ‒ a description of the
                        specific climate-related issues for each time horizon
                        (short,medium and long term) that could have a material
                        financial impact on the organization,and <br />
                        -a description of the process(es) used to determine
                        which risks and opportunities could have a material
                        financial impact on the organization.
                        <br />
                        Organizations should consider providing a description of
                        their risks and opportunities by Sector and/or geography
                        ,as appropriate.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques8}
                          onChange={(e) =>
                            this.setState({ stratques8: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques8}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques9}
                          onChange={(e) =>
                            this.setState({ stratques9: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques9}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.stratques10}
                          onChange={(e) =>
                            this.setState({ stratques10: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.stratques10}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.stratques8}
                      <br />
                      {this.props.report.stratques9}
                      <br />
                      {this.props.report.stratques10}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
            </Grid>
            <Grid className="tcfd-page-break" centered>
              <Row>
                <Grid.Column
                  computer={8}
                  style={{ backgroundColor: "#5a5a5c" }}
                >
                  <Header as="h1">
                    Risk Management
                    <p>
                      Disclose how the organization identifies, assesses, and
                      manages climate-related risks.
                    </p>
                  </Header>
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    a) Describe the organization’s processes for identifying and
                    assessing climate- related risks.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should describe their risk management
                        processes for identifying and assessing climate-related
                        risks. An important aspect of this description is how
                        organizations determine the relative significance of
                        climate-related risks in relation to other risks.
                        <br />
                        Organizations should describe whether they consider
                        existing and emerging regulatory requirements related to
                        climate change (e.g., limits on emissions) as well as
                        other relevant factors considered.
                        <br />
                        Organizations should also consider disclosing the
                        following:
                        <br />
                        ‒ processes for assessing the potential size and scope
                        of identified climate- related risks and
                        <br />‒ definitions of risk terminology used or
                        references to existing risk classification frameworks
                        used.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ riskques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques1}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ riskques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques2}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should describe their risk management
                        processes for identifying and assessing climate-related
                        risks. An important aspect of this description is how
                        organizations determine the relative significance of
                        climate-related risks in relation to other risks.
                        <br />
                        Organizations should describe whether they consider
                        existing and emerging regulatory requirements related to
                        climate change (e.g., limits on emissions) as well as
                        other relevant factors considered.
                        <br />
                        Organizations should also consider disclosing the
                        following:
                        <br />
                        ‒ processes for assessing the potential size and scope
                        of identified climate- related risks and
                        <br />‒ definitions of risk terminology used or
                        references to existing risk classification frameworks
                        used.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.riskques1}
                          onChange={(e) =>
                            this.setState({ riskques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques1}
                        </TextArea>
                      </Form.Field>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.riskques2}
                          onChange={(e) =>
                            this.setState({ riskques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques2}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.riskques1}
                      <br />
                      {this.props.report.riskques2}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    b) Describe the organization’s processes for managing
                    climate- related risks..
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should describe their processes for
                        managing climate-related risks, including how they make
                        decisions to mitigate, transfer, accept, or control
                        those risks. In addition, organizations should describe
                        their processes for prioritizing climate-related risks,
                        including how materiality determinations are made within
                        their organizations.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ riskques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques3}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should describe their processes for
                        managing climate-related risks, including how they make
                        decisions to mitigate, transfer, accept, or control
                        those risks. In addition, organizations should describe
                        their processes for prioritizing climate-related risks,
                        including how materiality determinations are made within
                        their organizations.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.riskques3}
                          onChange={(e) =>
                            this.setState({ riskques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques3}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>{this.props.report.riskques3}</p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    c) Describe the organization’s processes for managing
                    climate- related risks..
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Describe how processes for identifying, assessing, and
                        managing climate-related risks are integrated into the
                        organization’s overall risk management.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ riskques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques4}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Describe how processes for identifying, assessing, and
                        managing climate-related risks are integrated into the
                        organization’s overall risk management.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.riskques4}
                          onChange={(e) =>
                            this.setState({ riskques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.riskques4}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>{this.props.report.riskques4}</p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
            </Grid>
            <Grid className="tcfd-page-break" centered>
              <Row>
                <Grid.Column
                  computer={8}
                  style={{ backgroundColor: "#dedede" }}
                >
                  <Header as="h1">
                    Metrics and Targets
                    <p>
                      Disclose the metrics and targets used to assess and manage
                      relevant climate-related risks and opportunities where
                      such information is material.
                    </p>
                  </Header>
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    a) Disclose the metrics used by the organization to assess
                    climate-related risks and opportunities in line with its
                    strategy and risk management process.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should provide the key metrics used to
                        measure and manage climate-related risks and
                        opportunities, as described in Organizations should
                        consider including metrics on climate-related risks
                        associated with water, energy, land use, and waste
                        management where relevant and applicable.
                        <br />
                        Where climate-related issues are material, organizations
                        should consider describing whether and how related
                        performance metrics are incorporated into remuneration
                        policies.
                        <br />
                        Where relevant, organizations should provide their
                        internal carbon prices as well as climate-related
                        opportunity metrics such as revenue from products and
                        Metrics should be provided for historical periods to
                        allow for trend analysis. In services designed for a
                        lower-carbon economy.
                        <br />
                        addition, where not apparent, organizations should
                        provide a description of the methodologies used to
                        calculate or estimate climate-related metrics.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ metricques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques1}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should provide the key metrics used to
                        measure and manage climate-related risks and
                        opportunities, as described in Organizations should
                        consider including metrics on climate-related risks
                        associated with water, energy, land use, and waste
                        management where relevant and applicable.
                        <br />
                        Where climate-related issues are material, organizations
                        should consider describing whether and how related
                        performance metrics are incorporated into remuneration
                        policies.
                        <br />
                        Where relevant, organizations should provide their
                        internal carbon prices as well as climate-related
                        opportunity metrics such as revenue from products and
                        Metrics should be provided for historical periods to
                        allow for trend analysis. In services designed for a
                        lower-carbon economy.
                        <br />
                        addition, where not apparent, organizations should
                        provide a description of the methodologies used to
                        calculate or estimate climate-related metrics.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.metricques1}
                          onChange={(e) =>
                            this.setState({ metericques1: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metericques1}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>{this.props.report.metricques1}</p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    b) Disclose Scope 1, Scope 2, and, if appropriate, Scope 3
                    greenhouse gas (GHG) emissions, and the related risks.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should provide their Scope 1 and Scope 2
                        GHG emissions and, if appropriate, Scope 3 GHG emissions
                        and the related risks.
                        <br />
                        GHG emissions should be calculated in line with the GHG
                        Protocol methodology to allow for aggregation and
                        comparability across organizations and jurisdictions. 40
                        As appropriate, organizations should consider providing
                        related, generally accepted industry-specific GHG
                        efficiency ratios.
                        <br />
                        GHG emissions and associated metrics should be provided
                        for historical periods to allow for trend analysis. In
                        addition, where not apparent, organizations should
                        provide a description of the methodologies used to
                        calculate or estimate the metrics.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ metricques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques2}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should provide their Scope 1 and Scope 2
                        GHG emissions and, if appropriate, Scope 3 GHG emissions
                        and the related risks.
                        <br />
                        GHG emissions should be calculated in line with the GHG
                        Protocol methodology to allow for aggregation and
                        comparability across organizations and jurisdictions. 40
                        As appropriate, organizations should consider providing
                        related, generally accepted industry-specific GHG
                        efficiency ratios.
                        <br />
                        GHG emissions and associated metrics should be provided
                        for historical periods to allow for trend analysis. In
                        addition, where not apparent, organizations should
                        provide a description of the methodologies used to
                        calculate or estimate the metrics.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.metricques2}
                          onChange={(e) =>
                            this.setState({ metricques2: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques2}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>{this.props.report.metricques2}</p>
                  ) : null}
                </Grid.Column>
              </Row>
              <hr></hr>
              <Row>
                <Grid.Column
                  computer={2}
                  tablet={3}
                  mobile={3}
                  style={{ backgroundColor: "#d8e9ec" }}
                >
                  <Header as="h3">Recommended Disclosure </Header>
                  <p>
                    c) Describe the targets used by the organization to manage
                    climate- related risks and opportunities and performance
                    against targets.
                  </p>
                </Grid.Column>

                <Grid.Column computer={6} tablet={3} mobile={3}>
                  <Header as="h3">Guidance for All Sectors</Header>
                  {this.state.edit && this.props.report == "null" ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should describe their key climate-related
                        targets such as those related to GHG emissions, water
                        usage, energy usage, etc., in line with anticipated
                        regulatory requirements or market constraints or other
                        goals. Other goals may include efficiency or financial
                        goals, financial loss tolerances, avoided GHG emissions
                        through the entire product life cycle, or net revenue
                        goals for products and services designed for a
                        lower-carbon economy. In describing their targets,
                        organizations should consider including the following:
                        <br />
                        ‒ whether the target is absolute or intensity based,
                        <br />
                        ‒ time frames over which the target applies,
                        <br />
                        ‒ base year from which progress is measured, and
                        <br />
                        ‒ key performance indicators used to assess progress
                        against targets.
                        <br />
                        Where not apparent, organizations should provide a
                        description of the methodologies used to calculate
                        targets and measures.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ metricques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques3}
                        </TextArea>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ metricques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques4}
                        </TextArea>
                        <TextArea
                          placeholder="Answer"
                          onChange={(e) =>
                            this.setState({ metricques5: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques5}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.state.edit && this.props.report ? (
                    <div>
                      <p style={{ lineHeight: "1.5rem", color: "grey" }}>
                        Organizations should describe their key climate-related
                        targets such as those related to GHG emissions, water
                        usage, energy usage, etc., in line with anticipated
                        regulatory requirements or market constraints or other
                        goals. Other goals may include efficiency or financial
                        goals, financial loss tolerances, avoided GHG emissions
                        through the entire product life cycle, or net revenue
                        goals for products and services designed for a
                        lower-carbon economy. In describing their targets,
                        organizations should consider including the following:
                        <br />
                        ‒ whether the target is absolute or intensity based,
                        <br />
                        ‒ time frames over which the target applies,
                        <br />
                        ‒ base year from which progress is measured, and
                        <br />
                        ‒ key performance indicators used to assess progress
                        against targets.
                        <br />
                        Where not apparent, organizations should provide a
                        description of the methodologies used to calculate
                        targets and measures.
                      </p>
                      <Form.Field>
                        <TextArea
                          placeholder={this.props.report.metricques3}
                          onChange={(e) =>
                            this.setState({ metricques3: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques3}
                        </TextArea>
                        <TextArea
                          placeholder={this.props.report.metricques4}
                          onChange={(e) =>
                            this.setState({ metricques4: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques4}
                        </TextArea>

                        <TextArea
                          placeholder={this.props.report.metricques5}
                          onChange={(e) =>
                            this.setState({ metricques5: e.target.value })
                          }
                          style={{ borderRadius: "0px" }}
                        >
                          {this.state.metricques5}
                        </TextArea>
                      </Form.Field>
                    </div>
                  ) : this.props.report != "null" ? (
                    <p>
                      {this.props.report.metricques3}
                      <br />
                      {this.props.report.metricques4}
                      <br />
                      {this.props.report.metricques5}
                    </p>
                  ) : null}
                </Grid.Column>
              </Row>
            </Grid>
          </div>
        );
    }
  }
  handleInput = (e) => {
    console.log("function");
    this.setState({ input: this.state.text, text: "" }, () =>
      console.log("input", this.state.input, this.state.text)
    );
  };

  handleSubmit = () => {
    let formdata = new FormData();

    let data = JSON.stringify({
      ques1: this.state.ques1,
      ques2: this.state.ques2,
      ques3: this.state.ques3,
      ques4: this.state.ques4,
      ques5: this.state.ques5,
      ques6: this.state.ques6,
      ques7: this.state.ques7,
      ques8: this.state.ques8,
      ques9: this.state.ques9,
      governques1: this.state.governques1,
      governques2: this.state.governques2,
      governques3: this.state.governques3,
      governques4: this.state.governques4,
      governques5: this.state.governques5,
      governques6: this.state.governques6,
      governques7: this.state.governques7,

      stratques1: this.state.stratques1,
      stratques2: this.state.stratques2,
      stratques3: this.state.stratques3,
      stratques4: this.state.stratques4,
      stratques5: this.state.stratques5,
      stratques6: this.state.stratques6,
      stratques7: this.state.stratques7,
      stratques8: this.state.stratques8,
      stratques9: this.state.stratques9,
      stratques10: this.state.stratques10,
      riskques1: this.state.riskques1,
      riskques2: this.state.riskques2,
      riskques3: this.state.riskques3,
      riskques4: this.state.riskques4,
      metricques1: this.state.metricques1,
      metricques2: this.state.metricques2,
      metricques3: this.state.metricques3,
      metricques4: this.state.metricques4,
      metricques5: this.state.metricques5,
      metricques6: this.state.metricques6,
      metricques7: this.state.metericques7,
    });
    formdata.append("project_name", "Project - dict_1");
    formdata.append("data", data);

    this.setState({ edit: 0 }, () => this.props.addReport(formdata));
  };
  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };
  render() {
    const { activeStep } = this.state;
    const steps = this.getSteps();
    console.log("steps length", this.props.tcfd, this.props.tcfd.asset_tables);
    if (this.state.tcfd_heat) {
      for (let i = 0; i < this.state.tcfd_heat["2650"].names.length; i++) {
        console.log("new heatmap", this.state.tcfd_heat["2650"]);
        heatmapdata.push({
          asset: this.state.tcfd_heat["2650"].names[i].slice(0, 10),

          "River Flood": this.state.tcfd_heat["2650"].values[i]["River Flood"],
          Drought: this.state.tcfd_heat["2650"].values[i]["Drought"],
          "Storm Surge": this.state.tcfd_heat["2650"].values[i]["Storm Surge"],
          "Extreme Heat":
            this.state.tcfd_heat["2650"].values[i]["Extreme Heat"],

          "Rainfall Flood":
            this.state.tcfd_heat["2650"].values[i]["Rainfall Flood"],
          Typhoon: this.state.tcfd_heat["2650"].values[i]["Typhoon"],
        });
      }
    }
    if (this.state.tcfd_bar) {
      console.log("overall bar", this.state.tcfd_bar);
      data = [
        {
          name: "2020",
          "RCP2.6": 0,
          "RCP4.5": 0,
          "RCP8.5": this.state.tcfd_bar["2020"],
        },

        {
          name: "2030",
          "RCP2.6": this.state.tcfd_bar["2030_26"],
          "RCP4.5": this.state.tcfd_bar["2030_45"],
          "RCP8.5": this.state.tcfd_bar["2030_85"],
        },
        {
          name: "2050",
          "RCP2.6": this.state.tcfd_bar["2050_26"],
          "RCP4.5": this.state.tcfd_bar["2050_45"],
          "RCP8.5": this.state.tcfd_bar["2050_85"],
        },
      ];
    }
    console.log("heatmapdata", data);

    return (
      <div>
        <div className="tcfd-download">
          <Button
            style={{
              float: "right",
              backgroundColor: "#002060",
              color: "white",
              marginRight: "2px",
              marginTop: "20px",
            }}
            onClick={this.handleSubmit}
          >
            Save Draft
          </Button>
          <Button
            style={{
              float: "right",
              backgroundColor: "white",
              color: "#002060",
              border: "1px solid #002060",
              marginRight: "2px",
              marginTop: "20px",
            }}
            onClick={this.Edit}
          >
            Edit
          </Button>
        </div>
        <Grid centered>
          {/* <Stepper activeStep={activeStep} orientation="vertical">
            
            <Step key={0}>
            <StepLabel>Cover Page</StepLabel>

            <StepContent>
            	<Typography>{this.getStepContent(0)}</Typography>
            	<br/>
            	<br/>
            	<div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                    
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
            </StepContent>
            </Step>

            <Step key={1}>
            
            	<StepLabel>Questionare</StepLabel>
					<StepContent>
            	<Typography>{this.getStepContent(1)}</Typography>
            	<br/><br/>
            	<div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                  >

                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
            </StepContent>
					
					</Step>
					
					<Step key={2}>
					<StepLabel>Governance</StepLabel>
					<StepContent>
            	<Typography>{this.getStepContent(2)}</Typography>
            	<br/>
            	<br/>
            	<div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
            </StepContent>
					</Step>
					<Step key={3}>
					<StepLabel>
						Strategy
					</StepLabel>
					<StepContent>
            	<Typography>{this.getStepContent(3)}</Typography>
            	<br/>
            	<br/>
            	<div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
            </StepContent>
					</Step>
						<Step key={4}>
					<StepLabel>
						Risk Management
					</StepLabel>
					<StepContent>
            	<Typography>{this.getStepContent(4)}</Typography>
            	<br/>
            	<br/>
            	<div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
            </StepContent>
					</Step>
						<Step key={5}>
					<StepLabel>
						Metrics and Tragets
					</StepLabel>
					<StepContent>
            	<Typography>{this.getStepContent(5)}</Typography>
            	<br/><br/>
            	<div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                  >

                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  </div>
                  </StepContent>
                  </Step>

                  <Step key={6}>
                  <StepLabel> Download TCFD Report</StepLabel>
                  <StepContent>
                  <Typography>{this.getStepContent(6)}</Typography>
                  <br/><br/>
                  <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={{float:'right'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    style={{float:'right'}}
                  >

                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  {((!this.state.edit)&&(this.props.report!='null'))?<Button color="primary" onClick={this.printDocument} style={{color:'white',float:'right',marginRight:'5px',backgroundColor:'#002060'}}>Download Pdf</Button>:null}
                </div>
            </StepContent>
					</Step>
					
					
					
		</Stepper>*/}
          <Row style={{ marginBottom: "2%" }}>
            <Col className="tcfd">
              <Tabs className="tabstcfd" defaultActiveKey="cover">
                <Tab eventKey="cover" title="Cover">
                  {this.getStepContent(0)}
                </Tab>
                <Tab eventKey="questionare" title="Questionare">
                  {this.getStepContent(1)}
                </Tab>
                <Tab eventKey="governance" title="Governance">
                  {this.getStepContent(2)}
                </Tab>
                <Tab eventKey="strategy" title="Strategy">
                  {this.getStepContent(3)}
                </Tab>
                <Tab eventKey="risk" title="Risk Management">
                  {this.getStepContent(4)}
                </Tab>
                <Tab eventKey="metrics" title="Metrics & Targets">
                  {this.getStepContent(5)}
                </Tab>

                <Tab eventKey="Download" title="Download PDF">
                  {this.getStepContent(6)}
                </Tab>
              </Tabs>
              <br />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    report: state.project.report,
    tcfd: state.project.tcfd,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReport: (formdata) => {
      dispatch(project.getReport(formdata));
    },
    addReport: (formdata) => {
      dispatch(project.addReport(formdata));
    },
    getTcfdReport: (formdata) => {
      dispatch(project.getTcfdReport(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TCFD);
