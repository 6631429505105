import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Progress,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
  Card,
} from "semantic-ui-react";
import logo from "../assets/logo.png";
import home from "../assets/home.png";
import add from "../assets/images/add.png";
import floatcard from "../assets/images/floatcard.jpg";
import search from "../assets/search.png";
import { loadModules } from "esri-loader";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ScatterChart,
  Scatter,
} from "recharts";
import { project, auth, dashboard, locus } from "../actions";
import Building from "./building";
import LineExample from "./Line";
import "./dashboard.css";
import Spinner from "./loader";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Detail from "./detail";
import Circle from "react-circle";
import Donut from "./donut";
import Heatmap from "react-simple-heatmap";
import RCPDonut from "./rcpdonut";
import YEARDonut from "./yeardonut";
import SingleDonut from "./singledonut";
import BeatLoader from "react-spinners/BeatLoader";
import { HeatMap } from "@nivo/heatmap";
import { ResponsiveBar } from "@nivo/bar";
import { Redirect } from "react-router-dom";
import Map from "./map";
import CandleStick from "./candlestick";
import PopupMap from "./map1";

import MdryearDonut from "./mdryeardonut";
import MdrrcpDonut from "./mdrrcpdonut";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

let data = [];
let options = [];
let Item = [];
let singledata = [];
let data01 = [];
let flat_data = [];
let losses_data = [];
let losses_data_flat = [];

const ContinentOptions = [
  { key: "Asia", value: ["Asia", 100.6197, 34.04, 3], text: "Asia" },
  { key: "Europe", value: ["Europe", 15.2551, 54.526, 3], text: "Europe" },
  {
    key: "Australia",
    value: ["Australia", 133.7751, -25.2744, 3],
    text: "Australia",
  },
  { key: "America", value: ["America", -95.7129, 37.0902, 2], text: "America" },
  { key: "Africa", value: ["Africa", 34.5085, 8.7832, 3], text: "Africa" },
];

const RcpOptions = [
  { key: 2.6, value: 2.6, text: "RCP 2.6" },
  { key: 4.5, value: 4.5, text: "RCP 4.5" },
  { key: 8.5, value: 8.5, text: "RCP 8.5" },
];
const VariableOptions = [
  { key: "Total", value: "Total", text: "Total" },
  { key: "Flood", value: "Flood", text: "Flood" },
  { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
  { key: "Storm", value: "Storm", text: "Storm" },
];
const YearOptions = [
  { key: 2030, value: 2030, text: 2030 },
  { key: 2050, value: 2050, text: 2050 },
];
const basementOptions = [
  { key: "yes", value: "yes", text: "yes" },
  { key: "no", value: "no", text: "no" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const constructionOptions = [
  { key: "wood", value: "wood", text: "wood" },
  { key: "concrete", value: "concrete", text: "concrete" },
  { key: "masonry", value: "masonry", text: "masonry" },
  { key: "mobile home", value: "mobile home", text: "mobile home" },
  { key: "light metal", value: "light metal", text: "light metal" },
  { key: "steel", value: "steel", text: "steel" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const storiesOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: "3 or More", value: "3 or More", text: "3 or More" },
  { key: "unknown", valeu: "unknown", text: "unknown" },
];
const occupancyOptions = [
  { key: "res", value: "res", text: "res" },
  { key: "com", value: "com", text: "com" },
  { key: "ind", value: "ind", text: "ind" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const tableOptions = [
  { key: "Top 3", value: 3, text: "Top 3" },
  { key: "Top 5", value: 5, text: "Top 5" },
  { key: "Top 10", value: 10, text: "Top 10" },
];
const CountOptions = [
  { key: "Top 3", value: "Top 3", text: "Top 3" },
  { key: "Top 5", value: "Top 5", text: "Top 5" },
  { key: "All", value: "All", text: "All" },
];
let ParameterOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "loss", value: "loss", text: "Overall Loss" },
];
let PropertyOptions = [];

const CustomCell = ({
  value,
  x,
  y,
  width,
  height,
  color,
  opacity,
  borderWidth,
  borderColor,
  textColor,
}) => (
  <g transform={`translate(${x}, ${y})`}>
    <path
      transform={`rotate(${value < 5 ? 180 : 0})`}
      fill={color}
      fillOpacity={opacity}
      strokeWidth={borderWidth}
      stroke={borderColor}
      d={`
                M0 -${Math.round(height / 2)}
                L${Math.round(width / 2)} ${Math.round(height / 2)}
                L-${Math.round(width / 2)} ${Math.round(height / 2)}
                L0 -${Math.round(height / 2)}
            `}
    />
    <text
      dominantBaseline="central"
      textAnchor="middle"
      style={{ fill: textColor, fontSize: "16px" }}
      dy={value < 50 ? -6 : 6}
    >
      {value}
    </text>
  </g>
);

class Report extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    project: "",
    variable: "Rainfall Flood",
    rcp: 8.5,
    year: 2050,
    analysis: "local",
    modalOpen: false,
    feedback: "",
    asset_table: "",
    table: 3,
    risk: "",
    single_asset: "",
    activeItemName: "",
    single_asset_overall: "",
    singel_asset_chart: "",
    single_asset_loss: "",
    single_asset_progress: "",
    single_asset_cone_chart: "",
    RcpData: "",
    YearData: "",
    basement: "yes",
    construction: "wood",
    stories: "1",
    occupancy: "res",
    per_sq_m_value: 40000,
    scatter: "",
    yearDetail: "",
    detailed: "false",
    global: false,
    singleYearDetail: "",
    portfolio_losses: "",
    portfolio_losses_flat: "",
    overall: "",
    heatmap: "",
    losses: "",
    data: "",
    financial: true,
    mapVariable: "FL8570",
    assets: [],
    mapAsset: "",
    table_condition: false,
    oldname: "",
    newname: "",
    area: "",
    type: "",
    valuation: "",
    count: "All",
    parameter: "loss",
    million: true,
    exposure_variable: "Total",
    property_type: "NA",
    long: 114.1838,
    lat: 22.2797,
    zoom: 16,
    continent: "Continents",
    activeIndex: -1,
  };

  componentDidMount() {
    this.props.getAssets();
    this.props.getProjects();

    if (this.props.data) {
      let formdata = new FormData();
      if (this.props.data) {
        formdata.append("project", this.props.data);
        formdata.append("rcp", this.state.rcp);
        formdata.append("variable", this.state.variable);
        formdata.append("year", this.state.year);
        formdata.append("count", this.state.count);
        formdata.append("parameter", this.state.parameter);
        formdata.append("property_type", this.state.property_type);
        this.setState({ project: this.props.data }, () =>
          this.props.addDashboard(formdata)
        );
      }
    }

    let formdata1 = new FormData();
    if (this.props.data) {
      formdata1.append("project", this.props.data);
      formdata1.append("rcp", this.state.rcp);
      formdata1.append("year", this.state.year);
      formdata1.append("variable", "Total");
      this.props.getDetailByYear(formdata1);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.feedback !== this.props.feedback) {
      this.setState(
        {
          feedback: this.props.feedback.overall.overall_bar_chart,
          asset_table: this.props.feedback.overall.asset_table,
          risk: this.props.feedback.overall.progress_bars,
          losses: this.props.feedback.overall.loss_bars,
          single_asset: this.props.feedback.single_asset,
          portfolio_losses: this.props.feedback.overall.portfolio_versus_losses,
          portfolio_losses_flat:
            this.props.feedback.overall.portfolio_versus_losses_flat_adjusted,
          overall: this.props.feedback.overall,
          heatmap: this.props.feedback.overall.heatmap,
        },
        () => {
          this.setState({ table_condition: true }, () =>
            this.handleSingleAsset()
          );
        }
      );
    }
    if (prevProps.assets !== this.props.assets) {
      console.log("user assets", this.props.assets);
      this.setState({ assets: this.props.assets });
    }
    if (prevProps.detailyear !== this.props.detailyear) {
      console.log("year api update", this.props.detailyear.values);
      this.setState({ detailyear: this.props.detailyear.values });
    }
  }
  Edit = (asset, newAsset) => {
    this.setState(
      {
        oldname: asset.name,
        newname: newAsset.name,
        area: newAsset.area,
        valuation: newAsset.valuation,
        type: newAsset.type.toLowerCase(),
      },
      this.EditAsset
    );
  };
  EditAsset() {
    let formdata = new FormData();
    formdata.append("oldname", this.state.oldname);
    formdata.append("newname", this.state.newname);
    formdata.append("area", this.state.area);
    formdata.append("type", this.state.type);
    formdata.append("valuation", this.state.valuation);

    this.props.updateAsset(formdata);
  }

  handleProject = (e, { value }) => {
    this.setState({ project: value }, () => console.log(this.state.project));
  };
  exportPDFWithComponent = () => {
    savePDF(ReactDOM.findDOMNode(this.container), {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };

  printDocument() {
    const input = document.getElementById("dashboard");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      console.log("imagedata", imgData);
      const pdf = new jsPDF("p", "mm", [700, 370]);
      pdf.addImage(imgData, "JPEG", 0, 0);
      console.log("download", pdf);
      pdf.save("download.pdf");
    });
  }
  handleRCP = (e, { value }) => {
    this.setState({ rcp: value }, () => console.log(this.state.rcp));
  };
  handleVariable = (e, { value }) => {
    this.setState({ mapVariable: value }, () =>
      console.log("map variabele", this.state.mapVariable)
    );
  };

  handleYear = (e, { value }) => {
    this.setState({ year: value }, () => console.log(this.state.year));
  };
  handleLogout = () => {
    this.props.logout();
  };
  handleOpen = (asset) =>
    this.setState(
      {
        modalOpen: true,
        activeItemName: asset,
      },
      this.handleSingleAsset
    );
  handleClose = () => this.setState({ modalOpen: false });

  handleSingleAsset = () => {
    if (this.state.single_asset.length > 0) {
      console.log(
        "itemksfk",
        this.state.activeItemName,
        this.state.single_asset
      );
      Item = this.state.single_asset[0];
      this.setState({ single_asset_overall: Item }, this.handleSingleChart);
    }
    if (this.state.yearDetail.length > 0) {
      let ItemYear = this.state.yearDetail.filter(
        (asset) => asset.name == this.state.activeItemName
      );
      this.setState({ singleYearDetail: ItemYear }, () =>
        console.log("single year", this.state.singleYearDetail)
      );
    }
    if (this.state.assets.length > 0) {
      let Item = this.state.assets.filter(
        (asset) => asset.name == this.state.activeItemName
      );
      this.setState(
        {
          mapAsset: Item,
        },
        () => console.log("single asset map wala", this.state.mapAsset)
      );
    }
  };
  handleSingleChart = () => {
    console.log("fndfdgdgdsgdijgdos", this.state.single_asset_overall);
    singledata = [
      {
        name: "2020",
        "RCP2.6": 0,
        "RCP4.5": 0,
        "RCP8.5": this.state.single_asset_overall.overall_bar_chart["2020"],
      },

      {
        name: "2030-2050",
        "RCP2.6": this.state.single_asset_overall.overall_bar_chart["2030_26"],
        "RCP4.5": this.state.single_asset_overall.overall_bar_chart["2030_45"],
        "RCP8.5": this.state.single_asset_overall.overall_bar_chart["2030_85"],
      },

      {
        name: "2050-2070",
        "RCP2.6": this.state.single_asset_overall.overall_bar_chart["2050_26"],
        "RCP4.5": this.state.single_asset_overall.overall_bar_chart["2050_45"],
        "RCP8.5": this.state.single_asset_overall.overall_bar_chart["2050_85"],
      },
    ];
    console.log("singledata", singledata);
    this.setState(
      {
        singel_asset_chart: singledata,
        single_asset_loss: this.state.single_asset_overall.loss_bars,
        single_asset_progress: this.state.single_asset_overall.progress_bars,
        single_asset_cone_chart: this.state.single_asset_overall.cone_chart,
      },
      this.handleComparison
    );
  };

  handleComparison = () => {
    let RcpData = [
      {
        name: "OverAll",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["OVERALL"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["OVERALL"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["OVERALL"],
      },

      {
        name: "River Flood",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["River Flood"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["River Flood"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["River Flood"],
      },
      {
        name: "Drought",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Drought"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Drought"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Drought"],
      },

      {
        name: "Storm Surge",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Storm Surge"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Storm Surge"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Storm Surge"],
      },
      {
        name: "LandSlide",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Landslide"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Landslide"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Landslide"],
      },
      {
        name: "Rainfall",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Rainfall"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Rainfall"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Rainfall"],
      },

      {
        name: "Extreme Heat",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Extreme heat"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Extreme heat"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Extreme heat"],
      },
      {
        name: "Rainfall Flood",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Rainfall Flood"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Rainfall Flood"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Rainfall Flood"],
      },
      {
        name: "SnowMelt",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Snow Melt"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Snow Melt"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Snow Melt"],
      },
      {
        name: "Sea Level Rise",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Sea Level Rise"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Sea Level Rise"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Sea Level Rise"],
      },
      {
        name: "Typhoon",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Typhoon"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Typhoon"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Typhoon"],
      },
    ];

    let yearData = [
      {
        name: "OverAll",
        2020: this.state.single_asset_cone_chart.cone3_20.both["OVERALL"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["OVERALL"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["OVERALL"],
      },

      {
        name: "River Flood",
        2020: this.state.single_asset_cone_chart.cone3_20.both["River Flood"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["River Flood"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["River Flood"],
      },
      {
        name: "Drought",
        2020: this.state.single_asset_cone_chart.cone1_26.both["Drought"],
        2030: this.state.single_asset_cone_chart.cone1_45.both["Drought"],
        2050: this.state.single_asset_cone_chart.cone1_85.both["Drought"],
      },

      {
        name: "Storm Surge",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Storm Surge"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Storm Surge"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Storm Surge"],
      },
      {
        name: "LandSlide",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Landslide"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Landslide"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Landslide"],
      },
      {
        name: "Rainfall",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Rainfall"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Rainfall"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Rainfall"],
      },

      {
        name: "Extreme Heat",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Extreme heat"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Extreme heat"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Extreme heat"],
      },
      {
        name: "Rainfall Flood",
        2020: this.state.single_asset_cone_chart.cone1_26.both[
          "Rainfall Flood"
        ],
        2030: this.state.single_asset_cone_chart.cone1_45.both[
          "Rainfall Flood"
        ],
        2050: this.state.single_asset_cone_chart.cone1_85.both[
          "Rainfall Flood"
        ],
      },
      {
        name: "SnowMelt",
        2020: this.state.single_asset_cone_chart.cone1_26.both["Snow Melt"],
        2030: this.state.single_asset_cone_chart.cone1_45.both["Snow Melt"],
        2050: this.state.single_asset_cone_chart.cone1_85.both["Snow Melt"],
      },
      {
        name: "Sea Level Rise",
        2020: this.state.single_asset_cone_chart.cone1_26.both[
          "Sea Level Rise"
        ],
        2030: this.state.single_asset_cone_chart.cone1_45.both[
          "Sea Level Rise"
        ],
        2050: this.state.single_asset_cone_chart.cone1_85.both[
          "Sea Level Rise"
        ],
      },
      {
        name: "Typhoon",
        2020: this.state.single_asset_cone_chart.cone1_26.both["Typhoon"],
        2030: this.state.single_asset_cone_chart.cone1_45.both["Typhoon"],
        2050: this.state.single_asset_cone_chart.cone1_85.both["Typhoon"],
      },
    ];
    this.setState(
      {
        RcpData: RcpData,
        YearData: yearData,
      },
      () => console.log("yeardata", this.state.YearData)
    );
  };
  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleChangePro = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };
  handleTable = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => console.log("select table", this.state.table)
    );
  };
  handleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };
  handleSingleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };

  handleSubmitPro = () => {
    let formdata = new FormData();
    formdata.append("project", this.state.project);
    formdata.append("rcp", this.state.rcp);
    formdata.append("variable", this.state.variable);
    formdata.append("year", this.state.year);
    formdata.append("count", this.state.count);
    formdata.append("parameter", this.state.parameter);
    formdata.append("property_type", this.state.property_type);

    console.log("overall api ka form data", this.state.rcp);
    this.props.addDashboard(formdata);

    let formdata1 = new FormData();
    formdata1.append("project", this.state.project);
    formdata1.append("rcp", this.state.rcp);
    formdata1.append("year", this.state.year);
    formdata1.append("variable", this.state.exposure_variable);
    console.log("year api ka form data", this.state.year);
    this.setState(
      { financial: true, million: true },
      this.props.getDetailByYear(formdata1)
    );
  };

  handleLocal = (e) => {
    this.setState(
      {
        localactive: true,
        globalactive: false,
        analysis: "local",
      },
      this.handleSubmitPro
    );
  };

  handleGlobal = (e) => {
    this.setState(
      {
        globalactive: true,
        localactive: false,
        analysis: "global",
      },
      this.handleSubmitPro
    );
  };
  handleFinance = (e) => {
    this.setState((prevState) => ({
      financial: !prevState.financial,
    }));
  };
  handleMillion = (e) => {
    this.setState((prevState) => ({
      million: !prevState.million,
    }));
  };

  handleContinent = (e, { value }) => {
    console.log("Continents", value);
    this.setState({
      continent: value[0],
      long: value[1],
      lat: value[2],
      zoom: value[3],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("building", this.state.activeItemName);
    formdata.append("basement", this.state.basement);
    formdata.append("construction", this.state.construction);
    formdata.append("stories", this.state.stories);
    formdata.append("occupancy", this.state.occupancy);
    formdata.append("per_sq_m_value", this.state.per_sq_m_value);
    this.props.getBuilding(formdata);
  };
  handleClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  handleAccClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex }, () =>
      console.log("active", this.state.activeIndex)
    );
  };
  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
  }

  render() {
    console.log("rendering");
    const isDarkMode = localStorage.getItem("theme") == "dark";
    const Var = JSON.parse(localStorage.getItem("variables"));
    const Property = JSON.parse(localStorage.getItem("property_types"));

    if (Property != "NA") {
      console.log("Property value", this.state.property_type);
      PropertyOptions = [];
      for (let i = 0; i < Property.length; i++) {
        PropertyOptions.push({
          key: Property[i],
          value: Property[i],
          text: Property[i],
        });
      }
      ParameterOptions = [];
      ParameterOptions.push(
        { key: "valuation", value: "valuation", text: "valuation" },
        { key: "loss", value: "loss", text: "Overall Loss" },
        {
          key: "revenue",
          value: "revenue",
          text: "revenue",
        }
      );
    }

    const {
      continent,
      value,
      basement,
      construction,
      stories,
      occupancy,
      project,
      rcp,
      year,
      active,
      table,
      count,
      parameter,
      exposure_variable,
      property_type,
    } = this.state;

    let heatmapdata = [];
    let commonProperties = {};
    if (this.state.heatmap.length === undefined) {
      for (let i = 0; i < this.state.heatmap.names.length; i++) {
        console.log("new heatmap", this.state.heatmap);
        heatmapdata.push({
          asset: this.state.heatmap.names[i].slice(0, 20),
          Landslide: this.state.heatmap.values[i]["Landslide"],
          Rainfall: this.state.heatmap.values[i]["Rainfall"],
          "River Flood": this.state.heatmap.values[i]["River Flood"],
          Drought: this.state.heatmap.values[i]["Drought"],
          "Storm Surge": this.state.heatmap.values[i]["Storm Surge"],
          "Extreme heat": this.state.heatmap.values[i]["Extreme Heat"],
          "Snow Melt": this.state.heatmap.values[i]["Snow Melt"],
          "Rainfall Flood": this.state.heatmap.values[i]["Rainfall Flood"],
          Typhoon: this.state.heatmap.values[i]["Typhoon"],
          "Sea Level Rise": this.state.heatmap.values[i]["Sea Level Rise"],
        });
      }
      let n = this.state.heatmap.names.length;
      commonProperties = {
        width: 1000,
        height: n * 100,
      };
    }
    let total_asset_value = 0;

    if (this.state.portfolio_losses) {
      losses_data = [];
      for (let i = 0; i < this.state.portfolio_losses.x.length; i++) {
        losses_data.push({
          name: this.state.portfolio_losses.x[i],
          value: this.state.portfolio_losses.y[i],
        });
      }
    }
    if (this.state.portfolio_losses_flat) {
      for (let i = 0; i < this.state.portfolio_losses_flat.x.length; i++) {
        losses_data_flat.push({
          name: this.state.portfolio_losses_flat.x[i],
          value: this.state.portfolio_losses_flat.y[i],
        });
        console.log("proffsdfdd", losses_data_flat);
      }
    }

    data = [
      {
        name: "2020",
        "RCP2.6": 0,
        "RCP4.5": 0,
        "RCP8.5": this.state.feedback["2020"],
      },

      {
        name: "2030",
        "RCP2.6": this.state.feedback["2030_26"],
        "RCP4.5": this.state.feedback["2030_45"],
        "RCP8.5": this.state.feedback["2030_85"],
      },
      {
        name: "2050",
        "RCP2.6": this.state.feedback["2050_26"],
        "RCP4.5": this.state.feedback["2050_45"],
        "RCP8.5": this.state.feedback["2050_85"],
      },
    ];

    if (this.props.project.length > 0) {
      let user_id = localStorage.getItem("user_id");
      const projects = this.props.project.filter(
        (project) => project.users_id == user_id
      );
      console.log("projscddkfl", projects);
      options = [];
      for (let i = 0; i < projects.length; i++) {
        options.push({
          key: projects[i].name,
          value: projects[i].name,
          text: projects[i].name,
        });
      }
      console.log("option", data[0][`RCP${this.state.rcp}`]);
    }
    let barstyle = `${this.state.year}RCP${this.state.rcp}`;

    const BarComponent = (props) => {
      let propstyle = `${props.data.indexValue}${props.data.id}`;

      return (
        <g transform={`translate(${props.x},${props.y})`}>
          <rect
            x={-3}
            y={7}
            width={props.width}
            height={props.height}
            fill="rgba(0, 0, 0, .07)"
          />
          {barstyle == propstyle ? (
            <rect
              width={props.width}
              height={props.height}
              fill={props.color}
              className="custom"
            />
          ) : (
            <rect
              width={props.width}
              height={props.height}
              fill={props.color}
            />
          )}
          <rect
            x={props.width - 5}
            width={5}
            height={props.height}
            fill={props.borderColor}
            fillOpacity={0.2}
          />
          <text
            x={props.width - 16}
            y={props.height / 2 - 8}
            textAnchor="end"
            dominantBaseline="central"
            fill="black"
            style={{
              fontWeight: 900,
              fontSize: 15,
            }}
          ></text>
          <text
            x={props.width - 16}
            y={props.height / 2 + 10}
            textAnchor="end"
            dominantBaseline="central"
            fill={props.borderColor}
            style={{
              fontWeight: 400,
              fontSize: 13,
            }}
          ></text>
        </g>
      );
    };

    if (this.props.detailyear && this.props.detailyear.values.length > 0) {
      console.log("detailyear", this.props.detailyear);
      this.state.yearDetail = this.props.detailyear.values;
    }

    if (this.props.isLoading && isDarkMode) {
      return <div id="darkloading"></div>;
    } else if (this.props.isLoading) {
      return <div id="loading"></div>;
    }
    let progress_bar = [];
    let progress_bar_acute = [];
    let progress_bar_chronic = [];

    if (this.state.risk.OVERALL) {
      for (let i = 0; i < Var.length; i++) {
        if (this.state.risk.acute[Var[i]] >= 0) {
          console.log("progessbar tye", Var[2], this.state.risk.acute[Var[2]]);
          progress_bar_acute.push(
            <div className="Chronic">
              <p style={{ fontSize: "13px" }}>
                {Var[i]}
                <b style={{ float: "right" }}>
                  {this.state.risk.acute[Var[i]]}%
                </b>
              </p>
              <Progress percent={this.state.risk.acute[Var[i]]} color="green" />
            </div>
          );
        }
      }
      for (let i = 0; i < Var.length; i++) {
        if (this.state.risk.chronic[Var[i]] >= 0) {
          progress_bar_chronic.push(
            <div className="Chronic">
              <p style={{ fontSize: "13px" }}>
                {Var[i]}
                <b style={{ float: "right" }}>
                  {this.state.risk.chronic[Var[i]]}%
                </b>
              </p>
              <Progress
                percent={this.state.risk.chronic[Var[i]]}
                color="green"
              />
            </div>
          );
        }
      }
    }
    let single_progress_bar = [];
    let single_progress_bar_acute = [];
    let single_progress_bar_chronic = [];
    console.log("progessbar tye", this.state.risk);
    if (this.state.single_asset_progress.OVERALL) {
      for (let i = 0; i < Var.length; i++) {
        single_progress_bar.push(
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              {Var[i]}
              <b style={{ float: "right" }}>
                {this.state.single_asset_progress.both[Var[i]]}%
              </b>
            </p>
            <Progress
              percent={this.state.single_asset_progress.both[Var[i]]}
              color="green"
            />
          </div>
        );
      }
      for (let i = 0; i < Var.length; i++) {
        if (this.state.single_asset_progress.acute[Var[i]] >= 0) {
          single_progress_bar_acute.push(
            <div className="Chronic">
              <p style={{ fontSize: "13px" }}>
                {Var[i]}
                <b style={{ float: "right" }}>
                  {this.state.single_asset_progress.acute[Var[i]]}%
                </b>
              </p>
              <Progress
                percent={this.state.single_asset_progress.acute[Var[i]]}
                color="green"
              />
            </div>
          );
        }
      }
      for (let i = 0; i < Var.length; i++) {
        if (this.state.single_asset_progress.chronic[Var[i]] >= 0) {
          single_progress_bar_chronic.push(
            <div className="Chronic">
              <p style={{ fontSize: "13px" }}>
                {Var[i]}
                <b style={{ float: "right" }}>
                  {this.state.single_asset_progress.chronic[Var[i]]}%
                </b>
              </p>
              <Progress
                percent={this.state.single_asset_progress.chronic[Var[i]]}
                color="green"
              />
            </div>
          );
        }
      }
    }
  }

  render() {
    const isDarkMode = localStorage.getItem("theme") == "dark";
    const Var = JSON.parse(localStorage.getItem("variables"));
    console.log("dashbaord dta", this.state.single_asset);

    const {
      value,
      basement,
      construction,
      stories,
      occupancy,
      project,
      rcp,
      year,
      active,
    } = this.state;

    let heatmapdata = [];
    let commonProperties = {};
    if (this.state.heatmap.length === undefined) {
      for (let i = 0; i < this.state.heatmap.names.length; i++) {
        console.log("new heatmap", this.state.heatmap);
        heatmapdata.push({
          asset: this.state.heatmap.names[i].slice(0, 20),
          Landslide: this.state.heatmap.values[i]["Landslide"],
          Rainfall: this.state.heatmap.values[i]["Rainfall"],
          "River Flood": this.state.heatmap.values[i]["River Flood"],
          "Drought Index": this.state.heatmap.values[i]["Drought Index"],
          "Storm Surge": this.state.heatmap.values[i]["Storm Surge"],
          "Extreme heat": this.state.heatmap.values[i]["Extreme Heat"],
          "Snow Melt": this.state.heatmap.values[i]["Snow Melt"],
          "Rainfall Flood": this.state.heatmap.values[i]["Rainfall Flood"],
          Typhoon: this.state.heatmap.values[i]["Typhoon"],
        });
      }
      let n = this.state.heatmap.names.length;
      commonProperties = {
        width: 1000,
        height: n * 100,
      };
    }
    let total_asset_value = 0;

    if (this.state.portfolio_losses) {
      losses_data = [];
      for (let i = 0; i < this.state.portfolio_losses.x.length; i++) {
        losses_data.push({
          name: this.state.portfolio_losses.x[i],
          value: this.state.portfolio_losses.y[i],
        });
      }
    }
    if (this.state.portfolio_losses_flat) {
      for (let i = 0; i < this.state.portfolio_losses_flat.x.length; i++) {
        losses_data_flat.push({
          name: this.state.portfolio_losses_flat.x[i],
          value: this.state.portfolio_losses_flat.y[i],
        });
        console.log("proffsdfdd", losses_data_flat);
      }
    }

    const data = [
      {
        name: "2020",
        "RCP2.6": 0,
        "RCP4.5": 0,
        "RCP8.5": this.state.feedback["2020"],
      },

      {
        name: "2030-2050",
        "RCP2.6": this.state.feedback["2030_26"],
        "RCP4.5": this.state.feedback["2030_45"],
        "RCP8.5": this.state.feedback["2030_85"],
      },

      {
        name: "2050-2070",
        "RCP2.6": this.state.feedback["2050_26"],
        "RCP4.5": this.state.feedback["2050_45"],
        "RCP8.5": this.state.feedback["2050_85"],
      },
    ];
    if (this.props.project.length > 0) {
      let user_id = localStorage.getItem("user_id");
      const projects = this.props.project.filter(
        (project) => project.users_id == user_id
      );
      console.log("projscddkfl", projects);
      options = [];
      for (let i = 0; i < projects.length; i++) {
        options.push({
          key: projects[i].name,
          value: projects[i].name,
          text: projects[i].name,
        });
      }
      console.log("option", data);
    }

    if (this.props.isLoading) {
      return <div id="loading"></div>;
    }

    return (
      <div id="dashboard">
        <PDFExport
          ref={(component) => (this.pdfExportComponent = component)}
          paperSize="auto"
          margin={40}
          fileName={`Report for ${new Date().getFullYear()}`}
          author="KendoReact Team"
        >
          <br />
          <div ref={(container) => (this.container = container)}>
            <Container fluid={true}>
              <Grid.Row>
                <Grid.Column
                  className="card"
                  mobile={16}
                  tablet={16}
                  computer={4}
                >
                  <Grid.Row>
                    <p style={{ width: "65%", paddingLeft: "5px" }}>
                      <span className={"dark-mode-theme-color-1"}>
                        {" "}
                        Extreme Hazard Increase for {this.state.year}
                      </span>
                    </p>

                    <br />
                    <br />
                    <br />
                    <Grid.Column
                      className="circle"
                      computer={5}
                      style={{ marginLeft: "10%", marginTop: "2%" }}
                    >
                      <Circle progress={this.state.overall.per_10_years_rise} />
                    </Grid.Column>
                    <Grid.Column computer={4} style={{ marginLeft: "15%" }}>
                      <p
                        style={{
                          fontSize: "1rem",
                          float: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Maximum Exposure
                      </p>

                      <div>
                        <p style={{ fontSize: "1.25rem" }}>
                          ${" "}
                          {Math.max(
                            this.state.overall.Typhoon_exposure,
                            this.state.overall.Flood_exposure,
                            this.state.overall.Storm_exposure
                          ).toFixed(2)}{" "}
                          Million
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>

                  {this.state.losses["Asset Flood Damage"] ? (
                    <Grid.Row>
                      <Grid.Column style={{ width: "48%" }}></Grid.Column>

                      <Grid.Column
                        className="small"
                        style={{ float: "right", width: "45%" }}
                      >
                        <p
                          style={{
                            fontSize: "1rem",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Climate Value at Risk
                        </p>

                        <div>
                          <p>
                            $ {this.state.losses["Total Loss"][0].toFixed(2)}{" "}
                            Million
                          </p>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                </Grid.Column>

                <Grid.Column
                  className="card"
                  mobile={16}
                  tablet={16}
                  computer={5}
                >
                  <p>
                    <span className={"dark-mode-theme-color-1"}>
                      Year Loss for RCP {this.state.rcp}
                    </span>
                  </p>

                  <YEARDonut
                    data={this.state.detailyear}
                    rcp={this.state.rcp}
                  />
                </Grid.Column>
                <Grid.Column
                  className="card"
                  mobile={16}
                  tablet={16}
                  computer={5}
                >
                  <p>
                    <span className={"dark-mode-theme-color-1"}>
                      RCP Loss for Year {this.state.year}
                    </span>
                  </p>

                  <RCPDonut
                    data={this.state.detailyear}
                    year={this.state.year}
                  />
                </Grid.Column>
              </Grid.Row>
            </Container>
            <br />

            <Grid.Row>
              <Grid.Column
                computer={6}
                mobile={16}
                tablet={16}
                className="card dark-mode-black"
                style={{ marginLeft: "1%", width: "30%", height: "500px" }}
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Risk Index
                  </span>
                </p>
                {data[0]["RCP8.5"] ? (
                  <ResponsiveBar
                    data={data}
                    keys={["RCP2.6", "RCP4.5", "RCP8.5"]}
                    indexBy="name"
                    margin={{ top: 60, right: 100, bottom: 100, left: 60 }}
                    padding={0.3}
                    enableGridY={false}
                    groupMode="grouped"
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={["#6c85bd", "#60b1cc", "#bac3d2"]}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "#38bcb2",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "#eed312",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "fries",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "sandwich",
                        },
                        id: "lines",
                      },
                    ]}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,

                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Climate Index",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    enableLabel={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    theme={{
                      textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                    }}
                  />
                ) : null}
              </Grid.Column>

              <Grid.Column
                computer={10}
                tablet={16}
                mobile={16}
                className="card"
                style={{ marginLeft: "2%", width: "67%", height: "500px" }}
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    {" "}
                    Climate spread (% loss in {this.state.year} adjusted by
                    return period)
                  </span>
                </p>
                <CandleStick data={this.state.overall.candle_stick_chart} />
              </Grid.Column>
            </Grid.Row>
            <br />
            <Grid.Row>
              <Grid.Column
                computer={14}
                mobile={16}
                tablet={16}
                className="card"
                style={{ width: "100%" }}
              >
                <Table className="dark-mode-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell>Area</Table.HeaderCell>
                      <Table.HeaderCell>Valuation</Table.HeaderCell>
                      <Table.HeaderCell>Climate Score</Table.HeaderCell>
                      <Table.HeaderCell>Overalll Loss(mil $)</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.asset_table.length > 0 ? (
                      this.state.asset_table.map((asset, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{asset.name}</Table.Cell>

                          <Table.Cell style={{ textTransform: "capitalize" }}>
                            {asset.type}
                          </Table.Cell>
                          <Table.Cell>{asset.area}</Table.Cell>
                          <Table.Cell>{asset.valuation}</Table.Cell>
                          <Table.Cell>
                            <Progress percent={asset.climatic_score} />
                          </Table.Cell>
                          <Table.Cell>
                            {asset.overall_loss.toFixed(2)}
                          </Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row></Table.Row>
                    )}
                  </Table.Body>

                  <Table.Footer></Table.Footer>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <br />
            {/*<Grid.Row>
      <Grid.Column computer={1}></Grid.Column>
      <Grid.Column computer={4} tablet={16} mobile={16} className="card">
        
        <p><span className={"dark-mode-theme-color-1"}>Portfolio Losses</span></p>

          {this.state.losses['Asset Flood Damage']?<div>
            
            <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Total Loss </span><b style={{float:'right'}}><span className={"dark-mode-primary-color"}>$ {this.state.losses['Total Loss'][0].toFixed(2)} Billion</span></b></p>
            <Progress percent={this.state.losses['Total Loss'][1]}/>

              <p style={{fontSize:'14px'}}>Asset Flood Damage <b style={{float:'right'}}>$ {this.state.losses['Asset Flood Damage'][0].toFixed(2)} Billion</b></p>

            <Progress percent={this.state.losses['Asset Flood Damage'][1]} color='green'/>
            <p style={{fontSize:'14px'}}>Asset Storm Damage <b style={{float:'right'}}>$ {this.state.losses['Asset Storm Damage'][0].toFixed(2)} Billion</b></p>

            <Progress percent={this.state.losses['Asset Storm Damage'][1]} color='green'/>
            <p style={{fontSize:'14px'}}>Operational Flood Loss <b style={{float:'right'}}>$ {this.state.losses['Operational Flood Loss'][0].toFixed(2)} Billion</b></p>

            <Progress percent={this.state.losses['Operational Flood Loss'][1]} color='green'/>
            <p style={{fontSize:'14px'}}>Operational Storm Surge Loss <b style={{float:'right'}}>$ {this.state.losses['Operational Storm Loss'][0].toFixed(2)} Billion</b></p>

            <Progress percent={this.state.losses['Operational Storm Loss'][1]} color='green'/>
            

            

            


          </div>:null}
        </Grid.Column>*/}
            <Grid.Row>
              <Grid.Column
                computer={10}
                mobile={16}
                tablet={16}
                className="card"
                style={{ width: "100%" }}
                centered
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Extreme Hazard Risk Map (0 to 10){" "}
                  </span>
                </p>
                <div
                  className="heatmap"
                  style={{ padding: "10px", color: "white" }}
                >
                  {/*              {heatmapdata.length>0?<HeatMap
        {...commonProperties}            
        data={heatmapdata}
        keys={Var}
        indexBy="asset"
        margin={{ top: 0, right: 0, bottom: 80, left: 160 }}
        forceSquare={false}
        axisBottom={{ orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36 }}
        axisRight={null}
        axisTop={null}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            
            legendPosition: 'middle',
            legendOffset: -40
        }}
        cellOpacity={1}
        cellShape='circle'
        minValue={0}
        maxValue={10}
        sizeVariation={0.8}
        enableGridX={true}
        enableGridY={true}
        cellBorderWidth={2}
        colors='YlOrRd'
        cellBorderColor="#ffffff"
        labelTextColor="black"
        defs={[
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(0, 0, 0, 0.1)',
                rotation: -45,
                lineWidth: 4,
                spacing: 7
            }
        ]}
        fill={[ { id: 'lines' } ]}
        animate={true}
        motionConfig="wobbly"
        motionStiffness={80}
        motionDamping={9}
        hoverTarget="cell"
        cellHoverOthersOpacity={0.25}
        tooltip={({xKey,yKey,value,color})=>(
            <strong style={{color}}>
            {xKey}  /  {yKey}:  {value}
            </strong>)}
            theme={{
            tooltip: {
                container: {
                    background: 'black',
                },
            },
        }}
        theme={{
            textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
        }}
    />
:null}*/}

                  <svg
                    style={{
                      width: "60%",
                      marginTop: "-50px",
                      marginLeft: "300px",
                    }}
                    height="20"
                  >
                    <defs>
                      <linearGradient id="MyGradient">
                        <stop offset="5%" stop-color="#fef8cc" />
                        <stop offset="95%" stop-color="#812327" />
                      </linearGradient>
                    </defs>

                    <rect width="600" height="20" fill="url(#MyGradient)" />
                  </svg>
                  <ul
                    style={{ display: "flex", marginLeft: "300px" }}
                    className="legend"
                  >
                    <li style={{ color: "black" }}>0</li>
                    <li style={{ color: "black" }}>1</li>
                    <li style={{ color: "black" }}>2</li>
                    <li style={{ color: "black" }}>3</li>
                    <li style={{ color: "black" }}>4</li>
                    <li style={{ color: "black" }}>5</li>
                    <li style={{ color: "black" }}>6</li>
                    <li style={{ color: "black" }}>7</li>
                    <li style={{ color: "black" }}>8</li>
                    <li style={{ color: "black" }}>9</li>
                    <li style={{ color: "black" }}>10</li>
                  </ul>
                </div>
              </Grid.Column>
            </Grid.Row>
            <br />

            <br />
            <br />
            {this.state.single_asset ? (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                Asset Wise Report: {this.state.single_asset[0].asset_name}{" "}
              </p>
            ) : null}
            <br />
            <Grid.Row>
              <Grid.Column
                width="4"
                className="card dark-mode-black"
                style={{ height: "500px", marginLeft: "2%", width: "33%" }}
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Risk Index
                  </span>
                </p>
                <ResponsiveBar
                  data={singledata}
                  keys={["RCP2.6", "RCP4.5", "RCP8.5"]}
                  indexBy="name"
                  enableGridY={false}
                  margin={{ top: 60, right: 100, bottom: 100, left: 60 }}
                  padding={0.3}
                  groupMode="grouped"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={["#6c85bd", "#60b1cc", "#bac3d2"]}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "#38bcb2",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "#eed312",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  fill={[
                    {
                      match: {
                        id: "fries",
                      },
                      id: "dots",
                    },
                    {
                      match: {
                        id: "sandwich",
                      },
                      id: "lines",
                    },
                  ]}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,

                    legendPosition: "middle",
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Climate Index",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  enableLabel={false}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  theme={{
                    textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                  }}
                />
              </Grid.Column>

              <Grid.Column
                width="3"
                className="card"
                style={{ marginLeft: "2.5%", width: "27%" }}
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>Risks</span>
                </p>

                <div>
                  <p style={{ fontSize: "12px" }}>
                    <span className={"dark-mode-primary-color"}>OverAll </span>
                    <b style={{ float: "right" }}>
                      <span className={"dark-mode-primary-color"}>
                        {this.state.single_asset_progress.OVERALL}%
                      </span>
                    </b>
                  </p>
                  <Progress
                    percent={this.state.single_asset_progress.OVERALL}
                  />
                  <p style={{ fontSize: "12px" }}>
                    Flood{" "}
                    <b style={{ float: "right" }}>
                      {this.state.single_asset_progress.Flood}%
                    </b>
                  </p>

                  <Progress
                    percent={this.state.single_asset_progress.Flood}
                    color="green"
                  />
                  <p style={{ fontSize: "12px" }}>
                    Rainfall{" "}
                    <b style={{ float: "right" }}>
                      {this.state.single_asset_progress.Rainfall}%
                    </b>
                  </p>

                  <Progress
                    percent={this.state.single_asset_progress.Rainfall}
                    color="yellow"
                  />
                  <p style={{ fontSize: "12px" }}>
                    Storm Surge{" "}
                    <b style={{ float: "right" }}>
                      {this.state.single_asset_progress["Storm Surge"]}%
                    </b>
                  </p>

                  <Progress
                    percent={this.state.single_asset_progress["Storm Surge"]}
                    color="red"
                  />
                  <p style={{ fontSize: "12px" }}>
                    Drought{" "}
                    <b style={{ float: "right" }}>
                      {this.state.single_asset_progress.Drought}%
                    </b>
                  </p>

                  <Progress
                    percent={this.state.single_asset_progress.Drought}
                    color="red"
                  />
                  <p style={{ fontSize: "12px" }}>
                    Extreme Heat{" "}
                    <b style={{ float: "right" }}>
                      {this.state.single_asset_progress["Extreme Heat"]}%
                    </b>
                  </p>

                  <Progress
                    percent={this.state.single_asset_progress["Extreme Heat"]}
                    color="red"
                  />
                  <p style={{ fontSize: "12px" }}>
                    LandSlide{" "}
                    <b style={{ float: "right" }}>
                      {this.state.single_asset_progress.Landslide}%
                    </b>
                  </p>

                  <Progress
                    percent={this.state.single_asset_progress["Landslide"]}
                    color="red"
                  />
                </div>
              </Grid.Column>

              {this.state.single_asset_loss["Total Loss"] ? (
                <Grid.Column
                  width="3"
                  className="card"
                  style={{ marginLeft: "2.5%", width: "27%" }}
                >
                  <p>
                    <span className={"dark-mode-theme-color-1"}>
                      Asset Losses
                    </span>
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    Total Loss{" "}
                    <b style={{ float: "right" }}>
                      {" "}
                      US ${" "}
                      {this.state.single_asset_loss["Total Loss"][0].toFixed(
                        2
                      )}{" "}
                      million
                    </b>
                  </p>
                  <Progress
                    percent={this.state.single_asset_loss["Total Loss"][1]}
                  />
                  <p style={{ fontSize: "12px" }}>
                    Asset Flood Damage{" "}
                    <b style={{ float: "right" }}>
                      {" "}
                      US ${" "}
                      {
                        this.state.single_asset_loss[
                          "Asset Rainfall Flood Damage"
                        ][0]
                      }{" "}
                      million
                    </b>
                  </p>

                  <Progress
                    percent={
                      this.state.single_asset_loss[
                        "Asset Rainfall Flood Damage"
                      ][1]
                    }
                    color="green"
                  />
                  <p style={{ fontSize: "12px" }}>
                    Asset Storm Damage{" "}
                    <b style={{ float: "right" }}>
                      US ${" "}
                      {
                        this.state.single_asset_loss[
                          "Asset Storm Surge Damage"
                        ][0]
                      }{" "}
                      million
                    </b>
                  </p>

                  <Progress
                    percent={
                      this.state.single_asset_loss[
                        "Asset Storm Surge Damage"
                      ][1]
                    }
                    color="yellow"
                  />
                  <p style={{ fontSize: "12px" }}>
                    Operational Flood Loss{" "}
                    <b style={{ float: "right" }}>
                      US ${" "}
                      {
                        this.state.single_asset_loss[
                          "Operational Rainfall Flood Loss"
                        ][0]
                      }{" "}
                      million
                    </b>
                  </p>

                  <Progress
                    percent={
                      this.state.single_asset_loss[
                        "Operational Rainfall Flood Loss"
                      ][1]
                    }
                    color="red"
                  />
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Operational Storm Surge Loss{" "}
                    <b style={{ float: "right" }}>
                      US ${" "}
                      {
                        this.state.single_asset_loss[
                          "Operational Storm Surge Loss"
                        ][0]
                      }{" "}
                      million
                    </b>
                  </p>

                  <Progress
                    percent={
                      this.state.single_asset_loss[
                        "Operational Storm Surge Loss"
                      ][1]
                    }
                    color="red"
                  />
                </Grid.Column>
              ) : null}
            </Grid.Row>
            <br />

            <Grid.Row>
              <Grid.Column width="1"></Grid.Column>
              <Grid.Column
                width="7"
                className="card dark-mode-black"
                style={{ height: "500px", width: "45%", marginLeft: "2%" }}
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    RCP 2.6 vs RCP 4.5 vs RCP 8.5 for Year 2050
                  </span>
                </p>

                <ResponsiveBar
                  data={this.state.RcpData}
                  keys={["RCP2.6", "RCP4.5", "RCP8.5"]}
                  indexBy="name"
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  enableGridY={false}
                  groupMode="grouped"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={["#6c85bd", "#60b1cc", "#bac3d2"]}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "#38bcb2",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "#eed312",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  fill={[
                    {
                      match: {
                        id: "fries",
                      },
                      id: "dots",
                    },
                    {
                      match: {
                        id: "sandwich",
                      },
                      id: "lines",
                    },
                  ]}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,

                    legendPosition: "middle",
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Climate Index",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  enableLabel={false}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  theme={{
                    textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                  }}
                />
              </Grid.Column>
              <Grid.Column width="1"></Grid.Column>
              <Grid.Column
                width="7"
                className="card dark-mode-black"
                style={{ height: "500px", width: "45%", marginLeft: "2%" }}
              >
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Year 2020 vs Year 2030 vs Year 2050 for RCP 8.5
                  </span>
                </p>
                <ResponsiveBar
                  data={this.state.YearData}
                  keys={["2020", "2030", "2050"]}
                  indexBy="name"
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  enableGridY={false}
                  groupMode="grouped"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={["#6c85bd", "#60b1cc", "#bac3d2"]}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "#38bcb2",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "#eed312",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  fill={[
                    {
                      match: {
                        id: "fries",
                      },
                      id: "dots",
                    },
                    {
                      match: {
                        id: "sandwich",
                      },
                      id: "lines",
                    },
                  ]}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,

                    legendPosition: "middle",
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Climate Index",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  enableLabel={false}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  theme={{
                    textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                  }}
                />{" "}
              </Grid.Column>
            </Grid.Row>
            <br />
          </div>
        </PDFExport>
        <br />
        <div style={{ float: "right" }}>
          {" "}
          <Button primary onClick={this.printDocument}>
            Download
          </Button>
        </div>
        <br />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    project: state.project.project,
    feedback: state.feedback.feedback,
    building: state.feedback.building,
    detailyear: state.feedback.detailyear,
    isLoading: state.feedback.isLoading,
    assets: state.locus.assets,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addDashboard: (formdata) => {
      dispatch(dashboard.postDashboard(formdata));
    },
    getProjects: () => {
      dispatch(project.getProjects());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    getDetailByYear: (formdata) => {
      dispatch(dashboard.getDetailByYear(formdata));
    },

    getAssets: () => {
      dispatch(locus.getAssets());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
