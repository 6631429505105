import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";

let data = [];

const BarComponent = (props) => {
  if (props.data["2020"] || props.data["2020"] == 0) {
    data = [
      {
        "RCP 2.6": props.data["2050_26"],

        "RCP 4.5": props.data["2050_45"],

        "RCP 8.5": props.data["2050_85"],
      },
    ];
  }

  console.log("Bar Component ", data, props.data);

  return (
    <ResponsiveBar
      groupMode="grouped"
      data={data}
      padding={0.5}
      keys={["RCP 2.6", "RCP 4.5", "RCP 8.5"]}
      margin={{ top: 50, right: 110, bottom: 150, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="cardinal"
      colors={["#808080", "#2e2e38", "#f5d76e"]}
      axisTop={null}
      axisRight={null}
      gridYValues={10}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Year",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "RCP",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      labelTextColor={{
        from: "color",
        modifiers: [["brighter", 1.6]],
      }}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
export default BarComponent;
