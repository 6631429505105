import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

const Heatmap = (props) => {
  const isDarkMode = localStorage.getItem("theme") == "dark";

  let heatmapdata = [];
  let commonProperties = {};

  for (let i = 0; i < props.data.names.length; i++) {
    heatmapdata.push({
      asset: props.data.names[i].slice(0, 25),

      Rainfall: props.data.values[i]["Rainfall"],
      "River Flood": props.data.values[i]["River Flood"],

      "Storm Surge": props.data.values[i]["Storm Surge"],
      "Extreme heat": props.data.values[i]["Extreme Heat"],
      "Snow Melt": props.data.values[i]["Snow Melt"],
      "Rainfall Flood": props.data.values[i]["Rainfall Flood"],
      Typhoon: props.data.values[i]["Typhoon"],
      "Sea Level Rise": props.data.values[i]["Sea Level Rise"],
      Landslide: props.data.values[i]["Landslide"],
      "Wild Fire": props.data.values[i]["Wild Fire"],
    });
  }
  let n = props.data.names.length;
  commonProperties = {
    width: 800,
    height: n * 100,
  };

  return (
    <div
      id="HeatMap"
      className="heatmap"
      style={{
        padding: "10px",
        color: "white",
      }}
    >
      <ResponsiveHeatMap
        {...commonProperties}
        data={heatmapdata}
        keys={props.variable}
        indexBy="asset"
        margin={{ top: 50, right: 10, bottom: 0, left: 250 }}
        axisBottom={null}
        axisRight={null}
        axisTop={{
          orient: "top",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: 36,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,

          legendPosition: "middle",
          legendOffset: -40,
        }}
        cellOpacity={1}
        minValue={0}
        maxValue={100}
        enableContainerX={true}
        enableContainerY={true}
        cellBorderWidth={2}
        colors="YlOrRd"
        cellBorderColor="#ffffff"
        labelTextColor="black"
        defs={[
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(0, 0, 0, 0.1)",
            rotation: -45,
            lineWidth: 4,
            spacing: 7,
          },
        ]}
        fill={[{ id: "lines" }]}
        animate={true}
        motionConfig="wobbly"
        motionStiffness={80}
        motionDamping={9}
        hoverTarget="cell"
        cellHoverOthersOpacity={0.25}
        tooltip={({ xKey, yKey, value, color }) => (
          <strong style={{ color }}>
            {xKey} / {yKey}: {value}
          </strong>
        )}
        theme={{
          tooltip: {
            container: {
              background: "black",
            },
          },
        }}
        theme={{
          textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
        }}
      />
      <br />
    </div>
  );
};

export default Heatmap;
