import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Progress,
  Select,
} from "antd";
import { Icon, Button } from "semantic-ui-react";

const ProjectTable = (props) => {
  const [form] = Form.useForm();
  const originData = [];

  const [data, setData] = useState(originData);

  for (let i = 0; i < props.data.length; i++) {
    originData.push({
      key: i.toString(),
      name: props.data[i].name,
      date: props.data[i].date.slice(0, 10),
      severity: props.data[i].severity,
      assets: props.data[i].assets,
      variables: props.data[i].variables,
      property_type: props.data[i].property_type,
      value: props.data[i].value,
      loss: `$ ` + props.data[i].loss.toFixed(2) + " Million",
    });
  }
  useEffect(() => {
    if (props.data) {
      setData(originData);
    }
  }, [setData, props.data]);

  console.log("project_table data", data);

  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Severity",
      dataIndex: "severity",
    },
    {
      title: "Assets",
      dataIndex: "assets",
    },
    {
      title: "Valuation",
      dataIndex: "value",
    },
    {
      title: "Overall Loss",
      dataIndex: "loss",
    },
    {
      title: "Analyse",
      dataIndex: "",
      render: (_, record) => (
        <Button onClick={() => props.handleDashboard(record)}>Analyse</Button>
      ),
    },
  ];

  return <Table bordered dataSource={data} columns={columns} />;
};

export default ProjectTable;
