import React, { PureComponent } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ScatterChart,
  Scatter,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Row, Col, Button } from "react-bootstrap";
import { ResponsiveBar } from "@nivo/bar";
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryAxis,
} from "victory";
import { Modal } from "semantic-ui-react";

let data = [];

export default class RCPDonut extends PureComponent {
  state = {
    activeIndex: 0,
    piedata: "",
    key_finding: false,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      piedata: "",
    });
  };
  handleKeyFindings = () => {
    this.setState({
      key_finding: true,
    });
  };
  handleClose = () => {
    this.setState({
      key_finding: false,
    });
  };

  render() {
    const isDarkMode = localStorage.getItem("theme") == "dark";
    console.log("piemdkdsmks", this.props.data, this.props.year);
    this.state.piedata = this.props.data;
    let x = 0;
    let y = 0;
    let z = 0;
    let a = 0;

    if (this.state.piedata) {
      for (let i = 0; i < this.state.piedata.length; i++) {
        x += this.state.piedata[i]["year_constant"]["2.6"];
        y += this.state.piedata[i]["year_constant"][`4.5`];
        z += this.state.piedata[i]["year_constant"][`8.5`];
        a += this.state.piedata[i].exposure;
      }
    }
    if (x > 1000 || y > 1000 || z > 1000) {
      console.log("condition", x, y, z);

      data = [
        //{ name:'Exposure',value:a.toFixed(2),dot:a.toFixed(2)},
        {
          name: "2.6",
          "RCP 2.6": (x / 1000).toFixed(2),
          line: (x / 1000).toFixed(2),
          dot: a.toFixed(2),
        },
        {
          name: "4.5",
          "RCP 4.5": (y / 1000).toFixed(2),
          line: (y / 1000).toFixed(2),
          dot: a.toFixed(2),
        },
        {
          name: "8.5",
          "RCP 8.5": (z / 1000).toFixed(2),
          line: (z / 1000).toFixed(2),
          dot: a.toFixed(2),
        },
      ];
    } else {
      data = [
        //{ name:'Exposure',value:a.toFixed(2),dot:a.toFixed(2)},
        {
          name: "2.6",
          "RCP 2.6": x.toFixed(2),
          line: x.toFixed(2),
          dot: a.toFixed(2),
        },
        {
          name: "4.5",
          "RCP 4.5": y.toFixed(2),
          line: y.toFixed(2),
          dot: a.toFixed(2),
        },
        {
          name: "8.5",
          "RCP 8.5": z.toFixed(2),
          line: z.toFixed(2),
          dot: a.toFixed(2),
        },
      ];
    }
    console.log("xy", data);

    const COLORS = [isDarkMode ? "#ffffff" : "#002060", "#00c49f", "#ffbb28"];

    return (
      <div>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col
            md={12}
            xs={12}
            sm={12}
            className="graph"
            style={{ height: "25vh" }}
          >
            {(x > 1000) | (y > 1000) | (z > 1000) ? (
              <ResponsiveBar
                data={data}
                keys={["RCP 2.6", "RCP 4.5", "RCP 8.5"]}
                indexBy="name"
                margin={{ top: 50, right: 110, bottom: 50, left: 50 }}
                padding={0.7}
                enableLabel={false}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                valueFormat={{ format: "", enabled: false }}
                colors={["#FF0000", "#0055FF", "#002B82"]}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 5,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "fries",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "sandwich",
                    },
                    id: "lines",
                  },
                ]}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Representative concentration pathways (RCPs)",
                  legendPosition: "start",
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 1,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 3,
                  legend: "Loss in Bil $",
                  legendPosition: "middle",
                  legendOffset: -45,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 1,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            ) : (
              <ResponsiveBar
                data={data}
                keys={["RCP 2.6", "RCP 4.5", "RCP 8.5"]}
                indexBy="name"
                margin={{ top: 50, right: 110, bottom: 50, left: 50 }}
                padding={0.7}
                enableLabel={false}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                valueFormat={{ format: "", enabled: false }}
                colors={["#FF0000", "#0055FF", "#002B82"]}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 5,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "fries",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "sandwich",
                    },
                    id: "lines",
                  },
                ]}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Representative concentration pathways (RCPs)",
                  legendPosition: "start",
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 1,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 3,
                  legend: "Loss in Mil $",
                  legendPosition: "middle",
                  legendOffset: -45,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 1,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            )}
          </Col>
        </Row>

        <Button
          style={{
            color: "#002060",
            backgroundColor: "white",
            borderColor: "#002060",
          }}
          variant="info"
          onClick={this.handleKeyFindings}
        >
          Key Findings
        </Button>
        <Modal
          open={this.state.key_finding}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Key Findings
          </Modal.Header>
          <Modal.Content>
            <p>
              For the given year 2050, the projected loss depends on the amount
              of carbon concentration, captured by certain RCPs. The difference
              between RCP8.5 AND RCP2.6 reflects the Value of Transition
              Policies which is US$ xxx.xx million by 2050. <br />
              This is calculated by deducting the CVaR of US$ xxx.xx under RCP
              8.5 from the CVaR of US$ xxx.xx under RCP 2.6.
            </p>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
