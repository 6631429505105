import React, { useState,useCallback,useEffect,Component} from 'react';
import {Icon,Button,Table} from 'semantic-ui-react';
import {Grid} from 'semantic-ui-react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import './apidocs.css';


class APIDocs extends Component{
    constructor(props) {
        super(props);
        
    }
    render() {

   const isDarkMode = localStorage.getItem("theme") == "dark"
 
        return (
          <Tabs className="GettingStarted" defaultActiveKey="gettingstarted">
          <Tab title="Getting Started" eventKey="gettingstarted">
          <div>
          <Grid.Column
              id="gettingstarted"
              className="card"
              style={{
                height: "90%",
                width: "69%",
                maxWidth: "92.5%",
                overflowX: "auto",
                overflowY: "hidden",
                marginLeft: "90px",
                marginTop: "20px",
              }}
            >
          <p style={{fontSize:'40px' ,color:'#002060'}}>Getting Started</p>
         
          <h2>Step 0: Base Endpoint for API calls</h2>
          <p style={{fontSize:'16px'}}> > <a href="https://api.intensel.live/apiv1/">https://api.intensel.live/apiv1/</a></p>

          <h2>Step 1: Prepare API key</h2>
          <p style={{fontSize:'16px'}}>You have to pass api key in headers for authorization</p>
          <p style={{fontSize:'16px'}}>Authorization: Api-Key <code>{'<API_KEY> '}</code> </p>

          <h2>Step 2: Make Request</h2>
          <p style={{fontSize:'16px'}}>import requests <br />
            URL = "https://api.intensel.live/apiv1/create/" <br />
            headers =<code>{'{"Authorization":"Api-Key API_KEY"}'}</code><br />
            payload =<code>{'{"project_name": "My Awesome Project"}'}</code><br />
            response = requests.post(URL, json=payload, headers=headers) <br />
            data = response.json()</p>
            <p style={{fontSize:'16px'}}>Make Sure to include API_KEY in headers for authentication</p>

           <h2>Step 3: Done</h2>
           <p style={{fontSize:'16px'}}>Congrates you have make your first query.</p>
          </Grid.Column>
          </div>
          </Tab>

          <Tab title="Create Project" eventKey="createproject">
          <div>
          <Grid.Column
              id="gettingstarted"
              className="card"
              style={{
                height: "90%",
                width: "69%",
                maxWidth: "92.5%",
                overflowX: "auto",
                overflowY: "hidden",
                marginLeft: "90px",
                marginTop: "20px",
              }}
            >
          <p style={{fontSize:'40px' ,color:'#002060'}}>Create Project</p>
         
          <h2>Create A Project</h2>
          <p style={{fontSize:'16px'}}>Project Contains All Your Assets And It Allows To Analyze All Of Your Submitted Locations At Once <br />
          Endpoint:
          </p>
          <p style={{fontSize:'16px'}}> > <a href="https://api.intensel.live/apiv1/create/ ">https://api.intensel.live/apiv1/create/</a></p>

          <table id="t01">
              <tr>
              <th>Parameters</th>
              <th>Value</th>
              </tr>
              <tr>
              <td>project_name</td>
              <td>Name Of the Project</td>
              </tr>
          </table>

          <h2>Sample Request</h2>
          <p style={{fontSize:'16px'}}>URL: https://api.intensel.live/apiv1/create/ <br />
          <br />
            data: {
                  <code>{' "project_name": "My Awesome Project", '} <br />
                  <br />
                            "variables":["Rainfall Flood", "Extreme Heat"], <br />
                            <br />
                  } </code>
                }</p>
            <p style={{fontSize:'16px'}}>Variable Name Are Climate Variables Which You Wish To Analyze</p>

           <h2>Climate Variables</h2>
           <table id="t01">
           <th>Variables*</th>
           <tr>River Flood*</tr>
           <tr>Typhoon*</tr>
           <tr>Storm Surge</tr>
           <tr>LandSlide</tr>
           <tr>Extreme Heat</tr>
           <tr>Snow Melt</tr>
           <tr>Rainfall Flood</tr>
           <tr>Drought</tr>
           <tr>Sea Level Rise</tr>
           </table>
           <p style={{fontSize:'16px'}}>* Marked Are Required And Cant Be Skipped</p>
          
           <h2>Code Example</h2>
           <p style={{fontSize:'16px'}}>
            import requests
            URL = "https://api.intensel.live/apiv1/create/" <br />
            <br />
            <code>{' headers = {"Authorization":"Api-Key API_KEY"} '} <br />
            <br />
            {' payload = {"project_name": "My Awesome Project"} '}</code> <br />
            <br />
            response = requests.post(URL, json=payload, headers=headers) <br />
            <br />
            data = response.json() <br />
            <br />
            </p>

            <h2>Expected Response</h2>
            <p>
            status: HTTP 201
            <code>{' {"success":"Project Created Successfully"} '}</code>
            </p>
            </Grid.Column>
            </div>
           </Tab>

           <Tab title="Add Single Asset" eventKey="addasset">
           <div>
            <Grid.Column
              id="addasset"
              className="card"
              style={{
                height: "90%",
                width: "69%",
                maxWidth: "92.5%",
                overflowX: "auto",
                overflowY: "hidden",
                marginLeft: "90px",
                marginTop: "20px",
              }}
            >
              <p style={{fontSize:'40px' ,color:'#002060'}}>Add Single Asset</p>
              <h2>Add Single Asset To Project</h2>
              <p style={{fontSize:'16px'}}>
                Using This API You Can Add Single Location To Get Analyzed And
                Added To Your Project Endpoint: <br /> &gt;
                <a href="https://api.intensel.live/apiv1/add/">https://api.intensel.live/apiv1/add/</a>
              </p>
              <h2> Api Parameters</h2>
              <table id="t01">
                <tr>
                  <th>Parameters</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>project_name</td>
                  <td>Name of the Project</td>
                </tr>
                <tr>
                  <td>asset_data</td>
                  <td>Valid Json Data Containing Information About Location</td>
                </tr>
              </table>
              <h2>Sample Project</h2>
              <p style={{textAlign:'left', fontSize:'16px'}}><code>
                URL: https://api.intensel.live/apiv1/add/
                <br />
                data: {"{"}
                <br />
                "project_name": "My Awesome Project", <br />
                "asset_data" : {"{"}
                <br />
                {"  "}"name": "Hong Kong Park", <br />
                {"  "}"latitude": 22.424645494250665, <br />
                {"  "}"longitude" : 114.2135547607693, <br />
                {"  "}"area": 2509, <br />
                {"  "}"valuation": 2117.25, <br />
                {"  "}"stories": 3,
                <br />
                {"}"}
                <br />
                {"}"}
              </code></p>
              <h2>asset_data paramters</h2>
              <table id="t01">
                <tr>
                  <th>Parameters</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>name (optional)</td>
                  <td>
                    Name Of The location (If Not Provided Will Get identified By
                    Us){" "}
                  </td>
                </tr>
                <tr>
                  <td>latitude *</td>
                  <td>Latitude Of Location</td>
                </tr>
                <tr>
                  <td>Longitude *</td>
                  <td>Longitude Of Location</td>
                </tr>
                <tr>
                  <td>area (optional) </td>
                  <td>
                    Area of location in sqm (If Not Provided Will Get identified
                    By Us){" "}
                  </td>
                </tr>
                <tr>
                  <td>Valuation (Optional)</td>
                  <td>
                    Valuation Of Location in USD/sqm (If Not Provided Will Get
                    identified By Us){" "}
                  </td>
                </tr>
                <tr>
                  <td>Stories (Optional)</td>
                  <td>
                    Number Of Floors/Stories Of The Location (If Not Provided
                    Will Get identified By Us){" "}
                  </td>
                </tr>
              </table>
              * Marked Parameters are required
              <h2>Code Example</h2>
              <p style={{textAlign:'left' ,fontSize:'16px'}}><code>
                import requests
                <br />
                URL = "https://api.intensel.live/apiv1/add/"
                <br />
                headers = {"{"}"Authorization":"Api-Key API_KEY"{"}"}
                payload = {"{"} <br />
                "project_name": "My Awesome Project", <br />
                "asset_data" : {"{"}
                "name": "Hong Kong Park", <br />
                "latitude": 22.424645494250665, <br />
                "longitude" : 114.2135547607693, <br />
                "area": 2509, <br />
                "valuation": 2117.25, <br />
                "stories": 3, <br />
                {"}"} <br />
                {"}"} <br />
                response = requests.post(URL, json=payload, headers=headers)
                <br />
                data = response.json()
              </code>
              <h2>Expected Response</h2>
              <code>
                status: HTTP 201
                {"{"}"success":"Asset Added To Project My Awesome Project With
                Name Hong Kong Park"{"}"}
              </code></p>
            </Grid.Column>
          </div>
            </Tab>

             <Tab title="Add Bulk Assets" eventKey="addbulkassets">
          <div>
            <Grid.Column
              id="addbulkassets"
              className="card"
              style={{
                height: "90%",
                width: "69%",
                maxWidth: "92.5%",
                overflowX: "auto",
                overflowY: "hidden",
                marginLeft: "90px",
                marginTop: "20px",
              }}
            >
              <p style={{fontSize:'40px' ,color:'#002060'}}>Add Bulk Assets</p>
              <h2>Add Multiple Asset To Project</h2>
              <p style={{fontSize:'16px'}}>
                Using This API You Can Add Multiple Location To Get Analyzed And
                Added To Your Project In One Single API Call
                <br /> Endpoint: <br /> &gt;
               <a href=" https://api.intensel.live/apiv1/add/">https://api.intensel.live/apiv1/add/</a>
              </p>
              <h2> Api Parameters</h2>
              <table id="t01">
                <tr>
                  <th>Parameters</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>project_name</td>
                  <td>Name of the Project</td>
                </tr>
                <tr>
                  <td>asset_data</td>
                  <td>
                    Valid Json Data Containing Information About Multiple
                    Locations
                  </td>
                </tr>
              </table>
              <h2>Sample Project</h2>
              <p style={{textAlign:'left' ,fontSize:'16px'}}><code>
                URL: https://api.intensel.live/apiv1/add/
                <br />
                data: {"{"}
                <br />
                "project_name": "My Awesome Project", <br />
                "asset_data" : [{"{"}
                <br />
                {"  "}"name": "Hong Kong Park", <br />
                {"  "}"latitude": 22.424645494250665, <br />
                {"  "}"longitude" : 114.2135547607693, <br />
                {"  "}"area": 2509, <br />
                {"  "}"valuation": 2117.25, <br />
                {"  "}"stories": 3,
                <br />
                {"}"},<br />
                {"{"}
                <br />
                {"  "}"name": "Canal Rd West", <br />
                {"  "}"latitude": 22.27806830351389, <br />
                {"  "}"longitude" : 114.18119155173112, <br />
                {"  "}"area": 1810, <br />
                {"  "}"valuation": 2117.25, <br />
                {"  "}"stories": 3,
                <br />
                {"}"}]
                <br />
                {"}"}
              </code></p>
              <h2>asset_data paramters</h2>
              <p style={{fontSize:'16px'}}>asset_data is Same As Single API</p>
              <table id="t01">
                <tr>
                  <th>Parameters</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>name (optional)</td>
                  <td>
                    Name Of The location (If Not Provided Will Get identified By
                    Us){" "}
                  </td>
                </tr>
                <tr>
                  <td>latitude *</td>
                  <td>Latitude Of Location</td>
                </tr>
                <tr>
                  <td>Longitude *</td>
                  <td>Longitude Of Location</td>
                </tr>
                <tr>
                  <td>area (optional) </td>
                  <td>
                    Area of location in sqm (If Not Provided Will Get identified
                    By Us){" "}
                  </td>
                </tr>
                <tr>
                  <td>Valuation (Optional)</td>
                  <td>
                    Valuation Of Location in USD/sqm (If Not Provided Will Get
                    identified By Us){" "}
                  </td>
                </tr>
                <tr>
                  <td>Stories (Optional)</td>
                  <td>
                    Number Of Floors/Stories Of The Location (If Not Provided
                    Will Get identified By Us){" "}
                  </td>
                </tr>
              </table>
              * Marked Parameters are required
              <h2>Code Example</h2>
             <p style={{textAlign:'left' ,fontSize:'16px'}}><code>
                import requests
                <br />
                URL = "https://api.intensel.live/apiv1/add/"
                <br />
                headers = {"{"}"Authorization":"Api-Key API_KEY"{"}"}
                payload = {"{"} <br />
                "project_name": "My Awesome Project", <br />
                "asset_data" : "asset_data" : [{"{"}
                <br />
                {"  "}"name": "Hong Kong Park", <br />
                {"  "}"latitude": 22.424645494250665, <br />
                {"  "}"longitude" : 114.2135547607693, <br />
                {"  "}"area": 2509, <br />
                {"  "}"valuation": 2117.25, <br />
                {"  "}"stories": 3,
                <br />
                {"}"},<br />
                {"{"}
                <br />
                {"  "}"name": "Canal Rd West", <br />
                {"  "}"latitude": 22.27806830351389, <br />
                {"  "}"longitude" : 114.18119155173112, <br />
                {"  "}"area": 1810, <br />
                {"  "}"valuation": 2117.25, <br />
                {"  "}"stories": 3,
                <br />
                {"}"}]<br />
                {"}"} <br />
                response = requests.post(URL, json=payload, headers=headers)
                <br />
                data = response.json()
              </code></p>
              <h2>Expected Response</h2>
              <code>
                status: HTTP 201
                {"{"}"success":"2 assets added to project My Awesome Project"
                {"}"}
              </code>
            </Grid.Column>
          </div>
        </Tab>


<Tab title="Get Analysis" eventKey="getanalysis">
          <div>
            <Grid.Column
              id="getanalysis"
              className="card"
              style={{
                height: "90%",
                width: "69%",
                maxWidth: "92.5%",
                overflowX: "auto",
                overflowY: "hidden",
                marginLeft: "90px",
                marginTop: "20px",
              }}
            >
              <p style={{fontSize:'40px' ,color:'#002060'}}>Add Multiple Asset To Project</p>
              <h2>Add Single Asset To Project</h2>
              <p style={{fontSize:'16px'}}>
                Using This API You Can Analyze complete Project <br />
                Endpoint: <br /> &gt;
                <a href="https://api.intensel.live/apiv1/getanalysis/">https://api.intensel.live/apiv1/getanalysis/</a>
              </p>
              <h2> Api Parameters</h2>
              <table id="t01">
                <tr>
                  <th>Parameters</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>project_name</td>
                  <td>Name of the Project</td>
                </tr>
              </table>
              <h2>Sample Request</h2>
             <p style={{textAlign:'left' ,fontSize:'16px'}}> <code>
                URL: https://api.intensel.live/apiv1/getanalysis/
                <br />
                data: {"{"}
                <br />
                "project_name": "My Awesome Project", <br />
                {"}"}
              </code>
              </p>
              <h2>Code Example</h2>
              <p style={{textAlign:'left' ,fontSize:'16px'}}><code>
                import requests
                <br />
                URL = "https://api.intensel.live/apiv1/getanalysis/"
                <br />
                headers = {"{"}"Authorization":"Api-Key API_KEY"{"}"}
                payload = {"{"} <br />
                "project_name": "My Awesome Project", <br />
                {"}"} <br />
                response = requests.post(URL, json=payload, headers=headers)
                <br />
                data = response.json()
              </code>
              </p>
              <h2>Expected Response</h2>
              <p style={{textAlign:'left' ,fontSize:'16px'}}><code>
                status: HTTP 201
                {"{"}"RCP_{"{"}rcp{"}"}-YEAR_{"{"}year{"}"}":{"{"}data{"}"}
                {"}"}
              </code>
              </p>
              <p style={{fontSize:'16px'}}>
              * Returned Data Will Be Risk Score Out Of 100 <br />
              You Will Get Data For Following RCPS And Years <br />
              RCP: <strong>26, 45, 85</strong> <br />
              Year: <strong>2030, 2050</strong></p>
            </Grid.Column>
          </div>
        </Tab>


          </Tabs>
         

              );
          }
    }
export default APIDocs;