import React from "react";
import { ResponsiveBar } from "@nivo/bar";

let data = [];

const RadialChart = (props) => {
  if (props.data) {
    let x = 0;
    let y = 0;
    let z = 0;
    let a = 0;

    for (let i = 0; i < props.data.length; i++) {
      x += props.data[i]["year_constant"][`2.6`];
      y += props.data[i]["year_constant"][`4.5`];
      z += props.data[i]["year_constant"][`8.5`];
    }

    if (x > 1000 || y > 1000 || z > 1000) {
      data = [
        {
          "RCP 2.6": (x / 1000).toFixed(2),
        },

        {
          "RCP 4.5": (y / 1000).toFixed(2),
        },

        {
          "RCP 8.5": (z / 1000).toFixed(2),
        },
      ];
    } else {
      data = [
        {
          "RCP 2.6": x.toFixed(2),
          "RCP 4.5": y.toFixed(2),
          "RCP 8.5": z.toFixed(2),
        },
      ];
    }
  }
  return (
    <ResponsiveBar
      groupMode="grouped"
      data={data}
      padding={0.5}
      keys={["RCP 2.6", "RCP 4.5", "RCP 8.5"]}
      margin={{ top: 50, right: 110, bottom: 150, left: 60 }}
      xScale={{ type: "point" }}
      labelTextColor={{
        from: "color",
        modifiers: [["brighter", 1.6]],
      }}
      yScale={{
        type: "linear",
        min: "0",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="cardinal"
      colors={["#808080", "#2e2e38", "#f5d76e"]}
      axisTop={null}
      axisRight={null}
      gridYValues={10}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Year",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "RCP",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default RadialChart;
