import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Progress,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
  Card,
} from "semantic-ui-react";
import rainfall from "../assets/rainfall_mt.png";
import flood from "../assets/flood_mt.png";
import fire from "../assets/fire_mt.png";
import drought from "../assets/drought_mt.png";
import storm from "../assets/storm_mt.png";
import heat from "../assets/heat_mt.png";
import wind from "../assets/wind_mt.png";

import bgsummary from "../assets/summary-bg.png";
import divimg from "../assets/divimg.png";
import concimg from "../assets/concimg.png";

import foundation from "../assets/foundation.png";
import asterisk from "../assets/asterisk.png";
import dollar from "../assets/dollar.png";
import location from "../assets/location.png";

import Heatmap from "./new_heatmap";

import MapChart from "./MapChart";

import { Tooltip } from "antd";

import { ResponsiveBar } from "@nivo/bar";
import index from "../assets/crisk.png";
import { linearGradientDef } from "@nivo/core";
import { connect } from "react-redux";

import { project, auth, dashboard, locus, asset } from "../actions";
import Building from "./building";

import "./dashboard.css";

import { Tabs, Tab, Row, Col, Container, tooltip } from "react-bootstrap";
import Detail from "./detail";
import Circle from "react-circle";

import RCPDonut from "./rcpdonut";
import YEARDonut from "./yeardonut";
import RadialChart from "./radialchart";

import Classicheatmap from "./classicheatmap";
import BarComponent from "./barcomponent";
import Progress_bar from "./progress_bar";
import LossComponent from "./losscomponent";

import Map from "./map";
import WorldMap from "./worldmap1";
import RiskMap from "./riskmap";
import CandleStick from "./candlestick";
import PopupMap from "./map1";
import EditableTable from "./table";
import EditableLossTable from "./losstable";
import EditableValuationTable from "./valuationtable";
import EditableClimateTable from "./climatetable";
import MdryearDonut from "./mdryeardonut";
import MdrrcpDonut from "./mdrrcpdonut";

import { ResponsiveLine } from "@nivo/line";

import SummaryMap from "./summarymap";

let data = [];
let options = [];
let Item = [];
let singledata = [];
let data01 = [];
let flat_data = [];

const ContinentOptions = [
  { key: "Asia", value: ["Asia", 100.6197, 34.04, 3], text: "Asia" },
  { key: "Europe", value: ["Europe", 15.2551, 54.526, 3], text: "Europe" },
  {
    key: "Australia",
    value: ["Australia", 133.7751, -25.2744, 3],
    text: "Australia",
  },
  { key: "America", value: ["America", -95.7129, 37.0902, 2], text: "America" },
  { key: "Africa", value: ["Africa", 34.5085, 8.7832, 3], text: "Africa" },
];

const RcpOptions = [
  { key: 2.6, value: 2.6, text: "RCP 2.6" },
  { key: 4.5, value: 4.5, text: "RCP 4.5" },
  { key: 8.5, value: 8.5, text: "RCP 8.5" },
];
let VariableOptions = [];
let AssetOptions = [];
const YearOptions = [
  { key: 2030, value: 2030, text: 2030 },
  { key: 2050, value: 2050, text: 2050 },
];
const basementOptions = [
  { key: "yes", value: "yes", text: "yes" },
  { key: "no", value: "no", text: "no" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const constructionOptions = [
  { key: "wood", value: "wood", text: "wood" },
  { key: "concrete", value: "concrete", text: "concrete" },
  { key: "masonry", value: "masonry", text: "masonry" },
  { key: "xs home", value: "xs home", text: "xs home" },
  { key: "light metal", value: "light metal", text: "light metal" },
  { key: "steel", value: "steel", text: "steel" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const storiesOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: "3 or More", value: "3 or More", text: "3 or More" },
  { key: "unknown", valeu: "unknown", text: "unknown" },
];
const occupancyOptions = [
  { key: "res", value: "res", text: "res" },
  { key: "com", value: "com", text: "com" },
  { key: "ind", value: "ind", text: "ind" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const tableOptions = [
  { key: "Top 3", value: 3, text: "Top 3" },
  { key: "Top 5", value: 5, text: "Top 5" },
  { key: "Top 10", value: 10, text: "Top 10" },
];
const CountOptions = [
  { key: "Top 3", value: "Top 3", text: "Top 3" },
  { key: "Top 5", value: "Top 5", text: "Top 5" },
  { key: "All", value: "All", text: "All" },
];
let ParameterOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "loss", value: "loss", text: "Overall Loss" },
];
let PropertyOptions = [];

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeIndex: 0,
    lossopen: false,
    heatopen: false,
    articleopen: false,
    percentopen: false,
    project: "",
    variable: "River Flood",
    rcp: 8.5,
    year: 2050,
    analysis: "local",
    modalOpen: false,
    feedback: "",
    asset_table: "",
    table: 3,
    risk: "",
    single_asset: "",
    activeItemName: "",
    single_asset_overall: "",
    singel_asset_chart: "",
    single_asset_loss: "",
    single_asset_progress: "",
    single_asset_cone_chart: "",
    RcpData: "",
    YearData: "",
    basement: "yes",
    construction: "wood",
    stories: "1",
    occupancy: "res",
    per_sq_m_value: 40000,
    scatter: "",
    yearDetail: "",
    detailed: false,
    global: false,
    singleYearDetail: "",
    portfolio_losses: "",
    portfolio_losses_flat: "",
    overall: "",
    heatmap: "",
    losses: "",
    data: "",
    financial: true,
    mapVariable: "FL8570",
    assets: [],
    mapAsset: "",
    table_condition: false,
    oldname: "",
    newname: "",
    area: "",
    type: "",
    valuation: "",
    count: "All",
    parameter: "loss",
    million: true,
    exposure_variable: "Total",
    property_type: "NA",
    long: "",
    lat: "",
    zoom: 14,
    continent: "Continents",
    activeIndex: -1,
    percentage: "",
    variables: "",
    property_types: "NA",
    country_loss: "",
    countryloss: "",
    with_content: false,
    defaultbuilding: "",
    riskOpen: false,
    worldmap: false,
    risk_2020: "",
    losses_2020: "",
    filterOpen: false,
    hazard: "",
    floors: "",
    ownership: "",
    construction_cost: "",
    list: true,
    map: false,
  };
  handleLossOpen = () => {
    this.setState({
      lossopen: true,
    });
  };
  handleArticleOpen = () => {
    this.setState({
      articleopen: true,
    });
  };
  handlePercentOpen = () => {
    this.setState({
      percentopen: true,
    });
  };
  handleHeatOpen = () => {
    this.setState({
      heatopen: true,
    });
  };
  handleRiskOpen = () => {
    this.setState({
      riskOpen: true,
    });
  };
  RiskClose = () => {
    this.setState({
      riskOpen: false,
    });
  };
  handleFilterOpen = () => {
    this.setState({
      filterOpen: true,
    });
  };
  filterClose = () => {
    this.setState({
      filterOpen: false,
    });
  };
  lossClose = () => {
    this.setState({
      lossopen: false,
    });
  };
  percentClose = () => {
    this.setState({
      percentopen: false,
    });
  };
  heatClose = () => {
    this.setState({
      heatopen: false,
    });
  };
  articleClose = () => {
    this.setState({
      articleopen: false,
    });
  };

  handleListView = () => {
    this.setState({ list: true, map: false });
  };
  handleMapView = () => {
    this.setState({ map: true, list: false });
  };

  componentDidMount() {
    this.props.getAssets();
    this.props.getProjects();

    if (this.props.project) {
      let forlgata = new FormData();
      if (
        this.props.location.state !== null &&
        this.props.location.state.project_name.project
      ) {
        forlgata.append(
          "project",
          this.props.location.state.project_name.project
        );
        forlgata.append("rcp", this.state.rcp);
        forlgata.append("variable", this.state.exposure_variable);
        forlgata.append("year", this.state.year);
        forlgata.append("count", this.state.count);
        forlgata.append("parameter", this.state.parameter);
        forlgata.append("with_content", this.state.with_content);
        forlgata.append("property_type", this.state.property_type);

        this.setState(
          {
            project: this.props.location.state.project_name.project,
            variables: this.props.location.state.project_name.variables,
            property_types:
              this.props.location.state.project_name.property_types,
          },
          () => this.props.addDashboard(forlgata)
        );
      } else if (
        this.props.location.state !== null &&
        this.props.location.state.project_name
      ) {
        forlgata.append("project", this.props.location.state.project_name);
        forlgata.append("rcp", this.state.rcp);
        forlgata.append("variable", this.state.exposure_variable);
        forlgata.append("year", this.state.year);
        forlgata.append("count", this.state.count);
        forlgata.append("parameter", this.state.parameter);
        forlgata.append("with_content", this.state.with_content);

        forlgata.append("property_type", this.state.property_type);

        this.setState(
          {
            project: this.props.location.state.project_name,
            variables: this.props.location.state.variables,
            property_types: this.props.location.state.property_type,
          },
          () => this.props.addDashboard(forlgata)
        );
      } else if (this.props.location.state == null) {
        this.props.history.push({
          pathname: "/location",
        });
      }
    }

    let forlgata1 = new FormData();
    if (
      this.props.location.state !== null &&
      this.props.location.state.project_name.project
    ) {
      forlgata1.append(
        "project",
        this.props.location.state.project_name.project
      );
      forlgata1.append("rcp", this.state.rcp);
      forlgata1.append("year", this.state.year);
      forlgata1.append("count", this.state.count);
      forlgata1.append("with_content", this.state.with_content);

      forlgata1.append("variable", this.state.exposure_variable);

      this.props.getDetailByYear(forlgata1);
    } else if (
      this.props.location.state !== null &&
      this.props.location.state.project_name
    ) {
      forlgata1.append("project", this.props.location.state.project_name);
      forlgata1.append("rcp", this.state.rcp);
      forlgata1.append("count", this.state.count);
      forlgata1.append("with_content", this.state.with_content);

      forlgata1.append("variable", this.state.exposure_variable);
      forlgata1.append("year", this.state.year);

      this.props.getDetailByYear(forlgata1);
    } else if (this.props.location.state == null) {
      this.props.history.push({
        pathname: "/location",
      });
    }

    let formdata = new FormData();
    if (
      this.props.location.state !== null &&
      this.props.location.state.project_name.project
    ) {
      formdata.append(
        "project_name",
        this.props.location.state.project_name.project
      );

      this.props.getSummary(formdata);
    } else if (
      this.props.location.state !== null &&
      this.props.location.state.project_name
    ) {
      formdata.append("project_name", this.props.location.state.project_name);

      this.props.getSummary(formdata);
    } else if (this.props.location.state == null) {
      this.props.history.push({
        pathname: "/location",
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.feedback !== this.props.feedback) {
      this.setState(
        {
          feedback: this.props.feedback.overall.overall_bar_chart,
          asset_table: this.props.feedback.overall.asset_table,
          risk: this.props.feedback.overall.progress_bars,
          risk_2020: this.props.feedback.overall.progress_bars_2020,
          losses: this.props.feedback.overall.loss_bars,
          losses_2020: this.props.feedback.overall.loss_bars_2020,
          single_asset: this.props.feedback.single_asset,
          hazard: this.props.feedback.overall.hazard_values,
          overall: this.props.feedback.overall,
          heatmap: this.props.feedback.overall.heatmap,
          lat: this.props.feedback.overall.asset_table[0].latitude,
          long: this.props.feedback.overall.asset_table[0].longitude,
          countryloss: this.props.feedback.overall.country_wise_loss_data,
          country_loss: this.props.feedback.overall.country_wise_loss_data,
        },
        () => {
          this.setState({ table_condition: true });
        }
      );
    }
    if (prevProps.assets !== this.props.assets) {
      console.log("user assets", this.props.assets);
      this.setState({ assets: this.props.assets });
    }

    if (prevProps.detailyear !== this.props.detailyear) {
      console.log("year api update", this.props.detailyear.values);
      this.setState({
        percentage: this.props.detailyear.percentage_values,
        yearDetail: this.props.detailyear.values,
      });
    }
  }
  Edit = (asset, newAsset) => {
    this.setState(
      {
        oldname: asset.name,
        newname: newAsset.name,
        area: newAsset.area,
        valuation: newAsset.valuation,
        type: newAsset.type.toLowerCase(),
        floors: newAsset.floors,
        ownership: newAsset.ownership,
        construction_cost: newAsset.construction_cost,
      },
      this.EditAsset
    );
  };
  EditAsset() {
    let forlgata = new FormData();
    forlgata.append("project", this.state.project);
    forlgata.append("oldname", this.state.oldname);
    forlgata.append("newname", this.state.newname);
    forlgata.append("area", this.state.area);
    forlgata.append("type", this.state.type);
    forlgata.append("valuation", this.state.valuation);
    forlgata.append("floors", this.state.floors);
    forlgata.append("ownership", this.state.ownership);
    forlgata.append("construction_cost", this.state.construction_cost);

    this.props.updateAsset(forlgata);
  }

  handleProject = (e, { value }) => {
    this.setState({ project: value }, () => console.log(this.state.project));
  };

  handleRCP = (e, { value }) => {
    this.setState({ rcp: value }, () => console.log(this.state.rcp));
  };
  handleVariable = (e, { value }) => {
    this.setState({ mapVariable: value }, () =>
      console.log("map variabele", this.state.mapVariable)
    );
  };

  handleYear = (e, { value }) => {
    this.setState({ year: value }, () => console.log(this.state.year));
  };
  handleLogout = () => {
    this.props.logout();
  };
  handleOpen = (asset) => {
    let buildingData = this.state.asset_table.filter(
      (building) => building.name == asset
    );
    console.log("data", buildingData, this.state.asset_table);
    this.setState(
      {
        modalOpen: true,
        defaultbuilding: buildingData,
        activeItemName: asset,
      },
      this.handleSingleAsset
    );
  };

  handleClose = () => this.setState({ modalOpen: false });

  handleSingleAsset = () => {
    if (this.state.single_asset.length > 0) {
      console.log(
        "itemksfk",
        this.state.activeItemName,
        this.state.single_asset
      );
      Item = this.state.single_asset.filter(
        (asset) => asset.asset_name == this.state.activeItemName
      );
      this.setState({ single_asset_overall: Item }, this.handleSingleChart);
    }
    if (this.state.yearDetail.length > 0) {
      let ItemYear = this.state.yearDetail.filter(
        (asset) => asset.name == this.state.activeItemName
      );
      this.setState({ singleYearDetail: ItemYear }, () =>
        console.log("single year", this.state.singleYearDetail)
      );
    }
    if (this.state.assets.length > 0) {
      let Item = this.state.assets.filter(
        (asset) => asset.name == this.state.activeItemName
      );
      this.setState(
        {
          mapAsset: Item,
        },
        () => console.log("single asset map wala", this.state.mapAsset)
      );
    }
  };
  handleSingleChart = () => {
    console.log("fndfdgdgdsgdijgdos", this.state.single_asset_overall);
    singledata = [
      {
        name: "2020",
        "RCP2.6": 0,
        "RCP4.5": 0,
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart["2020"],
      },

      {
        name: "2030",
        "RCP2.6":
          this.state.single_asset_overall[0].overall_bar_chart["2030_26"],
        "RCP4.5":
          this.state.single_asset_overall[0].overall_bar_chart["2030_45"],
        "RCP8.5":
          this.state.single_asset_overall[0].overall_bar_chart["2030_85"],
      },

      {
        name: "2050",
        "RCP2.6":
          this.state.single_asset_overall[0].overall_bar_chart["2050_26"],
        "RCP4.5":
          this.state.single_asset_overall[0].overall_bar_chart["2050_45"],
        "RCP8.5":
          this.state.single_asset_overall[0].overall_bar_chart["2050_85"],
      },
    ];

    this.setState(
      {
        singel_asset_chart: singledata,
        single_asset_loss: this.state.single_asset_overall[0].loss_bars,
        single_asset_loss_2020:
          this.state.single_asset_overall[0].loss_bars_2020,
        single_asset_progress: this.state.single_asset_overall[0].progress_bars,
        single_asset_progress_2020:
          this.state.single_asset_overall[0].progress_bars_2020,
        single_asset_cone_chart: this.state.single_asset_overall[0].cone_chart,
      },
      this.handleComparison
    );
  };

  handleComparison = () => {
    let RcpData = [
      {
        name: "OverAll",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["OVERALL"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["OVERALL"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["OVERALL"],
      },

      {
        name: "River Flood",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["River Flood"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["River Flood"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["River Flood"],
      },
      {
        name: "Drought",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Drought"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Drought"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Drought"],
      },

      {
        name: "Storm Surge",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Storm Surge"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Storm Surge"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Storm Surge"],
      },
      {
        name: "LandSlide",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Landslide"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Landslide"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Landslide"],
      },
      {
        name: "Rainfall",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Rainfall"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Rainfall"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Rainfall"],
      },

      {
        name: "Extreme Heat",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Extreme heat"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Extreme heat"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Extreme heat"],
      },
      {
        name: "Rainfall Flood",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Rainfall Flood"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Rainfall Flood"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Rainfall Flood"],
      },
      {
        name: "SnowMelt",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Snow Melt"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Snow Melt"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Snow Melt"],
      },
      {
        name: "Sea Level Rise",
        "RCP2.6":
          this.state.single_asset_cone_chart.cone1_26.both["Sea Level Rise"],
        "RCP4.5":
          this.state.single_asset_cone_chart.cone1_45.both["Sea Level Rise"],
        "RCP8.5":
          this.state.single_asset_cone_chart.cone1_85.both["Sea Level Rise"],
      },
      {
        name: "Typhoon",
        "RCP2.6": this.state.single_asset_cone_chart.cone1_26.both["Typhoon"],
        "RCP4.5": this.state.single_asset_cone_chart.cone1_45.both["Typhoon"],
        "RCP8.5": this.state.single_asset_cone_chart.cone1_85.both["Typhoon"],
      },
    ];

    let yearData = [
      {
        name: "OverAll",
        2020: this.state.single_asset_cone_chart.cone3_20.both["OVERALL"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["OVERALL"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["OVERALL"],
      },

      {
        name: "River Flood",
        2020: this.state.single_asset_cone_chart.cone3_20.both["River Flood"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["River Flood"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["River Flood"],
      },
      {
        name: "Drought",
        2020: this.state.single_asset_cone_chart.cone1_26.both["Drought"],
        2030: this.state.single_asset_cone_chart.cone1_45.both["Drought"],
        2050: this.state.single_asset_cone_chart.cone1_85.both["Drought"],
      },

      {
        name: "Storm Surge",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Storm Surge"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Storm Surge"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Storm Surge"],
      },
      {
        name: "LandSlide",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Landslide"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Landslide"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Landslide"],
      },
      {
        name: "Rainfall",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Rainfall"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Rainfall"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Rainfall"],
      },

      {
        name: "Extreme Heat",
        2020: this.state.single_asset_cone_chart.cone3_20.both["Extreme heat"],
        2030: this.state.single_asset_cone_chart.cone3_30.both["Extreme heat"],
        2050: this.state.single_asset_cone_chart.cone3_50.both["Extreme heat"],
      },
      {
        name: "Rainfall Flood",
        2020: this.state.single_asset_cone_chart.cone1_26.both[
          "Rainfall Flood"
        ],
        2030: this.state.single_asset_cone_chart.cone1_45.both[
          "Rainfall Flood"
        ],
        2050: this.state.single_asset_cone_chart.cone1_85.both[
          "Rainfall Flood"
        ],
      },
      {
        name: "SnowMelt",
        2020: this.state.single_asset_cone_chart.cone1_26.both["Snow Melt"],
        2030: this.state.single_asset_cone_chart.cone1_45.both["Snow Melt"],
        2050: this.state.single_asset_cone_chart.cone1_85.both["Snow Melt"],
      },
      {
        name: "Sea Level Rise",
        2020: this.state.single_asset_cone_chart.cone1_26.both[
          "Sea Level Rise"
        ],
        2030: this.state.single_asset_cone_chart.cone1_45.both[
          "Sea Level Rise"
        ],
        2050: this.state.single_asset_cone_chart.cone1_85.both[
          "Sea Level Rise"
        ],
      },
      {
        name: "Typhoon",
        2020: this.state.single_asset_cone_chart.cone1_26.both["Typhoon"],
        2030: this.state.single_asset_cone_chart.cone1_45.both["Typhoon"],
        2050: this.state.single_asset_cone_chart.cone1_85.both["Typhoon"],
      },
    ];
    this.setState(
      {
        RcpData: RcpData,
        YearData: yearData,
      },
      () => console.log("yeardata", this.state.YearData)
    );
  };
  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleChangePro = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleTable = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => console.log("select table", this.state.table)
    );
  };
  handleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };
  handleSingleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };

  handleFullScreen = () => {
    document.getElementById("HeatMap").requestFullscreen();
  };
  handleLossFullScreen = () => {
    document.getElementById("Loss").requestFullscreen();
  };
  handleIntFullScreen = () => {
    document.getElementById("Intensity").requestFullscreen();
  };

  handleClickOpen = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSubmitPro = () => {
    let forlgata = new FormData();
    forlgata.append("project", this.state.project);
    forlgata.append("rcp", this.state.rcp);
    forlgata.append("variable", this.state.exposure_variable);
    forlgata.append("year", this.state.year);
    forlgata.append("count", this.state.count);
    forlgata.append("parameter", this.state.parameter);
    forlgata.append("with_content", this.state.with_content);
    forlgata.append("property_type", this.state.property_type);

    let project = this.props.project.filter(
      (project) => project.name == this.state.project
    );
    console.log("variables", project[0].variables);
    this.props.addDashboard(forlgata);

    let forlgata1 = new FormData();
    forlgata1.append("project", this.state.project);
    forlgata1.append("rcp", this.state.rcp);
    forlgata1.append("year", this.state.year);
    forlgata1.append("with_content", this.state.with_content);
    forlgata1.append("variable", this.state.exposure_variable);
    console.log("year api ka form data", this.state.year);
    this.setState(
      {
        filterOpen: false,
        million: true,
        property_types: JSON.parse(project[0].property_type.replace(/'/g, '"')),
        variables: JSON.parse(project[0].variables.replace(/'/g, '"')),
      },
      this.props.getDetailByYear(forlgata1)
    );
  };

  handleLocal = (e) => {
    this.setState(
      {
        localactive: true,
        globalactive: false,
        analysis: "local",
      },
      this.handleSubmitPro
    );
  };

  handleGlobal = (e) => {
    this.setState(
      {
        globalactive: true,
        localactive: false,
        analysis: "global",
      },
      this.handleSubmitPro
    );
  };
  handleFinance = (e) => {
    this.setState({
      financial: !this.state.financial,
    });
  };
  handleWorld = (e) => {
    this.setState((prevState) => ({
      worldmap: !prevState.worldmap,
    }));
  };
  handleDetail = (e) => {
    this.setState((prevState) => ({
      detailed: !prevState.detailed,
    }));
  };
  handleContent = (e) => {
    this.setState({
      with_content: !this.state.with_content,
    });
  };
  handleAsset = (e, { value }) => {
    let selectedAsset = this.state.asset_table.filter(
      (asset) => asset.name == value
    );
    console.log("selectedAsset", selectedAsset[0].latitude);
    this.setState(
      {
        lat: selectedAsset[0].latitude,
        long: selectedAsset[0].longitude,
      },
      () => console.log("lat passing", this.state.lat, this.state.long)
    );
  };

  handleContinent = (e, { value }) => {
    console.log("Continents", value);
    this.setState({
      continent: value[0],
      long: value[1],
      lat: value[2],
      zoom: value[3],
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  handleAccClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex }, () =>
      console.log("active", this.state.activeIndex)
    );
  };
  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
  }

  render() {
    console.log("hazard", this.state.hazard);

    let newVar = localStorage.getItem("variables") === this.state.variables;
    const isDarkMode = localStorage.getItem("theme") == "dark";
    let Var = this.state.variables;
    let Property = this.state.property_types;

    if (this.state.variables.includes("Rainfall Flood")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
      VariableOptions.push({
        key: "Rainfall Flood",
        value: "Rainfall Flood",
        text: "Rainfall Flood",
      });
    }
    if (!this.state.variables.includes("Rainfall Flood")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
    }
    if (this.state.variables.includes("Extreme heat")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
      VariableOptions.push({
        key: "Extreme Heat",
        value: "Extreme heat",
        text: "Extreme Heat",
      });
    }
    if (!this.state.variables.includes("Extreme heat")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
    }
    if (
      this.state.variables.includes("Extreme heat") &&
      this.state.variables.includes("Rainfall Flood")
    ) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
      VariableOptions.push(
        {
          key: "Extreme Heat",
          value: "Extreme heat",
          text: "Extreme Heat",
        },
        {
          key: "Rainfall Flood",
          value: "Rainfall Flood",
          text: "Rainfall Flood",
        }
      );
    }

    if (Property != "NA") {
      console.log("Property value", this.state.property_types);
      PropertyOptions = [];
      for (let i = 0; i < Property.length; i++) {
        PropertyOptions.push({
          key: Property[i],
          value: Property[i],
          text: Property[i],
        });
      }
      ParameterOptions = [];
      ParameterOptions.push(
        { key: "valuation", value: "valuation", text: "valuation" },
        { key: "loss", value: "loss", text: "Overall Loss" },
        {
          key: "revenue",
          value: "revenue",
          text: "revenue",
        }
      );
    }
    if (this.state.asset_table) {
      AssetOptions = [];
      for (let i = 0; i < this.state.asset_table.length; i++) {
        AssetOptions.push({
          key: this.state.asset_table[i].name,
          value: this.state.asset_table[i].name,
          text: this.state.asset_table[i].name.slice(0, 10),
        });
      }
    }

    const {
      continent,
      value,
      basement,
      construction,
      stories,
      occupancy,
      project,
      rcp,
      year,
      active,
      table,
      count,
      parameter,
      exposure_variable,
      property_type,
    } = this.state;

    let total_asset_value = 0;

    const { activeIndex } = this.state;

    if (this.props.project.length > 0) {
      let user_id = localStorage.getItem("user_id");
      const projects = this.props.project.filter(
        (project) => project.users_id == user_id
      );

      options = [];
      for (let i = 0; i < projects.length; i++) {
        options.push({
          key: projects[i].name,
          value: projects[i].name,
          text: projects[i].name,
        });
      }
    }

    if (this.props.isLoading && isDarkMode) {
      return <div id="darkloading"></div>;
    } else if (this.props.isLoading) {
      return <div id="loading"></div>;
    }

    return (
      <div>
        <Container fluid={true}>
          <Row className="firstshadow"></Row>
          {/*   <Row>
                    <Col className="firstshadow" lg={7} xs={12} sm={12}>
                      <Row>
                        <Col lg={6} xs={12} sm={12}>
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Year Loss for RCP {this.state.rcp}
                              </span>
                            </p>
                          </Row>

                          {this.state.financial ? (
                            <YEARDonut
                              data={this.state.yearDetail}
                              rcp={this.state.rcp}
                            />
                          ) : (
                            <MdryearDonut
                              rcp={this.state.rcp}
                              data={this.state.percentage}
                            />
                          )}
                        </Col>
                        <Col lg={6} xs={12} sm={12}>
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                RCP Loss for Year {this.state.year}
                              </span>
                            </p>
                          </Row>

                          {this.state.financial ? (
                            <RCPDonut
                              data={this.state.yearDetail}
                              year={this.state.year}
                            />
                          ) : (
                            <MdrrcpDonut
                              year={this.state.year}
                              data={this.state.percentage}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col className="firstshadowmap" lg={4} xs={12} sm={12}>
                      <Row className="mainheading">
                        <p style={{ width: "100%" }}>
                          <span className={"dark-mode-theme-color-1"}>
                            {" "}
                            Physical Climate Risk for {this.state.year}
                          </span>
                        </p>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {" "}
                        <Select
                          style={{
                            float: "right",
                            minHeight: "0.8em",
                            marginTop: "-3%",
                          }}
                          placeholder={exposure_variable}
                          options={VariableOptions}
                          onChange={(e, { value }) =>
                            this.handleExposure(value, "exposure_variable")
                          }
                          value={exposure_variable}
                        />
                      </Row>

                      <br />
                      <br />
                      <Row>
                        <Col
                          className="marginhead"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {this.state.overall.overall_risk ? (
                            <div className="severity" style={{ width: "80%" }}>
                              <h5 style={{ fontSize: "18px" }}>
                                Risk Severity:
                                <span
                                  style={{
                                    color: "#6a2846",
                                    fontSize: "16px",
                                  }}
                                >
                                  {this.state.overall.overall_risk} %{" "}
                                </span>
                              </h5>
                              <Progress
                                percent={this.state.overall.overall_risk}
                                color="blue"
                              ></Progress>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <br />
                      <br />

                      {this.state.exposure_variable == "Total" &&
                      this.state.losses["Asset Rainfall Flood Damage"] ? (
                        <Row>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "1rem",
                                textAlign: "center",
                              }}
                            >
                              Climate Value at Risk
                              <br />
                              {this.state.losses["Total Loss"][0] < 1000 ? (
                                <p>
                                  ${" "}
                                  {this.state.losses["Total Loss"][0].toFixed(
                                    2
                                  )}{" "}
                                  Million
                                </p>
                              ) : (
                                <p>
                                  ${" "}
                                  {(
                                    this.state.losses["Total Loss"][0] / 1000
                                  ).toFixed(2)}{" "}
                                  Billion
                                </p>
                              )}
                            </p>

                            <p style={{ fontSize: "1rem" }}>
                              Maximum Exposure
                              <br />
                              {this.state.overall["Max_exposure"] < 1000 ? (
                                <p style={{ fontSize: "1rem" }}>
                                  ${" "}
                                  {this.state.overall["Max_exposure"].toFixed(
                                    2
                                  )}{" "}
                                  Million
                                </p>
                              ) : (
                                <p>
                                  ${" "}
                                  {(
                                    this.state.overall["Max_exposure"] / 1000
                                  ).toFixed(2)}{" "}
                                  Billion
                                </p>
                              )}
                            </p>
                          </Col>
                        </Row>
                      ) : null}
                      {this.state.losses["Asset Rainfall Flood Damage"] &&
                      this.state.exposure_variable != "Total" &&
                      this.state.exposure_variable != "Extreme heat" ? (
                        <Row>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "1rem",
                                textTransform: "capitalize",
                              }}
                            >
                              {this.state.exposure_variable} Exposure
                              <br />
                              {this.state.overall[
                                `${this.state.exposure_variable}_exposure`
                              ] < 1000 ? (
                                <p>
                                  ${" "}
                                  {this.state.overall[
                                    `${this.state.exposure_variable}_exposure`
                                  ].toFixed(2)}{" "}
                                  Million
                                </p>
                              ) : (
                                <p>
                                  ${" "}
                                  {(
                                    this.state.overall[
                                      `${this.state.exposure_variable}_exposure`
                                    ] / 1000
                                  ).toFixed(2)}{" "}
                                  Billion
                                </p>
                              )}
                            </p>

                            <Tooltip
                              title={`Operational Loss: $ ${this.state.losses[
                                `Operational ${this.state.exposure_variable} Loss`
                              ][0].toFixed(2)} Mn
                    Damage Loss $ ${this.state.losses[
                      `Asset ${this.state.exposure_variable} Damage`
                    ][0].toFixed(2)} Mn`}
                            >
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  marginBottom: "0em",
                                }}
                              >
                                {this.state.exposure_variable} Value at Risk
                              </p>

                              {parseFloat(
                                this.state.losses[
                                  `Asset ${this.state.exposure_variable} Damage`
                                ]
                              ) +
                                parseFloat(
                                  this.state.losses[
                                    `Operational ${this.state.exposure_variable} Loss`
                                  ]
                                ) <
                              1000 ? (
                                <p>
                                  {" "}
                                  ${" "}
                                  {(
                                    parseFloat(
                                      this.state.losses[
                                        `Asset ${this.state.exposure_variable} Damage`
                                      ]
                                    ) +
                                    parseFloat(
                                      this.state.losses[
                                        `Operational ${this.state.exposure_variable} Loss`
                                      ]
                                    )
                                  ).toFixed(2)}{" "}
                                  Million
                                </p>
                              ) : (
                                <p>
                                  ${" "}
                                  {(
                                    (parseFloat(
                                      this.state.losses[
                                        `Asset ${this.state.exposure_variable} Damage`
                                      ]
                                    ) +
                                      parseFloat(
                                        this.state.losses[
                                          `Operational ${this.state.exposure_variable} Loss`
                                        ]
                                      )) /
                                    1000
                                  ).toFixed(2)}{" "}
                                  Billion
                                </p>
                              )}
                            </Tooltip>
                          </Col>
                        </Row>
                      ) : null}
                      {this.state.losses["Asset Rainfall Flood Damage"] &&
                      this.state.exposure_variable == "Extreme heat" ? (
                        <Row>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "1rem",
                                textAlign: "center",
                                textTransform: "capitalize",
                              }}
                            >
                              {this.state.exposure_variable} Exposure
                              <br />
                              {this.state.overall[
                                `${this.state.exposure_variable}_exposure`
                              ] < 1000 ? (
                                <p>
                                  ${" "}
                                  {this.state.overall[
                                    `${this.state.exposure_variable}_exposure`
                                  ].toFixed(2)}{" "}
                                  Million
                                </p>
                              ) : (
                                <p>
                                  ${" "}
                                  {(
                                    this.state.overall[
                                      `${this.state.exposure_variable}_exposure`
                                    ] / 1000
                                  ).toFixed(2)}{" "}
                                  Billion
                                </p>
                              )}
                            </p>

                            <p
                              style={{
                                fontSize: "1rem",
                                textAlign: "center",
                                textTransform: "capitalize",
                                marginBottom: "0em",
                              }}
                            >
                              {this.state.exposure_variable} Value at Risk
                              {parseFloat(
                                this.state.losses[`Temprature/heat loss`]
                              ) < 1000 ? (
                                <p>
                                  {" "}
                                  ${" "}
                                  {parseFloat(
                                    this.state.losses[`Temprature/heat loss`]
                                  )}{" "}
                                  Million
                                </p>
                              ) : (
                                <p>
                                  ${" "}
                                  {parseFloat(
                                    this.state.losses[`Temprature/heat loss`]
                                  )}{" "}
                                  Billion
                                </p>
                              )}
                            </p>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                                  </Row>*/}
        </Container>
        <br />
        <Container fluid={true}>
          <Row>
            <Col className="firstshadowmap" lg={7} sm={12} xs={12}>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>Asset List</span>
                </p>
              </Row>

              {this.state.table_condition && (
                <EditableTable
                  data={this.state.asset_table}
                  open={this.handleOpen}
                  update={this.Edit}
                />
              )}
            </Col>

            <Col>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Project Summary
                  </span>
                </p>
              </Row>
              <Row className="firstshadow_cards">
                {this.state.losses["Total Loss"] ? (
                  <Col lg={12}>
                    <p className="_heading">
                      Total <br /> Loss
                    </p>

                    <h3>
                      $ {this.state.losses["Total Loss"][0].toFixed(2)} Mil
                    </h3>
                  </Col>
                ) : null}
              </Row>

              <Row className="firstshadow_cards">
                <Col lg={12}>
                  <p className="_heading">
                    Max
                    <br /> Exposure
                  </p>

                  {this.state.overall ? (
                    <h3>$ {this.state.overall.Max_exposure.toFixed(2)} Mil</h3>
                  ) : null}
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}></span>
                </p>
              </Row>
              <Row className="firstshadow_cards">
                {this.state.overall.asset_table ? (
                  <Col lg={12}>
                    <p className="_heading">
                      Asset <br /> Count
                    </p>

                    <h3>{this.state.overall.asset_table.length}</h3>
                  </Col>
                ) : null}
              </Row>

              <Row className="firstshadow_cards">
                <Col lg={12}>
                  <p className="_heading">
                    Asset <br /> Value
                  </p>

                  <h3>{this.state.overall.asset_value}</h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="firstshadow" lg={7} sm={12} xs={12}>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Asset Level Risk Map
                  </span>
                </p>
              </Row>
              {this.state.country_loss &&
              this.state.country_loss.length == undefined ? (
                <WorldMap data={this.state.country_loss} />
              ) : null}
            </Col>

            <Col lg={1}></Col>
            <Col lg={4} style={{ height: "600px" }} className="firstshadow">
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Variable Intensity (out of 100)
                  </span>
                </p>
              </Row>
              {this.state.yearDetail ? (
                <RadialChart
                  data={this.state.yearDetail}
                  year={this.state.year}
                />
              ) : null}
            </Col>

            {/* <Col lg={{ span: 4 }} xs={12} sm={12} className="firstshadowmap dark-mode-black">

                                    <Row className="mainheading" style={{ width: '100%' }}><p style={{ width: '85%' }}><span className={"dark-mode-theme-color-1"}>Climate Risk Index <Icon name="info circle" style={{cursor:'pointer'}} onClick={this.handleRiskOpen}/></span></p>  </Row>





                                    <Row style={{ height: '60vh' }}>

                                        <BarComponent data={this.state.feedback} />




                                    </Row>
                        </Col>*/}
          </Row>
          <br />

          {/*              <Row>
                    <Col
                      lg={7}
                      xs={12}
                      sm={12}
                      className="firstshadow"
                      style={{
                        height: "550px",
                        overflowX: "auto",
                        overflowY: "hidden",
                      }}
                    >
                      <CandleStick
                        data={this.state.overall.candle_stick_chart}
                      />
                    </Col>

                    <Col
                      id="Loss"
                      className="firstshadowmap"
                      lg={{ span: 4 }}
                      sm={12}
                      xs={12}
                      style={{ maxHeight: "550px", overflowY: "auto" }}
                    >
                      <Row className="mainheading">
                        {" "}
                        <p>
                          <span className={"dark-mode-theme-color-1"}>
                            Portfolio Losses
                          </span>
                        </p>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            style={{ float: "right" }}
                            onClick={this.handlePercentOpen}
                          >
                            <Icon name="expand arrows alternate" />{" "}
                          </Button>

                          {this.state.losses["Asset Rainfall Flood Damage"] ? (
                            <div>
                              <LossComponent
                                data={this.state.losses}
                                newdata={this.state.losses_2020}
                              />
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <br />

                          <Row></Row>*/}
          <br />
          {/*        <Row>
                    <Col
                      className="firstshadow"
                      lg={12}
                      xs={16}
                      sm={16}
                      style={{
                        maxWidth: "1440px",
                        maxHeight: "550px",
                        overflowY: "auto",
                      }}
                    >
                      <Row className="mainheading">
                        {" "}
                        <p>
                          <span className={"dark-mode-theme-color-1"}>
                            Asset Table
                          </span>
                        </p>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Select
                            style={{ float: "right" }}
                            placeholder="Select"
                            options={tableOptions}
                            onChange={(e, { value }) =>
                              this.handleTable(value, "table")
                            }
                            value={table}
                          />
                          <Tabs
                            defaultActiveKey="All"
                            style={{
                              padding: "5px",
                              borderBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            <Tab title="All" eventKey="All">
                              <br />
                              {this.state.table_condition && (
                                <EditableTable
                                  data={this.state.asset_table}
                                  open={this.handleOpen}
                                  update={this.Edit}
                                />
                              )}
                            </Tab>
                            <Tab title="Overall Loss" eventKey="Loss">
                              <br />{" "}
                              {this.state.table_condition && (
                                <EditableLossTable
                                  data={this.state.asset_table}
                                  open={this.handleOpen}
                                  update={this.Edit}
                                  table={this.state.table}
                                />
                              )}
                            </Tab>
                            <Tab title="Climate  Score" eventKey="Climate">
                              <br />{" "}
                              {this.state.table_condition && (
                                <EditableClimateTable
                                  data={this.state.asset_table}
                                  open={this.handleOpen}
                                  update={this.Edit}
                                  table={this.state.table}
                                />
                              )}
                            </Tab>
                            <Tab title="Value" eventKey="Value">
                              <br />
                              {this.state.table_condition && (
                                <EditableValuationTable
                                  data={this.state.asset_table}
                                  open={this.handleOpen}
                                  update={this.Edit}
                                  table={this.state.table}
                                />
                              )}
                            </Tab>
                          </Tabs>
                        </Col>
                      </Row>
                    </Col>
                              </Row>*/}
          <Row>
            <Col className="firstshadow" lg={7} xs={16} sm={16}>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Risk Heat Map (0 to 100)
                  </span>
                </p>
              </Row>
              <Row>
                <Col style={{ height: "400px" }}>
                  {/*<Button
                            style={{ float: "right" }}
                            onClick={this.handleHeatOpen}
                          >
                            <Icon name="expand arrows alternate" />{" "}
                          </Button>*/}

                  {this.state.heatmap ? (
                    <Heatmap
                      data={this.state.heatmap}
                      variable={this.state.variables}
                    />
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={1}></Col>
            <Col
              id="Intensity"
              className="firstshadowmap"
              lg={4}
              xs={12}
              sm={12}
              style={{ maxHeight: "687.88px", overflowY: "auto" }}
            >
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Variable Intensity (out of 100)
                  </span>
                </p>
              </Row>
              <br />
              <Row>
                <Col>
                  {/*<Button
                            style={{ float: "right" }}
                            onClick={this.handleLossOpen}
                          >
                            <Icon name="expand arrows alternate" />{" "}
                          </Button>*/}
                  {this.state.risk &&
                  this.state.risk_2020 &&
                  this.state.hazard ? (
                    <Progress_bar
                      data={this.state.risk}
                      newdata={this.state.risk_2020}
                      hazard={this.state.hazard}
                      variable={this.state.variables}
                      activeIndex={this.state.activeIndex}
                    />
                  ) : null}
                </Col>
              </Row>
              <br />
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={7} style={{ height: "450px" }} className="firstshadow">
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Variable Intensity (out of 100)
                  </span>
                </p>
              </Row>
              {this.state.feedback ? (
                <BarComponent data={this.state.feedback} />
              ) : null}
            </Col>
            <Col lg={1}></Col>
            <Col
              lg={4}
              className="firstshadowmap"
              style={{ maxHeight: "450px", overflowY: "auto" }}
            >
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Portfolio Losses
                  </span>
                </p>
              </Row>
              <br />
              {this.state.losses["Asset Rainfall Flood Damage"] ? (
                <div>
                  <LossComponent
                    data={this.state.losses}
                    newdata={this.state.losses_2020}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>

        {/*   <Tab title="DETAILED" eventKey="Detailed">
                                <Detail rcp={this.state.rcp} year={this.state.year} project={this.state.project} detailed={this.state.detailed} handleDetail={this.handleDetail} asset={this.state.asset_table} />

                                </Tab>

                                
                                <Tab title="HAZARDS" eventKey="hazards">
                                <div className="note-hazards">
                                    <p style={{fontSize:'18px' ,color:'#6a2846' ,float:'right' ,marginRight:'50px'}}>Hover-Over the icons to go through the information on the hover wheel</p>
                                    </div>
                                <div class="parent_div">
                                <div class="circle_3">
                                <p class="text-center"> <span class="mytooltip tooltip-effect-1" style={{marginTop:'200px'}}> <span class="tooltip-item"><img class="windimg" src={wind} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix">
                                <span class="tooltip-text"> <h2>Extreme Wind Speeds</h2> <br /> Maximum wind speed at multiple return periods <br /><br />
                                Fraction of building, contents and inventory damaged by extreme wind events <br /><br />
                                Annual average loss to building, contents and inventory<br />
                                Number of days of business interruption</span> </span> </span></p>

                                <p class="text-center"> <span class="mytooltip tooltip-effect-1"> <span class="tooltip-item"><img class="rainfallimg" src={rainfall} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix">
                                <span class="tooltip-text"><h2>Extreme Rainfall</h2><br />Maximum daily precipitation across multiple return periods</span> </span> </span></p>
                                
                                <div><p class="text-center"> <span class="mytooltip tooltip-effect-1"> <span class="tooltip-item"><img class="fireimg" src={fire} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix">
                                <span class="tooltip-text"><h2>Wildfire Risk</h2><br />The annual number of wildfires, which ignite and grow to detectable size, <br />that are expected in a 1 square km grid cell</span> </span> </span></p>
                                
                                <p class="text-center"> <span class="mytooltip tooltip-effect-1"> <span class="tooltip-item"><img class="stormimg" src={storm} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix">
                                <span class="tooltip-text"><h2>Hail And Thunderstorm Probability</h2><br />Number of days per year where large hail (>2 in / 5 cm in diameter) is possible<br /><br />
                                Number of days per year where environmental conditions are conducive to severe thunderstorm formation</span> </span> </span></p></div>
                                
                                <p class="text-center"> <span class="mytooltip tooltip-effect-1"> <span class="tooltip-item"><img class="droughtimg" src={drought} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix">
                                <span class="tooltip-text" ><h2>Drought Frequency</h2><br />Months per year where the rolling 3-month or 6-month average<br /><br />
                                Standardized Precipitation Evapotranspiration Index (SPEI) is below -2</span> </span> </span></p>
                                
                                <p class="text-center"> <span class="mytooltip tooltip-effect-1"> <span class="tooltip-item"><img class="floodimg" src={flood} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix" style={{marginBottom:'20px'}}>
                                <span class="tooltip-text" style={{marginBottom:'20px'}}><h2>Flood Depth of Water</h2><br />Flood depth of water across multiple return periods<br /><br />
                                Percentage of the central square flooded across multiple return periods<br /><br />
                                Fraction of building, contents and inventory damaged by flood events</span> </span> </span></p>
                                
                                <p class="text-center"> <span class="mytooltip tooltip-effect-1" style={{marginBottom:'50px'}}> <span class="tooltip-item"><img class="heatimg" src={heat} style={{height:'50px' ,border:'2px solid #000'}}/></span> <span class="tooltip-content clearfix" style={{marginBottom:'20px'}}>
                                <span class="tooltip-text" style={{marginBottom:'20px'}}><h2>Extreme Heat</h2><br />Days per year exceeding 35°C, 38°C and the historical 99th percentile<br /><br />
                                Heating and cooling degree days<br /><br /> 
                                Days exceeding high (32°C) and dangerously high (35°C) Wet-Bulb Globe Temperature</span> </span> </span></p>
                                    <div class="circle_2">
                                    <div class="fillerdiv"></div>
                                    <div class="circle_mt">
                                    <div class="text_mt">HAZARDS</div>
                                    
                                    </div>
                                    </div>
                                    </div>
                                    </div>

                    </Tab>*/}

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          itemName={this.state.activeItemName}
          size="fullscreen"
        >
          <Modal.Header>
            Asset Analysis - {this.state.activeItemName}
          </Modal.Header>
          <Modal.Content>
            <br />
            <Row>
              <Col>
                <Tabs defaultActiveKey="Risk">
                  <Tab eventKey="Risk" title="Risk">
                    <br />
                    <Container fluid={true}>
                      <Row>
                        <Col lg={3}>
                          <Row>
                            <Col lg={3}>
                              <Image src={dollar} />
                            </Col>
                            {this.state.single_asset_loss["Total Loss"] ? (
                              <Col lg={9}>
                                <p>Total Loss</p>
                                <p>
                                  ${" "}
                                  {this.state.single_asset_loss[
                                    "Total Loss"
                                  ][0].toFixed(2)}{" "}
                                  Million
                                </p>
                              </Col>
                            ) : null}
                          </Row>
                        </Col>

                        <Col lg={3}>
                          <Row>
                            <Col lg={3}>
                              <Image src={dollar} />
                            </Col>
                            <Col lg={9}>
                              <p>Maximum Exposure</p>
                              {this.state.single_asset_overall ? (
                                <p>
                                  ${" "}
                                  {this.state.single_asset_overall[0].Max_exposure.toFixed(
                                    2
                                  )}
                                  &nbsp; Million
                                </p>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={3}>
                          <Row>
                            <Col lg={3}>
                              <Image src={asterisk} />
                            </Col>
                            <Col lg={9}>
                              <p>Asset Value</p>
                              {this.state.single_asset_overall ? (
                                <p>
                                  {
                                    this.state.single_asset_overall[0]
                                      .asset_value
                                  }
                                </p>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={3}>
                          {this.state.mapAsset ? (
                            <PopupMap data={this.state.mapAsset[0]} />
                          ) : null}
                        </Col>
                      </Row>
                      <br />
                      {/*  <Row>
                        {this.state.single_asset_overall ? (
                          <Col className="card" xs={16} sm={16} lg={3}>
                            <Row className="mainheading">
                              <p>
                                <span className={"dark-mode-theme-color-1"}>
                                  {" "}
                                  Climate Risk Increase for {this.state.year}
                                </span>
                              </p>
                            </Row>

                            <Row>
                              <Col className="circle">
                                <Circle
                                  progress={
                                    this.state.single_asset_overall[0]
                                      .per_10_years_rise
                                  }
                                />
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Maximum Exposure
                                  {this.state.single_asset_overall[0][
                                    "Max_exposure"
                                  ] < 1000 ? (
                                    <p>
                                      ${" "}
                                      {this.state.single_asset_overall[0][
                                        "Max_exposure"
                                      ].toFixed(2)}{" "}
                                      Million
                                    </p>
                                  ) : (
                                    <p>
                                      ${" "}
                                      {(
                                        this.state.single_asset_overall[0][
                                          "Max_exposure"
                                        ] / 1000
                                      ).toFixed(2)}{" "}
                                      Billion
                                    </p>
                                  )}
                                </p>
                              </Col>
                            </Row>

                            <br />
                            {this.state.exposure_variable == "Total" &&
                            this.state.single_asset_loss[
                              "Asset Rainfall Flood Damage"
                            ] ? (
                              <Row>
                                <Col
                                  className="small"
                                  style={{ width: "38%" }}
                                ></Col>
                                <Col className="small" style={{ width: "45%" }}>
                                  <p
                                    style={{
                                      fontSize: "1rem",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Climate Value at Risk
                                  </p>

                                  <div>
                                    {this.state.single_asset_loss[
                                      "Total Loss"
                                    ][0] < 1000 ? (
                                      <p>
                                        ${" "}
                                        {this.state.single_asset_loss[
                                          "Total Loss"
                                        ][0].toFixed(2)}{" "}
                                        Million
                                      </p>
                                    ) : (
                                      <p>
                                        ${" "}
                                        {(
                                          this.state.single_asset_loss[
                                            "Total Loss"
                                          ][0] / 1000
                                        ).toFixed(2)}{" "}
                                        Billion
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            ) : null}
                            {this.state.single_asset_loss[
                              "Asset Rainfall Flood Damage"
                            ] &&
                            this.state.exposure_variable != "Total" &&
                            this.state.exposure_variable != "Extreme heat" ? (
                              <Row>
                                <Col
                                  className="small"
                                  style={{ width: "45%", marginLeft: "3%" }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1rem",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {this.state.exposure_variable} Exposure
                                  </p>

                                  <div>
                                    {this.state.single_asset_overall[0][
                                      `${this.state.exposure_variable}_exposure`
                                    ] < 1000 ? (
                                      <p>
                                        ${" "}
                                        {this.state.single_asset_overall[0][
                                          `${this.state.exposure_variable}_exposure`
                                        ].toFixed(2)}{" "}
                                        Million
                                      </p>
                                    ) : (
                                      <p>
                                        ${" "}
                                        {(
                                          this.state.single_asset_overall[0][
                                            `${this.state.exposure_variable}_exposure`
                                          ] / 1000
                                        ).toFixed(2)}{" "}
                                        Billion
                                      </p>
                                    )}
                                  </div>
                                </Col>
                                <Col className="small" style={{ width: "45%" }}>
                                  <div>
                                    {parseFloat(
                                      this.state.single_asset_loss[
                                        `Asset ${this.state.exposure_variable} Damage`
                                      ]
                                    ) +
                                      parseFloat(
                                        this.state.single_asset_loss[
                                          `Operational ${this.state.exposure_variable} Loss`
                                        ]
                                      ) <
                                    1000 ? (
                                      <p>
                                        {" "}
                                        ${" "}
                                        {(
                                          parseFloat(
                                            this.state.single_asset_loss[
                                              `Asset ${this.state.exposure_variable} Damage`
                                            ]
                                          ) +
                                          parseFloat(
                                            this.state.single_asset_loss[
                                              `Operational ${this.state.exposure_variable} Loss`
                                            ]
                                          )
                                        ).toFixed(2)}{" "}
                                        Million
                                      </p>
                                    ) : (
                                      <p>
                                        ${" "}
                                        {(
                                          (parseFloat(
                                            this.state.single_asset_loss[
                                              `Asset ${this.state.exposure_variable} Damage`
                                            ]
                                          ) +
                                            parseFloat(
                                              this.state.single_asset_loss[
                                                `Operational ${this.state.exposure_variable} Loss`
                                              ]
                                            )) /
                                          1000
                                        ).toFixed(2)}{" "}
                                        Billion
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            ) : null}
                            {this.state.losses["Asset Rainfall Flood Damage"] &&
                            this.state.exposure_variable == "Extreme heat" ? (
                              <Row>
                                <Col
                                  lg={8}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: "100%",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1rem",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {this.state.exposure_variable} Exposure
                                    <br />
                                    {this.state.single_asset_overall[0][
                                      `${this.state.exposure_variable}_exposure`
                                    ] < 1000 ? (
                                      <p>
                                        ${" "}
                                        {this.state.single_asset_overall[0][
                                          `${this.state.exposure_variable}_exposure`
                                        ].toFixed(2)}{" "}
                                        Million
                                      </p>
                                    ) : (
                                      <p>
                                        ${" "}
                                        {(
                                          this.state.single_asset_overall[0][
                                            `${this.state.exposure_variable}_exposure`
                                          ] / 1000
                                        ).toFixed(2)}{" "}
                                        Billion
                                      </p>
                                    )}
                                  </p>

                                  <p
                                    style={{
                                      fontSize: "1rem",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                      marginBottom: "0em",
                                    }}
                                  >
                                    {this.state.exposure_variable} Value at Risk
                                    {parseFloat(
                                      this.state.single_asset_loss[
                                        `Temprature/heat loss`
                                      ]
                                    ) < 1000 ? (
                                      <p>
                                        {" "}
                                        ${" "}
                                        {parseFloat(
                                          this.state.single_asset_loss[
                                            `Temprature/heat loss`
                                          ]
                                        )}{" "}
                                        Million
                                      </p>
                                    ) : (
                                      <p>
                                        ${" "}
                                        {parseFloat(
                                          this.state.single_asset_loss[
                                            `Temprature/heat loss`
                                          ]
                                        )}{" "}
                                        Billion
                                      </p>
                                    )}
                                  </p>
                                </Col>
                              </Row>
                            ) : null}
                          </Col>
                        ) : null}

                        <Col xs={16} sm={16} lg={3}>
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Year Loss for RCP {this.state.rcp}
                              </span>
                            </p>
                          </Row>

                          {this.state.financial ? (
                            <YEARDonut
                              data={this.state.singleYearDetail}
                              rcp={this.state.rcp}
                            />
                          ) : (
                            <MdryearDonut
                              rcp={this.state.rcp}
                              data={this.state.singleYearDetail}
                            />
                          )}
                        </Col>
                        <Col xs={16} sm={16} lg={3}>
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                RCP Loss for {this.state.year}
                              </span>
                            </p>
                          </Row>

                          {this.state.financial ? (
                            <RCPDonut
                              data={this.state.singleYearDetail}
                              year={this.state.year}
                            />
                          ) : (
                            <MdrrcpDonut
                              year={this.state.year}
                              data={this.state.singleYearDetail}
                            />
                          )}
                        </Col>
                        <Col lg={3} sm={16} xs={16} className="card">
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Asset Map Location
                              </span>
                            </p>
                          </Row>
                          {this.state.mapAsset ? (
                            <PopupMap data={this.state.mapAsset[0]} />
                          ) : null}
                        </Col>
                          </Row>*/}
                      <Row>
                        <Col
                          lg={4}
                          sm={16}
                          xs={16}
                          className="card dark-mode-black mx-auto"
                          style={{ height: "600px" }}
                        >
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Climate Risk Index
                              </span>
                            </p>
                          </Row>
                          {this.state.single_asset_overall[0] ? (
                            <BarComponent
                              data={
                                this.state.single_asset_overall[0]
                                  .overall_bar_chart
                              }
                            />
                          ) : null}
                        </Col>

                        <Col
                          lg={4}
                          sm={16}
                          xs={16}
                          className="modalfirstshadowmap mx-auto"
                          style={{
                            maxHeight: "600px",
                            boxShadow: "none",
                            overflowY: "auto",
                          }}
                        >
                          <Row className="mainheading">
                            {" "}
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Climate Variable Intensity
                              </span>
                            </p>
                          </Row>

                          <div>
                            <Row>
                              <Col>
                                {this.state.single_asset_progress &&
                                this.state.single_asset_progress_2020 ? (
                                  <Progress_bar
                                    hazard={this.state.hazard}
                                    newdata={
                                      this.state.single_asset_progress_2020
                                    }
                                    data={this.state.single_asset_progress}
                                    variable={this.state.variables}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        {this.state.single_asset_loss["Total Loss"] ? (
                          <Col
                            lg={4}
                            xs={16}
                            sm={16}
                            className="modalfirstshadowmap mx-auto"
                            style={{
                              maxHeight: "600px",
                              boxShadow: "none",
                              overflowY: "auto",
                            }}
                          >
                            <Row className="mainheading">
                              {" "}
                              <p>
                                <span className={"dark-mode-theme-color-1"}>
                                  Asset Losses
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <Col>
                                {this.state.losses[
                                  "Asset Rainfall Flood Damage"
                                ] ? (
                                  <div>
                                    <LossComponent
                                      data={this.state.single_asset_loss}
                                      newdata={
                                        this.state.single_asset_loss_2020
                                      }
                                    />
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="Comparison" title="Comparison">
                    <br />
                    <Container fluid={true}>
                      <Row>
                        <Col
                          lg={12}
                          xs={16}
                          sm={16}
                          className="card dark-mode-black"
                          style={{ height: "500px" }}
                        >
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                RCP 2.6 vs RCP 4.5 vs RCP 8.5 for Year 2050
                              </span>
                            </p>
                          </Row>

                          <ResponsiveBar
                            data={this.state.RcpData}
                            keys={["RCP2.6", "RCP4.5", "RCP8.5"]}
                            indexBy="name"
                            margin={{
                              top: 50,
                              right: 90,
                              bottom: 50,
                              left: 60,
                            }}
                            padding={0.3}
                            enableContainerY={false}
                            groupMode="grouped"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            colors={["#808080", "#2e2e38", "#f5d76e"]}
                            defs={[
                              {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "#38bcb2",
                                size: 4,
                                padding: 1,
                                stagger: true,
                              },
                              {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "#eed312",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                              },
                            ]}
                            fill={[
                              {
                                match: {
                                  id: "fries",
                                },
                                id: "dots",
                              },
                              {
                                match: {
                                  id: "sandwich",
                                },
                                id: "lines",
                              },
                            ]}
                            borderColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,

                              legendPosition: "middle",
                              legendOffset: 32,
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: "Climate  Risk  Index",
                              legendPosition: "middle",
                              legendOffset: -40,
                            }}
                            enableLabel={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            legends={[
                              {
                                dataFrom: "keys",
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: "hover",
                                    style: {
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            theme={{
                              textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                            }}
                          />
                        </Col>

                        <Col
                          lg={12}
                          xs={16}
                          sm={16}
                          className="card dark-mode-black"
                          style={{ height: "500px" }}
                        >
                          <Row className="mainheading">
                            {" "}
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Year 2020 vs Year 2030 vs Year 2050 for RCP 8.5
                              </span>
                            </p>
                          </Row>
                          <ResponsiveBar
                            data={this.state.YearData}
                            keys={["2020", "2030", "2050"]}
                            indexBy="name"
                            margin={{
                              top: 50,
                              right: 90,
                              bottom: 50,
                              left: 60,
                            }}
                            padding={0.3}
                            enableContainerY={false}
                            groupMode="grouped"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            colors={["#808080", "#2e2e38", "#f5d76e"]}
                            defs={[
                              {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "#38bcb2",
                                size: 4,
                                padding: 1,
                                stagger: true,
                              },
                              {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "#eed312",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                              },
                            ]}
                            fill={[
                              {
                                match: {
                                  id: "fries",
                                },
                                id: "dots",
                              },
                              {
                                match: {
                                  id: "sandwich",
                                },
                                id: "lines",
                              },
                            ]}
                            borderColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,

                              legendPosition: "middle",
                              legendOffset: 32,
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: "Climate  Risk  Index",
                              legendPosition: "middle",
                              legendOffset: -40,
                            }}
                            enableLabel={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            legends={[
                              {
                                dataFrom: "keys",
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: "hover",
                                    style: {
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            theme={{
                              textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                            }}
                          />{" "}
                        </Col>
                        <Col width="1"></Col>
                      </Row>
                    </Container>
                  </Tab>
                  {/* <Tab eventKey="Building" title="Building">
                    <br />
                    <Building
                      table={this.state.defaultbuilding}
                      name={this.state.activeItemName}
                      project={this.state.project}
                      var={this.state.variables}
                    />
                          </Tab>*/}
                </Tabs>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>

        <Modal open={this.state.lossopen} closeIcon onClose={this.lossClose}>
          <Modal.Content>
            <Col
              id="Intensity"
              lg={12}
              xs={12}
              sm={12}
              style={{ padding: "5%" }}
            >
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Variable Intensity (out of 100)
                  </span>
                </p>
              </Row>
              <Row>
                <Col></Col>
              </Row>
              <br />

              {this.state.risk && this.state.risk_2020 ? (
                <Progress_bar
                  hazard={this.state.hazard}
                  newdata={this.state.risk_2020}
                  data={this.state.risk}
                  variable={this.state.variables}
                  activeIndex={this.state.activeIndex}
                />
              ) : null}
            </Col>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.percentopen}
          closeIcon
          onClose={this.percentClose}
        >
          <Modal.Content>
            <Col id="Loss" lg={12} sm={12} xs={12}>
              <Row className="mainheading">
                {" "}
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Portfolio Losses
                  </span>
                </p>
              </Row>

              {this.state.losses["Asset Rainfall Flood Damage"] ? (
                <div>
                  <LossComponent
                    data={this.state.losses}
                    newdata={this.state.losses_2020}
                  />
                </div>
              ) : null}
            </Col>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.heatopen}
          closeIcon
          size="fullscreen"
          onClose={this.heatClose}
        >
          <Modal.Content>
            <Col className="firstshadow" lg={12} xs={16} sm={16}>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Risk Heat Map (0 to 100){" "}
                  </span>
                </p>
              </Row>
              <Row>
                <Col>
                  <Tabs defaultActiveKey="heatmap">
                    <Tab title="Heatmap" eventKey="heatmap"></Tab>
                    <Tab title="Classic Heatmap" eventKey="classicheatmap">
                      {this.state.heatmap ? (
                        <Classicheatmap
                          data={this.state.heatmap}
                          variable={this.state.variables}
                        />
                      ) : null}
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Col>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.riskOpen} closeIcon onClose={this.RiskClose}>
          <Modal.Header>Climate Risk Index</Modal.Header>
          <Modal.Content>
            <Image src={index} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.filterOpen}
          closeIcon
          onClose={this.filterClose}
        >
          <Modal.Header>
            <h2 style={{ color: "white" }}>Advanced Filter Options</h2>
            <p>Filtering your data for more insight</p>
          </Modal.Header>
          <Modal.Content className="Filter">
            <Row>
              <Col>
                <p>Projects</p>
                <Form.Select
                  placeholder="Project"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "project")
                  }
                  value={project}
                  options={options}
                  disabled={this.state.detailed}
                />
              </Col>

              <Col>
                <p>RCP</p>
                <Form.Select
                  placeholder="RCP"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "rcp")
                  }
                  value={rcp}
                  options={RcpOptions}
                  disabled={this.state.detailed}
                />
              </Col>
              <Col>
                <p>Year</p>
                <Form.Select
                  placeholder="Year"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "year")
                  }
                  value={year}
                  options={YearOptions}
                  disabled={this.state.detailed}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Count</p>
                <Form.Select
                  placeholder="count"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "count")
                  }
                  value={count}
                  options={CountOptions}
                  disabled={this.state.detailed}
                />
              </Col>
              <Col>
                <p>Analysis Type</p>

                <p style={{ float: "center" }}>
                  Financial
                  <Checkbox
                    checked={!this.state.financial}
                    value={this.state.financial}
                    onClick={this.handleFinance}
                    toggle
                  />
                  Percent
                </p>
                <p style={{ float: "center" }}>
                  Remove
                  <Checkbox
                    checked={this.state.with_content}
                    value={this.state.with_content}
                    onClick={this.handleContent}
                    toggle
                  />
                  Add Content
                </p>
              </Col>
              {Property != "NA" ? (
                <Col>
                  <p>Property Type</p>
                  <Form.Select
                    placeholder="Property Types"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "property_type")
                    }
                    value={property_type}
                    options={PropertyOptions}
                  />
                </Col>
              ) : null}
            </Row>
            <Button
              onClick={this.handleSubmitPro}
              style={{
                backgroundColor: "#6a2846",
                color: "white",
                float: "right",
              }}
            >
              Apply Filter
            </Button>
            <br />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    project: state.project.project,
    feedback: state.feedback.feedback,
    building: state.feedback.building,
    detailyear: state.feedback.detailyear,
    isLoading: state.feedback.isLoading,
    assets: state.asset.asset,
    summary: state.feedback.summary,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addDashboard: (forlgata) => {
      dispatch(dashboard.postDashboard(forlgata));
    },
    getProjects: () => {
      dispatch(project.getProjects());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    getDetailByYear: (forlgata) => {
      dispatch(dashboard.getDetailByYear(forlgata));
    },
    getAssets: () => {
      dispatch(asset.getAssets());
    },
    updateAsset: (forlgata) => {
      dispatch(asset.updateAsset(forlgata));
    },
    getSummary: (formdata) => {
      dispatch(dashboard.getSummary(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
