import React, { Component } from "react";
import { withRouter, Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Register from "./components/register";
import Login from "./components/login";
import Location from "./components/location";
import Home from "./components/home";

import { loadReCaptcha } from "react-recaptcha-google";
import Project from "./components/project";
import Dashboard from "./components/dashboard";
import Map from "./components/hazard";

import PrivateRoute from "./private-route";
import { history } from "./_helpers/history";
import Building from "./components/building";
import AppLayout from "./components/AppLayout";
import ContentMap from "./components/map";
import Download from "./components/download";
import Spinner from "./components/loader";
import store from "./store";
import Darkmode from "darkmode-js";

class RootContainerComponent extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />

          <PrivateRoute path="/" component={AppLayout} />
        </Switch>
      </Router>
    );
  }
}

let RootContainer = RootContainerComponent;

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer />
      </Provider>
    );
  }
}

export default withRouter(App);
