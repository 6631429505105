import { history } from "../_helpers/history";

const api_url = process.env.REACT_APP_API_URL;

export const addProject = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/project/update/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PROJECT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "ADD_PROJECT", project: res.data });
          console.log("project ke action mine", res.data);
          history.go(0);
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        } else {
          dispatch({ type: "PROJECT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getProjects = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `${token}`;
    }

    fetch(api_url + "api/project/" + id + "/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PROJECT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_PROJECT", project: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        } else {
          dispatch({ type: "PROJECT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getProjectList = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `${token}`;
    }

    fetch(api_url + "api/project/list/" + id + "/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PROJECT_LIST_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_PROJECT_LIST", list: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        } else {
          dispatch({ type: "PROJECT_LIST_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getCSV = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/portfolio/data/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "CSV_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_CSV", csv: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", csv: res.data });
          throw res.data;
        } else {
          dispatch({ type: "CSV_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getLoss = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/portfolio/loss&risk/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "CSV_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_CSV", csv: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", csv: res.data });
          throw res.data;
        } else {
          dispatch({ type: "CSV_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getSummary = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/portfolio/summary/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "CSV_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_CSV", csv: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", csv: res.data });
          throw res.data;
        } else {
          dispatch({ type: "CSV_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const deleteProject = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/project/delete/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PROJECT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "DELETE_PROJECT", project: res.data });
          history.go(0);
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        } else {
          dispatch({ type: "PROJECT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getInvoice = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/invoice/financial/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "INVOICE_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_INVOICE", invoice: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", invoice: res.data });
          throw res.data;
        } else {
          dispatch({ type: "INVOICE_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/draft/get/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "REPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_REPORT", report: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", report: res.data });
          throw res.data;
        } else {
          dispatch({ type: "REPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const addReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/draft/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "REPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "ADD_REPORT", report: res.data });
          history.go(0);
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", report: res.data });
          throw res.data;
        } else {
          dispatch({ type: "REPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getDetailedReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/variable_data/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "DETAILREPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_DETAILREPORT", detailreport: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", detailreport: res.data });
          throw res.data;
        } else {
          dispatch({ type: "DETAILREPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getWindReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/wind_data/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "WINDREPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_WINDREPORT", windreport: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", windreport: res.data });
          throw res.data;
        } else {
          dispatch({ type: "WINDREPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getLossReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/loss_data/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "LOSSREPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_LOSSREPORT", lossreport: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", lossreport: res.data });
          throw res.data;
        } else {
          dispatch({ type: "LOSSREPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getCountryWiseLossReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/country_loss/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "COUNTRYWISELOSSREPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_COUNTRYWISELOSSREPORT",
            countrywiselossreport: res.data,
          });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
            countrywiselossreport: res.data,
          });
          throw res.data;
        } else {
          dispatch({ type: "COUNTRYWISELOSSREPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getOverallMap = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/loss_map/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "OVERALLMAPIMAGE_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_OVERALLMAPIMAGE",
            overallmapimage: res.data,
          });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
            overallmapimage: res.data,
          });
          throw res.data;
        } else {
          dispatch({ type: "OVERALLMAPIMAGE_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getAssetMap = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/dashboard/plot_map/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "ASSETMAPIMAGE_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_ASSETMAPIMAGE",
            assetmapimage: res.data,
          });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
            assetmapimage: res.data,
          });
          throw res.data;
        } else {
          dispatch({ type: "ASSETMAPIMAGE_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getTcfdReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/report_data/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "TCFDREPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_TCFDREPORT", tcfd: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", tcfd: res.data });
          throw res.data;
        } else {
          dispatch({ type: "TCFDREPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getPptReport = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/all_data/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PPTREPORT_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_PPTREPORT", ppt: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", ppt: res.data });
          throw res.data;
        } else {
          dispatch({ type: "PPTREPORT_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getInsurance = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/insurance_loss/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "INSURANCE_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_INSURANCE", insurance: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", insurance: res.data });
          throw res.data;
        } else {
          dispatch({ type: "INSURANCE_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const getSatelliteMap = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/assets_map/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "ASSETMAP_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_ASSETMAP", map: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", map: res.data });
          throw res.data;
        } else {
          dispatch({ type: "ASSETMAP_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};


export const getMap = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/dashboard/plot_mapbox/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "MAP_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_MAP", overall_map: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", overall_map: res.data });
          throw res.data;
        } else {
          dispatch({ type: "MAP_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const Dropbox = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    

    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = token;
    }

    fetch(api_url + "api/report/get_link/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "LINK_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "FETCH_LINK", link: res.data });
          
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", link: res.data });
          throw res.data;
        } else {
          dispatch({ type: "LINK_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};