import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Menu,
  Select,
  Dropdown,
  Icon,
  Table,
  Modal,
  Popup,
  StepTitle,
  Progress,
} from "semantic-ui-react";
import logo from "../assets/logo.png";
import "./project.css";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import { Row, Col, Card } from "react-bootstrap";
import Spinner from "./loader";
import CsvDownload from "react-json-to-csv";
import dropbox from "../assets/dropbox.png";
import team1 from "../assets/team_1.png";
import team2 from "../assets/team_2.png";

import var_eg from "../assets/var_eg.jpg";
import Report from "./report";
import { Popconfirm } from "antd";
import "antd/dist/antd.css";
import pptxgen from "pptxgenjs";
import { runppt } from "./ppt";
import ProjectTable from "./project_table";
import { Redirect } from "react-router-dom";
import HorizontalTimeline from "react-horizontal-timeline";
import { ResponsiveLine } from "@nivo/line";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

let options = [];

/*const VALUES = [
	'2021-1-02',
    '2021-1-01',
    '2021-2-01',
    '2021-3-01',
    '2021-4-01',
    '2021-5-01',
    '2021-6-01',
    '2021-7-01',
	'2021-8-01',
	'2021-9-01',
	'2021-10-01',
	'2021-11-01',
	'2021-12-01'
];*/

const StatusOptions = [
  { key: "active", value: "Active", text: "Active" },
  { key: "finished", value: "Finished", text: "Finished" },
];
const csv = [];
const othersOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "type", value: "type", text: "type" },
  { key: "Area", value: "Area", text: "Area" },
  { key: "Ownership", value: "ownership", text: "Ownership" },
  { key: "floors", value: "floors", text: "floors" },
  {
    key: "Construction Cost",
    value: "construction_cost",
    text: "Construction Cost",
  },
];
let variableOptions = [];
const rcpOptions = [
  { key: "0.0", value: "0.0", text: "0.0" },
  { key: "2.6", value: "2.6", text: "2.6" },
  { key: "4.5", value: "4.5", text: "4.5" },
  { key: "8.5", value: "8.5", text: "8.5" },
];
const yearOptions = [
  { key: "2020", value: "2020", text: "2020" },
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
];
const PortfolioLossOptions = [
  {
    key: "Rainfall Flood Loss",
    value: "Rainfall Flood Loss",
    text: "Rainfall Flood Loss",
  },
  {
    key: "River Flood Loss",
    value: "River Flood Loss",
    text: "River Flood Loss",
  },
  {
    key: "Storm Surge Loss",
    value: "Storm Surge Loss",
    text: "Storm Surge Loss",
  },
  { key: "Typhoon Loss", value: "Typhoon Loss", text: "Typhoon Loss" },
  {
    key: "Temprature/heat Loss",
    value: "Temprature/heat Loss",
    text: "Temprature/heat Loss",
  },
  {
    key: "Solar Production Loss",
    value: "Solar Production Loss",
    text: "Solar Production Loss",
  },
  {
    key: "Wind Production Loss",
    value: "Wind Production Loss",
    text: "Wind Production Loss",
  },
  {
    key: "Hydro Production Loss",
    value: "Hydro Production Loss",
    text: "Hydro Production Loss",
  },
  {
    key: "Wheat Production Loss",
    value: "Wheat Production Loss",
    text: "Wheat Production Loss",
  },
  {
    key: "Rice Production Loss",
    value: "Rice Production Loss",
    text: "Rice Production Loss",
  },
  {
    key: "Maize Production Loss",
    value: "Maize Production Loss",
    text: "Maize Production Loss",
  },
  {
    key: "Fruit Production Loss",
    value: "Fruit Production Loss",
    text: "Fruit Production Loss",
  },
  {
    key: "Vegetable Production Loss",
    value: "Vegetable Production Loss",
    text: "Vegetable Production Loss",
  },
];

const piedata = [
  {
    id: "haskell",
    label: "haskell",
    value: 517,
    color: "hsl(102, 70%, 50%)",
  },
];

const data = [
  {
    id: "japan",
    color: "hsl(68, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 63,
      },
      {
        x: "helicopter",
        y: 49,
      },
      {
        x: "boat",
        y: 124,
      },
      {
        x: "train",
        y: 233,
      },
      {
        x: "subway",
        y: 231,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(233, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 221,
      },
      {
        x: "helicopter",
        y: 189,
      },
      {
        x: "boat",
        y: 262,
      },
      {
        x: "train",
        y: 234,
      },
      {
        x: "subway",
        y: 183,
      },
    ],
  },
];

class Project extends Component {
  state = {
    name: "",
    description: "",
    status: "",
    assets: [],
    loading: false,
    activeItemName: "",
    modalOpen: false,
    variables: [],
    others: "",
    year: "",
    rcp: "",
    modalloading: false,
    lossmodalOpen: false,
    reportmodal: false,
    portfolios: "",
    summarymodalOpen: false,
    new_project: "",
    portfolio_loss: "",
    modalEdit: false,
    loading: false,
    dropbox: true,
    value: 0,
    previous: 0,
    share: false,
  };

  componentDidMount() {
    this.props.getProjects();
    this.props.getProjectList();
  }

  onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("oldname", this.state.activeItemName);
    formdata.append("status", this.state.status);
    formdata.append("description", this.state.description);
    formdata.append("name", this.state.new_project);
    this.setState({ loading: true }, () => {
      this.props.addProject(formdata);
    });
  };
  handleChange = (value, key) => {
    this.setState({ [key]: value }, () =>
      console.log("othres", this.state.others)
    );
  };

  handlePptReport(portfolio) {
    let formdata = new FormData();
    formdata.append("project_name", portfolio);

    this.setState({ loading: true }, () => {
      this.props.getPptReport(formdata);
    });
  }
  handleShare = () => {
    this.setState({ share: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    formdata.append("variables", JSON.stringify(this.state.variables));
    formdata.append("others", JSON.stringify(this.state.others));
    formdata.append("year", JSON.stringify(this.state.year));
    formdata.append("rcp", JSON.stringify(this.state.rcp));
    formdata.append(
      "portfolio_loss",
      JSON.stringify(this.state.portfolio_loss)
    );

    this.setState({ modalloading: true }, () => {
      this.props.getCSV(formdata);
    });
  };
  handleView = () => {
    console.log("view ", this.props.csv.success);
    this.props.history.push({
      pathname: "/pivottable",
      state: { data: this.props.csv.success },
    });
  };
  handleLossSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    formdata.append("year", this.state.year);
    this.setState({ modalloading: true }, () => {
      this.props.getLoss(formdata);
    });
  };

  handleSummarySubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    this.setState({ modalloading: true }, () => {
      this.props.getSummary(formdata);
    });
  };

  handleLogout = () => {
    this.props.logout();
  };
  handleOpen = (portfolio, variable) =>
    this.setState(
      { modalOpen: true, activeItemName: portfolio, activeVariable: variable },
      () => this.handleVariable()
    );

  handleVariable() {
    console.log("Climate Variable", this.state.activeVariable);
    variableOptions = [];
    for (let i = 0; i < this.state.activeVariable.length; i++) {
      variableOptions.push({
        key: this.state.activeVariable[i],
        value: this.state.activeVariable[i],
        text: this.state.activeVariable[i],
      });
    }
  }
  handleEdit = (portfolio) => {
    this.setState({ modalEdit: true, activeItemName: portfolio });
  };

  handleLossModalOpen = (portfolio) =>
    this.setState({ lossmodalOpen: true, activeItemName: portfolio }, () =>
      console.log(this.state.activeItemName)
    );

  handleReport = (portfolio) =>
    this.setState({ reportmodal: true, activeItemName: portfolio }, () =>
      console.log(this.state.activeItemName)
    );

  handleLossModalClose = () => {
    this.setState({ lossmodalOpen: false }, () =>
      window.location.reload(false)
    );
  };
  handleSummaryModalOpen = (portfolio) =>
    this.setState({ summarymodalOpen: true, activeItemName: portfolio });

  handleSummaryModalClose = () => {
    this.setState({ summarymodalOpen: false }, () =>
      window.location.reload(false)
    );
  };

  handleDropbox = (base, map) => {
    if (this.props.link.url == undefined) {
      console.log("function called", map);
      let formdata = new FormData();
      formdata.append("ppt", base);
      formdata.append("project_name", map);
      this.props.Dropbox(formdata);
    }
  };

  handleClose = () =>
    this.setState(
      {
        modalOpen: false,
        lossmodalOpen: false,
        reportmodal: false,
        modalEdit: false,
        dropbox: false,
      },
      () => window.location.reload(false)
    );

  handleDashboard = (portfolio) => {
    console.log("table", portfolio);
    localStorage.setItem("variables", portfolio.variables);
    this.props.history.push({
      pathname: "/dashboard",
      state: {
        project_name: portfolio.name,
        variables: portfolio.variables,
        property_type: portfolio.property_type,
      },
    });
  };
  handleDetailedReport = (portfolio) => {
    this.props.history.push({
      pathname: "/tcfd",
      state: { portfolio: portfolio.name },
    });
  };
  delete = (portfolio) => {
    let formdata = new FormData();
    formdata.append("name", portfolio);
    this.props.deleteProject(formdata);
  };

  render() {
    const { value, others, variables, rcp, year, status, portfolio_loss } =
      this.state;

    let project_data = [];
    let user_id = localStorage.getItem("user_id");
    console.log("ppt", this.props.ppt.dropbox_url);

    let timeline = document.getElementsByClassName("dot-label");

    for (let i = 0; i < timeline.length; i++) {
      let textnode = document.createTextNode(timeline[i].innerText.slice(0, 4));
      let startnode = document.createTextNode("All");
      console.log("timeline", textnode);
      timeline[0].replaceChild(startnode, timeline[0].childNodes[0]);
      timeline[i].replaceChild(textnode, timeline[i].childNodes[0]);
    }

    if (this.props.ppt.dropbox_url && this.state.loading) {
      this.state.loading = false;
      window.open(this.props.ppt.dropbox_url, "_blank") ||
        window.location.replace(this.props.ppt.dropbox_url);
    }
    if (this.state.loading) {
      return <div id="loading"></div>;
    }
    if (this.props.list.data && this.props.list.data.length > 0) {
      options = [];
      if (this.state.value != 0) {
        let assets = this.props.list.data.filter(
          (asset) => asset.month == this.state.value
        );
        this.state.portfolios = assets.reverse();
      } else {
        let assets = this.props.list.data;
        this.state.portfolios = assets.reverse();
        for (let i = 0; i < assets.length; i++) {
          project_data.push({
            key: i.toString(),
            name: assets[i].name,
            date: assets[i].date.slice(0, 10),
            severity: assets[i].severity,
            overall: assets[i].overall,
            assets: assets[i].assets,
            value: assets[i].value,
            loss: assets[i].loss,
          });
        }
      }

      const members = this.props.list.members;

      for (let i = 0; i < members.length; i++) {
        options.push({
          key: members[i],
          value: members[i],
          text: members[i],
        });
      }
    }

    return (
      <div>
        <Container style={{ height: "90vh" }} fluid>
          <Row className="project_table">
            <Col lg={1}></Col>

            <Col lg={10}>
              {this.state.portfolios.length > 0 ? (
                <ProjectTable
                  data={this.state.portfolios}
                  handleDashboard={this.handleDashboard}
                />
              ) : null}
              {/* <table className="ant-table">
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell">Project Name</th>
                    <th className="ant-table-cell">Date</th>
                    <th className="ant-table-cell">Risk Severity</th>
                    <th className="ant-table-cell">Asset Count</th>
                    <th className="ant-table-cell">Valuation</th>
                    <th className="ant-table-cell">Loss</th>
                    <th className="ant-table-cell">Analyse</th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {this.state.portfolios && this.state.portfolios.length > 0
                    ? this.state.portfolios
                        .reverse()
                        .map((portfolio, index) => (
                          <tr key={index} className="ant-table-row">
                            <td className="ant-table-cell">{portfolio.name}</td>

                            <td className="ant-table-cell">
                              {portfolio.date.slice(0, 10)}
                            </td>
                            <td className="ant-table-cell">
                              {portfolio.severity}
                            </td>
                            <td className="ant-table-cell">
                              {portfolio.assets}
                            </td>
                            <td className="ant-table-cell">
                              {portfolio.value}
                            </td>
                            <td className="ant-table-cell">
                              {portfolio.loss.toFixed(3)}
                            </td>
                            <td className="ant-table-cell">
                              <Button
                                style={{
                                  backgroundColor: "#f5d76e",
                                  color: "#000",
                                  border: "1px solid #000",
                                }}
                                variant="primary"
                                onClick={() => this.handleDashboard(portfolio)}
                              >
                                Analyse
                              </Button>
                            </td>
                          </tr>
                        ))
                    : null}
                </tbody>
              </table>*/}
            </Col>

            {/*{this.props.list.data && this.props.list.data.length > 0 ? (
              <Col
                lg={2}
                className="card_eg_top_2"
                style={{
                  height: "68.2vh",
                  marginTop: "15vh",
                  paddingTop: "5%",
                }}
              >
                <p style={{ fontSize: "16px" }}>
                  <span style={{ fontSize: "16px" }}>
                    Company Name : {this.props.list.organization}
                  </span>{" "}
                </p>
                <br />
                <p style={{ fontSize: "16px" }}>
                  <span style={{ fontSize: "16px" }}>
                    Username : {this.props.list.username}
                  </span>{" "}
                </p>
                <br />
                <p style={{ fontSize: "16px" }}>
                  <span style={{ fontSize: "16px" }}>
                    Project Count : {this.props.list.data.length}
                  </span>
                </p>
                <br />
                <p style={{ fontSize: "16px" }}>
                  <span style={{ fontSize: "16px" }}>
                    Total Loss : $ {this.props.list.overall_data.loss} Billion
                  </span>
                </p>
                <br />
                <p style={{ fontSize: "16px" }}>
                  <span style={{ fontSize: "16px" }}>
                    Assets Count : {this.props.list.overall_data.assets}
                  </span>
                </p>
                <br />
                <p style={{ fontSize: "16px" }}>
                  <span style={{ fontSize: "16px" }}>
                    Valuation : $ {this.props.list.overall_data.valuation}
                    Million
                  </span>
                </p>
              </Col>
              ) : null}*/}

            <Col lg={12}>
              <Row>
                {/* {this.state.portfolios && this.state.portfolios.length > 0
                  ? this.state.portfolios.reverse().map((portfolio, index) => (
                      <Col lg={4} key={index}>
                        <Card className="card_eg_project">
                          <Card.Body>
                            <Card.Title style={{ fontWeight: "bold" }}>
                              {portfolio.name}
                              <span
                                style={{
                                  float: "right",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                              >
                                {portfolio.date.slice(0, 10)}
                              </span>
                            </Card.Title>

                            {/* <Progress percent={20} color="yellow" />
                  <p>
                    <span style={{ fontWeight: "bold" }}>Risk Severity</span>
                    <span style={{ float: "right" }}>20%</span>
					</p>
                            <Card.Text>
                              {/* The project has assets with assets value of
                    <span style={{ color: "green" }}></span>
                    and max yearly probable loss under RCP 8.5 as
                    <span style={{ color: "red" }}>$</span>
				   million
                            </Card.Text>
                            <Row>
                              <Col lg={8} style={{ height: "100px" }}>
                                <span className="project_info">
                                  {portfolio.severity}
                                </span>
                                <br />
                                <span>Risk Severity</span>

                                {/*  <ResponsiveLine
                                  data={data}
                                  margin={{
                                    top: 10,
                                    right: 10,
                                    bottom: 10,
                                    left: 10,
                                  }}
                                  xScale={{ type: "point" }}
                                  yScale={{
                                    type: "linear",
                                    min: "auto",
                                    max: "auto",
                                    stacked: true,
                                    reverse: false,
                                  }}
                                  yFormat=" >-.2f"
                                  curve="linear"
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={null}
                                  axisLeft={null}
                                  pointSize={10}
                                  pointColor={{ theme: "background" }}
                                  pointBorderWidth={2}
                                  pointBorderColor={{ from: "serieColor" }}
                                  pointLabelYOffset={-12}
                                  enableArea={true}
                                  useMesh={true}
                                  legends={[]}
                                />
                              </Col>
                              <Col lg={3} style={{ height: "100px" }}>
                                <CircularProgressbar
                                  value={portfolio.overall}
                                  text={`${portfolio.overall}%`}
                                  styles={buildStyles({
                                    textColor: "#fff",
                                    pathColor: "#f5d76e",
                                    trailColor: "#d6d6d6",
                                  })}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={2}>
                                <span className="project_info">
                                  {portfolio.assets}
                                </span>
                                <br />

                                <span>Assets</span>
                              </Col>
                              <Col lg={5}>
                                <span className="project_info">
                                  {" "}
                                  {portfolio.value}{" "}
                                </span>
                                <br />

                                <span> Asset Value</span>
                              </Col>
                              <Col lg={5}>
                                <span className="project_info">
                                  $ {portfolio.loss.toFixed(2)} Million
                                </span>
                                <br />

                                <span>Loss</span>
                              </Col>
                            </Row>
                            <br />

                            <br />
                            <Row>
                              <Col lg={3}>
                                <Button
                                  style={{
                                    backgroundColor: "#f5d76e",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  variant="primary"
                                  onClick={() =>
                                    this.handleDashboard(portfolio)
                                  }
                                >
                                  Analyse
                                </Button>
                              </Col>
                              {/*  <Col lg={3}>
                      <Button
                        style={{
                          backgroundColor: "#cacfd9",
                          color: "#002060",
                          border: "1px solid #002060",
                          borderRadius: "25px",
                        }}
                        variant="primary"
                        onClick={() => this.handlePptReport()}
                      >
                        Report
                      </Button>
                      </Col>*/}
                {/* <Col lg={6}>  <Button style={{backgroundColor:'#FDB827', color:'white'}} variant="primary" onClick={()=>this.handleDetailedReport(portfolio)}>TCFD Report</Button></Col>
                            </Row>
                            <br />

                            {/* <Row>
                        <Col lg={6}>
                          {this.state.share ? (
                            <span style={{ marginTop: "0%" }}>
                              <Dropdown
                                placeholder="Share"
                                fluid
                                multiple
                                selection
                                options={options}
                              />
                            </span>
                          ) : (
                            <span>
                              <p style={{ fontWeight: "bold" }}>Team</p>
                              <Row>
                                {portfolio.shared_with
                                  .slice(0, 2)
                                  .map((share, index) => {
                                    console.log("share", share);
                                    return (
                                      <>
                                        <Col lg={4}>
                                          <Popup
                                            content={share}
                                            trigger={
                                              <Image src={team1} size="mini" />
                                            }
                                          />
                                        </Col>
                                      </>
                                    );
                                  })}
                                <Col lg={4}>
                                  <p>
                                    +{portfolio.shared_with.length - 2} more
                                  </p>
                                </Col>
                              </Row>
                            </span>
                          )}
                        </Col>

                        <Col lg={6}>
                          <Row>
                            <Col lg={6}>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Download CSVs
                              </p>
                              <Popup
                                content="Download CSV"
                                trigger={
                                  <Icon
                                    name="file"
                                    onClick={() =>
                                      this.handleOpen(
                                        portfolio.name,
                                        portfolio.variables
                                      )
                                    }
                                    size="large"
                                  />
                                }
                              />
                              <Popup
                                content="Download Loss"
                                trigger={
                                  <Icon
                                    name="dollar sign"
                                    onClick={() =>
                                      this.handleLossModalOpen(portfolio.name)
                                    }
                                    size="large"
                                  />
                                }
                              />

                              <Popup
                                content="Download Summary"
                                trigger={
                                  <Icon
                                    name="file alternate"
                                    onClick={() =>
                                      this.handleSummaryModalOpen(
                                        portfolio.name
                                      )
                                    }
                                    size="large"
                                  />
                                }
                              />
                            </Col>
                            <Col lg={6}>
                              <p></p>
                              <Popconfirm
                                placement="topLeft"
                                title={
                                  <div>
                                    Are you sure you want to <br /> delete{" "}
                                    <b>{portfolio.name}</b>
                                  </div>
                                }
                                onConfirm={() => this.delete(portfolio.name)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Icon
                                  name="trash alternate"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  size="large"
                                ></Icon>
                              </Popconfirm>

                              <button
                                class="button button--primary buttons__comprar"
                                onClick={() => this.handleEdit(portfolio.name)}
                              >
                                <Icon
                                  name="edit"
                                  style={{ color: "#002060" }}
                                  size="large"
                                />{" "}
                              </button>
                            </Col>
                          </Row>
                        </Col>
								</Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  : null}*/}
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal
          open={this.state.modalEdit}
          onClose={this.handleClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Update Project</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Row>
                <Form.Field
                  control={Input}
                  placeholder={this.state.activeItemName}
                  value={this.state.name}
                  disabled
                  style={{ width: "100%" }}
                />
              </Row>
              <br />
              <Row>
                <Dropdown
                  placeholder="Status"
                  fluid
                  selection
                  options={StatusOptions}
                  value={status}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "status")
                  }
                />
              </Row>
              <br />
              <Row>
                <Form.Field
                  control={Input}
                  placeholder="description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </Row>
              <br />
              <Row>
                <Form.Field
                  control={Input}
                  placeholder="New Project Name"
                  value={this.state.new_project}
                  onChange={(e) =>
                    this.setState({ new_project: e.target.value })
                  }
                />
              </Row>
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <Button
                  style={{ backgroundColor: "#002060", marginLeft: "45%" }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#002060",
                    color: "white",
                    marginLeft: "45%",
                  }}
                  onClick={this.onSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.reportmodal}
          onClose={this.handleClose}
          closeIcon
          itemName={this.state.activeItemName}
          size="fullscreen"
        >
          <Modal.Header>Download Report</Modal.Header>
          <Modal.Content>
            <Report data={this.state.activeItemName} />
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Download CSV</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <p>Select Data You want to Download</p>
              <Row>
                <Dropdown
                  placeholder="Other variables"
                  fluid
                  multiple
                  selection
                  options={othersOptions}
                  value={others}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "others")
                  }
                />
              </Row>
              <p>Select Portfolio Losses</p>
              <Row>
                <Dropdown
                  placeholder="Portfolio Losses"
                  fluid
                  multiple
                  selection
                  options={PortfolioLossOptions}
                  value={portfolio_loss}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "portfolio_loss")
                  }
                />
              </Row>
              {variableOptions.length > 0 ? (
                <>
                  <p>Select Climate Variable</p>
                  <Row>
                    <Dropdown
                      placeholder="Climate variables"
                      fluid
                      multiple
                      selection
                      options={variableOptions}
                      value={variables}
                      onChange={(e, { value }) =>
                        this.handleChange(value, "variables")
                      }
                    />
                  </Row>
                </>
              ) : null}
              <p>Select RCP</p>
              <Row>
                <Dropdown
                  placeholder="RCP"
                  fluid
                  multiple
                  selection
                  options={rcpOptions}
                  value={rcp}
                  onChange={(e, { value }) => this.handleChange(value, "rcp")}
                />
              </Row>
              <p>Select Year</p>
              <Row>
                <Dropdown
                  placeholder="Year"
                  fluid
                  multiple
                  selection
                  options={yearOptions}
                  value={year}
                  onChange={(e, { value }) => this.handleChange(value, "year")}
                />
              </Row>
              <br />

              {this.state.modalloading &&
              !this.props.csv.length === undefined ? (
                <Button
                  style={{ backgroundColor: "#002060", marginLeft: "45%" }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#002060",
                    color: "white",
                    marginLeft: "45%",
                  }}
                  onClick={this.handleSubmit}
                  primary
                >
                  Submit
                </Button>
              )}

              {this.props.csv.length === undefined ? (
                <div>
                  <CsvDownload
                    data={this.props.csv.success}
                    style={{
                      backgroundColor: "#002060",
                      color: "white",
                      border: "0px solid white",
                      padding: "10px",
                      float: "right",
                      borderRadius: "5%",
                      fontWeight: "bold",
                    }}
                  />
                  <Button onClick={this.handleView} primary>
                    View
                  </Button>
                </div>
              ) : null}
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.lossmodalOpen}
          onClose={this.handleLossModalClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Download Loss</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <p>Select Year</p>
              <Row>
                <Dropdown
                  placeholder="Year"
                  fluid
                  selection
                  options={yearOptions}
                  value={year}
                  onChange={(e, { value }) => this.handleChange(value, "year")}
                />
              </Row>
              <br />

              {this.state.modalloading &&
              !this.props.csv.length === undefined ? (
                <Button
                  style={{ backgroundColor: "#002060", marginLeft: "45%" }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#002060",
                    color: "white",
                    marginLeft: "45%",
                  }}
                  onClick={this.handleLossSubmit}
                  primary
                >
                  Submit
                </Button>
              )}

              {this.props.csv.length === undefined ? (
                <CsvDownload
                  data={this.props.csv.success}
                  style={{
                    backgroundColor: "#002060",
                    color: "white",
                    border: "0px solid white",
                    padding: "10px",
                    float: "right",
                    borderRadius: "5%",
                    fontWeight: "bold",
                  }}
                />
              ) : null}
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.summarymodalOpen}
          onClose={this.handleSummaryModalClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Download Summary</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Row>
                <Form.Field
                  id="form-input-control-project"
                  control={Input}
                  label="Portfolio"
                  placeholder={this.state.activeItemName}
                  disabled
                  style={{ width: "100%" }}
                />
              </Row>
              <br />

              {this.state.modalloading &&
              !this.props.csv.length === undefined ? (
                <Button
                  style={{ backgroundColor: "#fdb827", marginLeft: "45%" }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#002060",
                    color: "white",
                    marginLeft: "40%",
                  }}
                  onClick={this.handleSummarySubmit}
                  primary
                >
                  Submit
                </Button>
              )}

              {this.props.csv.length === undefined ? (
                <div>
                  <CsvDownload
                    data={this.props.csv.success}
                    style={{
                      backgroundColor: "#002060",
                      color: "white",
                      border: "0px solid white",
                      padding: "10px",
                      float: "right",
                      borderRadius: "5%",
                      fontWeight: "bold",
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    locus: state.locus.locus,
    csv: state.project.csv,
    project: state.project.project,
    ppt: state.project.ppt,
    map: state.project.map,
    overall_map: state.project.overall_map,
    link: state.project.link,
    list: state.project.list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: () => {
      dispatch(locus.getLocations());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    addProject: (formdata) => {
      dispatch(project.addProject(formdata));
    },
    getCSV: (formdata) => {
      dispatch(project.getCSV(formdata));
    },
    getLoss: (formdata) => {
      dispatch(project.getLoss(formdata));
    },
    getSummary: (formdata) => {
      dispatch(project.getSummary(formdata));
    },

    getProjects: () => {
      dispatch(project.getProjects());
    },
    deleteProject: (formdata) => {
      dispatch(project.deleteProject(formdata));
    },
    getPptReport: (formdata) => {
      dispatch(project.getPptReport(formdata));
    },
    getSatelliteMap: (formdata) => {
      dispatch(project.getSatelliteMap(formdata));
    },
    getMap: (formdata) => {
      dispatch(project.getMap(formdata));
    },
    Dropbox: (formdata) => {
      dispatch(project.Dropbox(formdata));
    },
    getProjectList: () => {
      dispatch(project.getProjectList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Project);
