import React, { Component } from "react";
import { Button, Image, Menu, Icon, Checkbox } from "semantic-ui-react";

import { connect } from "react-redux";
import { auth } from "../actions";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import logo from "../assets/logo.png";

class NavMenu extends Component {
  state = {};
  handleLogout = () => {
    this.props.logout();
  };

  render() {
    //const isDarkMode = localStorage.getItem("theme") == "dark"

    return (
      <Navbar className="climate_brain_navbar ml-auto">
        <Container fluid>
          <Navbar.Brand href="/" style={{ marginLeft: "5%" }}>
            <img src={logo} width="49" height="52" />
          </Navbar.Brand>
          <Nav className="mr-auto" style={{ marginLeft: "10%" }}>
            <Nav.Link href="/location">Analyse</Nav.Link>
            <Nav.Link href="/project">Project</Nav.Link>
            <Nav.Link href="/dashboard">Dashboard</Nav.Link>
          </Nav>
          <Nav.Link>
            <Button
              onClick={this.handleLogout}
              style={{ backgroundColor: "white", color: "#6a2846" }}
            >
              Logout
            </Button>
          </Nav.Link>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(auth.logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
