import React from "react";
import { Taps, Tap, Row, Col } from "react-bootstrap";
import { Progress } from "antd";

const LossComponent = (props) => {
  console.log("Losses 2020", props.newdata);

  return (
    <div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>Total Loss </span>
          <p>
            {props.data["Total Loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Total Loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                $ {(props.data["Total Loss"][0] / 1000).toFixed(2)} Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Total Loss"][1]}
          className="lessloss"
        />
        <Progress percent={props.data["Total Loss"][1]} />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Asset Typhoon Damage{" "}
          </span>
          <p>
            {props.data["Asset Typhoon Damage"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Asset Typhoon Damage"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                $ {(props.data["Asset Typhoon Damage"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Asset Typhoon Damage"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Asset Typhoon Damage"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Asset River Flood Damage{" "}
          </span>
          <p>
            {props.data["Asset River Flood Damage"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Asset River Flood Damage"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(props.data["Asset River Flood Damage"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Asset River Flood Damage"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Asset River Flood Damage"][1]}
          color="green"
        />{" "}
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Asset Rainfall Flood Damage{" "}
          </span>
          <p>
            {props.data["Asset Rainfall Flood Damage"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Asset Rainfall Flood Damage"][0].toFixed(2)}{" "}
                Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(props.data["Asset Rainfall Flood Damage"][0] / 1000).toFixed(
                  2
                )}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Asset Rainfall Flood Damage"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Asset Rainfall Flood Damage"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Asset Storm Surge Damage{" "}
          </span>
          <p>
            {props.data["Asset Storm Surge Damage"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Asset Storm Surge Damage"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(props.data["Asset Storm Surge Damage"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Asset Storm Surge Damage"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Asset Storm Surge Damage"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Operational Rainfall Flood Loss{" "}
          </span>
          <p>
            {props.data["Operational Rainfall Flood Loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Operational Rainfall Flood Loss"][0].toFixed(3)}{" "}
                Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(
                  props.data["Operational Rainfall Flood Loss"][0] / 1000
                ).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Operational Rainfall Flood Loss"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Operational Rainfall Flood Loss"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Operational River Flood Loss{" "}
          </span>
          <p>
            {props.data["Operational River Flood Loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Operational River Flood Loss"][0].toFixed(3)}{" "}
                Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(props.data["Operational River Flood Loss"][0] / 1000).toFixed(
                  2
                )}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Operational River Flood Loss"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Operational River Flood Loss"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Operational Storm Surge Loss{" "}
          </span>
          <p>
            {props.data["Operational Storm Surge Loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Operational Storm Surge Loss"][0].toFixed(3)}{" "}
                Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(props.data["Operational Storm Surge Loss"][0] / 1000).toFixed(
                  2
                )}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Operational Storm Surge Loss"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Operational Storm Surge Loss"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Operational Typhoon Loss{" "}
          </span>
          <p>
            {props.data["Operational Typhoon Loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Operational Typhoon Loss"][0].toFixed(3)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${" "}
                {(props.data["Operational Typhoon Loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Operational Typhoon Loss"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Operational Typhoon Loss"][1]}
          color="green"
        />
      </div>
      <div className="Progress">
        <p>
          <span className={"dark-mode-primary-color"}>
            Temperature/Heat Loss
          </span>
          <p>
            {props.data["Temprature/heat loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                $ {props.data["Temprature/heat loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                $ {(props.data["Temprature/heat loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </p>
        </p>
        <Progress
          percent={props.newdata["Temprature/heat loss"][1]}
          color="green"
          className="lessloss"
        />
        <Progress
          percent={props.data["Temprature/heat loss"][1]}
          color="green"
        />
      </div>
      {/* <Tap title="Energy" eventKey="energy">
         <pr/>

         <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Total Energy  Loss</span><p style={{float:'right'}}>{props.data.energy['total_loss'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.energy['total_loss'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.energy['total_loss'][0]/1000).toFixed(2)} Billion</span>}</p></p>
         <Progress percent={props.newdata.energy['total_loss'][1]} color='green' className="lessloss"/>

<Progress percent={props.data.energy['total_loss'][1]} color='green'/>

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Solar Production Loss</span><p style={{float:'right'}}>{props.data.energy['solar_loss'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.energy['solar_loss'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.energy['solar_loss'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.energy['solar_loss'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.energy['solar_loss'][1]} color='green'/>

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Wind Production Loss</span><p style={{float:'right'}}>{props.data.energy['wind_loss'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.energy['wind_loss'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.energy['wind_loss'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.energy['wind_loss'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.energy['wind_loss'][1]} color='green'/>

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Hydro Production Loss</span><p style={{float:'right'}}>{props.data.energy['hydro_loss'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.energy['hydro_loss'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.energy['hydro_loss'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.energy['hydro_loss'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.energy['hydro_loss'][1]} color='green'/>
     </Tap>
     <Tap title="Agriculture" eventKey="agri">
     <pr/>
     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Total Agriculture Loss</span><p style={{float:'right'}}>{props.data.agriculture['total_loss'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.agriculture['total_loss'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.agriculture['total_loss'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.agriculture['total_loss'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.agriculture['total_loss'][1]} color='green'/>

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Wheat Production Loss</span><p style={{float:'right'}}>{props.data.agriculture['wheat'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.agriculture['wheat'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.agriculture['wheat'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.agriculture['wheat'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.agriculture['wheat'][1]} color='green'/>
     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Rice Production Loss</span><p style={{float:'right'}}>{props.data.agriculture['rice'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.agriculture['rice'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.agriculture['rice'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.agriculture['rice'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.agriculture['rice'][1]} color='green'/>
     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Maize Production Loss</span><p style={{float:'right'}}>{props.data.agriculture['maize'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.agriculture['maize'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.agriculture['maize'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.agriculture['maize'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.agriculture['maize'][1]} color='green'/>
     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Fruit Production Loss</span><p style={{float:'right'}}>{props.data.agriculture['fruit'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.agriculture['fruit'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.agriculture['fruit'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.agriculture['fruit'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.agriculture['fruit'][1]} color='green'/>
     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Vegetaple Production Loss</span><p style={{float:'right'}}>{props.data.agriculture['vegetaple'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data.agriculture['vegetaple'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data.agriculture['vegetaple'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata.agriculture['vegetaple'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data.agriculture['vegetaple'][1]} color='green'/>
     
     </Tap>
     <Tap title="Total" eventKey="total">

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Total Loss</span><p style={{float:'right'}}>{props.data['Total Loss'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data['Total Loss'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data['Total Loss'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata['Total Loss'][1]} color='green' className="lessloss"/>

<Progress percent={props.data['Total Loss'][1]} color='green'/>

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Total Damage</span><p style={{float:'right'}}>{props.data['Total Damage'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data['Total Damage'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data['Total Damage'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata['Total Damage'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data['Total Damage'][1]} color='green'/>

     <p style={{fontSize:'14px'}}><span className={"dark-mode-primary-color"}>Total Operational</span><p style={{float:'right'}}>{props.data['Total Operational'][0]<1000?<span className={"dark-mode-primary-color"}>$ {props.data['Total Operational'][0].toFixed(2)} Million</span>:<span className="dark-mode-theme-color-1">$ {(props.data['Total Operational'][0]/1000).toFixed(2)} Billion</span>}</p></p>
     <Progress percent={props.newdata['Total Operational'][1]} color='green' className="lessloss"/>

     <Progress percent={props.data['Total Operational'][1]} color='green'/>

    </Tap> */}
    </div>
  );
};

export default LossComponent;
