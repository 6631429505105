import React, { Component } from "react";
import PropTypes from "prop-types";
import { Defs, linearGradientDef } from "@nivo/core";
import Slider from "@material-ui/core/Slider";
import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Progress,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
} from "semantic-ui-react";
import { project, auth, dashboard, asset } from "../actions";
import { connect } from "react-redux";
import { Tabs, Tab, Row, Col, Container } from "react-bootstrap";
import "./detail.css";
import { Bar } from "@nivo/bar";
import mapboxgl from "mapbox-gl";
import { ResponsiveLine } from "@nivo/line";
import LayerMap from "./layermap";
import LayerMap_85 from "./layermap_1";

import DetailMap from "./detailmap";
import LayerMap_Change from "./layemap_2";

mapboxgl.accessToken =
  "pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";

let feature = [];
let marks = [];
let line = [];

const RcpOptions = [
  { key: 2.6, value: 26, text: "RCP 2.6" },
  { key: 4.5, value: 45, text: "RCP 4.5" },
  { key: 8.5, value: 85, text: "RCP 8.5" },
];
const VariableOptions = [
  { key: "Total", value: "TO", text: "Total" },
  { key: "Rainfall Flood", value: "RFL", text: "Rainfall Flood" },
  { key: "Typhoon", value: "TY", text: "Typhoon" },
  { key: "Storm", value: "SS", text: "Storm" },
];
const YearOptions = [
  { key: 2030, value: 30, text: 2030 },
  { key: 2050, value: 50, text: 2050 },
];
let AssetOptions = [];

class Detail extends Component {
  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();

    this.state = {
      lng: -70.9,
      lat: 42.35,
      zoom: 9,
      loss: [],
      variables: "TO",
      rcp: "85",
      year: "50",
      max: "",
      Slidervalue: "",
      marks: "",
      center: "",
      asset: "",
    };
  }

  componentDidMount() {
    console.log("project", this.props.project);
    let formdata = new FormData();
    formdata.append("portfolio_name", this.props.project.slice(10));
    this.props.getLossReport(formdata);
    console.log("asset", this.props.lossreport);

    if (this.props.asset) {
      let formdata1 = new FormData();
      formdata1.append("project_name", this.props.project);
      formdata1.append("asset_name", this.props.asset[0].name);
      formdata1.append("rcp", this.props.rcp);
      formdata1.append("year", this.props.year);
      this.props.getInsurance(formdata1);
    }
  }
  valuetext(value) {
    console.log("slider", value);
  }
  handleSlider = (e, newvalue) => {
    var listing = document.getElementById("listings");
    listing.innerHTML = "";
    this.setState({ Slidervalue: newvalue }, () =>
      console.log("max", this.state.Slidervalue)
    );
  };
  handleChange = (value, key) => {
    var listing = document.getElementById("listings");
    listing.innerHTML = "";

    this.setState(
      {
        [key]: value,
      },
      () => console.log("state", this.state.asset)
    );
  };
  handleAsset = (value, key) => {
    console.log("selection", value);
    this.setState(
      {
        [key]: value,
      },
      () => console.log("asset selected", this.state.asset)
    );
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lossreport != this.props.lossreport) {
      this.setState(
        {
          center: this.props.lossreport.loc_data,
          loss: this.props.lossreport.data,
          max: this.props.lossreport.max_loss.toFixed(0),
          Slidervalue: this.props.lossreport.max_loss.toFixed(0),
        },
        () => console.log("loss", this.state.Slidervalue)
      );
    }
  }
  render() {
    if (this.props.asset.length > 0) {
      AssetOptions = [];
      for (let i = 0; i < this.props.asset.length; i++) {
        AssetOptions.push({
          text: this.props.asset[i].name,
          value: this.props.asset[i].name,
          key: this.props.asset[i].name,
        });
      }
    }

    if (this.props.insurance) {
      this.state.asset = this.props.asset[0].name;

      line = `data:image/jpeg;base64,${this.props.insurance.graph}`;
    }
    console.log("line", this.state.loss);

    const isDarkMode = localStorage.getItem("theme") == "dark";
    /*   if(this.state.loss.length>0){
        const { lng, lat, zoom} = this.state;
        var map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [ (this.state.center.lon*57.324), (this.state.center.lat*57.324) ],
            zoom: 4,
            scrollZoom: true
        });
        feature=[];
        marks.push(
        {
            value:this.state.max/36,
            label:`${(this.state.max/36).toFixed(0)}mil`
        },
        {
            value:this.state.max/4,
            label:`${(this.state.max/4).toFixed(0)}mil`
        },
        {
            value:this.state.max/1.5,
            label:`${(this.state.max/1.5).toFixed(0)}mil`
        },
        {
            value:this.state.max/1,
            label:`${this.state.max}mil`
        },
        )
        for(let i=0;i<this.state.loss.length;i++){

        feature.push({
            'type':'Feature',
            'geometry':{
                'type':'Point',
                'coordinates':[this.state.loss[i].longitude,this.state.loss[i].latitude]
            },
            'properties':{
                'address':this.state.loss[i].name,
                'loss':this.state.loss[i][`${this.state.variables}${this.state.rcp}${this.state.year}`].toFixed(2)
            }
        })
    }
    
        
        var FilterFeature = feature.filter(feature=>feature.properties.loss<this.state.Slidervalue)
        console.log("detail",FilterFeature,feature)
        var stores = {
            'type': 'FeatureCollection',
            'features': FilterFeature
            
        };
    

        /**
         * Assign a unique id to each store. You'll use this `id`
         * later to associate each point on the map with a listing
         * in the sidebar.
         *
        stores.features.forEach(function(store, i) {
            store.properties.id = i;
        });
        

        /**
         * Wait until the map loads to make changes to the map.
         *
        map.on('load', function(e) {

            map.on('touchstart',function(){
                map.resize();
            })
            /**
             * This is where your '.addLayer()' used to be, instead
             * add only the source without styling a layer
             *
            map.addSource('places', {
                'type': 'geojson',
                'data': stores
            });

            /**
             * Add all the things to the page:
             * - The location listings on the side of the page
             * - The markers onto the map
             *
            buildLocationList(stores);
            addMarkers();
        });

        /**
         * Add a marker to the map for every store listing.
         **
        function addMarkers() {

            /* For each feature in the GeoJSON object above: *
            stores.features.forEach(function(marker) {
                /* Create a div element for the marker. *
                var el = document.createElement('div');
                /* Assign a unique `id` to the marker. *
                el.id = 'marker-' + marker.properties.id;
                /* Assign the `marker` class to each marker for styling. *
                el.className = 'marker';

                /**
                 * Create a marker using the div element
                 * defined above and add it to the map.
                 **
                
                new mapboxgl.Marker(el, { offset: [0, -23] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map);

                /**
                 * Listen to the element and when it is clicked, do three things:
                 * 1. Fly to the point
                 * 2. Close all other popups and display popup for clicked store
                 * 3. Highlight listing in sidebar (and remove highlight for all other listings)
                 **
                el.addEventListener('click', function(e) {
                    /* Fly to the point *
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store *
                    createPopUp(marker);
                    /* Highlight listing in sidebar *
                    var activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    var listing = document.getElementById(
                        'listing-' + marker.properties.id
                    );
                    listing.classList.add('active');
                });
            });
        }

        /**
         * Add a listing for each store to the sidebar.
         **
        function buildLocationList(data) {
            console.log("detailfeature",data.features)
            data.features.forEach(function(store, i) {
                /**
                 * Create a shortcut for `store.properties`,
                 * which will be used several times below.
                 **
                var prop = store.properties;
                
                /* Add a new listing section to the sidebar. *
                var listings = document.getElementById('listings');
                
                var listing = listings.appendChild(document.createElement('div'));
            
                /* Assign a unique `id` to the listing. *
                listing.id = 'listing-' + prop.id;
                /* Assign the `item` class to each listing for styling. *
                listing.className = 'item';

                /* Add the link to the individual listing created above. *
                var link = listing.appendChild(document.createElement('a'));
                
                link.className = 'title';
                link.id = 'link-' + prop.id;
                link.innerHTML = prop.address;

                /* Add details to the individual listing. *
                var details = listing.appendChild(document.createElement('div'));
                details.innerHTML = `US$ ${prop.loss}  Mil`;
                if (prop.phone) {
                    details.innerHTML += ' &middot; ' + prop.phoneFormatted;
                }
    

                var popUps = document.getElementsByClassName('mapboxgl-popup');
            //if (popUps[0]) popUps[0].remove();
            
                var popup = new mapboxgl.Popup({ closeOnClick: false })
                .setLngLat(store.geometry.coordinates)
                .setHTML(
                    '<h3>Loss</h3>' +
                    '<h4>US$ ' +
                    store.properties.loss +' Mil'+
                    '</h4>'
                )
                .addTo(map);

                /**
                 * Listen to the element and when it is clicked, do four things:
                 * 1. Update the `currentFeature` to the store associated with the clicked link
                 * 2. Fly to the point
                 * 3. Close all other popups and display popup for clicked store
                 * 4. Highlight listing in sidebar (and remove highlight for all other listings)
                 **
                link.addEventListener('click', function(e) {
                    for (var i = 0; i < data.features.length; i++) {
                        if (this.id === 'link-' + data.features[i].properties.id) {
                            var clickedListing = data.features[i];
                            flyToStore(clickedListing);
                            
                        }
                    }
                    var activeItem = document.getElementsByClassName('active');
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    this.parentNode.classList.add('active');
                });
            });
            
        }

        /**
         * Use Mapbox GL JS's `flyTo` to move the camera smoothly
         * a given center point.
         **
        function flyToStore(currentFeature) {
            map.flyTo({
                center: currentFeature.geometry.coordinates,
                zoom: 18
            });
        }

        /**
         * Crate a Mapbox GL JS `Popup`.
         **
        function createPopUp(currentFeature) {
            var popUps = document.getElementsByClassName('mapboxgl-popup');
            //if (popUps[0]) popUps[0].remove();
            
            var popup = new mapboxgl.Popup({ closeOnClick: false })
                .setLngLat(currentFeature.geometry.coordinates)
                .setHTML(
                    '<h3>Storm Surge loss</h3>' +
                    '<h4>' +
                     currentFeature.properties.loss +
                    '</h4>'
                )
                .addTo(map);
        }

}*/

    return (
      <Grid padded>
        <Grid.Row className="mapbox" style={{ marginTop: "2%" }}>
          <p className="mainheading" style={{ width: "100%" }}>
            <span className={"dark-mode-theme-color-1"}>
              Geographical Loss View{" "}
            </span>
          </p>

          <Grid.Column computer={1}></Grid.Column>

          <Grid.Column computer={3} className="Filter">
            <div>
              <div>
                <label>Select Variables</label>
                <Form.Select
                  placeholder="Variables"
                  value={this.state.variables}
                  options={VariableOptions}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "variables")
                  }
                />
              </div>
              <hr className="losshr" />
              <div>
                <label>Select RCP</label>
                <Form.Select
                  placeholder="RCP"
                  value={this.state.rcp}
                  options={RcpOptions}
                  onChange={(e, { value }) => this.handleChange(value, "rcp")}
                />
              </div>
              <hr className="losshr" />
              <div>
                <label>Select Year</label>
                <Form.Select
                  placeholder="Year"
                  value={this.state.year}
                  options={YearOptions}
                  onChange={(e, { value }) => this.handleChange(value, "year")}
                />
              </div>
              <hr className="losshr" />
              {/* <div>
                          <label>Select Loss Range</label>
                              <Slider 
                                aria-labelledby="discrete-slider-small-steps"
                                valueLabelDisplay="auto"
                                getAriaValueText={this.valuetext}
                                step={10}
                                min={0}
                                valueLabelDisplay="on"
                                marks={marks}
                                max={this.state.max}
                                defaultValue={this.state.max}
                                
                                onChange={this.handleSlider}

                              />

                         </div>*/}
              <br />
            </div>
          </Grid.Column>

          <Grid.Column computer={12}>
            <div className="shadow-inset">
              {/* <div ref={this.mapContainer} className="map-container1" />*/}
              {this.state.loss && this.state.loss.length > 0 ? (
                <DetailMap
                  data={this.state.loss}
                  center={this.state.center}
                  rcp={this.state.rcp}
                  year={this.state.year}
                  variable={this.state.variables}
                  slider={this.state.Slidervalue}
                />
              ) : null}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Row>
          <Col>
            <LayerMap />
          </Col>
          <Col>
            <LayerMap_85 />
          </Col>
          <Col>
            <LayerMap_Change />
          </Col>
        </Row>
        {/*    <Grid.Row style={{height:'600px'}}>
             <p className="mainheading" style={{width:'100%'}}><span className={"dark-mode-theme-color-1"}>Insurance Coverage Projection</span></p>
       
       
                <Grid.Column computer={13}>

            {this.props.insurance?<Image src={line} style={{width:'80%',height:'80%'}}/>:null}
    </Grid.Column>
    <Grid.Column computer={3}>
    <div>
                        <label>Select Asset</label>
                         <Dropdown placeholder="Asset"
                          value={this.state.asset}
                          selection
                          options={AssetOptions}
                          onChange={(e,{value})=>this.setState({asset:value})}
                          
                          />
                          </div>
    </Grid.Column>
        </Grid.Row>*/}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    detail: state.feedback.detail,
    assets: state.asset.asset,
    lossreport: state.project.lossreport,
    insurance: state.project.insurance,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLossReport: (formdata) => {
      dispatch(project.getLossReport(formdata));
    },
    getAssets: () => {
      dispatch(asset.getAssets());
    },
    getInsurance: (formdata) => {
      dispatch(project.getInsurance(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
