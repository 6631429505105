import * as React from "react";
import { useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Popup, FlyToInterpolator } from "react-map-gl";
import { Row, Col } from "react-bootstrap";

import { ConsoleSqlOutlined } from "@ant-design/icons";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

const DetailMap = (props) => {
  console.log("detail", props.data);
  let countries = Object.keys(props.data);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 450,
    latitude: props.center.lat * 57.324,
    longitude: props.center.lon * 57.324,
    zoom: 8,
  });

  let feature = [];

  for (let i = 0; i < props.data.length; i++) {
    let locations = [];

    feature.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [props.data[i].longitude, props.data[i].latitude],
      },
      properties: {
        address: props.data[i].name,
        loss: props.data[i][
          `${props.variable}${props.rcp}${props.year}`
        ].toFixed(2),
      },
    });
  }

  var FilterFeature = feature.filter(
    (feature) => feature.properties.loss < props.slider
  );

  var stores = {
    type: "FeatureCollection",
    features: feature,
  };

  /**
   * Assign a unique id to each store. You'll use this `id`
   * later to associate each point on the map with a listing
   * in the sidebar.
   */
  stores.features.forEach(function (store, i) {
    store.properties.id = i;
  });

  const listings = stores.features.map((listing, index) => {
    return (
      <a onClick={() => handlezoom(listing.geometry)}>
        {listing.properties.address}
      </a>
    );
  });

  const handlezoom = (geometry) => {
    setViewport({
      ...viewport,
      zoom: 18,
      latitude: geometry.coordinates[1],
      longitude: geometry.coordinates[0],
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  let html = [];
  for (let i = 0; i < stores.features.length; i++) {
    html.push(
      <tr>
        <td>{stores.features[i].properties.address.slice(0, 20)}</td>
        <td>{stores.features[i].properties.loss} mil $</td>
      </tr>
    );
  }

  const markers = stores.features.map((marker, index) => (
    <Marker
      key={`marker-${index}`}
      longitude={marker.geometry.coordinates[0]}
      latitude={marker.geometry.coordinates[1]}
      onClick={() => handlezoom(marker.geometry)}
    ></Marker>
  ));

  const popup = stores.features.map((popup, index) => (
    <Popup
      longitude={popup.geometry.coordinates[0]}
      latitude={popup.geometry.coordinates[1]}
      anchor="top"
    >
      <div>
        <h5>Asset & Loss</h5>
        <table>
          <tr>
            <td>Asset Name</td>
            <td>Loss</td>
          </tr>
          <tr>
            <td>{popup.properties.address}</td>
            <td>{popup.properties.loss}</td>
          </tr>
        </table>
      </div>
    </Popup>
  ));

  return (
    <div>
      <Row>
        <Col lg={9}>
          <ReactMapGL
            {...viewport}
            width="100%"
            height="450px"
            mapStyle="mapbox://styles/mapbox/light-v10"
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken="pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ"
          >
            {markers}
            {popup}
          </ReactMapGL>
        </Col>
        <Col lg={3}>
          <div id="listings">
            <h2>Our Locations</h2>
            {listings}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default DetailMap;
