import React, {
  Component,useEffect,useState
} from 'react'
import  { loadModules } from 'esri-loader';

let mapcards=[];

const ReportMap =(props)=> {


  
    

  useEffect(()=>{
    loadModules(['esri/Map', 'esri/views/MapView','esri/layers/FeatureLayer','esri/widgets/Legend','esri/Graphic','esri/widgets/Search','esri/tasks/Locator','esri/widgets/Fullscreen','esri/symbols/WebStyleSymbol'], { css: true })
    .then(([ArcGISMap, MapView,FeatureLayer,Legend,Graphic,Search,Locator,Fullscreen,WebStyleSymbol]) => {
    
    
      const map = new ArcGISMap({
        basemap: 'satellite',
        
      });

      const view = new MapView({
        container:`viewDiv${props.data}`,
        map: map,
        center: [props.long,props.lat],
        zoom: 18
      });

  
       
  
          var point={
      type:'point',
      longitude:props.long,
      latitude:props.lat
    };

    var WebStyleSymbol = new WebStyleSymbol({
       name: "tear-pin-1",
  styleName: "Esri2DPointSymbolsStyle"
               });

       var pointGraphic = new Graphic({
         geometry: point,
         symbol: WebStyleSymbol
       });

      view.graphics.add(pointGraphic);
    
    

       return()=>view.destroy();
         });


    
     


        
    },[props.lat]);
   


      return (<div id ={`viewDiv${props.data}`} style={{height:'400px',width:'100%'}}></div>)

    }


export default ReportMap;
