import React, { PureComponent } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ScatterChart,
  Scatter,
  Cell,
} from "recharts";
import { Grid } from "semantic-ui-react";

import { Row, Col } from "react-bootstrap";

import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryAxis,
} from "victory";
import { ResponsiveBar } from "@nivo/bar";

export default class MdrrcpDonut extends PureComponent {
  state = {
    activeIndex: 0,
    piedata: "",
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      piedata: "",
    });
  };

  render() {
    const isDarkMode = localStorage.getItem("theme") == "dark";
    console.log("percentage", this.props.data);
    this.state.piedata = this.props.data;
    let x = 0;
    let y = 0;
    let z = 0;
    let a = 0;
    let b = 0;

    if (this.state.piedata) {
      x = this.state.piedata["year_constant"]["2.6"];
      y = this.state.piedata["year_constant"][`4.5`];
      z = this.state.piedata["year_constant"][`8.5`];
    }

    const data = [
      //{ name:'exposure',value:((b/a)*100).toFixed(2),dot:((b/a)*100).toFixed(2)},
      { name: "2.6", "RCP 2.6": x.toFixed(2), line: x.toFixed(2) },
      { name: "4.5", "RCP 4.5": y.toFixed(2), line: y.toFixed(2) },
      { name: "8.5", "RCP 8.5": z.toFixed(2), line: z.toFixed(2) },
    ];
    console.log("xyz", data);

    const COLORS = [isDarkMode ? "#ffffff" : "#002060", "#00c49f", "#ffbb28"];

    return (
      <div>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col
            md={12}
            xs={12}
            sm={12}
            className="graph"
            style={{ height: "25vh" }}
          >
            <ResponsiveBar
              data={data}
              keys={["RCP 2.6", "RCP 4.5", "RCP 8.5"]}
              indexBy="name"
              margin={{ top: 50, right: 110, bottom: 50, left: 50 }}
              padding={0.7}
              enableLabel={false}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              valueFormat={{ format: "", enabled: false }}
              colors={["#FF0000", "#0055FF", "#002B82"]}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 5,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "fries",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "sandwich",
                  },
                  id: "lines",
                },
              ]}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Representative concentration pathways(RCPs)",
                legendPosition: "start",
                legendOffset: 40,
              }}
              axisLeft={{
                tickSize: 1,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 3,
                legend: "Loss in %",
                legendPosition: "middle",
                legendOffset: -45,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 1,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>{" "}
      </div>
    );
  }
}
