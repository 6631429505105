import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form ,Select} from 'antd';
import {Icon} from 'semantic-ui-react';
import './transitiontable.css';
const EditableContext = React.createContext(null);

const { Option }=Select;
let countryoptions=[];
let sectoroptions=[];
let sector=[
    "Automobiles & Auto Parts",
    "Banking Services",
    "Beverages",
    "Chemicals",
    "Coal",
    "Communications & Networking",
    "Electric Utilities & IPPs",
    "Electronic Equipments & Parts",
    "Food & Drug Retailing",
    "Food & Tobacco",
    "Freight & Logistics Services",
    "Healthcare Equipment & Supplies",
    "Homebuilding & Construction Supplies",
    "Industrial Conglomerates",
    "Insurance",
    "Investment Banking & Investment Services",
    "Leisure Products",
    "Media & Publishing",
    "Metals & Mining",
    "Natural Gas Utilities",
    "Oil & Gas",
    "Professional & Commercial Services",
    "Real Estate Operations",
    "Renewable Energy",
    "Software & IT Services",
    "Telecommunications Services",
    "Textiles & Apparel",
    "Water Utilities"
  ]
let country=["Hondurus", "Bhutan", "Algeria", "Mexico", "Micronesia", "Morocco", "Namibia",
 "USA", "Democratic Republic of the Congo", "CostaRica", "Australia", "Liechetensteian", 
 "Colombia", "Lesotho", "Montenegro", "Ukraine", "Oman", "Cambodia", "Slovakia", "Tunisia", 
 "The republic of North Mecidonia", "Brazil", "Bangaladesh", "Cameroon", "Lebanon", "Germany", 
 "peru", "Papua New Guinea", "State of palestine", "Togo", "Cyprus", "Qatar", "Botswana",
  "Zambia", "Greece", "Eritrea", "Trinidad and Tobago", "Argentina", "Mongolia", "UK", "Serbia",
   "Bulgaria", "Kuwait", "Luxemberg", "Malaysia", "Sri Lanka", "Equatorial Gunea", "Haiti", 
   "Burundi", "Kazakhstan", "Bosnia-Herzegovina", "Samoa", "Somalia", "Mali", "france", "UAE",
    "Tuvalu", "Grenada", "India", "Lao People Democratic Republic", "Nepal", "Ghana", "Marshall",
     "Afganistan", "gambia", "Niger", "Saudi Arabia", "Brunei Darussalam", "Uraguay", "Comoros ",
      "Latvia", "Dominika", "Benin ", "Myanmar", "Guatemala", "Guinea Biisau", "Sudan", "Jamica", 
      "Chad", "Egypt", "gabon", "Kenya", "Senegal", "Belarus", "Azerbaijan", "Fiji", "Singapore", 
      "Poland", "Zimbabwe", "republic of Moldova", "Chile", "Netherlands", "Philipinnes", "Estonia", 
      "Japan", "Cabo verde", "Uganda", "Paraguay", "Syria", "Monaco", "Bahamas", "Cook Island", "Cote D'Ivoire", 
      "Eswatini", "Madagascar", "Andorra", "Czechia", "Nigeria", "gerogia", "Iceland", "DJIBOUTI", "Uzbekistan", 
      "Timor-Leste", "Armenia", "San Marino", "Ethiopia", "Lithuania", "Angola", "Saint Lucia", 
      "The united Republic of Tanzania", "Canada", "Liberia", "Mozambique", "Nauru", "Palau", "Eorupean Union", 
      "Mauritius", "Tonga", "Indonesia", "Vietnam", "South Sudan", "niue", "Vanuatu", "Barbados", "Guinea", 
      "Democratic Peoples Republic of Korea", "Ecudoe", "finland", "Switzerland", "Turkmenistan", "Slovenia", 
      "Italy", "Saint Kitts and nevis", "Kyrgizstan", "Belgium", "St Vincent and the Grenadines", "Malawi", 
      "Burkina", "Antigua & Barbuda", "Baharain", "Israel", "Bolivia", "Austria", "Jordan", "Venezuela", 
      "Portugal", "Pakistan", "Tajikistan", "New Zealand", "Spain ", "Belize ", "Hungary", "Rwanda", "Panama", 
      "China", "Croatia", "South Africa", "Ireland", "Russia", "Malta", "Maldives", "Surriname", "Kiribati", 
      "Albania ", "Sierra Leone", "Republic of Korea", "Sao Tome and Principe", "El Salvador", "Sweden", 
      "Mauritania", "Guyana", "Domonikan Republic", "Congo", "Seychelles", "Thailand", "Central African Republic", 
      "Nicaragua", "Norway", "Romania", "Cuba", "Denmark", "Soloman Islands"]

for(let i=0;i<country.length;i++){
    countryoptions.push(
        <Option value={country[i]}>{country[i]}</Option>
    )
}
for (let i=0;i<sector.length;i++){
    sectoroptions.push(
        <Option value={sector[i]}>{sector[i]}</Option>
    )
}






const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
function onSearch(val){
    console.log(val)
}

const EditableCell = ({
  title,
  editable,
  inputType,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  const inputNode = inputType === 'select_country' ? <Select ref={inputRef} showSearch onSearch={onSearch}>
 
   {countryoptions}


</Select> :inputType==='select_sector'?<Select ref={inputRef} showSearch onSearch={onSearch}>
 
 {sectoroptions}


</Select>:

<Input ref={inputRef} onPressEnter={save} onBlur={save}/>;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class TransitionTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title:'Company Name',
        dataIndex:'company_name',
        editable:true
      },
      {
        title: 'Investment (in USD)',
        dataIndex: 'investment',
        
        editable: true,
      },
      {
        title: 'Market Cap (in USD)',
        dataIndex: 'market_cap',
        editable:true
      },
      {
        title: 'Revenue (in USD)',
        dataIndex: 'revenue',
        editable:true
      },
      {
          title:'Carbon Footprint',
          dataIndex:'carbon',
          editable:true
      },
      {
        title:'Country',
        dataIndex:'country',
        editable:true
      },
      {
          title:'Sector',
          dataIndex:'sector',
          editable:true
      },
      {
        title:'NAV %',
        dataIndex:'nav',
        editable:true
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
              <Icon name="trash alternate" color="red"/>
            </Popconfirm>
          ) : null,
      },
    ];
    this.state = {
      dataSource: [
        {
          company_name:'Company',
          key: 0,
          investment:0,
          market_cap:0,
          revenue:0,
          carbon:0,
          country:'India',
          sector:'automobile',
          nav:0,
        },
        
      ],
      count: 1,
      
    };
  }

  handleRisk=(data)=>{
      console.log("data",data)
    this.props.handleRisk(data)
  }
  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };
  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      company_name:'Company',
      investment:0,
      market_cap:0,
      revenue:0,
      carbon:0,
      country:'India',
      sector:'automobile',
      nav:0
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };
  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };

  render() {

    
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          inputType:col.dataIndex ==='country'?'select_country':'text'&&col.dataIndex==='sector'?'select_sector':'text',
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Button className="rowbtn"
          onClick={this.handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add a row
        </Button>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
         
        <Button disabled={!(this.props.portfolio_value&&this.props.portfolio_value.length>0)} style={{float:'right',backgroundColor:'#002060',color:'white'}} onClick={()=>this.handleRisk(this.state.dataSource)}>Submit</Button>
      </div>
     
    );
  }
}

export default TransitionTable;