import React,{Component} from 'react';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import {connect} from 'react-redux';	
import {locus,auth} from '../actions';

import {Dropdown,Button} from 'semantic-ui-react';
import {Row,Col} from 'react-bootstrap';
import {Table} from 'antd';

const SectorOptions=[
    {key:'Apparel',value:'Apparel',text:'Apparel'},
    {key:'Oil and Gas',value:'Oil and Gas',text:'Oil and Gas'},
    {key:'Power Generation',value:'Power Generation',text:'Power Generation'},
    {key:'Financial Services',value:'Financial Services',text:'Financial Services'},
    {key:'Information',value:'Information',text:'Information'},
    {key:'Communication',value:'Communication',text:'Communication'},
    {key:'Technology',value:'Technology',text:'Technology'},
    {key:'Aviation',value:'Aviation',text:'Aviation'},
    {key:'Transport',value:'Transport',text:'Transport'}
]

const SubSectorOptions=[
  {key:'Mobile Networks',value:'Mobile Networks',text:'Mobile Networks'},
  {key:'Fixed Networks',value:'Fixed Networks',text:'Fixed Networks'},
  {key:'Data Centres',value:'Data Centres',text:'Data Centres'}
]

let Data=[];
let columns=[
  {
    title:'Company Name',
    dataIndex:'company_name',
    key:'company_name'
  },
  {
    title:'Company Sector',
    dataIndex:'company_sector',
    key:'company_sector'

  },
  {
    title:'Base year',
    dataIndex:'base_year',
    key:'base_year'
  },
  {
    title:'Target year',
    dataIndex:'target_year',
    key:'target_year'
  },
  {
    title:'Scope 1 Emission for Base Year',
    dataIndex:'scope_1',
    key:'scope_1'
  },
  {
    title:'Scope 2 Emission for Base Year',
    dataIndex:'scope_2',
    key:'scope_2'
  },
  {
    title:'Scope 3 Emission for Base Year',
    dataIndex:'scope_3',
    key:'scope_3'
  }
]

class SBTITable extends Component{
    state={
        company_name:'',
        company_sector:'',
        sub_sector:'',
        base_year:'',
        target_year:'',
        scope_1:'',
        scope_2:'',
        scope_3:0,
        sbti_data:''
        
    }
    componentDidMount(){
        this.stepper = new Stepper(document.querySelector('#stepper1'), {
            linear: true,
            animation: false
          })
    }
    handleSector=(e,{value})=>{
        this.setState({company_sector:value})
    }

    handleSubmit=(e)=>{
      let formdata=new FormData();
      formdata.append('data',JSON.stringify(this.state.sbti_data))
      this.props.getSBTI(formdata);
    }
    handleData=()=>{
      
        Data.push(
          {
            'key':0,
            'company_name':this.state.company_name,
            'company_sector':this.state.company_sector,
            'base_year':this.state.base_year,
            'target_year':this.state.target_year,
            'scope_1':this.state.scope_1,
            'scope_2':this.state.scope_2,
            'scope_3':this.state.scope_3
          }
        )
        
        if(this.state.company_sector=="Information"||this.state.company_sector=="Communication"||this.state.company_sector=="Technology"){
          Data.push([
            {
              'sub_sector':this.state.sub_sector
            }
          ])
          columns.push([
            {
              title:'Sub Sector',
              dataIndex:'sub_sector',
              key:'sub_sector'
            }
          ])
        }
        this.setState({company_name:'',company_sector:'',base_year:'',target_year:'',sub_sector:'',scope_1:'',scope_2:'',scope_3:'',sbti_data:Data},()=>{

        this.stepper = new Stepper(document.querySelector('#stepper1'), {
          linear: true,
          animation: false
        })
      })
        console.log("Data",Data,this.state.company_name)
        
        
    }

    render(){
      console.log("Output",this.props.sbti)
      let x=(this.state.company_sector=="Information"||this.state.company_sector=="Communication"||this.state.company_sector=="Technology")
    
        return(
            
            <div>
        
            <div id="stepper1" class="bs-stepper">
              <div class="bs-stepper-header">
                <div class="step" data-target="#test-l-1">
                  <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Company Details</span>
                  </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-2">
                  <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Year Details</span>
                  </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-3">
                  <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Emission Details</span>
                  </button>
                </div>
              </div>
              <div class="bs-stepper-content">
                <form onSubmit={this.onSubmit}>

                  <div id="test-l-1" class="content">
                    <div class="form-group">
                        <Row>
                            <Col>
                      <label for="exampleInputEmail1">Company Name</label>
                      <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Enter Company Name" value={this.state.company_name} onChange={(e)=>(this.setState({company_name:e.target.value}))} />
                      </Col>
                      <Col>
                      <label for="exampleInputEmail1">Company Sector</label>
                      <Dropdown placeholder="Select Company Sector"  fluid selection options={SectorOptions} value={this.state.company_sector} onChange={this.handleSector}/>
                      </Col>
                      </Row>
                      <br/>
                      <br/>
                      <Row>
                        {(x) ? <Col>
                          <Dropdown placeholder='Select Sub Sector' fluid selection options={SubSectorOptions} value={this.state.sub_sector} onChange={(e,{value})=>this.setState({sub_sector:value})}/>
                          </Col>:null}
                          <Col>
                          <button type="button" style={{float:'right'}} class="btn btn-primary" onClick={() => this.stepper.next()}>Next</button>

                          </Col>
                      </Row>
                    </div>
                  </div>
                  <div id="test-l-2" class="content">
                    <div class="form-group">
                      <Row>
                        <Col>
                      <label for="exampleInputPassword1">Base Year</label>
                      <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Base Year" value={this.state.base_year} onChange={(e)=>(this.setState({base_year:e.target.value}))}/>
                     {x?<p style={{color:'red'}}>Year should be between 2018 and 2025</p>:<p style={{color:'red'}}>Year should not be less than 2010</p>}
                      </Col>
                     {this.state.base_year? <Col>
                      <label for="exampleInputPassword2">Target Year</label>
                      <input type="text" class="form-control" placeholder="Target Year" value={this.state.target_year} onChange={(e)=>(this.setState({target_year:e.target.value}))}/>
                      {x?<p style={{color:'red'}}>Target year should be greater than equal to {parseInt(this.state.base_year)+5}</p>:<p style={{color:'red'}}>Target year should be greater than {parseInt(this.state.base_year)+5} and less than {parseInt(this.state.base_year)+15}</p>}
                      </Col>:null}
                      </Row>
                    </div>
                    <button type="button" class="btn btn-primary" style={{float:'right'}} onClick={() => this.stepper.next()}>Next</button>
                  </div>
                  <div id="test-l-3" class="content text-center">
                    <div class="form-group">
                      <Row>
                        <Col>
                          <label for="emission">Scope 1 Emission for Base Year</label>
                          <input type="text" class="form-control" value={this.state.scope_1} onChange={(e)=>(this.setState({scope_1:e.target.value}))}/>
                        </Col>
                        <Col>
                          <label for="emission">Scope 2 Emission for Base Year</label>
                          <input type="text" class="form-control" value={this.state.scope_2} onChange={(e)=>(this.setState({scope_2:e.target.value}))}/>
                        </Col>
                        <Col>
                          <label for="emission">Scope 3 Emission for Base Year</label>
                          <input type="text" class="form-control" defaultValue="0" value={this.state.scope_3} onChange={(e)=>(this.setState({scope_3:e.target.value}))}/>
                        </Col>
                      </Row>
                    </div>
                    <button type="button" class="btn btn-primary mt-5" style={{float:'right'}} onClick={this.handleData}>Add</button>
                  </div>
                </form>
              </div>
            </div>
            {this.state.sbti_data.length>0? <Row>
                          <Col lg={10} className='transitiontable'>
                          <Table dataSource={this.state.sbti_data} columns={columns}/>
                          <Button onClick={()=>this.handleSubmit()} style={{backgroundColor:'#002060',color:'white',float:'right'}}>Submit</Button>
                        </Col>
                        </Row>:null}
          </div>
        )
    }

}
const mapStateToProps=state=>{
  return{
    sbti:state.locus.sbti
  }
}
const mapDispatchToProps=dispatch=>{
  return{
    getSBTI:(formdata)=>{
      dispatch(locus.getsbti(formdata));
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(SBTITable);