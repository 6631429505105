import React from "react";
import { Dimmer, Loader, Image, Segment, Button } from "semantic-ui-react";

const Spinner = () => (
  <div>
    <Segment style={{ border: "0px", borderRadius: "0px" }}>
      <Dimmer style={{ backgroundColor: "#f5d76e" }} active>
        <Loader></Loader>
      </Dimmer>
    </Segment>
  </div>
);

export default Spinner;
