import React, { Component } from 'react';
import { Form, Button, Input, TextArea, Grid, Container, Message, Image, Header, Menu, Select, Dropdown, Icon, Table, Modal, Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Map from './hazard.js';
import { project, auth, dashboard, locus, asset } from '../actions';
import storm from '../assets/storm.png';

const latOptions=[];
let assetOptions=[];


const radiusOptions=[
{key:50,value:50,text:50},
{key:100,value:100,text:100},
{key:200,value:200,text:200},


]


class HazardDashboard extends Component {
	constructor(props) {
        super(props);
    }
    state = {
        radius:50,
        lat:22.28033243460561,
        long:114.18335676495836,
        assets:'',
        track:'',
        loss:'',
        map_condition:false,
        isLoading:false

    }
    handleChangePro = (value, key) => {
        this.setState({
            [key]: value
        },this.handleSubmitPro)

    }
    handleLat=()=>{
    	let asset=this.props.assets.filter(asset=>asset.name==this.state.assets)
    	console.log("Asset",asset,asset[0].latitude)
    	this.setState({lat:asset[0].latitude,long:asset[0].longitude},this.handleSubmitPro)
    }
    handleSubmitPro = () => {
    	this.setState({isLoading:false})
        let formdata = new FormData();
        formdata.append('radius', this.state.radius)
        formdata.append('asset_name',this.state.assets)
        

        
        this.props.GetTyphoon(formdata)

        

    }
    componentDidMount() {
        this.props.getAssets();

        console.log("hazard page chal raha hai ")
        

    }
    apicall(){

        let formdata = new FormData();
        console.log("user asset",this.props.assets)
        formdata.append('radius', this.state.radius)
        formdata.append('asset_name',this.state.assets)
        

        
        this.props.GetTyphoon(formdata)
    }
    componentDidUpdate(prevProps,prevState){
    	if (prevProps.tracks !== this.props.tracks) {
            console.log("user assets", this.props.tracks)
            this.setState({ track: this.props.tracks.Tracks,loss:this.props.tracks.loss,isLoading:true })
        }
        if(prevProps.assets!==this.props.assets){
        	this.setState({assets:this.props.assets[0].name},()=>this.apicall())
        }
    }
    render() {
    	const isDarkMode = localStorage.getItem("theme") == "dark"


    	if (!(this.state.isLoading) && isDarkMode) {
                return <div id="darkloading"></div>
            } else if (!(this.state.isLoading)) {
                return <div id="loading"></div>
            }
    	if(this.props.assets){
        	assetOptions=[];
        	for(let i=0;i<this.props.assets.length;i++){
        	assetOptions.push(
        		{key:this.props.assets[i].name,value:this.props.assets[i].name,text:this.props.assets[i].name.split(",",2)}
        	)
        	
        }
        console.log("Latitude Options",this.state.track)
        }
            return (
	<div>                    

      <Grid.Column mobile={16} tablet={16} computer={14}>
            <Grid centered style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',borderRadius:'0.75em'}}>
              <Grid.Column mobile={8} tablet={5} computer={2}>
                  <Form.Select placeholder="Radius"
                          onChange={(e,{value})=>this.handleChangePro(value,'radius')}
                          options={radiusOptions}
                          value={this.state.radius}
                          
                          style={{marginTop:'25px'}}
                          
                          
                           />
                </Grid.Column>        
                <Grid.Column mobile={8} tablet={5} computer={2}>
                          <Form.Select placeholder="Asset"
                          onChange={(e,{value})=>this.handleChangePro(value,'assets')}
                          options={assetOptions}
                          style={{marginTop:'25px'}}
                          value={this.state.assets}
                          
                          
                          />
                   </Grid.Column>
                   
                    	
         </Grid>
         </Grid.Column>
         
         <br/>
      

        <br/>
    <br/>

    {this.state.loss&&this.state.loss['Asset Storm Damage']?<Grid.Row>
    
     <Grid.Column computer={5}>
    	<div className="card">
    			<p>Asset Storm Loss</p>
    	<span style={{fontSize:'22px',color:'red',paddingTop:'5px'}}><Icon name="chart bar outline"/>$ {this.state.loss['Asset Storm Damage'][0]} Million</span>
    	</div>
    	<br/><br/>
    	<div className="card">
    			<p>Asset Typhoon Loss</p>
    	<span style={{fontSize:'22px',color:'red',paddingTop:'5px'}}><Icon name="chart bar outline"/>$ {this.state.loss['Asset Typhoon Damage'][0]} Million</span>
    	</div>
    	<br/><br/>
    	<div className="card">
    			<p>Operational Storm Loss</p>
    	<span style={{fontSize:'22px',color:'red',paddingTop:'5px'}}><Icon name="chart bar outline"/>$ {this.state.loss['Operational Storm Loss'][0]} Million</span>
    	</div>
    	<br/><br/>
    	<div className="card">
    			<p>Operational Typhoon Loss</p>
    	<span style={{fontSize:'22px',color:'red',paddingTop:'5px'}}><Icon name="chart bar outline"/>$ {this.state.loss['Operational Typhoon Loss'][0]} Million</span>
    	</div>
    	<br/><br/>
    	<div className="card">
    			<p>Number of Typhoon</p>
    	<span style={{fontSize:'22px',color:'red',paddingTop:'5px'}}><Icon name="chart bar outline"/>{this.state.track.length}</span>
    	</div>


    </Grid.Column>
        

    <Grid.Column computer={9}>
    	{this.state.track?<Map data={this.state.track}/>:null}
    </Grid.Column>
   
    </Grid.Row>:
    <Grid.Row verticalAlign="middle" style={{textAlign:'center',marginLeft:'30%'}}>
    	
    	<Grid.Column computer={16}><p>No Tracks Found</p></Grid.Column>
    </Grid.Row>
}
    
    </div>
			)
	}
}

 const mapStateToProps = state =>{
  return{
    
    assets:state.asset.asset,
    tracks:state.asset.typhoon
  }
}
const mapDispatchToProps = dispatch =>{
  return{
    getAssets:()=>{
      dispatch(asset.getAssets())
    },
    GetTyphoon:(formdata)=>{
    	dispatch(asset.Typhoon(formdata))
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(HazardDashboard)