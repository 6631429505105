import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PrivateRoute from "../private-route";
import Spinner from "./loader";
import Location from "./location";
import Project from "./project";
import Dashboard from "./dashboard";
import Download from "./download";
import HazardDashboard from "./hazarddash";
import Navigation from "./navmenu";
import Export from "./report";

import { auth } from "../actions/";
import Invoice from "./invoice";
import TCFD from "./tcfd";
import DetailedReport from "./deatiledreport";
import Application from "./pivottable";
import SideNavbar from "./oldsidebar";
import Methodology from "./methodology";
import TransitionRisk from "./transitionrisk";
import Faqs from "./faqs";
import APIDocs from "./apidocs";

class AppLayout extends Component {
  render() {
    return (
      <div>
        {/*   <Navigation />*/}
        <SideNavbar />
        <div id="container-fluid">
          <PrivateRoute path="/location" component={Location} />

          <PrivateRoute path="/project" component={Project} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          {/*  <PrivateRoute path="/transition" component={TransitionRisk}/>
            <PrivateRoute path="/download" component={Download}/>
            <PrivateRoute path="/spinner" component={Spinner}/>
            <PrivateRoute path="/report" component={Export}/>
            <PrivateRoute path="/hazard" component={HazardDashboard}/>
            <PrivateRoute path="/invoice" component={Invoice}/>
            <PrivateRoute path="/tcfd" component={TCFD}/>
            <PrivateRoute path="/detailreport" component={DetailedReport}/>
            <PrivateRoute path="/methodology" component={Methodology}/>
            <PrivateRoute path="/pivottable" component={Application}/>
            <PrivateRoute path="/faqs" component={Faqs}/>
    <PrivateRoute path="/apidocs" component={APIDocs}/>*/}
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => {};
const mapActionToProps = {};

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(AppLayout)
);
