import React, { Component } from "react";

import { Layout, Menu } from "antd";
import {
  LineChartOutlined,
  HomeOutlined,
  ProjectOutlined,
  FundProjectionScreenOutlined,
  FileExcelOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Nav, NavItem, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";

import "./sidebar.css";

class SideNavbar extends Component {
  render() {
    const { Sider } = Layout;
    let loc = window.location.href.split("/")[3];

    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {}}
        className="sidebar"
      >
        <Nav>
          <Nav.Item id="logo_sidebar">
            <img src={logo} />
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/location" className="subnav">
              <LineChartOutlined />
              Analyse
            </Nav.Link>
          </Nav.Item>
          <ul>
            <li>
              <Nav.Link
                href="/location"
                className={loc == "location" ? "ant-menu-item-selected" : null}
              >
                <FundProjectionScreenOutlined />
                Map Select
              </Nav.Link>
            </li>
            <li>
              <Nav.Link disabled>
                <FileExcelOutlined />
                File Upload
              </Nav.Link>
            </li>
            <li>
              <Nav.Link disabled>
                <DatabaseOutlined />
                Company
              </Nav.Link>
            </li>
          </ul>

          <Nav.Item>
            <Nav.Link
              href="/project"
              className={loc == "project" ? "ant-menu-item-selected" : null}
            >
              <ProjectOutlined />
              Project
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="/dashboard"
              className={loc == "dashboard" ? "ant-menu-item-selected" : null}
            >
              <HomeOutlined />
              Dashboard
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Sider>
    );
  }
}
export default SideNavbar;
