import * as React from "react";
import { useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Popup, FlyToInterpolator } from "react-map-gl";
import { Row, Button } from "react-bootstrap";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
//mapboxgl.workerClass =
//require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const WorldMap = (props) => {
  let countries = Object.keys(props.data);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 450,
    latitude: props.data[countries[0]][1][0],
    longitude: props.data[countries[0]][1][1],
    zoom: 14,
  });

  let feature = [];

  let n = Object.keys(props.data).length;

  for (let i = 0; i < n; i++) {
    let locations = [];
    for (let j = 0; j < props.data[countries[i]][0].length; j++) {
      locations.push({
        name: props.data[countries[i]][0][j].name,
        loss: props.data[countries[i]][0][j].loss,
        percentage: props.data[countries[i]][0][j].percentage,
        country: props.data[countries[i]][0][j].country,
        lat: props.data[countries[i]][0][j].latitude,
        long: props.data[countries[i]][0][j].longitude,
      });
    }

    feature.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          props.data[countries[i]][1][1],
          props.data[countries[i]][1][0],
        ],
      },
      properties: {
        address: countries[i],
        locations: locations,
      },
    });
  }

  var stores = {
    type: "FeatureCollection",
    features: feature,
  };

  /**
   * Assign a unique id to each store. You'll use this `id`
   * later to associate each point on the map with a listing
   * in the sidebar.
   */
  stores.features.forEach(function (store, i) {
    store.properties.id = i;
  });

  const listings = stores.features.map((listing, index) => {
    console.log("listing", listing.properties.address);
    return (
      <Button onClick={() => handlezoom(listing.geometry)}>
        {listing.properties.address}
      </Button>
    );
  });

  const handlezoom = (geometry) => {
    setViewport({
      ...viewport,
      zoom: 16,
      latitude: geometry.coordinates[1],
      longitude: geometry.coordinates[0],
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  let html = [];
  for (let i = 0; i < stores.features.length; i++) {
    for (let j = 0; j < stores.features[i].properties.locations.length; j++) {
      html.push(
        <tr>
          <td>
            {stores.features[i].properties.locations[j].name.slice(0, 20)}
          </td>
          <td>
            {stores.features[i].properties.locations[j].loss.toFixed(2)} mil $
          </td>
        </tr>
      );
    }
  }

  const markers = stores.features.map((marker, index) => (
    <Marker
      key={`marker-${index}`}
      longitude={marker.geometry.coordinates[0]}
      latitude={marker.geometry.coordinates[1]}
      onClick={() => handlezoom(marker.geometry)}
    ></Marker>
  ));

  const popup = stores.features.map((popup, index) => (
    <Popup
      longitude={popup.geometry.coordinates[0]}
      latitude={popup.geometry.coordinates[1]}
      anchor="top"
    >
      <div>
        <table>
          <tr>
            <td>Asset</td>
            <td>Loss ($ Mn)</td>
          </tr>
          {popup.properties.locations.map((location, index) => (
            <tr key={index}>
              <td>{location.name.slice(0, 20)}</td>
              <td>{location.loss.toFixed(2)}</td>
            </tr>
          ))}
        </table>
      </div>
    </Popup>
  ));

  return (
    <Row>
      <div id="worldlist">
        <h4>Countries</h4>
        {listings}
      </div>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="450px"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        mapboxApiAccessToken="pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ"
      >
        {markers}
        {popup}
      </ReactMapGL>
    </Row>
  );
};
export default WorldMap;
