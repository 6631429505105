import React from "react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { Progress } from "antd";

const Progress_bar = (props) => {
  let progress_bar_acute = [];
  let progress_bar_chronic = [];

  for (let i = 0; i < props.variable.length; i++) {
    if (props.data.acute[props.variable[i]] >= 0) {
      progress_bar_acute.push(
        <div className="Chronic">
          <p>
            {props.variable[i]}
            <p style={{ float: "center", padding: "1%" }}>
              {props.hazard.acute[props.variable[i]][0]}{" "}
              {props.hazard.acute[props.variable[i]][1]}
            </p>
          </p>
          {props.newdata.acute[props.variable[i]] == 0 ? (
            <Progress percent={1} className="lessrisk" />
          ) : (
            <Progress
              percent={props.newdata.acute[props.variable[i]]}
              className="lessrisk"
            />
          )}

          <Progress percent={props.hazard.acute[props.variable[i]][2]} />
        </div>
      );
    }
  }
  for (let i = 0; i < props.variable.length; i++) {
    if (props.data.chronic[props.variable[i]] >= 0) {
      progress_bar_chronic.push(
        <div className="Chronic">
          <p>
            {props.variable[i]}
            <p style={{ float: "center", padding: "1%" }}>
              {props.hazard.chronic[props.variable[i]][0]}{" "}
              {props.hazard.chronic[props.variable[i]][1]}
            </p>
          </p>
          {props.newdata.chronic[props.variable[i]] == 0 ? (
            <Progress percent={1} className="lessrisk" />
          ) : (
            <Progress
              percent={props.newdata.chronic[props.variable[i]]}
              className="lessrisk"
            />
          )}

          <Progress percent={props.data.chronic[props.variable[i]][2]} />
        </div>
      );
    }
  }

  return (
    <>
      {props.data.both ? (
        <div>
          {props.variable.indexOf("Rainfall Flood") > -1 ? (
            <div className="Progress">
              {" "}
              <p>
                Rainfall Flood
                <p>
                  {props.hazard.both["Rainfall Flood"][0]}{" "}
                  {props.hazard.both["Rainfall Flood"][1]} /
                  {props.hazard.both["Rainfall Flood"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Rainfall Flood"][2] > 0 &&
                    props.hazard.both["Rainfall Flood"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Rainfall Flood"][2] >= 25 &&
                    props.hazard.both["Rainfall Flood"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Rainfall Flood"][2] >= 50 &&
                    props.hazard.both["Rainfall Flood"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Rainfall Flood"][2] >= 75 &&
                    props.hazard.both["Rainfall Flood"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Rainfall Flood"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Rainfall Flood"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Rainfall Flood"]}
                  className="lessrisk"
                />
              )}
              <Progress percent={props.hazard.both["Rainfall Flood"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("Typhoon") > -1 ? (
            <div className="Progress">
              <p>
                Typhoon
                <p>
                  {props.hazard.both["Typhoon"][0]}{" "}
                  {props.hazard.both["Typhoon"][1]} /
                  {props.hazard.both["Typhoon"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Typhoon"][2] > 0 &&
                    props.hazard.both["Typhoon"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Typhoon"][2] >= 25 &&
                    props.hazard.both["Typhoon"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Typhoon"][2] >= 50 &&
                    props.hazard.both["Typhoon"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Typhoon"][2] >= 75 &&
                    props.hazard.both["Typhoon"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Typhoon"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Typhoon"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Typhoon"]}
                  className="lessrisk"
                />
              )}

              <Progress
                percent={props.hazard.both["Typhoon"][2]}
                className="risk"
              />
            </div>
          ) : null}

          {props.variable.indexOf("Storm Surge") > -1 ? (
            <div className="Progress">
              {" "}
              <p>
                Storm Surge
                <p>
                  {props.hazard.both["Storm Surge"][0]}{" "}
                  {props.hazard.both["Storm Surge"][1]}/
                  {props.hazard.both["Storm Surge"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Storm Surge"][2] > 0 &&
                    props.hazard.both["Storm Surge"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Storm Surge"][2] >= 25 &&
                    props.hazard.both["Storm Surge"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Storm Surge"][2] >= 50 &&
                    props.hazard.both["Storm Surge"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Storm Surge"][2] >= 75 &&
                    props.hazard.both["Storm Surge"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Storm Surge"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Storm Surge"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Storm Surge"]}
                  className="lessrisk"
                />
              )}
              <Progress percent={props.hazard.both["Storm Surge"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("River Flood") > -1 ? (
            <div className="Progress">
              <p>
                River Flood
                <p>
                  {props.hazard.both["River Flood"][0]}{" "}
                  {props.hazard.both["River Flood"][1]} /
                  {props.hazard.both["River Flood"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["River Flood"][2] > 0 &&
                    props.hazard.both["River Flood"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["River Flood"][2] >= 25 &&
                    props.hazard.both["River Flood"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["River Flood"][2] >= 50 &&
                    props.hazard.both["River Flood"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["River Flood"][2] >= 75 &&
                    props.hazard.both["River Flood"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["River Flood"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["River Flood"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["River Flood"]}
                  className="lessrisk"
                />
              )}

              <Progress percent={props.hazard.both["River Flood"][2]} />
            </div>
          ) : null}

          <br />
          {props.variable.indexOf("Landslide") > -1 ? (
            <div className="Progress">
              <p style={{ fontSize: "13px" }}>
                LandSlide
                <p style={{ float: "center", padding: "1%" }}>
                  {props.hazard.both["Landslide"][0]}{" "}
                  {props.hazard.both["Landslide"][1]} /
                  {props.hazard.both["Landslide"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Landslide"][2] > 0 &&
                    props.hazard.both["Landslide"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Landslide"][2] >= 25 &&
                    props.hazard.both["Landslide"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Landslide"][2] >= 50 &&
                    props.hazard.both["Landslide"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Landslide"][2] >= 75 &&
                    props.hazard.both["Landslide"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Landslide"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              <Progress percent={props.hazard.both["Landslide"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("Extreme heat") > -1 ? (
            <div className="Progress">
              <p>
                Extreme Heat
                <p>
                  {props.hazard.both["Extreme heat"][0]}{" "}
                  {props.hazard.both["Extreme heat"][1]}/
                  {props.hazard.both["Extreme heat"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Extreme heat"][2] > 0 &&
                    props.hazard.both["Extreme heat"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Extreme heat"][2] >= 25 &&
                    props.hazard.both["Extreme heat"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Extreme heat"][2] >= 50 &&
                    props.hazard.both["Extreme heat"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Extreme heat"][2] >= 75 &&
                    props.hazard.both["Extreme heat"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Extreme heat"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Extreme heat"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Extreme heat"]}
                  className="lessrisk"
                />
              )}

              <Progress percent={props.hazard.both["Extreme heat"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("Snow Melt") > -1 ? (
            <div className="Progress">
              <p>
                SnowMelt
                <p>
                  {props.hazard.both["Snow Melt"][0]}{" "}
                  {props.hazard.both["Snow Melt"][1]} /
                  {props.hazard.both["Snow Melt"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Snow Melt"][2] > 0 &&
                    props.hazard.both["Snow Melt"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Snow Melt"][2] >= 25 &&
                    props.hazard.both["Snow Melt"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Snow Melt"][2] >= 50 &&
                    props.hazard.both["Snow Melt"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Snow Melt"][2] >= 75 &&
                    props.hazard.both["Snow Melt"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Snow Melt"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Snow Melt"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Snow Melt"]}
                  className="lessrisk"
                />
              )}

              <Progress percent={props.hazard.both["Snow Melt"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("Sea Level Rise") > -1 ? (
            <div className="Progress">
              <p>
                Sea Level Rise
                <p>
                  {props.hazard.both["Sea Level Rise"][0]}{" "}
                  {props.hazard.both["Sea Level Rise"][1]} /
                  {props.hazard.both["Sea Level Rise"][2] > 0 == 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Sea Level Rise"][2] > 0 &&
                    props.hazard.both["Sea Level Rise"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Sea Level Rise"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Sea Level Rise"]}
                  className="lessrisk"
                />
              )}

              <Progress percent={props.hazard.both["Sea Level Rise"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("Drought") > -1 ? (
            <div className="Progress">
              <p>
                Drought
                <p>
                  {props.hazard.both["Drought"][0]}{" "}
                  {props.hazard.both["Drought"][1]} /
                  {props.hazard.both["Drought"][2] <= 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Drought"][2] > 0 &&
                    props.hazard.both["Drought"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Drought"][2] >= 25 &&
                    props.hazard.both["Drought"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Drought"][2] >= 50 &&
                    props.hazard.both["Drought"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Drought"][2] >= 75 &&
                    props.hazard.both["Drought"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Drought"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Drought"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Drought"]}
                  className="lessrisk"
                />
              )}

              <Progress percent={props.hazard.both["Drought"][2]} />
            </div>
          ) : null}

          {props.variable.indexOf("Wild Fire") > -1 ? (
            <div className="Progress">
              <p>
                Wild Fire
                <p>
                  {props.hazard.both["Wild Fire"][0]}{" "}
                  {props.hazard.both["Wild Fire"][1]} /
                  {props.hazard.both["Wild Fire"][2] <= 0 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      No Risk
                    </span>
                  ) : props.hazard.both["Wild Fire"][2] > 0 &&
                    props.hazard.both["Wild Fire"][2] < 25 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Low
                    </span>
                  ) : props.hazard.both["Wild Fire"][2] >= 25 &&
                    props.hazard.both["Wild Fire"][2] < 50 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium
                    </span>
                  ) : props.hazard.both["Wild Fire"][2] >= 50 &&
                    props.hazard.both["Wild Fire"][2] < 75 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Medium-High
                    </span>
                  ) : props.hazard.both["Wild Fire"][2] >= 75 &&
                    props.hazard.both["Wild Fire"][2] < 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      High
                    </span>
                  ) : props.hazard.both["Wild Fire"][2] >= 100 ? (
                    <span style={{ color: "#white", fontSize: "13px" }}>
                      Very High
                    </span>
                  ) : null}
                </p>
              </p>
              {props.newdata.both["Wild Fire"] == 0 ? (
                <Progress percent={1} className="lessrisk" />
              ) : (
                <Progress
                  percent={props.newdata.both["Wild Fire"]}
                  className="lessrisk"
                />
              )}

              <Progress percent={props.hazard.both["Wild Fire"][2]} />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Progress_bar;
