import React  from 'react'
import './loader.css';
import $ from 'jquery';

import {Image} from 'semantic-ui-react';



let counter=0;
class PortfolioSpinner extends React.Component{

setInterval=()=>{
	if(counter==6){
		counter=0;
	}
	this.changeImage(counter);
	this.changeText(counter);
	counter++;


}
changeImage=(counter)=>{
	let images=[
	
	'<Image src="./assets/graph.png" />',
	'<Image src="./assets/hurricane.png" size="mini"/>',
	'<Image src="./assets/rain.png" size="mini"/>',
	'<Image src="./assets/storm.png" size="mini"/>',
	'<Image src="./assets/sea-level.png" size="mini"/>',
	'<Image src="./assets/flooding.png" size="mini"/>',
	
	]
	$(".loader .image").html(""+ images[counter] +"");
}
changeText=(counter)=>{
	let text=[
		'<p>Creating Portfolio</p>',
		'<p>Mapping Dashboard</p>',
		'<p>Calculating Risk</p>',
		'<p>Analysing Asset</p>',
		'<p>Preparing Dashboard</p>',
		'<p>Loading....</p>'
	]
	$(".loader p").html(""+text[counter]+"");
}

loading=()=>{
	let num=0;
	 for( let i=0; i<=100; i++) {
    setTimeout(function() { 
      $('.loader span').html(num+'%');

      if(num == 100) {
        
      }
      num++;
    },i*3600);
  };
}

componentDidMount(){
	this.interval = setInterval(this.setInterval,3000)
	this.loading();
}




render(){

	return(
  <div class="loader">
  <div class="image">
    	<Image src="./assets/flooding.png"/>
  </div>
  <p></p>
  <span></span>
</div>
)

}
}

export default PortfolioSpinner;