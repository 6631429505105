import React,{Component} from 'react';
import {connect} from 'react-redux';
import {locus,auth} from '../actions';
import TransitionTable from './transitiontable';
import {Row,Col,Container } from 'react-bootstrap';
import {Table} from 'antd';

const columns=[
    {
      title:'Company Name',
      key:'company_name',
      dataIndex:'company_name'

    },
    {
    title:'Portfolio Claim On Revenue',
    dataIndex:'portfolio_claim_on_revenue',
    key:'portfolio_claim_on_revenue'
    },
    {
      title:'Carbon FootPrint',
      dataIndex:'carbon_footprint',
      key:'carbon_footprint'
    },
    {
      title:'Weighted Average Carbon Intensity',
      dataIndex:'weighted_average_carbon_intensity',
      key:'weighted_average_carbon_intensity'
    },
    {
      title:'Total Carbon Emission Ownership Weight on Market Cap',
      dataIndex:'total_carbon_emmision_ownership_weight_on_market_cap',
      key:'total_carbon_emmision_ownership_weight_on_market_cap'
    },
    {
      title:'Carbon Intensity',
      dataIndex:'carbon_intensity',
      key:'carbon_intensity'
    }


  ]


class TransitionRisk extends Component{

    state={
        portfolio_value:'',
        portfolio_name:''
    }

    handleTransition=(data)=>{
        let formdata=new FormData();
        formdata.append('portfolio_value',this.state.portfolio_value)
        formdata.append("values",JSON.stringify(data))
        this.props.getTransition(formdata)
      }
    
      

    render(){

    return(
      <Container fluid>
    <Row>
    <Col lg={12} className="transitiontable">
      <h2>Input Data</h2>
      <Row>
        <Col lg={8}></Col>
     <Col lg={2}>           <div>
       <label>Portfolio Value</label>
       <input type="text" class="form-control" placeholder="Portfolio Value" value={this.state.portfolio_value} onChange={(e)=>this.setState({portfolio_value:e.target.value})}/>
       </div>
     </Col>
     <Col lg={2}>
       <div>
       <label>Portfolio Name</label>
       <input type="text" class="form-control" placeholder="Portfolio Name" value={this.state.portfolio_name} onChange={(e)=>this.setState({portfolio_name:e.target.value})}/>
     </div>
     </Col>
     </Row>
     
  <TransitionTable handleRisk={this.handleTransition} portfolio_value={this.state.portfolio_value}/>

  </Col>

 
  </Row>


<Row>
<Col lg={10} className="transition">

{this.props.transition.length>0?
<><h2>Output Data</h2><Table columns={columns} dataSource={this.props.transition}/></>:null}
</Col>
</Row>
</Container>)
    

}
}
const mapStateToProps = state =>{
	
	return{
	errors:state.locus.errors,	
    transition:state.locus.transition
		
	}

}
const mapDispatchToProps = dispatch =>{
	return{

    getTransition:(formdata)=>{
      dispatch(locus.getTransition(formdata))
    }
	}
}   

export default connect(mapStateToProps,mapDispatchToProps)(TransitionRisk);