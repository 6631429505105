const initialState ={
	locus:[],
	errors:{},
	isLoading:true,
	assets:[],
	permissions:[],
	name:[],
	point:[],
	indices:[],
	index:[],
	transition:[],
	sbti:[],
};

export default function locus(state=initialState,action){
	switch(action.type){
		case 'LOCATION_ADDING':
			return {...state,isLoading:true}
		case 'ADD_LOCATION':
			localStorage.setItem("variables", JSON.stringify(action.locus.variables));
			localStorage.setItem("property_types", JSON.stringify(action.locus.property_types));

			return {...state,locus:action.locus,isLoading:false};
		case 'ADD_INDEX':
			return {...state,index:action.index,isLoading:false}
		case 'GET_TRANSITION':
			return {...state,transition:action.transition,isLoading:false}
		case 'GET_SBTI':
			return {...state,sbti:action.sbti,isLoading:false}
		case 'ADD_NONBUILDING':
			return {...state,locus:action.locus,isLoading:false};
		case 'POST_INDICES':
			return {...state,indices:action.indices};
		case 'FETCH_LOCATION':
			return {...state,locus:action.locus,isLoading:true};
		case 'FETCH_COORDINATES':
			return {...state,point:action.point};
		case 'FETCH_SEARCH':
			return {...state,name:action.name}
		case 'FETCH_PERMISSION':
			return {...state,permissions:action.permissions}
		case 'FETCH_ASSET':
			return {...state,assets:action.assets}
		case 'AUTHENTICATION_ERROR':
		case 'LOCATION_FAIL':
			return {...state,errors:action.data,locus:null,isLoading:false};
		case 'PERMISSION_FAIL':
			return {...state,errors:action.data,permissions:null}
		case 'SEARCH_FAIL':
			return {...state,errors:action.data,name:null}
		case 'NONBUILDING_FAIL':
			return{...state,errors:action.data,locus:null,isLoading:false}
		case 'COORDINATES_FAIL':
			return {...state,errors:action.data,point:null}
		case 'INDICES_FAIL':
			return {...state,errors:action.data,indices:null}
		case 'INDEX_FAIL':
			return {...state,errors:action.data,index:null}
		case 'SBTI_FAIL':
			return {...state,errors:action.data,index:null}
		case 'TRANSITION_FAIL':
			return {...state,errors:action.data,transition:null}
		default:
			return state;
	}
}