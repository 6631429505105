import React,{Component} from 'react';
import { ReCaptcha } from 'react-recaptcha-google'; 
import  {Redirect } from 'react-router-dom';
import signup from '../assets/signup.png';
import logo from '../assets/logo1.png';
import {connect} from 'react-redux';
import {auth} from '../actions';
import Zoom from 'react-reveal/Zoom';
import PropTypes from 'prop-types';
import Spinner from './spinner';
import './register.css';
import {Container,Row,Col,Image,Form,Button} from 'react-bootstrap';
import login_overlay from '../assets/login_overlay.png';




function ValidationMessage(props) {
  if (!props.valid) {
    return(
      <div className='error-msg'>{props.message}</div>
    )
  }
  return null;
}





class Register extends Component{

	componentDidMount(){
	if(this.captchaDemo){
		this.captchaDemo.reset();
		
	}
}
onLoadRecaptcha(){
	if(this.captchaDemo){
		this.captchaDemo.reset();
		
	}
}


	state={
		first_name:'',
		last_name:'',
		username:'',
		email:'',
		password:'',
		confirm_password:'',
		company_name:'',
		passwordConfirmValid:false,
		formValid:false,
		errorMsg:{},
		loading:false
	};

	validateForm=()=>{
		const {passwordConfirmValid} = this.state
		this.setState({
			formValid:passwordConfirmValid
		})
	}

    updatePasswordConfirm = (confirm_password) => {
   	
    this.setState({confirm_password}, this.validatePasswordConfirm)
    }

    validatePasswordConfirm = () => {
    const {confirm_password, password} = this.state;
    let passwordConfirmValid = true;
    let errorMsg = {...this.state.errorMsg}

    if (password !== confirm_password) {
      passwordConfirmValid = false;
      errorMsg.confirm_password = 'Passwords do not match'
      
    }

    this.setState({passwordConfirmValid, errorMsg}, this.validateForm);
  }
	onSubmit = e =>{
		e.preventDefault();
		this.setState({loading:true},()=>{
		this.props.register(this.state.first_name,this.state.last_name,this.state.username,this.state.email,this.state.password,this.state.confirm_password,this.state.company_name)});
	}
	
	render(){
			if(auth.isUserAuthenticated()){
					return <Redirect to="/location"/>
				}
				
		return(
		<Container fluid id="login">	
		<Container className="vertical-center">
		<Row className="login_row">	

		<Col lg={1}></Col>
		 <Col lg={4}>
		 	
		 	
		 	<h1>Create Account</h1>
		 	{ (this.props.errors.length>0) ?  <div className="row" style={{textAlign:"center"}}>
                  <span style={{color:'red'}}>{this.props.errors[0].message}</span>
                </div> : null}
		 	<br/>
			<Form>
				<Form.Group>
					<Form.Label>First Name</Form.Label>
					<Form.Control 

					 id="form-input-control-first-name"
					 type="name"
					 
					 
					 value={this.state.first_name}
					 onChange={e=>this.setState({first_name:e.target.value})}

					 />
					 <span class="enter"></span>
					<Form.Label>Last Name</Form.Label>
					<Form.Control 
					 id="form-input-control-last-name"
					 type="name"
					 
					 
					 value={this.state.last_name}
					 onChange={e=>this.setState({last_name:e.target.value})}
					 />
				</Form.Group>
				<Form.Label>Username</Form.Label>
				<Form.Control
					 id="form-input-control-username"
					 type="name"
					 
					 
					 value={this.state.username}
					 onChange={e=>this.setState({username:e.target.value})}
					 />
				<Form.Label>Email</Form.Label>
				<Form.Control
					 id="form-input-control-email"
					 type="email"
					 
					 
					 value={this.state.email}
					 onChange={e=>this.setState({email:e.target.value})}
					 
					 />
				< ValidationMessage valid={this.state.passwordConfirmValid} message={this.state.errorMsg.confirm_password} />
				<Form.Group>
					<Form.Label>Password</Form.Label>
				<Form.Control 
					 id="form-input-control-password"
					 
					 
					 type="password"
					 
					 value={this.state.password}
					 onChange={e=>this.setState({password:e.target.value})}
					 />
					 <Form.Label>Confirm Password</Form.Label>
				<Form.Control
					 id="form-input-control-confirm-password"
					 
					 
					 type="password"
					 
					 value={this.state.confirm_password}
					 onChange={e=>this.updatePasswordConfirm(e.target.value)}
					 />
				
				</Form.Group>
				<Form.Label>Company Name</Form.Label>
				<Form.Control
					 id="form-input-control-company"
					 type="name"
					 
					 
					 value={this.state.company_name}
					 onChange={(e)=>this.setState({company_name:e.target.value})}
					 />
					 <br/>
		
		{(this.state.loading && this.props.errors.length==0)?<Button className="login_button"><Spinner/></Button>:
				<div className="d-grid gap-2"><Button  className="login_button"  disabled={!this.state.formValid} onClick={this.onSubmit} variant="secondary" size="lg" >Register</Button></div>}
			</Form>

			<br/>
				Already have an Account ? <a href="/login">Login</a>
			
		</Col>
		<Col lg={2}></Col>
		<Col lg={5} id="login_overlay" >
			<Image src={login_overlay}/>
		</Col>
		</Row>
		</Container>
		</Container>
			)
	}
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    register: (first_name,last_name,username, email,password ,confirm_password,company_name) => dispatch(auth.register(first_name,last_name,username,email, password ,confirm_password,company_name)),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Register);