import React from 'react';
import ReactDOM from 'react-dom';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import { Header, Icon, Menu, Label, Button, Grid, Radio, Image, Form, Input, Modal, Popup, Select, Progress, Table, Checkbox, Accordion, Dropdown } from 'semantic-ui-react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import WebDataRocks from 'webdatarocks';

let data=[];
class Application extends React.Component {
    constructor(props) {
        super(props);
        
    }
    componentDidMount(){
    console.log("pivottable",this.props.location)
      //data=this.props.location.state.data;
        var pivot = new WebDataRocks({
        container: "#wdr-component",
        toolbar: true,
        report: {
            dataSource: {
                data:this.props.location.state.data
            },
            options: {
            grid: {
              type: "flat",
              showTotals: "off",
              showGrandTotals: "off",
            },
        },
      }
    });

    }
  
    render() {
 
      console.log("pivottable",this.props.location.state)
        return (
          
              <Grid verticalAlign='middle' centered style={{marginTop:'5%'}}>
              <Grid.Row>
              <Grid.Column computer={1}></Grid.Column>
              <Grid.Column computer={13}>
              <div id="wdr-component"></div>
              </Grid.Column>
              </Grid.Row>
              </Grid>

              
        );
    }
}

export default Application;

